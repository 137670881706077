import React, {useContext} from 'react';
import VideoWatchLogType from "../../interfaces/VideoWatchLogType";
import styled, {ThemeContext} from "styled-components";
import ProgressBar from "../ProgressBar";
import {BodyTextBox, ContentWrap, TitleTextBox} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";
import videoBackground from '../../assets/video_list_bg.png';
import TouchableOpacity from "../TouchableOpacity";
import Image from "../Image";
import nl2br from "../../utils/nl2br";

const Background = styled.div`
  border-bottom-color: ${({theme}) => theme.borderColor};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-image: url(${videoBackground});
  background-size: cover;
`;

const Section = styled(TouchableOpacity)`
  padding: 16px;
`;

const VideoInformationBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VideoThumbnail = styled.div`

`;

const ImageBox = styled.div`
  width: 150px;
  height: 84px;
  overflow: hidden;
`;

const ThumbnailImage = styled(Image)`
  width: 150px;
`;

const Title = styled(TitleTextBox)`
  font-size: 20px;
  font-weight: bold;
  margin-left: 8px;
  margin-right: auto;
  color: ${({theme}) => theme.mainColor};
`;

const Description = styled(BodyTextBox)`
  margin-top: 8px;
  color: ${({theme}) => theme.textGrayColor};
`;

interface Props {
    log: VideoWatchLogType;
    onPress: () => void;
}

const VideoWatchHistoryListItem: React.FC<Props> = ({log, onPress}) => {
    const theme = useContext(ThemeContext);
    return (
        <Background>
            <ContentWrap>
                <Section onClick={onPress}>
                    <VideoInformationBox>
                        <VideoThumbnail>
                            <ImageBox>
                                <ThumbnailImage src={log.video.thumbnail_url}/>
                            </ImageBox>
                            <ProgressBar max={log.duration} value={log.position}/>
                        </VideoThumbnail>
                        <Title>{log.video.name}</Title>
                        <ArrowIcon color={theme.subColor} size={20}/>
                    </VideoInformationBox>
                    <Description>{nl2br(log.video.description ?? '')}</Description>
                </Section>
            </ContentWrap>
        </Background>
    )
};

export default VideoWatchHistoryListItem;
