import React from 'react';
import styled from "styled-components";
import headerImage from '../../assets/header.png';

const SafeArea = styled.nav`
  background-color: white;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;

const Header = styled.div`
  padding: 16px;
  background-color: white;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  height: 25px;
  width: 94px;
`;

const NoAuthHeader: React.FC = () => {
    return (
        <SafeArea>
            <Header>
                <Icon src={headerImage}/>
            </Header>
        </SafeArea>
    )
};

export default NoAuthHeader;
