import JoinRequestType from "../interfaces/JoinRequestType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import {fetchChatGroupUsers} from "./chat_group_user";

export type JoinRequestState = {
    items: JoinRequestType[];
    isFetching: boolean;
    selectGroupId?: number;
}

const initialState: JoinRequestState = {
    items: [],
    isFetching: false,
    selectGroupId: undefined,
}

export const fetchJoinRequests = createAsyncThunk<FetchReturnType<JoinRequestType>, any>(
    'fetch/join_request',
    async (param) => {
        const {data} = await axios.get(`chat_groups/${param}/join`);
        return data as FetchReturnType<JoinRequestType>;
    }
);

type AllowDenyRequest = {
    chatGroupId: any,
    requestId: number,
}

export const allowJoinRequest = createAsyncThunk<void, AllowDenyRequest>(
    'allow/join_request',
    async (param, thunk) => {
        await axios.post(`join/${param.requestId}`);
        thunk.dispatch(fetchJoinRequests(param.chatGroupId));
        thunk.dispatch(fetchChatGroupUsers(param.chatGroupId));
    }
);

export const denyJoinRequest = createAsyncThunk<void, AllowDenyRequest>(
    'deny/join_request',
    async (param, thunk) => {
        await axios.delete(`join/${param.requestId}`);
        thunk.dispatch(fetchJoinRequests(param.chatGroupId));
    }
);

export const joinRequestSlice = createSlice({
    name: 'join_request',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchJoinRequests.fulfilled, (state, action) => ({
                ...state,
                items: action.payload.data,
                isFetching: false,
                selectGroupId: action.meta.arg
            }))
            .addCase(fetchJoinRequests.pending, (state, action) => {
                if (action.meta.arg === state.selectGroupId) {
                    return {
                        ...state,
                        isFetching: true
                    }
                } else {
                    return {
                        ...state,
                        items: [],
                        isFetching: true,
                    }
                }
            })
            .addCase(fetchJoinRequests.rejected, state => ({
                ...state,
                isFetching: false,
                items: []
            }))
    }
})