import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import UserType from "../interfaces/UserType";
import axios from "../http/axios";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import toastr from "toastr";
import imageCompress from "../utils/imageCompress";

export type UserState = {
    user: UserType | null;
    isProcessing: boolean;
    errors: Object;
};

const initialState: UserState = {
    user: null,
    isProcessing: false,
    errors: {}
};

export const fetchUser = createAsyncThunk<FetchDetailReturnType<UserType>>(
    'user/fetch',
    async () => {
        const {data} = await axios('user');
        return data as FetchDetailReturnType<UserType>;
    }
);

export const uploadImage = createAsyncThunk<void, File>(
    'user/upload',
    async (file, thunk) => {
        try {
            const formData = new FormData();
            const compress = await imageCompress(file);
            formData.append('file', compress);
            await axios.post('user/profile', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            });
            thunk.dispatch(fetchUser());
        } catch (e) {
        }
    }
);

export const updateUser = createAsyncThunk<void, any>(
    'user/update/sns',
    async (arg, thunk) => {
        try {
            await axios.put('user/sns', arg);
            thunk.dispatch(fetchUser());
            toastr.success('表示設定を更新しました');
        } catch (e) {
            console.log(e);
            toastr.error('プロフィールの更新に失敗しました。');
            thunk.dispatch(setErrors(e.response?.data?.errors || {}));
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setErrors: (state, action: PayloadAction<Object>) => ({
            ...state,
            errors: action.payload
        })
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUser.fulfilled, (state, action) => ({
                ...state,
                user: action.payload.data,
            }))
            .addCase(fetchUser.pending, state => ({...state}))
            .addCase(fetchUser.rejected, state => ({...state, user: null}))
            .addCase(uploadImage.fulfilled, state => ({
                ...state,
                isProcessing: false
            }))
            .addCase(uploadImage.rejected, state => ({
                ...state,
                isProcessing: false
            }))
            .addCase(uploadImage.pending, state => ({
                ...state,
                isProcessing: true
            }))
            .addCase(updateUser.fulfilled, state => ({
                ...state,
                isProcessing: false,
            }))
            .addCase(updateUser.rejected, state => ({
                ...state,
                isProcessing: false
            }))
            .addCase(updateUser.pending, state => ({
                ...state,
                isProcessing: true,
                errors: {}
            }))
    }
});

export const {setErrors} = userSlice.actions;
