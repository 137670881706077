import CategoryType from "../interfaces/CategoryType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type CategoryDetailState = {
    item: CategoryType | null;
    isFetching: boolean;
};

const initialState: CategoryDetailState = {
    item: null,
    isFetching: false
};

export const fetchCategoryDetail = createAsyncThunk<FetchDetailReturnType<CategoryType>, any>(
    'category.detail',
    async (param) => {
        const {data} = await axios.get(`categories/${param}`);
        return data as FetchDetailReturnType<CategoryType>;
    }
);

export const categoryDetailSlice = createSlice({
    name: 'category_detail',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCategoryDetail.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                item: action.payload.data
            }))
            .addCase(fetchCategoryDetail.rejected, state => ({
                ...state,
                isFetching: false,
            }))
            .addCase(fetchCategoryDetail.pending, state => ({
                ...state,
                isFetching: true,
                item: null
            }))
    }
})

