import React, {useCallback, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {fetchUser, updateUser, uploadImage} from "../../stores/user";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {ContentWrap} from "../../parts/AppStyles";
import ChatProfileEditHeader from "../../parts/ChatProfileEdit/ChatProfileEditHeader";
import SnsHeader from "../../parts/SnsTab/SnsHeader";
import ChatProfileEditForm, {EditParams} from "../../parts/ChatProfileEdit/ChatProfileEditForm";
import {useHistory} from "react-router-dom";

const ChatProfileEdit: React.FC = () => {

    const {user} = useSelector(state => ({
        user: state.user,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const [oldProcessing, setOldProcessing] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    const onSubmit = useCallback(async (params: EditParams) => {
        if (!user.user) {
            return;
        }
        if (params.file) {
            await dispatch(uploadImage(params.file));
        }
        dispatch(updateUser({
            industry: params.industry,
            nickname: params.nickname,
            pref: params.pref,
            introduction: params.introduction,
        }));
    }, [dispatch, user.user]);

    useEffect(() => {
        if (oldProcessing && !user.isProcessing && Object.keys(user.errors).length === 0) {
            history.push('/sns/users');
        }
        setOldProcessing(user.isProcessing);
    }, [history, oldProcessing, user.errors, user.isProcessing]);

    const onCancel = useCallback(() => {
        history.goBack();
    }, [history]);

    if (!user.user) {
        return <WithLoadingScreen loading={true}/>;
    }

    return (
        <>
            <Helmet>
                <title>SNSプロフィール編集</title>
            </Helmet>
            <SnsHeader user={user.user} canGoBack={true}/>
            <ContentWrap>
                <ChatProfileEditHeader/>
            </ContentWrap>
            <ChatProfileEditForm onSubmit={onSubmit}
                                 onCancel={onCancel}
                                 user={user.user}
                                 isProcessing={user.isProcessing}
                                 errors={user.errors}/>
        </>
    );
};

export default ChatProfileEdit;