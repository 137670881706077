import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {useDispatch} from "react-redux";
import ChatMessageType from "../../interfaces/ChatMessageType";
import {deleteChatMessage, editChatMessage, sendChatMessage} from "../../stores/chat_message";
import ChatResponseForm from "./ChatResponseForm";
import ChatMessageHeader from "./ChatMessageHeader";
import ChatCommentButtonBox from "./ChatCommentButtonBox";
import ChatMessageImage from "./ChatMessageImage";
import ChatMessageText from "./ChatMessageText";
import ChatMessageReport from "../../screens/Main/ChatMessageReport";
import SendChatMessage from "../../screens/Main/SendChatMessage";
import DeleteChatMessage from "../../screens/Main/DeleteChatMessage";
import {sendReaction} from "../../stores/send_reaction";
import ReactionType from "../../interfaces/ReactionType";
import {useHistory} from "react-router-dom";
import {pinChatMessage} from "../../stores/chat_message_pin";
import ChatPinView from "./ChatPinView";

const Section = styled.div`
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 2px;
  margin: 8px 0 16px;
  padding: 0;
`;

interface Props {
    message: ChatMessageType;
    showPin: boolean;
}

const ChatMessage: React.FC<Props> = ({message, showPin}) => {

    const [showForm, setShowForm] = useState<boolean>(false);
    const [reportMessage, setReportMessage] = useState<ChatMessageType | null>(null);
    const [showPost, setShowPost] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const onComment = useCallback((chatMessage: ChatMessageType, text: string) => {
        dispatch(sendChatMessage({
            text: text,
            parentId: chatMessage.id,
            groupId: message.group.id,
            uploadFile: null,
        }));
    }, [dispatch, message]);

    const onEdit = useCallback(() => {
        setShowPost(true);
        setShowDelete(false);
    }, []);

    const onPost = useCallback((text: string, uploadFile: File | null) => {
        setShowPost(false);
        dispatch(editChatMessage({
            id: message.id,
            groupId: message.group.id,
            text,
            uploadFile
        }));
    }, [dispatch, message]);

    const onDelete = useCallback(async () => {
        setShowDelete(true);
    }, []);

    const onDeleteExec = useCallback(() => {
        setShowDelete(false);
        dispatch(deleteChatMessage(message));
    }, [dispatch, message])

    const onPin = useCallback(() => {
        dispatch(pinChatMessage(message));
    }, [dispatch, message]);

    const onReport = useCallback((chatMessage: ChatMessageType) => {
        setReportMessage(chatMessage);
    }, []);

    const onReaction = useCallback((reaction?: ReactionType) => {
        dispatch(sendReaction({
            chatMessage: message,
            reactionId: reaction?.id,
            groupId: message.group.id
        }));
    }, [dispatch, message]);

    const onShowReactions = useCallback(() => {
        history.push(`/sns/reactions/${message.id}`);
    }, [history, message]);

    const onShowChatDetail = useCallback(() => {
        history.push(`/sns/chat_messages/${message.id}`);
    }, [history, message.id]);

    return (
        <>
            <ChatPinView message={message} show={showPin}/>
            <Section>
                <ChatMessageHeader message={message}
                                   onEdit={onEdit}
                                   onDelete={onDelete}
                                   onPin={onPin}
                                   onReport={onReport}/>
                <ChatMessageText message={message}/>
                <ChatMessageImage message={message}/>
                <ChatCommentButtonBox message={message}
                                      onShowComment={onShowChatDetail}
                                      onReaction={onReaction}
                                      showForm={showForm}
                                      onShowForm={setShowForm}
                                      onShowReactions={onShowReactions}/>
                <ChatResponseForm parentId={message.id}
                                  isShow={showForm}
                                  onSubmit={text => onComment(message, text)}/>
                <ChatMessageReport
                    message={reportMessage}
                    onClose={() => setReportMessage(null)}/>
                <SendChatMessage show={showPost}
                                 editItem={message}
                                 onClose={() => setShowPost(false)}
                                 onPost={onPost}/>
                <DeleteChatMessage show={showDelete}
                                   onClose={() => setShowDelete(false)}
                                   onDelete={onDeleteExec}
                                   onEdit={onEdit}
                                   deleteItem={message}/>
            </Section>
        </>
    )
};

export default ChatMessage;
