import ConfigType from "./interfaces/ConfigType";
import { createContext } from "react";

const developConfig: ConfigType = {
    // apiRoot: 'https://20fa-210-146-152-26.ngrok-free.app/api/',
    apiRoot: 'http://localhost:8000/api/',
    paymentUrl: 'https://stg.link.mul-pay.jp/link/tshop00061547/Multi/Entry',
    analyticsId: null
};

const stagingConfig: ConfigType = {
    apiRoot: 'https://staging.astec.dforest.jp/api/',
    paymentUrl: 'https://stg.link.mul-pay.jp/link/tshop00061547/Multi/Entry',
    analyticsId: null
};

const productionConfig: ConfigType = {
    apiRoot: 'https://api.cocolink.jp/api/',
    paymentUrl: 'https://link.mul-pay.jp/link/9200005637077/Multi/Entry',
    analyticsId: 'UA-188165760-1'
};

const Config = (): ConfigType => {
    if (process.env.NODE_ENV === 'development') {
        return developConfig;
    }
    if (process.env.REACT_APP_CHANNEL === 'production') {
        return productionConfig;
    }
    return stagingConfig;
};

export const ConfigContext = createContext<ConfigType>(developConfig);

export default Config();
