import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import * as toastr from "toastr";
import query from "query-string";

const PaymentSuccess: React.FC = () => {

    const history = useHistory();
    const {search} = useLocation();
    const params = query.parse(search);

    useEffect(() => {
        history.replace('/home');
        if (params.free ?? false) {
            toastr.success('お申し込みが正常に完了しました。');
        } else {
            toastr.success('お支払いが正常に完了しました。');
        }
    }, [history, params]);

    return (
        <></>
    )
};

export default PaymentSuccess;