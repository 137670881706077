import React from "react";
import styled from "styled-components";
import ChatMessageType from "../../interfaces/ChatMessageType";
import AutoLinkText from "../AutoLinkText";

const MessageBox = styled.div`
  padding: 0 8px;
  word-break: break-all;
`;

const Message = styled(AutoLinkText)`
`;

interface Props {
    message: ChatMessageType;
}

const ChatMessageText: React.FC<Props> = ({message}) => {
    return (
        <MessageBox>
            <Message text={message.text}/>
        </MessageBox>
    );
};

export default ChatMessageText;