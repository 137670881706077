import React from "react";
import styled from "styled-components";
import FlatButton from "../../parts/FlatButton";
import ScreenView from '../../parts/ScreenView';
import {BodyTextBox, TitleTextBox} from "../../parts/AppStyles";
import {useHistory} from "react-router-dom";

const Title = styled(TitleTextBox)`
  font-size: 20px;
  font-weight: bold;
`;

const Description = styled(BodyTextBox)`
  margin-top: 24px;
  font-size: 14px;
`;

const LoginButton = styled(FlatButton)`
  margin-top: 24px;
`;

const LogoutScreen: React.FC = () => {
    const history = useHistory();
    return (
        <ScreenView>
            <Title>ログアウトされました</Title>
            <Description>
                他の端末からログインされたか、ログインの有効期限切れによりログアウトされました。<br/>
                アプリを再度利用するにはログインしてください。
            </Description>
            <LoginButton text={'再ログイン'}
                         textColor={'#FFF'}
                         backgroundColor={'#616161'}
                         onPress={() => history.replace('/')}/>
        </ScreenView>
    )
};

export default LogoutScreen;
