import React, {useCallback, useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import ScreenView from "../../parts/ScreenView";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";
import PageHeader from "../../parts/Headers/PageHeader";
import BankEntryForm from "../../parts/PurchaseMemberBank/BankEntryForm";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import BankEntryParam from "../../interfaces/BankEntryParam";
import {resetRequestStatus, setErrors, submitBankTransaction, SubmitResult} from "../../stores/bank_transaction";
import toastr from "toastr";
import {fetchUser} from "../../stores/user";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {Link} from 'react-router-dom';

const SubmitBankTransaction: React.FC = () => {

    const {bank_transaction, user} = useSelector(state => ({
        bank_transaction: state.bank_transaction,
        user: state.user,
    }));
    const dispatch = useDispatch();
    const [submitResult, setSubmitResult] = useState<SubmitResult>();
    const formRef = useRef<HTMLFormElement | null>(null);

    const onSubmit = useCallback((entry: BankEntryParam) => {
        dispatch(setErrors({}));
        dispatch(submitBankTransaction(entry));
    }, [dispatch]);

    useEffect(() => {
        dispatch(resetRequestStatus());
        dispatch(fetchUser());
    }, [dispatch])

    useEffect(() => {
        if (bank_transaction.requestStatus === 'success') {
            setSubmitResult(bank_transaction.submitResult);
            dispatch(resetRequestStatus());
            setTimeout(() => {
                formRef.current?.submit();
            }, 1000);
        } else if (bank_transaction.requestStatus === 'failed') {
            if (Object.keys(bank_transaction.errors).length > 0) {
                toastr.error('入力内容をご確認ください。');
            } else {
                toastr.error('エラーが発生しました。');
            }
            dispatch(resetRequestStatus());
        }
    }, [bank_transaction.errors, bank_transaction.requestStatus, bank_transaction.submitResult, dispatch]);

    if (user.user === null) {
        return <WithLoadingScreen loading={true}/>
    }

    if (!user.user.require_bank_transaction) {
        return (
            <ScreenView>
                <p>
                    既に振替先の口座が登録済みです。
                </p>
                <Link to={'/'}>戻る</Link>
            </ScreenView>
        )
    }

    if (submitResult) {
        return (
            <ScreenView>
                <form method={'POST'} action={submitResult.RetURL} ref={formRef}>
                    <p>
                        金融機関に遷移します。<br/>
                        自動的に遷移しない場合はボタンをクリックしてください。
                    </p>
                    <input type='hidden' name='TranID' value={submitResult.TranID}/>
                    <input type='hidden' name='Token' value={submitResult.Token}/>
                    <button type={"submit"}>金融機関へ移動</button>
                </form>
            </ScreenView>
        );
    }

    return (
        <>
            <Helmet>
                <title>銀行口座振替の登録</title>
            </Helmet>
            <NoAuthHeader/>
            <PageHeader title={'銀行口座振替の登録'}/>
            <ScreenView>
                <BankEntryForm
                    onSubmit={onSubmit}
                    disabled={bank_transaction.requestStatus === 'pending'}
                    errors={bank_transaction.errors}/>
            </ScreenView>
        </>
    );
};

export default SubmitBankTransaction;