import React from 'react';
import styled from "styled-components";

const Base = styled.div`
  background-color: #FFF;
  flex: 1;
  padding: 16px 8px;
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;

  @media screen and (min-width: 480px) {
    padding: 16px 0;
  }
`;

interface Props {
    scroll?: boolean;
}

const ScreenView: React.FC<Props> = ({children, scroll = false}) => {
    return (
        <Base>
            {children}
        </Base>
    )
};

export default ScreenView;
