import ChatMessageType from "../interfaces/ChatMessageType";
import PaginateMeta from "../interfaces/PaginateMeta";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type ChatTimelineState = {
    items: ChatMessageType[];
    isFetching: boolean;
    lastRequestMeta?: PaginateMeta;
};

const initialState: ChatTimelineState = {
    items: [],
    isFetching: false,
    lastRequestMeta: undefined,
}

export const fetchChatTimeline = createAsyncThunk<FetchReturnType<ChatMessageType>, {
    page: number,
}>(
    'chat_timeline/fetch',
    async (arg) => {
        const {data} = await axios.get(`chat_timeline?page=${arg.page}`);
        return data as FetchReturnType<ChatMessageType>;
    }
);

export const chatTimelineSlice = createSlice({
    name: 'chat_timeline',
    initialState,
    reducers: {
        updateItem: (state, action: PayloadAction<ChatMessageType>) => {
            return {
                ...state,
                items: state.items.map((s) => {
                    if (s.id === action.payload.id) {
                        return {...action.payload};
                    } else {
                        return s;
                    }
                }),
            }
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchChatTimeline.fulfilled, (state, action) => ({
                ...state,
                items: action.meta.arg.page > 1 ? [...state.items, ...action.payload.data] : action.payload.data,
                isFetching: false,
                lastRequestMeta: action.payload.meta,
            }))
            .addCase(fetchChatTimeline.pending, (state, action) => {
                return {
                    ...state,
                    isFetching: true
                }
            })
            .addCase(fetchChatTimeline.rejected, (state) => {
                return {
                    ...state,
                    items: [],
                    isFetching: false
                }
            })
    }
});

export const {updateItem} = chatTimelineSlice.actions;