import React, {useContext} from "react";
import styled, {ThemeContext} from "styled-components";
import TouchableOpacity from "../TouchableOpacity";
import {TitleText} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";

const Setting = styled.div`
  margin: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkButton = styled(TouchableOpacity)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.subColor};
  text-align: center;
  padding: 0 16px;
  position: relative;
  border-radius: 20px;
  width: 500px;
  min-height: 40px;
  max-width: 100%;
`;

const LinkButtonText = styled(TitleText)`
  text-align: center;
  color: white;
  font-weight: bold;

  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
`;

const Arrow = styled(ArrowIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
`;

interface Props {
    onClick: () => void;
}

const SnsSettingLink: React.FC<Props> = ({onClick}) => {

    const theme = useContext(ThemeContext);

    return (
        <Setting>
            <LinkButton onClick={onClick}>
                <LinkButtonText>SNS交流でのプロフィールを見る</LinkButtonText>
                <Arrow size={14} color={theme.whiteColor}/>
            </LinkButton>
        </Setting>
    );
};
export default SnsSettingLink;