import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import RequestStatusType from "../interfaces/RequestStatusType";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import RequestErrorType from "../interfaces/RequestErrorType";
import localforage from "localforage";

export type AuthState = {
    authenticated: boolean;
    requestStatus: RequestStatusType;
    errors: RequestErrorType;
};

const initialState: AuthState = {
    authenticated: false,
    requestStatus: 'init',
    errors: {},
};

type LoginParams = {
    id: string,
    password: string
};

type TokenResult = {
    token: string;
};

export const execLogin = createAsyncThunk<FetchDetailReturnType<TokenResult>, LoginParams>(
    'auth/execLogin',
    async (request, thunk) => {
        const {data} = await axios.post('auth/login', request)
            .catch(error => {
                thunk.dispatch(setErrors(error.response?.data?.errors || {}));
                throw error;
            });
        return data as FetchDetailReturnType<TokenResult>;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
        setErrors: (state, action: PayloadAction<RequestErrorType>) => ({...state, errors: action.payload}),
        setAuthenticate: (state, action: PayloadAction<string>) => {
            localforage.setItem('__token', action.payload);
            return {...state, authenticated: true};
        },
        removeAuthenticate: (state, action: PayloadAction<void>) => {
            localforage.removeItem('__token');
            return {...state, authenticated: false}
        }
    },
    extraReducers: builder => {
        builder
            .addCase(execLogin.fulfilled, (state, action) => {
                localforage.setItem('__token', action.payload.data.token);
                return {...state, authenticated: true, requestStatus: 'success'};
            })
            .addCase(execLogin.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(execLogin.rejected, (state, action) => ({
                ...state,
                requestStatus: 'failed',
            }))
    }
});

export const {setAuthenticate, removeAuthenticate, resetRequestStatus, setErrors} = authSlice.actions;
