import React, {useState} from 'react';
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";
import Icon from "../Icon";

const Section = styled.div`
  position: relative;
`;

const LabelView = styled(TouchableOpacity)`
  background-color: ${({theme}) => theme.mainColor};
  padding: 12px 16px;
`;

const LabelText = styled(TitleTextBox)`
  color: ${({theme}) => theme.whiteColor};
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`;

const Allow = styled(Icon)`
  position: absolute;
  right: 16px;
  top: 8px;
  color: ${({theme}) => theme.whiteColor};
`;

const ExpandView = styled.div`
  border-left-color: ${({theme}) => theme.mainColor};
  border-right-color: ${({theme}) => theme.mainColor};
  border-bottom-color: ${({theme}) => theme.mainColor};
  border-left-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  background-color: white;
  padding: 16px 8px 8px;
`;

interface Props {
    labelText: string;
    onOpenChange?: (open: boolean) => void;
    className?: string;
}

const AccordionView: React.FC<Props> = (props) => {
    const [open, setOpen] = useState<boolean>(false);

    const changeOpen = () => {
        setOpen(!open);
        if (props.onOpenChange) {
            props.onOpenChange(!open);
        }
    };

    return (
        <Section className={props.className}>
            <LabelView onClick={changeOpen}>
                <LabelText>{props.labelText}</LabelText>
                <Allow name={open ? 'angle-up' : 'angle-down'} size={24}/>
            </LabelView>
            {open ? (
                <ExpandView>
                    {props.children}
                </ExpandView>
            ) : <></>}
        </Section>
    )
};

export default AccordionView;
