import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchNullableReturnType, FetchReturnType} from "../interfaces/FetchReturnType";
import VideoType from "../interfaces/VideoType";
import axios from "../http/axios";

export type SearchVideoState = {
    items?: VideoType[];
    isFetching: boolean;
    lastSearchText: string;
}

const initialState: SearchVideoState = {
    items: undefined,
    isFetching: false,
    lastSearchText: ''
};

type RequestParams = {
    search: string;
    qualificationId: number;
}

export const searchVideos = createAsyncThunk<FetchNullableReturnType<VideoType>, RequestParams>(
    'search_videos',
    async ({search, qualificationId}) => {
        if (search.length === 0) {
            return {data: undefined};
        }
        const {data} = await axios.post(`qualifications/${qualificationId}/videos/search`, {search});
        return data as FetchReturnType<VideoType>;
    }
);

export const searchVideoSlice = createSlice({
    name: 'search_videos',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(searchVideos.fulfilled, (state, action) => ({
                ...state,
                items: action.payload.data,
                isFetching: false
            }))
            .addCase(searchVideos.pending, (state, action) => ({
                ...state,
                items: action.meta.arg.search.length > 0 ? [] : undefined,
                isFetching: true,
                lastSearchText: action.meta.arg.search,
            }))
            .addCase(searchVideos.rejected, state => ({
                ...state,
                items: undefined,
                isFetching: false
            }))
    }
});
