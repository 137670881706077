import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchChatMessageDetail} from "../../stores/chat_message_detail";
import {useSelector} from "../../store";
import {Helmet} from "react-helmet-async";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import ChatMessageDetailHeader from "../../parts/ChatMessageDetail/ChatMessageDetailHeader";
import ChatMessageType from "../../interfaces/ChatMessageType";
import {deleteChatMessage, editChatMessage, sendChatMessage} from "../../stores/chat_message";
import ChatMessageReport from "./ChatMessageReport";
import SendChatMessage from "./SendChatMessage";
import DeleteChatMessage from "./DeleteChatMessage";
import ChatInformation from "../../parts/ChatMessageDetail/ChatInformation";
import ScreenView from "../../parts/ScreenView";
import ChatCommentButtonBox from "../../parts/ChatMessage/ChatCommentButtonBox";
import ChatResponseForm from "../../parts/ChatMessage/ChatResponseForm";
import ReactionType from "../../interfaces/ReactionType";
import {sendReaction} from "../../stores/send_reaction";
import styled from "styled-components";
import {ContentWrap} from "../../parts/AppStyles";
import ChatMessageChildList from "../../parts/ChatMessage/ChatMessageChildList";
import {pinChatMessage} from "../../stores/chat_message_pin";
import ChatMessageDetailImage from "../../parts/ChatMessageDetail/ChatMessageDetailImage";

const TopBorderView = styled.div`
  border-top: 1px solid ${({theme}) => theme.borderColor};
`

const ChatMessageDetail: React.FC = () => {

    const {chat_message_detail} = useSelector(state => ({
        chat_message_detail: state.chat_message_detail,
    }))
    const dispatch = useDispatch();
    const {id: chatMessageId} = useParams<{ id: string; }>();
    const [showForm, setShowForm] = useState<boolean>(false);
    const [reportMessage, setReportMessage] = useState<ChatMessageType | null>(null);
    const [showPost, setShowPost] = useState<ChatMessageType | null>(null);
    const [showDelete, setShowDelete] = useState<ChatMessageType | null>(null);
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchChatMessageDetail(chatMessageId));
    }, [chatMessageId, dispatch]);


    const onComment = useCallback(async (text: string) => {
        if (chat_message_detail.item === null) {
            return;
        }
        await dispatch(sendChatMessage({
            text: text,
            parentId: chat_message_detail.item.id,
            groupId: chat_message_detail.item.group.id,
            uploadFile: null,
        }));
        dispatch(fetchChatMessageDetail(chatMessageId));
    }, [chat_message_detail.item, dispatch, chatMessageId]);

    const onEdit = useCallback((chatMessage: ChatMessageType) => {
        setShowPost(chatMessage);
        setShowDelete(null);
    }, []);

    const onPost = useCallback(async (text: string, uploadFile: File | null) => {
        if (showPost === null) {
            return;
        }
        setShowPost(null);
        await dispatch(editChatMessage({
            id: showPost.id,
            groupId: showPost.group.id,
            text,
            uploadFile
        }));
        dispatch(fetchChatMessageDetail(chatMessageId));
    }, [chatMessageId, dispatch, showPost]);

    const onDelete = useCallback(async (chatMessage: ChatMessageType) => {
        setShowDelete(chatMessage);
    }, []);

    const onDeleteExec = useCallback(async () => {
        if (showDelete === null) {
            return;
        }
        setShowDelete(null);
        await dispatch(deleteChatMessage(showDelete));
        if (parseInt(chatMessageId) === showDelete.id) {
            history.replace('/sns');
        }
    }, [chatMessageId, dispatch, history, showDelete])

    const onPin = useCallback((chatMessage: ChatMessageType) => {
        dispatch(pinChatMessage(chatMessage));
    }, [dispatch]);

    const onReport = useCallback((chatMessage: ChatMessageType) => {
        setReportMessage(chatMessage);
    }, []);

    const onReaction = useCallback((reaction?: ReactionType) => {
        if (chat_message_detail.item === null) {
            return;
        }
        dispatch(sendReaction({
            chatMessage: chat_message_detail.item,
            reactionId: reaction?.id,
            groupId: chat_message_detail.item.group.id
        }));
    }, [dispatch, chat_message_detail.item]);

    const onShowReactions = useCallback(() => {
        if (chat_message_detail.item === null) {
            return;
        }
        history.push(`/sns/reactions/${chat_message_detail.item.id}`);
    }, [history, chat_message_detail.item]);

    const onChildReaction = useCallback((message: ChatMessageType, reaction?: ReactionType) => {
        dispatch(sendReaction({
            chatMessage: message,
            reactionId: reaction?.id,
            groupId: message.group.id
        }));
    }, [dispatch]);

    const onChildShowReactions = useCallback((message: ChatMessageType) => {
        history.push(`/sns/reactions/${message.id}`);
    }, [history]);

    if (chat_message_detail.item === null) {
        if (chat_message_detail.isFetching) {
            return <WithLoadingScreen loading={true}/>
        }
        return <>エラーが発生しました。</>;
    }

    return (
        <>
            <Helmet>
                <title>メッセージ詳細</title>
            </Helmet>
            <ChatMessageDetailHeader message={chat_message_detail.item}
                                     onEdit={onEdit}
                                     onDelete={onDelete}
                                     onReport={onReport}
                                     onPin={onPin}
                                     onGoBack={() => history.goBack()}/>
            <ScreenView>
                <ChatInformation message={chat_message_detail.item}/>
                <ChatMessageDetailImage message={chat_message_detail.item}/>
                <ChatCommentButtonBox message={chat_message_detail.item}
                                      onReaction={onReaction}
                                      showForm={showForm}
                                      onShowForm={setShowForm}
                                      onShowReactions={onShowReactions}/>
            </ScreenView>
            <TopBorderView/>
            <ContentWrap>
                <ChatResponseForm parentId={chat_message_detail.item.id}
                                  noBorder={true}
                                  isShow={showForm}
                                  onSubmit={onComment}/>
            </ContentWrap>
            <ChatMessageChildList message={chat_message_detail.item}
                                  onEdit={onEdit}
                                  onDelete={onDelete}
                                  onPin={onPin}
                                  onReport={onReport}
                                  onReaction={onChildReaction}
                                  onShowReactions={onChildShowReactions}/>
            <ChatMessageReport
                message={reportMessage}
                onClose={() => setReportMessage(null)}/>
            <SendChatMessage show={!!showPost}
                             editItem={showPost}
                             onClose={() => setShowPost(null)}
                             onPost={onPost}/>
            <DeleteChatMessage show={!!showDelete}
                               onClose={() => setShowDelete(null)}
                               onDelete={onDeleteExec}
                               onEdit={onEdit}
                               deleteItem={showDelete}/>
        </>
    );
};

export default ChatMessageDetail;