import React, {useContext, useMemo} from 'react';
import UserType from "../../interfaces/UserType";
import styled, {ThemeContext} from "styled-components";
import UserQualificationType from "../../interfaces/UserQualificationType";
import Barcode from "./Barcode";
import {TitleText, TitleTextBox} from "../AppStyles";
import SerialNumberType from "../../interfaces/SerialNumberType";

const CardBox = styled.div`
  margin-top: 24px;
  border: 2px solid ${({theme}) => theme.accentColor};
  border-radius: 8px;
  overflow: hidden;
  min-width: calc(100vw - 48px);
  margin-right: 8px;
  
  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (min-width: 480px) {
    min-width: 480px;
  }
`;

const Title = styled(TitleTextBox)`
  background-color: ${({theme}) => theme.accentColor};
  padding: 8px;
  color: #212121;
  text-align: center;
  font-size: 18px;
`;

const CardBody = styled.div`
  background-color: #FFFFFF;
  padding: 24px 8px 8px 8px;
`;

const InformationFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const NameBox = styled.div`
  margin-right: auto;
  margin-left: 24px;
`;

const UnitBox = styled.div`
  margin-right: 24px;
`;

const NameLabel = styled(TitleTextBox)`
  font-size: 10px;
`;

const NameValue = styled(TitleTextBox)`
  margin-top: 8px;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
`;

const UnitLabel = styled(TitleTextBox)`
  font-size: 10px;
`;

const UnitNumberValue = styled(TitleTextBox)`
  margin-top: 8px;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
`;

const UnitTextValue = styled(TitleText)`
  font-size: 16px;
  font-weight: normal;
`;

const BarcodeBox = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;
  width: 100%;
  height: 50px;
  padding: 0 4px;
`;

const IdText = styled(TitleTextBox)`
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  color: ${({theme}) => theme.textGrayColor};
`;

interface Props {
    user: UserType;
    qualification: UserQualificationType;
    serial_number: SerialNumberType;
}

const MemberCard: React.FC<Props> = ({user, qualification, serial_number}) => {
    const theme = useContext(ThemeContext);

    const barcodeNumber = useMemo(() => {
        return (`000000000000${serial_number.id}`).slice(-12);
    }, [serial_number.id]);

    return (
        <CardBox>
            <Title>{qualification.qualification_name}</Title>
            <CardBody>
                <InformationFlex>
                    <NameBox>
                        <NameLabel>Name:</NameLabel>
                        <NameValue>{user.name}</NameValue>
                    </NameBox>
                    <UnitBox>
                        <UnitLabel>取得単位:</UnitLabel>
                        <UnitNumberValue>
                            {qualification.unit}<UnitTextValue> 単位</UnitTextValue>
                        </UnitNumberValue>
                    </UnitBox>
                </InformationFlex>
                <BarcodeBox>
                    <Barcode value={barcodeNumber}
                             format={'EAN13'}
                             text={barcodeNumber}
                             textColor={theme.textGrayColor}
                             onError={error => console.log(error)}
                             height={30}/>
                </BarcodeBox>
                <IdText>会員番号: {user.id}</IdText>
            </CardBody>
        </CardBox>
    );
};

export default MemberCard;
