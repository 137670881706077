import React, {useMemo} from "react";
import EventType from "../../interfaces/EventType";
import styled from "styled-components";
import {BodyTextBox} from "../AppStyles";
import moment from "moment";

const Box = styled.section`
`;

const CancelButton = styled.button`
  outline: none;
  border: none;
  box-shadow: none;
  display: block;
  width: 100%;
  background-color: ${({theme}) => theme.dangerColor};
  color: white;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: ${({theme}) => theme.disableColor};
    opacity: 1 !important;
    cursor: not-allowed;
  }
`;

const CancelWarning = styled(BodyTextBox)`
  margin-top: 8px;
  text-align: center;
`;

type Props = {
    event: EventType;
    onCancel: () => void;
    disabled: boolean;
}

const EventCancelBox: React.FC<Props> = ({event, onCancel, disabled}) => {

    const enable = useMemo(() => {
        const now = moment().startOf('day').add(3, 'day').unix();
        return event.published_at_timestamp >= now;
    }, [event]);

    if (!event.is_own) {
        return <></>
    }

    return (
        <Box>
            <CancelButton onClick={onCancel} disabled={!enable || disabled}>
                申し込みをキャンセルする
            </CancelButton>
            <CancelWarning>
                キャンセルは開催日の3日前までとなります
            </CancelWarning>
        </Box>
    )
};

export default EventCancelBox;