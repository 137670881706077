import React, {HTMLProps, useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import {TitleText} from "../AppStyles";
import {Link} from "react-router-dom";
import ArrowIcon from "../ArrowIcon";

const Box = styled.div`
  align-items: center;
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const Touch = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({theme}) => theme.mainColor};
  text-decoration: none;
  padding: 8px 32px;
  border-radius: 50px;
  position: relative;
`;

const Text = styled(TitleText)`
  color: ${({theme}) => theme.whiteColor};
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`;

const RightArrow = styled(ArrowIcon)`
  position: absolute;
  right: 10px;
  top: 9px;
`;

interface Props extends HTMLProps<HTMLDivElement> {
    title: string;
    url: string;
}

const HomeLink: React.FC<Props> = (props) => {
    const theme = useContext(ThemeContext);
    return (
        <Box className={props.className}>
            <Touch to={props.url}>
                <Text>{props.title}</Text>
                <RightArrow color={theme.whiteColor} size={14}/>
            </Touch>
        </Box>
    )
};

export default HomeLink;
