import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../http/axios";
import {fetchOwnChatGroups} from "./chat_group";

export type ChatGroupJoinState = {
    requestState: RequestStatusType;
}

const initialState: ChatGroupJoinState = {
    requestState: "init"
};

export const joinChatGroup = createAsyncThunk<void, number>(
    'chat_group_join',
    async (chatGroupId, thunk) => {
        await axios.post(`chat_groups/${chatGroupId}/join`);
        await thunk.dispatch(fetchOwnChatGroups());
    }
);

export const chatGroupJoinSlice = createSlice({
    name: 'chat_group_join',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestState: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(joinChatGroup.fulfilled, state => ({
                ...state,
                requestState: 'success'
            }))
            .addCase(joinChatGroup.pending, state => ({
                ...state,
                requestState: 'pending'
            }))
            .addCase(joinChatGroup.rejected, state => ({
                ...state,
                requestState: 'failed'
            }))
    }
});

export const {resetRequestStatus} = chatGroupJoinSlice.actions;
