import React, {useCallback, useMemo} from "react";
import {useHistory, useParams} from "react-router-dom";
import PageHeader from "../../parts/Headers/PageHeader";
import styled from "styled-components";
import {Helmet} from "react-helmet-async";
import FlatButton from "../../parts/FlatButton";

const Screen = styled.article`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Wrap = styled.div`
  flex: 1;
  overflow: auto;
  position: relative;
`;

const View = styled.embed`
  min-width: 100%;
  min-height: 100%;
`;

const AndroidBox = styled.div`
  padding: 16px;
`;

const AndroidDescription = styled.p`
`;

const GuideShow: React.FC = () => {
        const {fileName} = useParams<{ fileName: string; }>();
        const history = useHistory();

        const getMobileOS = useMemo(() => {
            const ua = navigator.userAgent
            if (/android/i.test(ua)) {
                return "Android"
            } else if (/iPad|iPhone|iPod/.test(ua)
                || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
                return "iOS"
            }
            return "Other";
        }, []);

        const onClickLink = useCallback(() => {
            history.goBack();
            window.open(`/guide/${fileName}`);
        }, [fileName, history]);

        return (
            <>
                <Helmet>
                    <title>ココリンク使い方ガイド</title>
                </Helmet>
                <Screen>
                    <PageHeader title={'ココリンク使い方ガイド'}/>
                    {getMobileOS === 'Android' ? (
                        <AndroidBox>
                            <AndroidDescription>
                                Androidをご利用の場合は、Google Playから{'\n'}
                                Adobe Acrobat Readerをインストールして下記から使い方ガイドをダウンロードしてください。
                            </AndroidDescription>
                            <FlatButton text={'PDFをダウンロード'}
                                        onPress={onClickLink}/>
                        </AndroidBox>
                    ) : (
                        <Wrap>
                            <View src={`/guide/${fileName}`}/>
                        </Wrap>
                    )}
                </Screen>
            </>
        );
    }
;

export default GuideShow;