import React, {useEffect} from 'react';
import {useSelector} from "../../store";
import SnsHeader from "../../parts/SnsTab/SnsHeader";
import {useDispatch} from "react-redux";
import {fetchUser} from "../../stores/user";
import {fetchOwnChatGroups, fetchRecentChatGroups} from "../../stores/chat_group";
import ChatGroupType from "../../interfaces/ChatGroupType";
import ChatGroupList from "../../parts/SnsTab/ChatGroupList";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {useHistory, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {SnsTabState} from "../../parts/SnsTab/SnsTabView";

const ChatGroupTabPage: React.FC = () => {

    const {user, chat_group} = useSelector(state => ({
        user: state.user,
        chat_group: state.chat_group
    }));

    const dispatch = useDispatch();
    const history = useHistory();
    const {state: {tab} = {tab: "Own"}} = useLocation<{ tab: SnsTabState; }>();

    useEffect(() => {
        dispatch(fetchUser());
        dispatch(fetchOwnChatGroups());
        dispatch(fetchRecentChatGroups({page: 1}));
    }, [dispatch]);

    const onPressCreateGroup = () => {
        history.push('/sns/create_chat_group');
    };

    const onPressChatGroup = (item: ChatGroupType) => {
        if (item.is_own) {
            history.push(`/sns/chat_groups/${item.id}`);
        } else {
            history.push(`/sns/apply_chat_groups/${item.id}`);
        }
    };

    const onEndReached = () => {
        if (chat_group.lastRequestMeta === undefined) {
            return;
        }

        if (chat_group.lastRequestMeta.last_page > chat_group.lastRequestMeta.current_page) {
            dispatch(fetchRecentChatGroups({page: chat_group.lastRequestMeta.current_page + 1}));
        }
    };

    return (
        <>
            <Helmet>
                <title>SNS交流</title>
            </Helmet>
            <WithLoadingScreen
                loading={chat_group.recentItems.length === 0 && chat_group.ownItems.length === 0 && chat_group.isFetching}>
                <SnsHeader user={user.user} canGoBack={true}/>
                <ChatGroupList
                    defaultTab={tab}
                    chatGroup={chat_group}
                    onPressCreateGroup={onPressCreateGroup}
                    onPressChatGroup={onPressChatGroup}
                    onRecentEndReached={onEndReached}
                />
            </WithLoadingScreen>
        </>
    )
};

export default ChatGroupTabPage;
