import React, {useMemo} from "react";
import styled from "styled-components";

const SelectWrap = styled.div`
  position: relative;
  width: 200px;
  max-width: 100%;
`;

const Arrow = styled.div`
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 8px;
  border-bottom: ${({theme}) => theme.borderColor} 2px solid;
  border-right: ${({theme}) => theme.borderColor} 2px solid;
  transform: rotate(45deg) translateY(-30%);
`;

const SelectBox = styled.select`
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  color: #616161;
  font-size: 14px;
`;

interface Props {
    value: string | null;
    onChange: (pref: string) => void;
}

const PrefPicker: React.FC<Props> = ({value, onChange}) => {

    const prefList = useMemo(() => {
        return [
            null,
            '北海道',
            '青森県',
            '岩手県',
            '宮城県',
            '秋田県',
            '山形県',
            '福島県',
            '茨城県',
            '栃木県',
            '群馬県',
            '埼玉県',
            '千葉県',
            '東京都',
            '神奈川県',
            '新潟県',
            '富山県',
            '石川県',
            '福井県',
            '山梨県',
            '長野県',
            '岐阜県',
            '静岡県',
            '愛知県',
            '三重県',
            '滋賀県',
            '京都府',
            '大阪府',
            '兵庫県',
            '奈良県',
            '和歌山県',
            '鳥取県',
            '島根県',
            '岡山県',
            '広島県',
            '山口県',
            '徳島県',
            '香川県',
            '愛媛県',
            '高知県',
            '福岡県',
            '佐賀県',
            '長崎県',
            '熊本県',
            '大分県',
            '宮崎県',
            '鹿児島県',
            '沖縄県',
        ];
    }, []);

    return (
        <SelectWrap>
            <SelectBox onChange={e => onChange(e.target.value)} value={value ?? undefined}>
                {prefList.map(pref => (
                    <option key={pref} value={pref ?? undefined}>{pref ?? '未設定'}</option>
                ))}
            </SelectBox>
            <Arrow/>
        </SelectWrap>
    )
};

export default PrefPicker;