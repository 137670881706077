import React from "react";

type Props = {
    name: string;
    color?: string;
    size?: number;
    className?: string;
}

const Icon: React.FC<Props> = ({name, color, size, className}) => {
    return (
        <i className={`fa fa-${name} ${className}`}
           style={{
               color: color,
               fontSize: size
           }}/>
    )
};

export default Icon;