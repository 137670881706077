import React, {useContext, useEffect, useRef} from 'react';
import styled, {ThemeContext} from "styled-components";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {fetchUser, updateUser, uploadImage} from "../../stores/user";
import MemberCard from "../../parts/AccountTab/MemberCard";
import MemberInformation from "../../parts/AccountTab/MemberInformation";
import {fetchUserQualifications} from "../../stores/user_qualification";
import TabHeader from "../../parts/TabHeader";
import UserType from "../../interfaces/UserType";
import FlatButton from "../../parts/FlatButton";
import UnitHistory from "../../parts/AccountTab/UnitHistory";
import ApplyEventList from "../../parts/AccountTab/ApplyEventList";
import {fetchUserEvents} from "../../stores/event";
import EventType from "../../interfaces/EventType";
import {fetchUnitHistories} from "../../stores/unit_history";
import {TitleTextBox} from "../../parts/AppStyles";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {useHistory} from "react-router-dom";
import accountIcon from '../../assets/icons/account.png';
import {changeTab} from "../../stores/tab";
import {Helmet} from "react-helmet-async";
import SnsSettingLink from "../../parts/AccountTab/SnsSettingLink";

const Screen = styled.article`
  margin: auto;
  width: 100%;
  max-width: 1024px;
  box-sizing: border-box;
`;

const BaseSection = styled.div`
  padding: 16px;
`;

const Title = styled(TitleTextBox)`
  font-size: 18px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  text-align: center;
  line-height: 25px;
`;

const BottomMenu = styled.div`
  background-color: ${({theme}) => theme.lightColor};
  padding: 16px;
  margin-top: 8px;

  & > * {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1024px;
    box-sizing: border-box;
    display: block;
  }
`;

const Horizontal = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
`;

const AccountTab: React.FC = () => {

    const {user, user_qualification, event, unit_history, serial_number} = useSelector(state => ({
        user: state.user,
        user_qualification: state.user_qualification,
        event: state.event,
        unit_history: state.unit_history,
        serial_number: state.serial_number,
    }));

    const theme = useContext(ThemeContext);
    const screenRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchUser());
        dispatch(fetchUserQualifications());
        dispatch(fetchUserEvents());
        dispatch(fetchUnitHistories());
        dispatch(changeTab('account'));
    }, [dispatch]);

    const onSelectedImage = (uri: File) => {
        dispatch(uploadImage(uri));
    };

    const onPressUpdateUser = (user: UserType) => {
        dispatch(updateUser(user));
    };

    const onPressWatchHistory = () => {
        history.push('/video_watch_histories');
    };

    const onPressEvent = (event: EventType) => {
        history.push(`/events/${event.id}`);
    };

    if (user.user === null) {
        return <WithLoadingScreen loading={true}/>
    }

    return (
        <>
            <Helmet>
                <title>会員情報</title>
            </Helmet>
            <TabHeader source={accountIcon} title={'会員情報'}/>
            <Screen ref={screenRef}>
                <BaseSection>
                    <Title>会員情報</Title>
                    <Horizontal>
                        {user_qualification.items.map(item => (
                            <MemberCard user={user.user!!}
                                        serial_number={serial_number.item}
                                        key={item.qualification_id}
                                        qualification={item}/>
                        ))}
                    </Horizontal>
                    <MemberInformation user={user.user}/>
                </BaseSection>
                <SnsSettingLink onClick={() => history.push('/sns/users')}/>
            </Screen>
            <BottomMenu>
                <UnitHistory histories={unit_history.items}
                             onRefresh={() => dispatch(fetchUnitHistories())}/>
                <ApplyEventList events={event.userItems}
                                onRefresh={() => dispatch(fetchUserEvents())}
                                onPressEvent={onPressEvent}/>
                <FlatButton text={'視聴動画の履歴'}
                            backgroundColor={'#FFFFFF'}
                            textColor={theme.mainColor}
                            rightAllow={true}
                            borderColor={theme.mainColor}
                            onPress={onPressWatchHistory}/>
            </BottomMenu>
        </>
    );
};

export default AccountTab;
