import React, { useCallback } from 'react';
import QualificationType from "../../interfaces/QualificationType";
import btnOnShuumatsuki from "../../assets/buttons/btn_on_shuumatsuki.png";
import btnOffShuumatsuki from "../../assets/buttons/btn_off_shuumatsuki.png";
import btnOnJoukyuu from "../../assets/buttons/btn_on_joukyuu.png";
import btnOffJoukyuu from "../../assets/buttons/btn_off_joukyuu.png";
import btnOnKyuuseiki from "../../assets/buttons/btn_on_kyuuseiki.png";
import btnOffKyuuseiki from "../../assets/buttons/btn_off_kyuuseiki.png";

interface Props {
    qualifications: QualificationType[];
    selectQualification?: QualificationType;
    onSelect: (qualification: QualificationType) => void;
    setIntroduceQualification: (selectIntroduceQualification: QualificationType | null) => void;
}

const QualificationSelect: React.FC<Props> = ({ qualifications, onSelect, selectQualification, setIntroduceQualification }) => {

    const onClick = useCallback((e) => {
        const selectItem = qualifications.find(q => q.code === String(e.target.value));
        if (selectItem) {
            onSelect(selectItem);
            setIntroduceQualification(selectItem);
        }
    }, [onSelect, qualifications]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <input type='radio' id='btnShuumatsuki' onClick={onClick} value="JTCA" style={{ display: 'none' }} />
                <label htmlFor='btnShuumatsuki' style={{ padding: '4px 8px', maxWidth: '900px' }} >
                    <img src={selectQualification?.code === 'JTCA' ? btnOnShuumatsuki : btnOffShuumatsuki} alt="終末期ケア専門士" />
                </label>
                <input type='radio' id='btnJoukyuu' onClick={onClick} value="JTCA1" style={{ display: 'none' }} />
                <label htmlFor='btnJoukyuu' style={{ padding: '4px 8px', maxWidth: '900px' }}>
                    <img src={selectQualification?.code === 'JTCA1' ? btnOnJoukyuu : btnOffJoukyuu} alt="終末期ケア上級専門士" />
                </label>
                <input type='radio' id='btnKyuuseiki' onClick={onClick} value='JACA' style={{ display: 'none' }} />
                <label htmlFor='btnKyuuseiki' style={{ padding: '4px 8px', maxWidth: '900px' }}>
                    <img src={selectQualification?.code === 'JACA' ? btnOnKyuuseiki : btnOffKyuuseiki} alt="急性期ケア専門士" />
                </label>
            </div>
        </>
    )
};

export default QualificationSelect;
