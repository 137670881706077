import React from 'react';
import EventType from "../../interfaces/EventType";
import EventListItem from "./EventListItem";
import styled from "styled-components";
import {EventState} from "../../stores/event";
import SectionLabel from "../SectionLabel";
import {TitleText} from "../AppStyles";
import ScreenView from "../ScreenView";

const View = styled.div`
  padding: 16px;
`;

const EmptyView = styled(TitleText)`
  padding: 8px 0;
`;

const EventBox = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface Props {
    event: EventState;
    onPressItem: (event: EventType) => void;
}

const EventList: React.FC<Props> = ({event, onPressItem}) => {

    return (
        <ScreenView>
            <SectionLabel title={'申込済みイベント'}/>
            <EventBox>
                {event.userItems.length === 0 ? (
                    <EmptyView>
                        該当のイベントがありません。
                    </EmptyView>
                ) : <></>}
                {event.userItems.map((item, index) => (
                    <EventListItem event={item}
                                   key={`user.${item.id}`}
                                   isUser={true}
                                   index={index}
                                   onPress={() => onPressItem(item)}/>
                ))}
            </EventBox>

            <SectionLabel title={'新着イベント'}/>
            <EventBox>
                {event.recentItems.length === 0 ? (
                    <EmptyView>
                        該当のイベントがありません。
                    </EmptyView>
                ) : <></>}
                {event.recentItems.map((item, index) => (
                    <EventListItem event={item}
                                   key={`recent.${item.id}`}
                                   isUser={false}
                                   index={index}
                                   onPress={() => onPressItem(item)}/>
                ))}
            </EventBox>

            {event.types.map(type => (
                <div key={type.name}>
                    <SectionLabel title={type.name}/>
                    <EventBox>
                        {type.events.map((item, index) => (
                            <EventListItem event={item}
                                           key={`${type.name}.${item.id}`}
                                           isUser={false}
                                           index={index}
                                           onPress={() => onPressItem(item)}/>
                        ))}
                    </EventBox>
                </div>
            ))}
        </ScreenView>
    );
};

export default EventList;
