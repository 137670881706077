import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FetchDetailReturnType } from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import VideoType from "../interfaces/VideoType";

export type VideoDetailState = {
    item: VideoType | null;
    isFetching: boolean;
};

const initialState: VideoDetailState = {
    item: null,
    isFetching: false
};

export const fetchVideoDetail = createAsyncThunk<FetchDetailReturnType<VideoType>, any>(
    'video.detail',
    async (param) => {
        const { data } = await axios.get(`videos/${param}`);
        return data as FetchDetailReturnType<VideoType>;
    }
);

export const videoDetailSlice = createSlice({
    name: 'video_detail',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchVideoDetail.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                item: action.payload.data
            }))
            .addCase(fetchVideoDetail.rejected, state => ({
                ...state,
                isFetching: false,
            }))
            .addCase(fetchVideoDetail.pending, state => ({
                ...state,
                isFetching: true,
                item: null
            }))
    }
})
