import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "../../store";
import QualificationType from "../../interfaces/QualificationType";
import { useDispatch } from "react-redux";
import { fetchQualifications } from "../../stores/qualification";
import { searchVideos } from "../../stores/search_video";
import { fetchCategories } from "../../stores/category";
import { useHistory } from "react-router-dom";
import TabHeader from "../../parts/TabHeader";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import playIcon from '../../assets/icons/play.png';
import VideoListHeader from "../../parts/VideoTab/VideoListHeader";
import CategoryListItem from "../../parts/VideoTab/CategoryListItem";
import VideoListItem from "../../parts/CategoryPage/VideoListItem";
import { changeTab } from "../../stores/tab";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  max-width: 900px;
  margin: auto;
`;

const VideoTab: React.FC = () => {
    const [selectQualification, setSelectQualification] = useState<QualificationType>();

    const { category, qualification, search_video } = useSelector(state => ({
        category: state.category,
        qualification: state.qualification,
        search_video: state.search_video,
    }));

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const reloadQualifications = () => {
            dispatch(fetchQualifications());
        };
        reloadQualifications();
        dispatch(changeTab('video'));
    }, [dispatch]);

    useEffect(() => {
        if (qualification.items.length > 0 && selectQualification === undefined) {
            setSelectQualification(qualification.items.find(item => item.is_owned));
        }
    }, [qualification, selectQualification]);

    useEffect(() => {
        (async () => fetchQualificationCategories())();
    }, [selectQualification]);

    const onSelectQualification = useCallback(async (id: QualificationType) => {
        setSelectQualification(id);
    }, []);

    const onSearch = useCallback(async (text: string) => {
        if (selectQualification !== undefined) {
            dispatch(searchVideos({
                search: text,
                qualificationId: selectQualification.id
            }));
        }
    }, [dispatch, selectQualification]);

    const onPressHistory = useCallback(() => {
        history.push('/video_watch_histories');
    }, [history]);

    const fetchQualificationCategories = useCallback(async () => {
        if (selectQualification !== undefined) {
            await onSearch('');
            await dispatch(fetchCategories(selectQualification.id));
        }
    }, [dispatch, onSearch, selectQualification]);

    return (
        <>
            <Helmet>
                <title>動画視聴</title>
            </Helmet>
            <WithLoadingScreen
                loading={qualification.isFetching && qualification.items.length === 0}>
                <TabHeader source={playIcon} title={'動画視聴'}
                    back={search_video.items !== undefined}
                    onBack={() => onSearch('')} />
                <VideoListHeader
                    key={'video-list-header'}
                    qualifications={qualification.items}
                    selectQualification={selectQualification}
                    lastSearchText={search_video.lastSearchText}
                    onSelectQualification={onSelectQualification}
                    onSearch={onSearch}
                    onPressHistory={onPressHistory}
                />
                <WithLoadingScreen loading={category.isFetching || search_video.isFetching}>
                    <Flex>
                        {search_video.items === undefined ? category.items.map((item, index) => (
                            <CategoryListItem
                                category={item}
                                style={{
                                    marginRight: index % 2 ? 0 : 4,
                                    marginLeft: index % 2 ? 4 : 0
                                }}
                                key={`categories.${item.id}`}
                                onPress={() => history.push(`/categories/${item.id}`)} />
                        )) : search_video.items.map(item => (
                            <VideoListItem
                                video={item}
                                key={`videos.${item.id}`}
                                onPress={() => history.push(`/videos/${item.id}`)} />
                        ))}
                    </Flex>
                </WithLoadingScreen>
            </WithLoadingScreen>
        </>
    )
};

export default VideoTab;