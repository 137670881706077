import React from "react";
import { useSelector } from "../store";
import styled from "styled-components";
import moment from "moment";

const Background = styled.div`
  padding: 10px;
  margin: 16px 0px 16px 0px;
  border-radius: 8px;
  text-align: center;
  background-color: ${({theme}) => theme.mainColor};
`;

const GetUnitReminderAlertTextBox = styled.div`
  background-color: ${({theme}) => theme.whiteColor};
`;

const GetUnitReminderAlertText = styled.div`
  padding: 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
`;

const UnitReminderQualificationName = styled.text`
  color: ${({theme}) => theme.mainColor};
  font-size: 48px;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

const UnitReminderTitleBox = styled.div`
  display: flex;
  justify-content: center;
`

const UnitReminderTitle = styled.text`
  font-size: 40px;
  witdh: 50%;
  padding: 5px;
  color: ${({theme}) => theme.whiteColor};
  background-color: ${({theme}) => theme.mainColor};
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;
const UnitReminderText = styled.text`
  font-size: 24px;
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const UnitReminderBold = styled.text`
  font-size: 32px;
  color: ${({theme}) => theme.dangerColor};
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;


const GetUnitReminderAlert: React.FC = () => {

    const { user_qualification, qualification } = useSelector(state => ({
        user_qualification: state.user_qualification,
        qualification: state.qualification,
    }));

    if (user_qualification == null || qualification == null) {
        return <></>
    }

    type underRequireUnitQualificationType = {
      name: string,
      require_unit: number
    }

    const underRequireUnitQualification:underRequireUnitQualificationType = {
      name: "",
      require_unit: 0
    }

    const underRequireUnitQualificationArray:underRequireUnitQualificationType[] = [];

    user_qualification.items.forEach(
      userQualificationItem => {
        const correspondingQualification = qualification.items.find(
          qualificationItem => qualificationItem.name === userQualificationItem.qualification_name
        )
        if (correspondingQualification) {
          
          const overRequireUnit = userQualificationItem.unit >= correspondingQualification.require_unit;
          
          if ( overRequireUnit ){
            return <></>;
          }
          
          if ( !userQualificationItem.renewal_at ){
            return <></>;
          }

          const overDeadline = moment().add(3,'months').format('YYYY-MM-DD 00:00:00') >= userQualificationItem.renewal_at;

          if ( !overDeadline ){
            return <></>;
          }
            underRequireUnitQualification.name         = userQualificationItem.qualification_name;
            underRequireUnitQualification.require_unit = correspondingQualification.require_unit;
            underRequireUnitQualificationArray.push({...underRequireUnitQualification});
        }

      }
    )
    const deduplicationUnderRequireUnitQualificationArray:underRequireUnitQualificationType[] = Array.from(new Set(underRequireUnitQualificationArray));

    return (
      <>
      {deduplicationUnderRequireUnitQualificationArray.map(underRequireUnitQualification => (
        <Background>
          <GetUnitReminderAlertTextBox>
            <GetUnitReminderAlertText>
              <UnitReminderQualificationName>{underRequireUnitQualification.name}</UnitReminderQualificationName>
                <UnitReminderTitleBox>
                  <UnitReminderTitle>資格更新のお知らせ</UnitReminderTitle>
                </UnitReminderTitleBox>
              <UnitReminderText>資格の更新時期が近づいてきました</UnitReminderText>
              <UnitReminderText>資格更新には
                <UnitReminderBold>{underRequireUnitQualification.require_unit}単位</UnitReminderBold>が必要です！！</UnitReminderText>
              <UnitReminderText>【更新ご希望の方】<UnitReminderBold>更新日までに単位を取得</UnitReminderBold>してください</UnitReminderText>
            </GetUnitReminderAlertText>
          </GetUnitReminderAlertTextBox>
        </Background>
    ))}
    </>
    )
};

export default GetUnitReminderAlert;