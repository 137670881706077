import React, {useCallback, useEffect, useMemo} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {useDispatch} from "react-redux";
import {useSelector} from "../../store";
import {fetchChatUserProfile} from "../../stores/chat_user_profile";
import {fetchUser} from "../../stores/user";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import ChatUserType from "../../interfaces/ChatUserType";
import UserType from "../../interfaces/UserType";
import {fetchChatTimeline} from "../../stores/chat_timeline";
import SnsHeader from "../../parts/SnsTab/SnsHeader";
import ChatProfileView from "../../parts/ChatProfilePage/ChatProfileView";
import ScreenView from "../../parts/ScreenView";
import ChatProfileEditButton from "../../parts/ChatProfilePage/ChatProfileEditButton";
import ChatProfileTimeline from "../../parts/ChatProfilePage/ChatProfileTimeline";
import ChatGroupType from "../../interfaces/ChatGroupType";
import ChatMessageType from "../../interfaces/ChatMessageType";

const ChatProfilePage: React.FC = () => {
    const {chat_user_profile, user, chat_timeline} = useSelector(state => ({
        chat_user_profile: state.chat_user_profile,
        user: state.user,
        chat_timeline: state.chat_timeline,
    }));
    const {id: userId} = useParams<{ id?: string; }>();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (userId) {
            dispatch(fetchChatUserProfile(userId));
        } else {
            dispatch(fetchChatTimeline({page: 1}));
        }
        dispatch(fetchUser());
    }, [dispatch, userId]);

    const profileUser = useMemo((): ChatUserType | UserType | null => {
        if (userId && chat_user_profile.item?.id !== user.user?.id) {
            return chat_user_profile.item;
        } else {
            return user.user;
        }
    }, [userId, chat_user_profile.item, user.user]);

    const onEditProfile = useCallback(() => {
        if (!profileUser) {
            return;
        }
        history.push('/sns/profile/edit');
    }, [history, profileUser]);

    const onPressChatGroup = (item: ChatGroupType) => {
        if (item.is_own) {
            history.push(`/sns/chat_groups/${item.id}`);
        } else {
            history.push(`/sns/apply_chat_groups/${item.id}`);
        }
    };

    const onShowChatDetail = useCallback((message: ChatMessageType) => {
        history.push(`/sns/chat_messages/${message.id}`);
    }, [history]);

    if (userId && chat_user_profile.isFetching) {
        return <WithLoadingScreen loading={true}/>
    }
    if (!userId && user.isProcessing) {
        return <WithLoadingScreen loading={true}/>
    }

    if (profileUser === null) {
        return <></>;
    }

    return (
        <>
            <Helmet>
                <title>SNSプロフィール</title>
            </Helmet>
            <SnsHeader user={user.user} canGoBack={true}/>
            <ChatProfileView user={profileUser}/>
            <ScreenView>
                <ChatProfileEditButton user={profileUser} onEdit={onEditProfile}/>
                <ChatProfileTimeline
                    user={profileUser}
                    chatTimeline={chat_timeline}
                    onPressChatGroup={onPressChatGroup}
                    onPressChatMessage={onShowChatDetail}/>
            </ScreenView>
        </>
    );
};

export default ChatProfilePage;