import React, {useMemo} from 'react';
import styled from "styled-components";
import Image from "./Image";
import arrowRight from '../assets/icons/arrow_right.png';
import arrowLeft from '../assets/icons/arrow_left.png';

const Container = styled.div`
`;

const Icon = styled(Image)`
  width: 100%;
  height: 100%;
`;

interface Props {
    size: number;
    color: string;
    leftArrow?: boolean;
}

const ArrowIcon: React.FC<Props> = (props) => {
    const {size, color, leftArrow = false} = props;
    const images = useMemo(() => [
        arrowRight,
        arrowLeft,
    ], []);
    return (
        <Container {...props} style={{width: size, height: size}}>
            <Icon src={images[leftArrow ? 1 : 0]} color={color}/>
        </Container>
    )
};

export default ArrowIcon;
