import React from 'react'

const nl2br = (text: string) => {
    if (text === null || text === undefined) {
        return '';
    }
    const regex = /(\n)/g;
    return text.split(regex).map((line, key) => {
        if (line.match(regex)) {
            return <br key={key}/>
        } else {
            return line;
        }
    });
};

export default nl2br;