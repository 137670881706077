import Axios from 'axios';
import config from "../config";
import {requestCanceler} from "./requestCanceler";
import toastr from "toastr";
import localforage from "localforage";

const axios = Axios.create({
    baseURL: config.apiRoot,
    responseType: 'json',
    cancelToken: requestCanceler.token(),
});

axios.interceptors.request.use(async config => {
    const token = await localforage.getItem('__token');
    if (token) {
        if (config.headers) {
            config.headers['Authorization'] = `Bearer ${token}`;
        } else {
            config.headers = {
                Authorization: `Bearer ${token}`
            }
        }
    }
    return config;
});

axios.interceptors.response.use(response => {
    return response;
}, async error => {
    if (error.response?.status === 401) {
        const {removeAuthenticate} = require('../stores/auth');
        await require('../store').store.dispatch(removeAuthenticate());
        setTimeout(() => {
            window.location.href = "/logout";
        }, 100);
    }
    if (error.response?.status === 403) {
        setTimeout(() => {
            window.location.href = "/404";
        }, 100);
    }
    if (error.response?.status === 441) {
        toastr.error('会員の有効期限が切れています。お支払いが必要です。');
        setTimeout(() => {
            window.location.href = "/purchase";
        }, 100);
    }
    if (error.response?.status === 442) {
        const {removeAuthenticate} = require('../stores/auth');
        await require('../store').store.dispatch(removeAuthenticate());
        setTimeout(() => {
            window.location.href = "/banned";
        }, 100);
    }
    if (error.response?.status === 443) {
        const {removeAuthenticate} = require('../stores/auth');
        await require('../store').store.dispatch(removeAuthenticate());
        setTimeout(() => {
            window.location.href = "/expired";
        }, 100);
    }
    return Promise.reject(error);
});

export default axios;
