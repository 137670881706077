import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import ContactParams from "../../interfaces/ContactParams";
import styled, {ThemeContext} from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import FlatButton from "../FlatButton";
import UserType from "../../interfaces/UserType";
import ErrorText from "../ErrorText";

const Section = styled.div`
  margin-top: 16px;
`;

const Label = styled(TitleTextBox)`
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  margin-top: 24px;
`;

const TextBox = styled.input`
  margin-top: 8px;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  height: 200px;
  margin-top: 8px;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const WarnText = styled(BodyTextBox)`
  color: ${({theme}) => theme.dangerColor};
  margin-top: 4px;
`;

const ButtonFlex = styled.div`
  flex-direction: row;
  @media screen and (min-width: 480px) {
    display: flex;
  }
`;

const SubmitButton = styled(FlatButton)`
  margin-top: 24px;

  @media screen and (min-width: 480px) {
    margin-top: 0;
  }
`;

const CancelButton = styled(FlatButton)`
  margin: 24px 0;
`;

interface Props {
    onSubmit: (params: ContactParams) => void;
    onCancel: () => void;
    user: UserType | null;
    errors: Object;
    oldParams: ContactParams | null | undefined;
}

const ContactForm: React.FC<Props> = ({onSubmit, onCancel, user, errors, oldParams}) => {
    const theme = useContext(ThemeContext);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        if (oldParams) {
            setName(oldParams.name);
            setEmail(oldParams.email);
            setMessage(oldParams.message);
        }
    }, [oldParams]);

    useEffect(() => {
        if (user !== null) {
            setName(user.name);
            setEmail(user.email || '');
        }
    }, [user]);

    const available = useMemo(() => {
        return name.length > 0 && email.length > 0 && message.length > 0;
    }, [name, email, message]);

    const sendSubmit = useCallback(() => {
        if (available) {
            onSubmit({name, email, message});
        }
    }, [available, onSubmit, name, email, message]);

    return (
        <Section>
            <Label>名前</Label>
            <TextBox value={name}
                     onChange={e => setName(e.target.value)}/>
            <ErrorText errors={errors} name={'name'}/>
            <Label>メールアドレス</Label>
            <TextBox value={email}
                     onChange={e => setEmail(e.target.value)}/>
            <ErrorText errors={errors} name={'email'}/>
            <WarnText>
                ※入力ミスにご注意ください。<br/>
                ※携帯会社メールアドレスの場合、返信が届かない場合がございます。<br/>
                ※ご返信はココリンク内で各個人にお送りいたします。お知らせに表示されます。<br/>
                ※試験に関するお問い合わせは、各協会へお問い合わせください。
            </WarnText>
            <Label>お問い合わせ内容</Label>
            <TextArea value={message}
                      onChange={e => setMessage(e.target.value)}/>
            <ErrorText errors={errors} name={'message'}/>
            <ButtonFlex>
                <SubmitButton text={'入力内容の確認画面へ'}
                              rightAllow
                              disabled={!available}
                              onPress={sendSubmit}/>
                <CancelButton text={'入力をやめる'}
                              backgroundColor={theme.whiteColor}
                              borderColor={theme.mainColor}
                              textColor={theme.mainColor}
                              onPress={onCancel}/>
            </ButtonFlex>
        </Section>
    );
};

export default ContactForm;
