import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import ContactParams from "../interfaces/ContactParams";
import axios from "../http/axios";

type SendContactState = {
    requestStatus: RequestStatusType;
    errors: Object;
}

const initialState: SendContactState = {
    requestStatus: "init",
    errors: {}
};

export const sendContact = createAsyncThunk<void, ContactParams>(
    'send_contact',
    async (params, thunk) => {
        try {
            await axios.post('contacts', params);
        } catch (e) {
            thunk.dispatch(setErrors(e.response?.data?.errors || {}));
            throw e;
        }
    }
);

export const sendContactSlice = createSlice({
    name: 'send_contact',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
        setErrors: (state, action: PayloadAction<Object>) => ({
            ...state,
            errors: action.payload
        })
    },
    extraReducers: builder => {
        builder
            .addCase(sendContact.fulfilled, state => ({
                ...state,
                requestStatus: 'success',
            }))
            .addCase(sendContact.pending, state => ({
                ...state,
                requestStatus: 'pending',
                errors: {}
            }))
            .addCase(sendContact.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }));
    }
});

export const {setErrors, resetRequestStatus} = sendContactSlice.actions;
