import NoticeType from "../interfaces/NoticeType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import PaginateMeta from "../interfaces/PaginateMeta";
import {fetchUnreadNotice} from "./unread_notice";

export type NoticeState = {
    items: NoticeType<any>[];
    isFetching: boolean;
    lastRequestMeta?: PaginateMeta;
};

const initialState: NoticeState = {
    items: [],
    isFetching: false,
    lastRequestMeta: undefined,
};

export const fetchNotices = createAsyncThunk<FetchReturnType<NoticeType<any>>, { page: number }>(
    'notices/fetch',
    async ({page}) => {
        const {data} = await axios.get(`notices?page=${page}`);
        return data as FetchReturnType<NoticeType<any>>;
    }
);

export const setReadNotice = createAsyncThunk<void, NoticeType<any>>(
    'notices/setRead',
    async (notice, thunkAPI) => {
        if (notice.is_read) {
            return;
        }
        await axios.post(`notices/${notice.id}/read`);
        await thunkAPI.dispatch(fetchUnreadNotice());
    }
);

export const setAllReadNotice = createAsyncThunk<void>(
    'notices/set_all_read',
    async (_, thunkAPI) => {
        await axios.post(`all_notices/read`);
        await thunkAPI.dispatch(fetchUnreadNotice());
    }
)

export const noticeSlice = createSlice({
    name: 'notice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchNotices.fulfilled, (state, action) => ({
                ...state,
                items: action.meta.arg.page > 1 ? [...state.items, ...action.payload.data] : action.payload.data,
                isFetching: false,
                lastRequestMeta: action.payload.meta,
            }))
            .addCase(fetchNotices.pending, state => ({
                ...state,
                isFetching: true,
            }))
            .addCase(fetchNotices.rejected, state => ({
                ...state,
                isFetching: false,
            }))
            .addCase(setReadNotice.fulfilled, (state, action) => ({
                ...state,
                items: state.items.map(notice => {
                    if (action.meta.arg.id === notice.id) {
                        return {...notice, is_read: true};
                    } else {
                        return notice;
                    }
                })
            }))
            .addCase(setAllReadNotice.fulfilled, (state, action) => ({
                ...state,
                items: state.items.map(notice => {
                    return {...notice, is_read: true}
                })
            }))
    }
});
