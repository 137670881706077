import React from 'react'
import {Redirect, Route, RouteProps} from 'react-router-dom'
import _ from 'lodash'
import {useSelector} from "../store";

const GuestRoute: React.FC<RouteProps> = props => {
    const {auth} = useSelector(state => ({
        auth: state.auth
    }));

    const rest = _.omit(props, ['component'])
    return (
        <Route
            {...rest}
            render={innerProps =>
                auth.authenticated ? (
                    <Redirect
                        to={{
                            pathname: '/home',
                            state: {from: innerProps.location}
                        }}
                    />
                ) : (
                    <Route {...props} />
                )
            }
        />
    )
}

export default GuestRoute
