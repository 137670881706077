import React from "react";
import ChatMessageType from "../../interfaces/ChatMessageType";
import styled from "styled-components";
import ThumbImageView from "../ThumbImageView";

const ImageThumb = styled(ThumbImageView)`
  margin: 8px 0;
  width: 300px;
  height: 300px;
  max-width: 100%;
`;

interface Props {
    message: ChatMessageType;
}

const ChatMessageDetailImage: React.FC<Props> = ({message}) => {
    if (message.file_url) {
        return <ImageThumb src={message.file_url}/>;
    } else {
        return <></>;
    }
};

export default ChatMessageDetailImage;