import React from 'react';
import ChatGroupType from "../../interfaces/ChatGroupType";
import styled from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";
import Icon from "../Icon";
import nl2br from "../../utils/nl2br";

const Section = styled.div`
  padding: 16px;
  background-color: #f0f9f4;
`;

const Title = styled(TitleTextBox)`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: ${({theme}) => theme.mainColor};
`;

const MemberCount = styled(BodyTextBox)`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: ${({theme}) => theme.textColor};
  margin-top: 4px;
`;

const Description = styled(BodyTextBox)`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: ${({theme}) => theme.textColor};
  margin-top: 16px;
`;

const PostBox = styled.div`
  margin: 16px auto 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PostTouchable = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PostIcon = styled(Icon)`
  color: ${({theme}) => theme.mainColor};
  font-size: 20px;
  margin-right: 4px;
`;

const PostTextBox = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.mainColor};
  border-bottom-style: solid;
`;

const PostText = styled(TitleTextBox)`
  color: ${({theme}) => theme.mainColor};
  font-size: 16px;
  font-weight: bold;
`;

const SettingBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
`;

const SettingLink = styled(TouchableOpacity)`
  color: white;
  background-color: ${({theme}) => theme.mainColor};
  border-radius: 8px;
  padding: 4px 8px;
`;

interface Props {
    group: ChatGroupType;
    isAdmin: boolean;
    onPost: () => void;
    onSetting: () => void;
}

const ChatGroupHeader: React.FC<Props> = ({group, onPost, onSetting, isAdmin}) => {
    return (
        <>
            <Section>
                <Title>{group.name}</Title>
                <MemberCount>(メンバー{group.users_count}人)</MemberCount>
                {group.description ? (
                    <Description>{nl2br(group.description)}</Description>
                ) : <></>}
            </Section>
            <SettingBox>
                <SettingLink onClick={onSetting}>{isAdmin ? 'グループ管理' : 'メンバー一覧'}</SettingLink>
            </SettingBox>
            <PostBox>
                <PostTouchable onClick={onPost}>
                    <PostIcon name={'edit'}/>
                    <PostTextBox>
                        <PostText>情報を投稿する</PostText>
                    </PostTextBox>
                </PostTouchable>
            </PostBox>
        </>
    )
};

export default ChatGroupHeader;
