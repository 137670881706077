import React, {useEffect, useState} from 'react';
import ScreenView from "../../parts/ScreenView";
import styled from "styled-components";
import ExamItemView from "../../parts/ExamPage/ExamItemView";
import ExamAnswerType from "../../interfaces/ExamAnswerType";
import AnswerList from "../../interfaces/ExamAnswerListType";
import ExamResultView from "../../parts/ExamPage/ExamResultView";
import {useDispatch} from "react-redux";
import {sendFinishVideo} from "../../stores/finish_video";
import ExamHeader from "../../parts/ExamPage/ExamHeader";
import SectionLabel from "../../parts/SectionLabel";
import {useSelector} from "../../store";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {fetchExam} from "../../stores/exam";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {Helmet} from "react-helmet-async";

const Name = styled(SectionLabel)`
  margin-top: 8px;
`;

const ExamPage: React.FC = () => {

    const {exam} = useSelector(state => ({
        exam: state.exam,
    }));

    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const [answers, setAnswers] = useState<AnswerList>({});
    const [finished, setFinished] = useState<boolean>(false);
    const dispatch = useDispatch();
    const {id: videoId} = useParams<{ id: string; }>();

    useEffect(() => {
        dispatch(fetchExam(videoId));
    }, [videoId, dispatch]);

    useEffect(() => {
        if (exam.item === undefined) {
            return;
        }
        if (finished) {
            const grade = exam.item.items.reduce((grade, item) => {
                if (answers[item.id].is_correct) {
                    return grade + item.grade;
                } else {
                    return grade;
                }
            }, 0);

            if (grade >= exam.item.passing_grade) {
                dispatch(sendFinishVideo(videoId));
            }
        }
    }, [answers, dispatch, exam.item, finished, videoId]);

    const onAnswer = (answer: ExamAnswerType) => {
        if (exam.item === undefined) {
            return;
        }
        const newAnswer = {...answers};
        newAnswer[item.id] = answer;
        setAnswers(newAnswer);
        if (exam.item.items.length > (page + 1)) {
            setPage(page + 1);
        } else {
            setFinished(true);
        }
    };

    const onGoBack = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const onRetry = () => {
        setFinished(false);
        setPage(0);
        setAnswers({});
    };

    const onGoBackVideoPage = () => {
        history.push('/video');
    };

    if (exam.isProcessing) {
        return <WithLoadingScreen loading={true}/>
    }
    if (exam.item === undefined) {
        return <Redirect to={'/video'}/>
    }

    const item = exam.item.items[page];
    return (
        <>
            <Helmet>
                <title>{exam.item.name}</title>
            </Helmet>
            <ExamHeader/>
            <ScreenView>
                <Name title={exam.item.name}/>
                {finished ? (
                    <ExamResultView answers={answers}
                                    exam={exam.item}
                                    onRetry={onRetry}
                                    onGoBack={onGoBackVideoPage}/>
                ) : (
                    <ExamItemView exam={exam.item}
                                  item={item}
                                  page={page}
                                  initialAnswer={answers[item.id]}
                                  onGoBack={onGoBack}
                                  onAnswer={onAnswer}/>
                )}
            </ScreenView>
        </>
    );
};

export default ExamPage;
