import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import CategoryType from "../interfaces/CategoryType";
import axios from "../http/axios";
import {FetchReturnType} from "../interfaces/FetchReturnType";

export type CategoryState = {
    items: CategoryType[];
    isFetching: boolean;
    selectQualificationId?: number;
}

const initialState: CategoryState = {
    items: [],
    isFetching: false,
    selectQualificationId: undefined,
};

export const fetchCategories = createAsyncThunk<FetchReturnType<CategoryType>, number>(
    'categories/fetch',
    async (qualificationId) => {
        const {data} = await axios.get(`qualifications/${qualificationId}/categories`);
        return data as FetchReturnType<CategoryType>;
    }
);

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.fulfilled, (state, action) => {
                return {
                    ...state,
                    items: action.payload.data,
                    isFetching: false,
                    selectQualificationId: action.meta.arg,
                }
            })
            .addCase(fetchCategories.pending, (state, action) => {
                return {
                    ...state,
                    items: state.selectQualificationId === action.meta.arg ? state.items : [],
                    isFetching: true
                }
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                return {
                    ...state,
                    isFetching: false
                }
            })
    }
});
