import ChatMessageType from "../interfaces/ChatMessageType";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import PaginateMeta from "../interfaces/PaginateMeta";
import RequestStatusType from "../interfaces/RequestStatusType";
import * as toastr from "toastr";
import imageCompress from "../utils/imageCompress";
import {fetchChatTimeline} from "./chat_timeline";

export type ChatMessageState = {
    items: ChatMessageType[];
    isFetching: boolean;
    selectGroupId?: number;
    lastRequestMeta?: PaginateMeta;
    requestStatus: RequestStatusType;
};

const initialState: ChatMessageState = {
    items: [],
    isFetching: false,
    selectGroupId: undefined,
    lastRequestMeta: undefined,
    requestStatus: 'init',
};

export const fetchChatMessages = createAsyncThunk<FetchReturnType<ChatMessageType>, {
    groupId: any,
    page: number,
}>(
    'chat_messages/fetch',
    async (arg) => {
        const {data} = await axios.get(`chat_groups/${arg.groupId}/messages?page=${arg.page}`);
        return data as FetchReturnType<ChatMessageType>;
    }
);

type SendChatMessageParam = {
    groupId: any;
    parentId?: number;
    text: string;
    uploadFile: File | null;
}

export const sendChatMessage = createAsyncThunk<void, SendChatMessageParam>(
    'chat_messages/send',
    async (arg, thunk) => {
        const formData = new FormData();
        formData.append('text', arg.text);
        if (arg.parentId) {
            formData.append('parent_id', `${arg.parentId}`);
        }
        if (arg.uploadFile) {
            const compressFile = await imageCompress(arg.uploadFile);
            formData.append('file', compressFile);
        }
        await axios.post(`chat_groups/${arg.groupId}/messages`, formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            }
        });
        toastr.success('メッセージを投稿しました。');
        thunk.dispatch(fetchChatMessages({
            groupId: arg.groupId,
            page: 1
        }));
        thunk.dispatch(fetchChatTimeline({
            page: 1
        }));
    }
);

type EditChatMessageParam = {
    id: number | string;
    groupId: number;
    text: string;
    uploadFile: File | null;
}

export const editChatMessage = createAsyncThunk<void, EditChatMessageParam>(
    'chat_messages/edit',
    async (arg, thunk) => {
        const formData = new FormData();
        formData.append('text', arg.text);
        if (arg.uploadFile) {
            const compressFile = await imageCompress(arg.uploadFile);
            formData.append('file', compressFile);
        }
        await axios.post(`chat_messages/${arg.id}`, formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                'X-HTTP-Method-Override': 'PUT'
            }
        });
        toastr.success('メッセージを編集しました。');
        thunk.dispatch(fetchChatMessages({
            groupId: arg.groupId,
            page: 1
        }));
        thunk.dispatch(fetchChatTimeline({
            page: 1
        }));
    }
);

export const deleteChatMessage = createAsyncThunk<void, ChatMessageType>(
    'chat_messages/delete',
    async (arg, thunk) => {
        await axios.delete(`chat_messages/${arg.id}`);
        toastr.success('メッセージを削除しました。');
        thunk.dispatch(fetchChatMessages({
            groupId: arg.group.id,
            page: 1
        }));
        thunk.dispatch(fetchChatTimeline({
            page: 1
        }));
    }
);

export const chatMessageSlice = createSlice({
    name: 'chat_message',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
        updateItem: (state, action: PayloadAction<ChatMessageType>) => {
            return {
                ...state,
                items: state.items.map((s) =>{
                    if (s.id === action.payload.id) {
                        return {...action.payload};
                    } else {
                        return s;
                    }
                }),
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchChatMessages.fulfilled, (state, action) => ({
                ...state,
                items: action.meta.arg.page > 1 ? [...state.items, ...action.payload.data] : action.payload.data,
                isFetching: false,
                selectGroupId: action.meta.arg.groupId,
                lastRequestMeta: action.payload.meta,
            }))
            .addCase(fetchChatMessages.pending, (state, action) => {
                if (action.meta.arg.groupId === state.selectGroupId) {
                    return {
                        ...state,
                        isFetching: true
                    }
                } else {
                    return {
                        ...state,
                        items: [],
                        isFetching: true,
                        lastRequestMeta: undefined,
                    }
                }
            })
            .addCase(fetchChatMessages.rejected, (state) => {
                return {
                    ...state,
                    items: [],
                    isFetching: false
                }
            })
            .addCase(sendChatMessage.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(sendChatMessage.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(sendChatMessage.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
            .addCase(editChatMessage.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(editChatMessage.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(editChatMessage.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
            .addCase(deleteChatMessage.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(deleteChatMessage.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(deleteChatMessage.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }));
    }
});

export const {resetRequestStatus, updateItem} = chatMessageSlice.actions;

