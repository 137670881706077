import React, {useContext, useEffect, useRef} from 'react';
import {ConfigContext} from "../config";
import WithLoadingScreen from "./WithLoadingScreen";
import {useHistory} from "react-router-dom";

interface Props {
    params?: any;
    paymentUrl?: string;
}

const PaymentWebView: React.FC<Props> = ({params, paymentUrl}) => {
    const config = useContext(ConfigContext);
    const history = useHistory();
    const ref = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (params !== undefined && params !== null) {
            if (parseInt(params['Amount']) === 0) {
                history.replace('/payment/success?free=1');
            } else {
                ref.current?.submit();
            }
        }
    }, [history, params]);

    if (params === undefined || params === null) {
        return <WithLoadingScreen loading={true}/>
    }

    return (
        <form action={paymentUrl || config.paymentUrl} ref={ref} method={'post'}>
            {Object.keys(params).map(key => (
                <input type='hidden' name={key} value={params[key]} key={key}/>
            ))}
        </form>
    );
};

export default PaymentWebView;

