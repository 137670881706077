import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import BankEntryParam from "../interfaces/BankEntryParam";
import axios from "../http/axios";
import RequestErrorType from "../interfaces/RequestErrorType";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";

export type SubmitResult = {
    RetURL: string;
    TranID: string;
    Token: string;
}

export type BankTransactionState = {
    requestStatus: RequestStatusType;
    errors: RequestErrorType;
    submitResult?: SubmitResult;
}

const initialState: BankTransactionState = {
    requestStatus: "init",
    errors: {}
}

export const submitBankTransaction = createAsyncThunk<FetchDetailReturnType<SubmitResult>, BankEntryParam>(
    'submit_bank_transaction',
    async (args, thunk) => {
        const {data} = await axios.post('user/payments/bank', args).catch(error => {
            thunk.dispatch(setErrors(error.response?.data?.errors || {}));
            throw error
        });
        return data as FetchDetailReturnType<SubmitResult>;
    }
);

export const bankTransactionSlice = createSlice({
    name: 'bank_transaction',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init', submitResult: undefined}),
        setErrors: (state, action: PayloadAction<RequestErrorType>) => ({...state, errors: action.payload})
    },
    extraReducers: builder => {
        builder
            .addCase(submitBankTransaction.fulfilled, (state, action) => ({
                ...state,
                requestStatus: 'success',
                submitResult: action.payload.data,
            }))
            .addCase(submitBankTransaction.pending, state => ({
                ...state,
                requestStatus: 'pending',
                submitResult: undefined,
            }))
            .addCase(submitBankTransaction.rejected, state => ({
                ...state,
                requestStatus: 'failed',
                submitResult: undefined,
            }))
    }
});

export const {resetRequestStatus, setErrors} = bankTransactionSlice.actions;