import React, {useMemo} from "react";
import {Helmet} from "react-helmet-async";
import PageHeader from "../../parts/Headers/PageHeader";
import ScreenView from "../../parts/ScreenView";
import styled from "styled-components";
import FlatButton from "../../parts/FlatButton";
import {useHistory} from "react-router-dom";
import moment from "moment";

const Information = styled.div`
  margin-bottom: 24px;
`;

const ChangeCredit: React.FC = () => {

    const history = useHistory();

    const membership_fee = useMemo(() => {
        
        // 2025年以降、または2月以降の場合は550円
        if (moment().year() >= 2025 || moment().month() >= 1){
            return '550円';
        } else {
            return '500円';
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>クレジットカードの変更</title>
            </Helmet>
            <PageHeader title={'クレジットカードの変更'}/>
            <ScreenView>
                <Information>
                    クレジットカード払いの登録変更を行います。（カード番号の変更・更新）
                </Information>
                <Information>
                    なおカード番号の変更時、カード番号の有効性を確認するため、1ヶ月分のご利用料金({membership_fee})分の与信枠を確保いたします。<br/>
                    通常すぐキャンセルされますが、翌月分のご利用料金の決済に失敗していた場合は、翌月のご利用料金として決済処理を行わせていただきます。<br/>
                    また、デビットカード、プリペイドカードなどの即時にご利用代金を口座から引き落としするサービスでは、与信枠を確保した段階で即時お引き落しされる場合があります。<br/>
                    その場合は、自動的に取り消されカード会社より返金されます。返金までの期間についてはカード会社にご確認ください。
                </Information>
                <FlatButton text={'変更へ'}
                            onPress={() => history.push('/payment/change/exec')}/>
            </ScreenView>
        </>
    )
};

export default ChangeCredit;