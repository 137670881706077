import ChatGroupType from "../interfaces/ChatGroupType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type ChatGroupDetail = {
    item: ChatGroupType | null;
    isFetching: boolean;
}

const initialState: ChatGroupDetail = {
    item: null,
    isFetching: false
};

export const fetchChatGroupDetail = createAsyncThunk<FetchDetailReturnType<ChatGroupType>, any>(
    'chat_group.detail',
    async (param) => {
        const {data} = await axios.get(`/chat_groups/${param}`);
        return data as FetchDetailReturnType<ChatGroupType>;
    }
)

export const chatGroupDetailSlice = createSlice({
    name: 'chat_group_detail',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchChatGroupDetail.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                item: action.payload.data
            }))
            .addCase(fetchChatGroupDetail.rejected, state => ({
                ...state,
                isFetching: false,
            }))
            .addCase(fetchChatGroupDetail.pending, state => ({
                ...state,
                isFetching: true,
                item: null
            }))
    }
})