import ChatMessageReactionType from "../interfaces/ChatMessageReactionType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type ChatMessageReactionState = {
    items: ChatMessageReactionType[];
    selectChatMessageId?: number;
    isFetching: boolean;
}

const initialState: ChatMessageReactionState = {
    items: [],
    selectChatMessageId: undefined,
    isFetching: false
}

export const fetchChatMessageReactions = createAsyncThunk<FetchReturnType<ChatMessageReactionType>, any>(
    'chat_message_reaction/fetch',
    async (args) => {
        const {data} = await axios.get(`reactions/chat_message/${args}`);
        return data as FetchReturnType<ChatMessageReactionType>;
    },
)

export const chatMessageReactionSlice = createSlice({
    name: 'chat_message_reaction',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchChatMessageReactions.fulfilled, (state, action) => ({
                ...state,
                items: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchChatMessageReactions.rejected, (state, action) => ({
                ...state,
                isFetching: false
            }))
            .addCase(fetchChatMessageReactions.pending, (state, action) => ({
                ...state,
                items: action.meta.arg === state.selectChatMessageId ? state.items : [],
                isFetching: true
            }))
    }
})