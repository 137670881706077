import React, { useEffect } from "react";
import ScreenView from "../parts/ScreenView";
import {Helmet} from "react-helmet-async";
import styled from "styled-components";

const Title = styled.h1`
  margin: 0;
`;

const SectionTitle = styled.h4`
`;

const Body = styled.div`
`;

const Agreement: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // url が変更されてからスクロール量がリセットされる
    });

    return (
        <>
            <Helmet>
                <title>ココリンク - 利用規約</title>
            </Helmet>
            <ScreenView>
                <Title>ココリンク利用規約・個人情報の取扱いについて</Title>
                <SectionTitle>第 1 条（総則）</SectionTitle>
                <Body> ココリンクとは、アステッキホールディングス株式会社が運営する、ココリンクに加盟する各協会試験実施団体の認定資格を有した者が、学びを深めながら同じ資格を有する仲間と自由につながることができる【学びのためのコミュニティ】です。求められるニーズや持つべき姿勢は時代そして社会の在りようと共に変化していくものです。よって、資格を取得して終わりではなく、資格取得後も学び続け、資格保持者同士でつながることのできるシステムを構築しました。学習・コミュニティづくり・資格情報の管理が本システムですべて完結します。学びたいことは自由に自分で選択し、仕事やプライベートの空いた時間で効率的に学ぶことがこれからの新たなキャリアアップのカタチであると思います。そして、医療や介護の専門分野の枠組みに捉われず、多角的な視点をもった人材育成を目指します。</Body>
                <ol type="1">
                    <li><p>サービス内容及び利用規約の承諾</p></li>
                </ol>
                <p>アステッキホールディングス株式会社（以下「当社」といいます）は、動画配信、イベント管理、SNS機能及びその他のサービス（以下「本サービス」と総称します）を提供します。本サービスは、当社が運営するアプリケーション「ココリンク」を通じて利用することができます。<br/>
                    ユーザーは、本サービスの利用に際し、各協会試験実施団体規約、ココリンク利用規約(以下「本利用規約」といいます)の他、当社の定める各種利用規約への同意が必要です。予め同規約に同意されない場合、本サービスをご利用いただくことはできません。<br/>
                    本サービスは、以下の i ないし iii (以下｢本プロパティ｣)において視聴することができます。
                </p>
                <ol type="i">
                    <li>
                        <blockquote>
                            <p>当社のウェブサイト</p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>当社のスマートフォンアプリ【ココリンク】</p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>当社が許可したアプリケーション又はデバイス等</p>
                        </blockquote>
                    </li>
                </ol>
                <ol type="1">
                    <li><p>利用規約の変更について<br/>
                    当社は、その裁量により、随時本利用規約の一部を変更、修正、追加または削除する権利を有します。当社が本利用規約を変更した場合、本サービスの内容および提供条件は、変更後の規約によるものとします。ユーザーの責任において当社のお知らせブログにてお知らせします。本利用規約の変更に関する定期的なチェックを行ってください。変更後も引き続き本サービスを使用されたことにより、当該変更に関するユーザーの同意があったものとみなします。ユーザーが本利用規約を遵守する限りにおいて、当社は、ユーザーに、本サイトにアクセスし、本サービスを使用する個人的、非独占的、譲渡不可能かつ制限的な権利を付与します。<br/>
                    当社は、本利用規約の変更または廃止によりユーザーに生じた損害について一切の責任を負わないものとします。</p></li>
                    <li><p>本サービスの利用について</p></li>
                </ol>
                <ul>
                    <li><p>ユーザーは、本サービスにて該当資格の更新に関する学習および単位の管理を行います。<br/>
                    本サービスへの入会は、資格認定登録時（当社が指定した新規登録期間）および3年毎の更新時のみとし、途中入会は原則不可とします。</p></li>
                    <li><p>動画視聴・SNS機能・ミニテスト機能は各協会試験実施団体の認定資格保持者のみが使用できます。</p></li>
                    <li><p>ユーザーは、本サービスを利用するために、当社が提供する ID およびパスワードが必要となります。</p></li>
                    <li><p>ID およびパスワードによる認証ができない場合、ユーザーは、本サービスを利用することができません。</p></li>
                    <li><p>ID・パスワードをユーザーの責任において管理し、第三者に開示してはなりません。</p></li>
                </ul>
                <SectionTitle>第 2 条（利用規約で使用する用語の定義）</SectionTitle>
                <ul>
                    <li><p>認定資格保持者：各協会試験実施団体の認定試験に合格し、該当資格の認定登録をしている者</p></li>
                    <li><p>ユーザー：上記認定資格保持者および本サービスユーザー</p></li>
                    <li><p>単位：当社が認定している資格の更新に必要な単位</p></li>
                    <li><p>更新：上記認定資格保持者が資格保持をするために必要な手続き</p></li>
                    <li><p>イベント管理：オンラインセミナー、集合型セミナー、交流会など当社もしくは関連団体などが実施する各種イベントの管理</p></li>
                    <li><p>SNS：当社の認定資格保持者が使用できるコミュニケーションツール</p></li>
                </ul>
                <SectionTitle>第 3 条（本件契約の成立および継続）</SectionTitle>
                <ol type="1">
                    <li><p>ココリンク利用契約（以下「本件契約」といいます。)は、利用料納入済みの者が、本サービスの利用を当社に申込み、当社がこれを承諾した時点で成立するものとします。</p>
                    </li>
                    <li><p>当社は、次の場合には、本件契約の申込みを承諾しないことがあります。<br/>
                        (1)本件契約の申込者が、本利用規約に違反するおそれがある場合、本サービス以外の当社の提供するサービス利用契約に従ってサービスをご利用いただけていない場合、過去に違反したことがある、または違反のおそれがあると当社において判断した場合
                    </p></li>
                </ol>
                <p>(2)各協会試験実施団体の認定試験に合格し、認定資格の認定登録をした資格保持者でない場合<br/>
                    (3)本サービスの利用用途が当社の利用規約に違反すると当社において判断した場合<br/>
                    (4)当社が本サービスを提供するにあたり、当社の業務遂行上支障が生じる場合、またはそのおそれがあると当社において判断した場合<br/>
                    (5)その他、利用申込者をユーザーとすることが不適切であると当社において判断した場合</p>
                <p>当社は、ユーザーの承諾を得ることなく、本サービスの全部または一部を変更し、または廃止できるものとします。当社は、本サービスの変更または廃止によりユーザーに生じた損害について一切の責任を負わないものとします。</p>
                <SectionTitle>第 4 条（本サービスの利用）</SectionTitle>
                <ol type="1">
                    <li><p>資格制限<br/>
                    ユーザーが各協会試験実施団体の認定資格を持たない場合、本サービスを利用することはできません。<br/>
                    （但し、サンプル動画の視聴、イベント申し込み、問い合わせを行うことは可能です）</p></li>
                    <li><p>ユーザーのライセンス<br/>
                    当社はユーザーに対し、継続的に利用料を支払い、かつ本利用規約を遵守することを条件として、本サービスを継続して利用することができる非独占的な限定的ライセンスを付与します。かかるライセンスには、個人利用かつ非営利の目的のために、本ビデオプレーヤーでココリンクにて提供される動画（以下「本コンテンツ」といいます。)をストリーミング配信の方法により視聴することができる権利が含まれます。ユーザーは、本利用規約に従って、個人的かつ非営利目的でのみ本コンテンツを視聴することができます。
                    </p></li>
                    <li><p>禁止事項<br/>
                    ユーザーは、当社が予め書面にて許可した場合を除き、本サービスを利用中であるかキャンセル後であるかを問わず、以下の各事項を行うことはできません。</p></li>
                {/* </ol> */}
                <ol type="i">
                    <li>
                        <blockquote>
                            <p>本コンテンツに付された著作権、商標権又はその他の権利に関する表示や、本コンテンツのデジタル・ライツ・マネジメント(DRM)、コンテンツ保護手段、若しくはアクセス制御手段(ジオフィルタリング・メカニズムを含みます)を、削除、変更、迂回、無効化、妨害又は回避すること。</p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>本コンテンツをコピー、ダウンロード、ストリームキャプチャ、複製、複写、アーカイブ保管、配信、アップロード、公開、変更、翻訳、放映、実行、表示、販売、送信又は再送信すること。</p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>本コンテンツをハードウェアやソフトウェアに組み込んだり、又はかかるハードウェア若しくはソフトウェアによって本コンテンツをストリーム配信若しくは再送信したりすること、並びに、フレーム又はインラインリンクによって本コンテンツの利用を可能にすること。</p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>本コンテンツの重要な部分についてインデックスを作成、再現、配信又は広告すること。</p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>営利目的か否かに関わらず、本コンテンツを利用して事業を立ち上げること(本コンテンツには、テキスト、グラフィックス、レイアウト、インターフェース、ロゴ、写真、録音・録画資料及びスチール写真が含まれますが、これらに限定されません)。</p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>本コンテンツの二次的著作物や本コンテンツに依拠又は由来し、若しくはベースとする素材(モンタージュ、マッシュアップ並びに類似のビデオ、壁紙、デスクトップテーマ、グリーティングカード及び商品を含みます)を作成すること(二次的著作物である素材を無償提供するために行う場合を含みます)。</p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>本ビデオプレーヤー、本ビデオプレーヤーに利用される技術、本ビデオプレーヤーに組み込まれたDRM、コンテンツ保護手段、アクセス制御手段を、そのいかなる部分、いかなる態様であれ、修正、改良、削除、妨害又はその他の方法で変更すること。なお、かかる制限事項には、以下の各事項及びその他の本ビデオプレーヤーを無効化、リバースエンジニアリング、変更、妨害又はその他の方法により回避することが含まれますが、これらに限られません。
                                ①本ビデオプレーヤー及び本ビデオプレーヤーが配置されるウェブページ上のすべての周辺要素(グラフィカル・ユーザー・インターフェース並びに一切の広告、著作権表示及び商標を含みます)の双方を表示せずに本コンテンツを視聴できるようにすること、及び、<br/>
                                ②本ビデオプレーヤーのすべての機能(ビデオの画質及びディスプレイに関するすべての機能並びに双方向、選択的又はクリックスルー広告に関するあらゆる機能等)にアクセスせずに、本コンテンツを視聴できるようにすること。<br/>
                            </p>
                        </blockquote>
                    </li>
                </ol>
                {/* <ol type="1"> */}
                    <li><p>権利帰属<br/>
                        本サービス及び本コンテンツは、著作権法、商標法及びその他の知的財産関連法によって保護されています。</p></li>
                    <li><p>ユーザーの責任事項<br/>
                    本サービスをすべての皆様に安全にご利用いただくために、ユーザー、その他のユーザー及び当社が同じルールに従う必要があります。ユーザー及びその他のユーザーは、適法で、非営利かつ適切な目的のためにのみ本サービスを利用するものとします。<br/>
                    ユーザーは、本サービス、本コンテンツ及び本ビデオプレーヤーに関する上記1から5の制限事項を遵守すること及び、以下の態様で本サイトにアクセスし又は本サービスを利用しないことについて同意するものとします。
                    </p>
                        <ol type="i">
                            <li><p>第三者の権利(特許権、商標権、営業秘密、著作権、プライバシー、パブリシティ、肖像権その他の所有権又は人格権を含む)を侵害すること。</p></li>
                            <li>
                                <p>本サービス(本コンテンツを含みます)にアクセスし、インデックス若しくはフレームを作成し、又はリンクするために当社によって許可されていない技術又はその他の手段を利用すること(本サービスの不正なダウンロード、ストリームキャプチャ、リンク設定、フレーム作成、複製、アクセス又は配信の防止を目的とするコンテンツ保護手段又はアクセスコントロール手段を削除、無効化、迂回又は回避することを含みます)。</p>
                            </li>
                            <li>
                                <p>｢ロボット｣、｢スパイダー｣又は｢オフラインリーダー｣を含む自動化された手段によって本サービス(本コンテンツを含みます)にアクセスすること(本サービスの一般的に入手可能なサーチインデックス(但し、キャッシュ又はアーカイブではない)の作成のみを目的として、かかる目的に必要な範囲でのみ、一般的にアクセス可能なサーチエンジン上で個人的に行われるサーチによるものを除き、また、第三者の権利を侵害するコンテンツ又は不正なコンテンツのホスティング若しくは宣伝を行うか、又はかかるコンテンツを主たるリンク先とするサーチエンジン又はインデックスを除きます)。</p>
                            </li>
                            <li>
                                <p>ウィルス、あるいはコンピュータのソフトウェア若しくはハードウェア又は通信機器の機能を中断、破壊又は制限するその他のコンピュータコード、ファイル又はプログラムを持ち込むこと。</p>
                            </li>
                            <li><p>本サービス(当社のサーバー、コンピュータネットワーク又はユーザーアカウントを含みます)を毀損し、無効化し、過度の負担を課し、損ない、又は不正にアクセスすること。</p>
                            </li>
                            <li><p>当社によって事前に書面で明示的に承認されていないサービスを広告又は宣伝する目的で本サービスを利用すること。</p></li>
                            <li><p>個人情報を収集すること。</p></li>
                            <li><p>刑事犯罪を構成し又は民事責任を生じるような行為を助長すること。</p></li>
                            <li><p>本利用規約又は当社によって掲載される指針若しくは方針に違反すること。</p></li>
                            <li><p>第三者による本サービスの利用及び享受を妨害すること。</p></li>
                            <li><p>上記のいずれかを企てること。</p></li>
                        </ol>
                    </li>
                </ol>
                <p>当社が、当社の単独の裁量において、ユーザーが本利用規約に違反したと判断した場合、当社は、</p>
                <ol type="i">
                    <li>
                        <blockquote>
                            <p>ユーザーに通知を行い、かつ、</p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>技術的手段を用いて、ユーザーの本サービスへのアクセス又はご利用を中止又は制限することができるものとします。その場合、ユーザーは、いかなる方法であっても本サービスへのアクセス又はご利用(あるいはアクセス又はご利用を試みること)を直ちに中止することに同意し、かつかかる制限を逃れたり、回避したり、又は迂回したり、あるいはかかるアクセス又は利用をその他の方法で回復したり、又は回復しようと試みたりしないことに同意するものとします。(あるいはアクセス又はご利用を試みること)を直ちに中止することに同意し、かつかかる制限を逃れたり、回避したり、又は迂回したり、あるいはかかるアクセス又は利用をその他の方法で回復したり、又は回復しようと試みたりしないことに同意するものとします。</p>
                        </blockquote>
                    </li>
                </ol>
                <ol type="1">
                    <li><p>スパムメール/未承諾メール等の禁止<br/>	
                    スパムメールや相手方が承諾していない迷惑メール等の送信、又は送信を補助、若しくは助長することを目的とした情報収集のために本サービスを利用することは禁止されます。ユーザーは、スパムメール又は未承諾の迷惑メール等が当社のコンピュータネットワーク又は通信ネットワークへの侵入、利用又は滞留を防止するために、当社があらゆる技術的是正措置を講じることについて同意するものとします。ユーザーは、本サービスを利用してスパムメール、広告メール又はその他のいずれかの種類の未承諾メール等を投稿し、又はその他の方法で送信した場合に、当社が重大な損害を被ること、及び、その場合に当社が被った損害額を算定することが極めて困難であることを予め了解し、かかる場合における損害賠償の予約として、ユーザーは、ユーザーが本サービスを利用して送信したスパムメール又は迷惑メール等1件につき4,000円を当社に支払うことに予め同意するものとします。また、実際に生じた損害がこの金額を上回る場合には、ユーザーに対し、実際に生じた損害の賠償を請求できる。
                    </p></li>
                    <li><p>インターネット利用料<br/>
                    インターネットを利用することに関して発生する一切の費用は、ユーザーの負担といたします</p></li>
                </ol>
                <SectionTitle>第 5 条（本サービスアカウントについて）</SectionTitle>
                <ol type="1">
                    <li>
                        <p>本サービスの加入にあたっては、アカウントを開設することが必要となります。ユーザーはアカウントのあらゆる利用について責任を負うものとします。ユーザーは第三者に本アカウントを使用させてはなりません。また、第三者への譲渡・貸与等は一切禁止します。</p>
                    </li>
                    <li>
                        <p>ユーザーが提出するすべてのアカウント情報は正確かつ最新のものでなければなりません。ユーザーは、ユーザーのパスワードを秘密情報として取り扱い、自らの責任において厳密に管理するものとします。ユーザーのアカウントが不正使用されたことが判明した場合、又は不正使用の疑いがある場合は、<a
                            href="https://app.chatplus.jp/chat/visitor/1eae23a2_6?t=btn"><u>お問い合わせフォーム</u></a>により直ちに当社までお知らせ下さい。</p>
                    </li>
                    <li><p>ユーザーのアカウント情報に変更があった場合は、当社がユーザーにご連絡する際に支障のないよう、必ず当社までお知らせ下さい。</p></li>
                    <li>
                        <p>当社は、ユーザーが本利用規約、関連する各システムのガイドライン又は法令・規則に違反し、若しくはその他の不適切な行為を行ったと当社の単独の裁量により判断した場合、又はその他の業務上の理由に基づき、ユーザーのアカウント又はユーザーによる本サービスの利用若しくは本コンテンツへのアクセスを、事前の通知をすることなく、かつ何らの責任を負うことなく、いつでも、直ちに終了又は制限する権利を留保するものとします。</p>
                    </li>
                    <li>
                        <p>当社は、ユーザーによる本システムの利用が、当社のネットワーク又はサーバーに不当な負担をかけた場合、ユーザーのアカウント又はユーザーによる本サービスの利用若しくは本コンテンツへのアクセスを終了させる権利を留保するものとします。なお、当社は、当社のサーバーに対して行われるコール数の容量等を制限する技術を使用することができるものとし、ユーザーは、これらの制限に従い、それらを回避、無効化、又は迂回するためのいかなる手段も講じないことに同意することとします。</p>
                    </li>
                </ol>
                <SectionTitle>第 6 条（ユーザーによる解約）</SectionTitle>
                <p>ユーザーがアカウントを解約しようとするときは、ユーザーは当社所定の方法（第7条4項）により当社に通知するものとします。当該通知の当社へ到達日から起算する最短で到来する契約更新日の前日をもって、本件契約が解約されるものとします。</p>
                <p>ただし、電子媒体での当該通知については、当社への到達日の翌日から起算されるものとします。<br/>
                解約後、本システムにて取得した更新単位はすべて無効となります。</p>
                <SectionTitle>第 7 条（当社による本件契約の解除）</SectionTitle>
                <ol type="1">
                    <li>
                        <p>ユーザーが以下の各号のいずれかに該当した場合、当社は、通知催告等何らの手続を要することなく、本サービスの提供を停止し、本件契約を解除しまたはユーザーの資格を取り消すことができるものとします。<br/>
                            (1)ユーザーが本利用規約の定めに違反し、または違反するおそれのある行為を行い、当社から当該行為の是正を求められたにもかかわらず、相当の期間内にこれを是正しなかった場合<br/>
                            (2)ユーザーが、当社の提供する本サービス以外の当社の提供するサービスの利用にかかる契約に準じサービスをご利用いただけていない場合、または違反のおそれがあると当社において判断した場合<br/>
                            (3)ユーザーが、当社規約に違反した場合または違反のおそれがあると当社において判断した場合<br/>
                            (4)当社が本サービスを提供するにあたり、当社の業務遂行上支障が生じる場合またはそのおそれがあると当社において判断した場合<br/>
                            (5)その他、ユーザーが本サービスを利用することが不適切と当社において判断した場合</p></li>
                </ol>
                <SectionTitle>第 8 条（通知）</SectionTitle>
                <ol type="1">
                    <li><p>当社が本件サービスに関してユーザーへ通知を行う場合、当社Web サイト、ユーザーへの電子メールの送信またはその他当社が適当と認める方法により行うものとします。</p></li>
                </ol>
                <SectionTitle>第 9 条（本サービスの一時中断等）</SectionTitle>
                <ol type="1">
                    <li><p>当社は、次の各号のいずれかに該当する場合、ユーザーの同意を得ることなく、本サービスの全部もしくは一部の提供を一時中断しまたは一時停止することができるものとします<br/>
                        (1)火災、停電、天災等の不可抗力その他当社の責めに帰すべからざる事由に起因して本サービスの提供が不可能または困難になった場合<br/>
                        (2)交通事情、気象状況等により本サービスの提供が当社の事業遂行上支障があると判断する場合<br/>
                        (3)その他、当社が合理的な理由により、本サービスの提供を一時中断または一時停止する必要があると判断した場合</p></li>
                    <li><p>前項に基づき当社が行ったサービスの一時中断または一時停止に関して、当社はユーザーに対していかなる責任も負いません。</p></li>
                </ol>
                <SectionTitle>第 10 条（免責）</SectionTitle>
                <ol type="1">
                    <li><p>当社は、本サービスについて、その安全性、正確性、確実性、有用性、発生したトラブルの解決、ユーザーが意図する特定の目的との適合性等を何ら保証するものではありません。</p></li>
                    <li>
                        <p>当社は、前条に定める場合を除き、ユーザーが本件契約の有効期間中に本サービスを利用できなかったことおよび本サービスの提供が遅延したことについて一切の責任を負いません。ただし、当社の故意または重大な過失に基づく場合については、この限りではありません。</p>
                    </li>
                    <li>
                        <p>当社、本利用規約等に定める範囲を超える異議、苦情および請求等について何ら責任を負わないものとします。ただし、当社の故意または重大な過失に基づく場合については、この限りではありません。</p>
                    </li>
                </ol>
                <SectionTitle>第 11 条（個人情報の取り扱い）</SectionTitle>
                <ol type="1">
                    <li>
                        <p>当社は、ユーザーに係る氏名もしくは名称、電話番号、住所もしくは居所または請求書の送付先、生年月日、性別、メールアドレス等の情報（以下「個人情報等」といいます。）を、当社が当社ホームページ上で公開する個人情報の保護方針、ご提供いただく個人情報の利用目的、取り扱い目的に準じて管理します。</p>
                    </li>
                    <li>
                        <p>当社は、ユーザーの個人情報等を本サービスおよびこれに関連するサービスの提供、運営、料金の請求および品質向上、マーケティング分析ならびにユーザーにとって有益と考える情報（当社の提供するその他サービスもしくはサービスに関する情報広告を含みますがこれに限りません。）の選定および 配信の目的に利用します。</p></li>
                    <li><p>当社は、資格の更新・本システムの運営代行業務を行います。</p></li>
                    <li>
                        <p>当社は、ユーザーの会員情報、本サービスの開始日、本サービスの終了日、本サービスの月額利用料等請求の完了・未完了に関する情報を契約者サポート、資格更新のためのサポート、その他上記業務に付帯して生じる業務を行います。</p>
                    </li>
                    <li><p>第三者提供および共同利用にかかる手段または方法は情報を暗号化し、情報交換 対応者を限定したセキュアな交換方法とします。 対応者を限定したセキュアな交換方法とします。</p></li>
                    <li><p>共同利用する個人情報は、申込書、インターネット、ハガキなどを通じて当社が取得したものとします。</p></li>
                    <li>
                        <p>
                            当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。<br/>
                            このGoogleアナリティクスはデータの収集のためにCookieを使用しています。<br/>
                            このデータは匿名で収集されており、個人を特定するものではありません。<br/>
                            この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。<br/>
                            <br/>
                            この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。<br/>
                            この規約に関しての詳細は<a href={'http://www.google.com/analytics/terms/jp.html'} target={'_blank'} rel={'noreferrer'}>Googleアナリティクスサービス利用規約のページ</a>や<a href={'http://www.google.co.jp/policies/technologies/ads/'} target={'_blank'} rel={'noreferrer'}>Googleポリシーと規約ページ</a>をご覧ください。
                        </p>
                    </li>
                </ol>
                <SectionTitle>第 12 条（分離可能性）</SectionTitle>
                <Body>本利用規約等のいずれかの条項またはその一部が、消費者契約法その他の法令により無効または執行不能と判断された場合であっても、本利用規約等の残りの規定は、継続して有効に存続するものとします。</Body>
                <SectionTitle>第 13 条（譲渡禁止）</SectionTitle>
                <Body>ユーザーは、本利用規約等に基づく権利義務の全部または一部を第三者に譲渡し、または自己もしくは第三者のために担保に供してはならないものとします。</Body>
                <SectionTitle>第 14 条（債権譲渡）</SectionTitle>
                <Body>ユーザーは、各協会が当社に、有するユーザーに対する本サービスの月額利用料等その他についての債権を譲渡することがあることを予め承諾していただきます。</Body>
                <SectionTitle>第 15 条（準拠法）</SectionTitle>
                <Body>本利用規約の効力・履行および解釈に関しては、日本法が適用されるものとします。</Body>
                <SectionTitle>第 16 条（協議事項）</SectionTitle>
                <ol type="1">
                    <li><p>本利用規約に定めのない事項又は本利用規約の解釈に疑義が生じた場合には、当社と契約者は誠意をもって協議の上、その解決にあたるものとします。</p></li>
                    <li><p>前項により協議をしても解決しない場合、神戸地方裁判所を第一審の専属的合意管轄裁判所とします。</p></li>
                </ol>
                <p>附則 規約の施行日及び改正日</p>
                <p>2023 年 6 月 1日 改正</p>
                <Title>ココリンクに関する重要事項説明</Title>
                <p>この度は、アステッキホールディングス株式会社（以下、「当社」といいます。）が提供する動画配信、イベント管理、SNS機能及びその他のサービス（以下「本サービス」と総称します）「ココリンク」にお申込みいただき、ありがとうございます。</p>
                <p>下記に記載する重要事項説明に同意の上ご契約お願いします</p>
                <SectionTitle>第 1 条（当サービスシステムの利用について）</SectionTitle>
                <ol type="1">
                    <li><p>当サービスは、HTML5に準拠する技術、もしくは専用のアプリケーションを利用して提供されます。ユーザーは、その利用に際し、下記の内容について同意しなければなりません。</p>
                        <ol type="i">
                            <li><p>推奨OS・機種で動作する下記の推薦ブラウザを受講開始前にダウンロード、インストールし機能等について確認すること。</p></li>
                            <li><p>推薦ブラウザのダウンロード、インストール、設定、使用等について、すべて自己の責任と費用において行うこと。</p></li>
                            <li><p>受講開始後に発生した推薦ブラウザやHTML、その他アプリケーション等に起因する不具合や問題について、当社が一切の責任を負わないこと。</p></li>
                            <li><p>推薦ブラウザやHTMLによって定義されたサービス・機能に関する相談、問い合わせ等について、当社が一切対応する義務を負わないこと。</p></li>
                        </ol>
                    </li>
                    <li>
                        <p>当サービスの利用にあたりユーザーは当社の指定する推奨環境を準拠するものとします。当社の定める推奨環境外で当サービスを利用した場合、いかなる場合においても補償を行わないものとします。当社の指定する推奨環境は下記を参照することとします。</p>
                    </li>
                </ol>
                <blockquote>
                    <p>受講推奨環境<br/>
                        OS<br/>
                        ○PC：Windows 10以上/Mac OS Mojave以上<br/>
                        ○iPhone：iOS12以上 <br/>
                        ○Android：Android 5.1以上<br/>
                        ブラウザ<br/>
                        ○PC：Edge/Safari/Chrome</p>
                    <p>アプリバージョン要件<br/>
                        ○iPhone：iOS12.0以上<br/>
                        ○Android：Android 5.1以上</p>
                </blockquote>
                <ol type="1">
                    <li>
                        <p>ユーザーの利用する端末にインストールされている他のアプリケーションや、プラグイン等に起因する問題で当サービスシステムの利用に支障が出た場合、当社は問合せ対応等一切の義務を負わないものとします。</p>
                    </li>
                </ol>
                <SectionTitle>第 ２ 条（講義動画視聴について）</SectionTitle>
                <ol type="1">
                    <li><p>関心のある動画を検索し、視聴できるようサポートします。</p></li>
                    <li><p>動画は再生から終了まですべてを視聴して単位が取得できます。視聴途中で一時停止をしても続きを視聴していただくことで単位は取得できます。</p></li>
                    <li><p>講義動画などを音声のみでも視聴することが可能です。その場合も同様に単位取得ができます。</p></li>
                    <li><p>一部課金が必要な場合や、ミニテストで合格して単位が取得となる場合もございます。</p></li>
                    <li><p>一部の動画において、単位取得の対象ではない動画が存在します。</p></li>
                    <li><p>視聴する際は第1条にある当社指定の視聴環境にて視聴ください。</p></li>
                </ol>
                <SectionTitle>第 ３ 条（イベント管理について）</SectionTitle>
                <p>イベントの検索・申し込み・支払い・キャンセル・イベントに関する連絡などを行えます</p>
                <ol type="1">
                    <li><p>イベント管理を利用する際は、イベント受講ガイドラインに同意の上ご利用ください</p></li>
                    <li><p>イベント管理の利用をもってイベント受講ガイドラインに同意したものとします</p></li>
                    <li><p>キャンセルその他の内容につきましては、イベント受講ガイドラインに準じますのでご参照ください</p></li>
                </ol>
                <SectionTitle>第 ４ 条（SNS機能について）</SectionTitle>
                <p>SNS機能は情報共有したいことや、学びたいことについて認定資格保持者同士でグループを作成し、情報交換が可能です。</p>
                <p>この機能を活用することで、同じ関心事のある仲間や、職種、地域等でコミュニティづくりができるので、人と人をつなぐシステムとなります。</p>
                <ol type="1">
                    <li><p>SNS機能を利用する際は、SNS機能ガイドラインに同意の上ご利用ください。</p></li>
                    <li><p>SNS機能の利用をもってSNS機能ガイドラインに同意したものとします。</p></li>
                    <li><p>キャンセルその他の内容につきましてはSNS機能ガイドラインに準じますのでご参照ください。</p></li>
                </ol>
                <SectionTitle>第 5 条（会員情報について）</SectionTitle>
                <p>会員情報ページでは下記の機能があります</p>
                <ol type="1">
                    <li><p>本システムに登録されている個人情報の表示<br/>
                        個人情報は、必ず虚偽のない正確な情報を登録してください。</p></li>
                    <li><p>登録メールアドレスの変更<br/>
                        登録メールアドレスは、必ず当社からのメールが届くメールアドレスを設定ください。</p></li>
                    <li><p>各認定資格にかかる会員番号・習得済み単位の表示</p></li>
                    <li><p>電子会員証の表示<br/>
                        電子会員証には各資格にかかる会員番号・会員バーコード・習得済み単位が表示されます。<br/>
                        イベント管理にて実地研修に参加する場合、電子会員証に表示されている会員バーコードを現地で提示することにより、参加の確認・単位の付与が行えます。</p></li>
                    <li><p>SNSページでの表示設定<br/>
                        SNSページに表示される画像・職種・ニックネームを設定できます。</p></li>
                    <li><p>取得単位履歴の表示<br/>
                        現在まで取得した単位の履歴を確認できます。</p></li>
                    <li><p>申し込み中イベント一覧の表示と確認<br/>
                        現在申し込み中のイベントの確認と、各イベントの詳細に移動できます。</p></li>
                    <li><p>視聴動画の履歴<br/>
                        現在まで視聴をした動画の履歴が確認できます。</p></li>
                </ol>
                <Title>「会員登録における個人情報の取扱いについて」</Title>
                <p>（１）事業者の氏名または名称</p>
                <p>アステッキホールディングス株式会社</p>
                <p>（２）個人情報保護管理者（若しくはその代理人）の氏名又は職名、所属及び連絡先</p>
                <p>個人情報保護管理者：取締役兼管理本部長<br/>
                    電子メール        ：inquiry@astick0115.com</p>
                <p>（３）個人情報の利用目的<br/>
                会員登録、サービスの運営を遂行するため。</p>
                <p>（４）個人情報の第三者提供について<br/>
                取得した個人情報は法令等による場合を除いて第三者に提供することがあります。<br/>
                ―　第三者に提供する目的：資格更新確認のため<br/>
                ―　提供する個人情報の項目：登録年月日・会員番号・氏名・メールアドレス・ニックネーム・電話番号・生年月日・職種・保有資格・単位取得状況<br/>
                ―　提供の手段又は方法：管理者権限付与により提供<br/>
                ―　当該情報の提供を受ける者又は提供を受ける者の組織の種類、及び属性：一般社団法人日本終末期ケア協会、一般社団法人日本急性期ケア協会、一般社団法人全国在宅医療マネジメント協会<br/>
                その他個人の権利利益を保護するために必要なものとして個人情報保護委員会規則で定める事項については、必要かつ適切な措置を講じます。</p>
                <p>（５）個人情報の取扱いの委託について<br/>
                取得した個人情報はアプリ運用・保守業務において全部又は、一部を委託する場合があります。その場合には、当社において最善の考慮を行います。</p>
                <p>（６） 個人情報を与えなかった場合に生じる結果<br/>
                個人情報を与えることは任意です。個人情報に関する情報の一部をご提供いただけない場合は、お問い合わせ内容に回答できない可能性があります。</p>
                <p>（７）保有個人データの開示等および問い合わせ窓口について<br/>
                ご本人からの求めにより、当社が保有する保有個人データに関する開示、利用目的の通知、内容の訂正・追加または削除、利用停止、消去および第三者提供の停止、第三者提供記録の開示(以下、開示等という)に応じます。<br/>
                開示等に応ずる窓口は、下記「当社の個人情報の取扱いに関する苦情、相談等の問合せ先」を参照してください。</p>
                <p>（８）本人が容易に認識できない方法による個人情報の取得<br/>
                クッキーやウェブビーコン等を用いるなどして、本人が容易に認識できない方法による個人情報の取得は行っておりません。</p>
                <p>（９）個人情報の安全管理措置について<br/>
                取得した個人情報については、漏洩、減失またはき損の防止と是正、その他個人情報の安全管理のために必要かつ適切な措置を講じます。<br/>
                お問合せへの回答後、取得した個人情報は当社内において削除致します。</p>
                <p>（10）個人情報保護方針<br/>
                当社ホームページの個人情報保護方針をご覧下さい。</p>
                <p>（11）当社の個人情報の取扱いに関する苦情、相談等の問合せ先</p>
                <tr>
                    <td><p>窓口の名称</p></td>
                    <td><p>個人情報問合せ窓口</p></td>
                </tr>
                <tr>
                <td><p>連絡先</p></td>
                <td><p>窓口責任者：取締役兼管理本部長</p>
                <p>住所   ：兵庫県神戸市中央区栄町通6丁目1-19</p>
                <p>電子メール：inquiry@astick0115.com</p></td>
                </tr>
            </ScreenView>
        </>
    )
};

export default Agreement;