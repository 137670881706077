import React from "react";
import {Helmet} from "react-helmet-async";
import PageHeader from "../parts/Headers/PageHeader";
import styled from "styled-components";
import {Link} from 'react-router-dom';

const Section = styled.div`
  width: 100%;
  max-width: 768px;
  padding: 16px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const NotFound: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>ページが見つかりませんでした</title>
            </Helmet>
            <PageHeader title={'ページが見つかりませんでした'} disableGoBack={true}/>
            <Section>
                ページが見つからなかったか、既に削除されています。<br/>
                恐れ入りますが、ホームに戻ってください。<br/>
            </Section>
            <Section>
                <Link to={'/home'}>ホームに戻る</Link>
            </Section>
        </>
    )
};

export default NotFound;