import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type PaymentRenewQualificationState = {
    result?: object;
    requestStatus: RequestStatusType;
}

const initialState: PaymentRenewQualificationState = {
    result: undefined,
    requestStatus: 'init'
};

export const getPaymentRenewQualification = createAsyncThunk<FetchDetailReturnType<object>, number>(
    'payment_renew_qualification/get',
    async (qualificationId) => {
        const {data} = await axios.post(`qualifications/${qualificationId}/renew`);
        return data as FetchDetailReturnType<object>;
    }
);

export const paymentRenewQualificationSlice = createSlice({
    name: 'payment_renew_qualification',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(getPaymentRenewQualification.fulfilled, (state, action) => ({
                ...state,
                result: action.payload.data,
                requestStatus: 'success'
            }))
            .addCase(getPaymentRenewQualification.pending, state => ({
                ...state,
                result: undefined,
                requestStatus: 'pending'
            }))
            .addCase(getPaymentRenewQualification.rejected, state => ({
                ...state,
                result: undefined,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus} = paymentRenewQualificationSlice.actions;
