import React from 'react';
import styled from "styled-components";
import ErrorText from "../ErrorText";
import {BodyTextBox, TitleTextBox} from "../AppStyles";

const Section = styled.div`
  margin-top: 16px;
`;

const Label = styled(TitleTextBox)`
  color: ${({theme}) => theme.mainColor};
  font-size: 16px;
  font-weight: bold;
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const Input = styled.input`
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
  height: 200px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const WarnText = styled(BodyTextBox)`
  color: ${({theme}) => theme.dangerColor};
  margin-top: 4px;
`;

interface Props {
    name: string;
    description: string;
    setName: (name: string) => void;
    setDescription: (description: string) => void;
    errors: Object;
}

const ChatGroupCreateForm: React.FC<Props> = ({name, description, setName, setDescription, errors}) => {

    return (
        <Section>
            <Label>グループ名を入力</Label>
            <FormGroup>
                <Input value={name}
                       placeholder={'グループ名'}
                       maxLength={100}
                       onChange={e => setName(e.target.value)}/>
                <ErrorText errors={errors} name={'name'}/>
            </FormGroup>
            <FormGroup>
                <Label>このグループに関する概要、情報を入力（200字以内）</Label>
                <TextArea value={description}
                          placeholder={'グループ名'}
                          maxLength={200}
                          onChange={e => setDescription(e.target.value)}/>
                <ErrorText errors={errors} name={'description'}/>
            </FormGroup>
        </Section>
    );
};

export default ChatGroupCreateForm;
