import React from 'react';
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";

const LabelBox = styled.div`
  border-left-width: 2px;
  border-left-color: ${({theme}) => theme.textGrayColor};
  padding: 0 8px;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const LabelText = styled(TitleTextBox)`
  color: ${({theme}) => theme.textGrayColor};
  font-size: 20px;
`;

const RecentLabel: React.FC = () => (
    <LabelBox>
        <LabelText>新着グループ</LabelText>
    </LabelBox>
);

export default RecentLabel;
