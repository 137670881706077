import React, {useCallback, useEffect} from 'react';
import {useSelector} from "../../store";
import SnsHeader from "../../parts/SnsTab/SnsHeader";
import {useDispatch} from "react-redux";
import {fetchUser} from "../../stores/user";
import ChatGroupType from "../../interfaces/ChatGroupType";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {useHistory} from "react-router-dom";
import {changeTab} from "../../stores/tab";
import {Helmet} from "react-helmet-async";
import {fetchChatTimeline} from "../../stores/chat_timeline";
import ChatTimelineList from "../../parts/SnsTab/ChatTimelineList";
import SnsTabView, {SnsTabState} from "../../parts/SnsTab/SnsTabView";
import ScreenView from "../../parts/ScreenView";
import ChatMessageType from "../../interfaces/ChatMessageType";

const SnsTab: React.FC = () => {

    const {user, chat_timeline} = useSelector(state => ({
        user: state.user,
        chat_timeline: state.chat_timeline,
    }));

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(changeTab('sns'))
        dispatch(fetchUser());
        dispatch(fetchChatTimeline({page: 1}));
    }, [dispatch]);

    const onPressChatGroup = (item: ChatGroupType) => {
        if (item.is_own) {
            history.push(`/sns/chat_groups/${item.id}`);
        } else {
            history.push(`/sns/apply_chat_groups/${item.id}`);
        }
    };

    const onShowChatDetail = useCallback((message: ChatMessageType) => {
        history.push(`/sns/chat_messages/${message.id}`);
    }, [history]);

    const onEndReached = () => {
        if (chat_timeline.lastRequestMeta === undefined) {
            return;
        }

        if (chat_timeline.lastRequestMeta.last_page > chat_timeline.lastRequestMeta.current_page) {
            dispatch(fetchChatTimeline({page: chat_timeline.lastRequestMeta.current_page + 1}));
        }
    };

    const onTabChange = (tab: SnsTabState) => {
        history.push('/sns/chat_groups', {tab});
    };

    return (
        <>
            <Helmet>
                <title>SNS交流</title>
            </Helmet>
            <WithLoadingScreen
                loading={chat_timeline.isFetching && chat_timeline.items.length === 0}>
                <SnsHeader user={user.user}/>
                <ScreenView>
                    <SnsTabView tab={'Own'} onTabChange={onTabChange}/>
                    <ChatTimelineList chatTimeline={chat_timeline}
                                      onPressChatGroup={onPressChatGroup}
                                      onPressChatMessage={onShowChatDetail}
                                      onEndReached={onEndReached}/>
                </ScreenView>
            </WithLoadingScreen>
        </>
    )
};

export default SnsTab;
