import React from 'react';
import styled from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";

const Section = styled.div`
  padding: 8px 0;
`;

const Label = styled(TitleTextBox)`
  color: ${({theme}) => theme.mainColor};
  font-size: 14px;
`;

const ValueLabel = styled(BodyTextBox)`
  margin-top: 4px;
  margin-left: 8px;
  font-size: 16px;
`;

interface Props {
    label: string;
    value: string | null;
}

const ConfirmInformationBox: React.FC<Props> = ({label, value}) => {

    return (
        <Section>
            <Label>{label}</Label>
            <ValueLabel>{value}</ValueLabel>
        </Section>
    );
};

export default ConfirmInformationBox;
