import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import {TitleTextBox} from "../AppStyles";
import {useSelector} from "../../store";
import ArrowIcon from "../ArrowIcon";
import TouchableOpacity from "../TouchableOpacity";
import EventType from "../../interfaces/EventType";

const Wrap = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const NonMemberButton = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
`;

const NonMemberTextBox = styled.div`
  margin-right: 4px;
`;

const NonMemberText = styled(TitleTextBox)`
  color: ${({theme}) => theme.linkColor};
`;

const Border = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.linkColor};
`;

interface Props {
    onPress: () => void;
    event: EventType;
}

const NonMemberLink: React.FC<Props> = ({onPress, event}) => {
    const theme = useContext(ThemeContext);
    const {auth} = useSelector(state => ({
        auth: state.auth
    }));
    if (auth.authenticated) {
        return <></>
    }
    return (
        <Wrap>
            <NonMemberButton onClick={onPress} disabled={!event.can_apply || event.over_capacity}>
                <NonMemberTextBox>
                    <NonMemberText>
                        {(() => {
                            if (event.is_own) {
                                return '予約済み';
                            } else if (event.over_capacity) {
                                return '定員に達しました';
                            } else if (event.can_apply) {
                                return '非会員の方はコチラ';
                            } else {
                                return '予約期間外';
                            }
                        })()}
                    </NonMemberText>
                    <Border/>
                </NonMemberTextBox>
                <ArrowIcon size={14} color={theme.linkColor}/>
            </NonMemberButton>
        </Wrap>
    );
};

export default NonMemberLink;
