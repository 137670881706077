import React, {useMemo, useState} from 'react';
import ChatGroupType from "../../interfaces/ChatGroupType";
import SnsTabView, {SnsTabState} from "./SnsTabView";
import GroupCreateButton from "./GroupCreateButton";
import {ChatGroupState} from "../../stores/chat_group";
import styled from "styled-components";
import RecentLabel from "./RecentLabel";
import {ContentWrap, TitleTextBox} from "../AppStyles";
import ChatGroupOwnList from "./ChatGroupOwnList";
import ChatGroupAllList from "./ChatGroupAllList";

const EmptyView = styled.div`
  padding: 16px;
`;

const EmptyText = styled(TitleTextBox)`
  font-size: 16px;
  color: ${({theme}) => theme.textGrayColor};
`;

const HeaderView = styled.div`
  padding: 16px 16px 0;
`;

interface Props {
    chatGroup: ChatGroupState;
    onPressCreateGroup: () => void;
    onPressChatGroup: (chatGroup: ChatGroupType) => void;
    onRecentEndReached: () => void;
    defaultTab: SnsTabState;
}

const ChatGroupList: React.FC<Props> = ({chatGroup, onPressCreateGroup, onPressChatGroup, onRecentEndReached, defaultTab}) => {

    const [tab, setTab] = useState<SnsTabState>(defaultTab);

    const items = useMemo(() => {
        return tab === 'Own' ? chatGroup.ownItems : chatGroup.recentItems;
    }, [tab, chatGroup]);

    const hasMore = useMemo(() => {
        if (chatGroup.lastRequestMeta === undefined) {
            return false;
        }
        return chatGroup.lastRequestMeta.last_page > chatGroup.lastRequestMeta.current_page;
    }, [chatGroup.lastRequestMeta]);

    return (
        <ContentWrap>
            <HeaderView>
                <SnsTabView tab={tab} onTabChange={tab => setTab(tab)}/>
                <GroupCreateButton onPress={onPressCreateGroup}/>
                {tab === 'All' ? <RecentLabel/> : <></>}
            </HeaderView>
            {items.length === 0 ? (
                <EmptyView>
                    <EmptyText>{tab === 'Own' ? '所属しているグループはありません。' : 'グループがありません。'}</EmptyText>
                </EmptyView>
            ) : (tab === 'Own' ? (
                <ChatGroupOwnList items={items}
                                  onPress={onPressChatGroup}/>
            ) : (
                <ChatGroupAllList items={items}
                                  onPress={onPressChatGroup}
                                  hasMore={hasMore}
                                  onEndReached={onRecentEndReached}/>
            ))}
        </ContentWrap>
    )
};

export default ChatGroupList;
