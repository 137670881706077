import React, { useContext } from 'react';
import styled, { ThemeContext } from "styled-components";
import QualificationType from "../../interfaces/QualificationType";
import ModalView from "../../parts/ModalView";
import Icon from "../Icon";
import TouchableOpacity from "../TouchableOpacity";
import Image from "../Image";
import IntroduceKyuseikiImg from '../../assets/qualifications/intro_kyuseiki.png';
import IntroduceJyokyuImg from '../../assets/qualifications/intro_jokyu.png';
import IntroduceShumatsuImg from '../../assets/qualifications/intro_shumatsu.png';
import FlatButton from "../FlatButton";

const Header = styled.div`
  position: relative;
  padding: 16px;
  text-align: center;
  background-color: #FBFAEE;
`;

const Title = styled.p`
  margin: 12px 0 0;
  font-size: 16px;
`;

const CloseTouch = styled(TouchableOpacity)`
  position: absolute;
  width: 30px;
  height: 24px;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Close = styled(Icon)`
  font-size: 24px;
  color: ${({ theme }) => theme.mainColor};
`;

const Content = styled.div`
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FBFAEE;
`;

const IntroduceImage = styled(Image)`
  margin: auto;
`;

const Description = styled.p`
  font-size: 18px;
  font-weight: bold;
  white-space: pre-wrap;
`;

const Flex = styled.div`
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
  padding: 0 24px;
  margin: 18px auto;
  @media screen and (min-width: 480px) {
    display: flex;
    flex-direction: row;
  }
`;

type Introduction = {
    name: string,
    imageSrc: string,
    color: string,
    description: string,
    foreignUrl: string
} | null;

const JTCA: Introduction = {
    name: '終末期ケア専門士',
    imageSrc: IntroduceShumatsuImg,
    color: '#FD8666',
    description: `終末期ケア専門士とは\n臨床ケアにおけるスペシャリストを目指す専門士です。`,
    foreignUrl: 'https://jtca2020.or.jp/specialist/'
};

const JTCA1: Introduction = {
    name: '終末期ケア上級専門士',
    imageSrc: IntroduceJyokyuImg,
    color: '#F582B7',
    description: `終末期ケア上級専門士と\nはチームマネジメントにも力を発揮できる役割モデルを目指す専門士です。`,
    foreignUrl: 'https://jtca2020.or.jp/specialist-advanced/'
};

const JACA: Introduction = {
    name: '急性期ケア専門士',
    imageSrc: IntroduceKyuseikiImg,
    color: '#0067BD',
    description: `急性期ケア専門士とは急性期ケアを学び、\nシームレスな連携で、命をつなぐ専門士です。`,
    foreignUrl: 'https://jaca2021.or.jp/license/'
};

type Props = {
    qualification: QualificationType | null
    onClose: () => void;
}

const IntroduceQualification: React.FC<Props> = ({ qualification, onClose }) => {
    const theme = useContext(ThemeContext);

    if (qualification === null || qualification === undefined || qualification.is_owned) {
        return <></>
    }

    let introduction: Introduction = null;
    if (qualification.code === 'JTCA') {
        introduction = JTCA
    } else if (qualification.code === 'JTCA1') {
        introduction = JTCA1
    } else if (qualification.code === 'JACA') {
        introduction = JACA
    } else {
        return <></>
    }

    return (
        <ModalView show={!!qualification} onClose={onClose}>
            <Header>
                <Title>{'こちらの対象動画は以下の資格をお持ちの方のみ視聴することができます。'}</Title>
                <CloseTouch onClick={onClose}>
                    <Close name={'times'} />
                </CloseTouch>
            </Header>
            <Content>
                <IntroduceImage src={introduction.imageSrc} alt={introduction.name} />
                <Description style={{ color: introduction.color }}>{introduction.description}</Description>
            </Content>
            <Flex>
                <FlatButton text={'閉じる'}
                    backgroundColor={theme.whiteColor}
                    textColor={theme.mainColor}
                    borderColor={theme.mainColor}
                    borderRadius={10}
                    style={{ padding: '0 16px' }}
                    innerStyle={{ padding: '4px 28px' }}
                    onPress={onClose} />
                <FlatButton text={'詳しく見る'}
                    backgroundColor={theme.whiteColor}
                    textColor={theme.mainColor}
                    borderColor={theme.mainColor}
                    borderRadius={10}
                    style={{ padding: '0 16px' }}
                    innerStyle={{ padding: '4px 28px' }}
                    onPress={() => window.open(introduction?.foreignUrl)} />
            </Flex>
        </ModalView >
    );
}

export default IntroduceQualification;
