import EventType from "../interfaces/EventType";
import EventTypeType from "../interfaces/EventTypeType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import {RootState} from "../store";

export type EventState = {
    recentItems: EventType[];
    userItems: EventType[];
    types: EventTypeType[];
    isFetching: boolean;
};

const initialState: EventState = {
    recentItems: [],
    userItems: [],
    types: [],
    isFetching: false,
};

export const fetchRecentEvents = createAsyncThunk<FetchReturnType<EventType>>(
    'events/fetch/recent',
    async (v, thunk) => {
        const {serial_number} = thunk.getState() as RootState;
        const {data} = await axios.get('events', {
            headers: {
                'X-Device-ID': serial_number.item.device_code
            }
        });
        return data as FetchReturnType<EventType>;
    }
);

export const fetchUserEvents = createAsyncThunk<FetchReturnType<EventType>>(
    'events/fetch/user',
    async (v, thunk) => {
        const {serial_number} = thunk.getState() as RootState;
        const {data} = await axios.get('user/events', {
            headers: {
                'X-Device-ID': serial_number.item.device_code
            }
        });
        return data as FetchReturnType<EventType>;
    }
);

export const fetchEventTypes = createAsyncThunk<FetchReturnType<EventTypeType>>(
    'events/fetch/types',
    async (v, thunk) => {
        const {serial_number} = thunk.getState() as RootState;
        const {data} = await axios.get('event_types/events', {
            headers: {
                'X-Device-ID': serial_number.item.device_code
            }
        });
        return data as FetchReturnType<EventTypeType>;
    }
);

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchRecentEvents.fulfilled, (state, action) => ({
                ...state,
                recentItems: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchRecentEvents.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchRecentEvents.rejected, state => ({
                ...state,
                recentItems: [],
                isFetching: false
            }))
            .addCase(fetchUserEvents.fulfilled, (state, action) => ({
                ...state,
                userItems: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchUserEvents.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchUserEvents.rejected, state => ({
                ...state,
                userItems: [],
                isFetching: false
            }))
            .addCase(fetchEventTypes.fulfilled, (state, action) => ({
                ...state,
                types: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchEventTypes.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchEventTypes.rejected, state => ({
                ...state,
                types: [],
                isFetching: false
            }))
    }
});
