import React from 'react';
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Box = styled(TouchableOpacity)`
  margin-top: 8px;
  padding: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BoxBorder = styled.div`
  border-bottom-color: ${({theme}) => theme.subColor};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const BoxText = styled(TitleTextBox)`
  text-align: center;
  font-size: 16px;
  color: ${({theme}) => theme.subColor};
  font-weight: bold;
`;

const PlusIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid ${({theme}) => theme.subColor};
  color: ${({theme}) => theme.subColor};
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  margin-right: 4px;
`;

interface Props {
    onPress: () => void;
}

const GroupCreateButton: React.FC<Props> = ({onPress}) => (
    <Section>
        <Box onClick={onPress}>
            <PlusIcon>+</PlusIcon>
            <BoxBorder>
                <BoxText>グループ作成</BoxText>
            </BoxBorder>
        </Box>
    </Section>
);

export default GroupCreateButton;
