import React from "react";
import styled from "styled-components";

const Screen = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  justify-content: center;
  align-items: center;
  padding: 8px;
  z-index: 1000;
`;

const Modal = styled.div<{ width: number }>`
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 1px 1px 10px 1px #C1C1C1;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
  max-width: ${({width}) => width}px;
`;

type Props = {
    show: boolean;
    onClose: () => void;
    width?: number;
}

const ModalView: React.FC<Props> = ({children, show, onClose, width = 728}) => {
    if (!show) {
        return <></>
    }

    return (
        <Screen onClick={onClose}>
            <Modal
                width={width}
                onClick={(e) => e.stopPropagation()}>
                {children}
            </Modal>
        </Screen>
    )
};

export default ModalView;