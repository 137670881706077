import React from "react";
import {ChatTimelineState} from "../../stores/chat_timeline";
import ChatGroupType from "../../interfaces/ChatGroupType";
import ChatMessageType from "../../interfaces/ChatMessageType";
import ChatGroupEmptyView from "../ChatGroupThreadView/ChatGroupThreadEmpty";
import ChatMessageGroupHeader from "../SnsTab/ChatMessageGroupHeader";
import ChatMessage from "../ChatMessage/ChatMessage";
import styled from "styled-components";
import SectionLabel from "../SectionLabel";
import ChatUserType from "../../interfaces/ChatUserType";
import UserType from "../../interfaces/UserType";
import implementsUserType from "../../utils/implementsUserType";

const TimelineHeader = styled(SectionLabel)`
  margin-top: 56px;
`;

const MessageWrap = styled.div`
`;


interface Props {
    user: ChatUserType | UserType;
    chatTimeline: ChatTimelineState;
    onPressChatGroup: (chatGroup: ChatGroupType) => void;
    onPressChatMessage: (chatMessage: ChatMessageType) => void;
}

const ChatProfileTimeline: React.FC<Props> = ({user, chatTimeline, onPressChatGroup, onPressChatMessage}) => {

    if (implementsUserType(user)) {
        return (
            <>
                <TimelineHeader title={'所属グループの新着投稿'} fontSize={22}/>
                {chatTimeline.items.length === 0 ? (
                    <ChatGroupEmptyView/>
                ) : chatTimeline.items.map(item => (
                    <MessageWrap key={item.id}>
                        <ChatMessageGroupHeader chatGroup={item.group} onClick={onPressChatGroup}/>
                        <ChatMessage message={item} showPin={false}/>
                    </MessageWrap>
                ))}
            </>
        );
    } else {
        return <></>
    }
};

export default ChatProfileTimeline;