import React from "react";
import ChatUserType from "../../interfaces/ChatUserType";
import UserType from "../../interfaces/UserType";
import styled from "styled-components";
import TouchableOpacity from "../TouchableOpacity";
import {TitleText} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";
import implementsUserType from "../../utils/implementsUserType";

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
`;

const EditButton = styled(TouchableOpacity)`
  background-color: ${({theme}) => theme.subColor};
  padding: 8px 48px;
  border-radius: 16px;
  position: relative;
`;

const EditButtonText = styled(TitleText)`
  color: white;
  font-weight: bold;
`;

const Arrow = styled(ArrowIcon)`
  position: absolute;
  right: 8px;
  top: 10px;
`;

interface Props {
    user: ChatUserType | UserType;
    onEdit: () => void;
}

const ChatProfileEditButton: React.FC<Props> = ({user, onEdit}) => {
    if (implementsUserType(user)) {
        return (
            <Section>
                <EditButton onClick={onEdit}>
                    <EditButtonText>プロフィールを編集</EditButtonText>
                    <Arrow size={14} color={'#FFFFFF'}/>
                </EditButton>
            </Section>
        )
    } else {
        return <></>
    }
};

export default ChatProfileEditButton;