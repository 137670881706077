import React from "react";
import styled from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import ChatGroupType from "../../interfaces/ChatGroupType";

const Section = styled.div`
  padding: 16px;
  background-color: ${({theme}) => theme.subColor};
`;

const Title = styled(TitleTextBox)`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${({theme}) => theme.whiteColor};
`;

const Description = styled(BodyTextBox)`
  font-size: 14px;
  text-align: center;
  color: ${({theme}) => theme.whiteColor};
  margin-top: 4px;
`;

type Props = {
    group: ChatGroupType;
}

const UserListHeaderView: React.FC<Props> = ({group}) => {
    return (
        <Section>
            <Title>{group.name}</Title>
            {group.description ? (
                <Description>{group.description}</Description>
            ) : <></>}
        </Section>
    )
};

export default UserListHeaderView;