import React, {useMemo} from "react";
import {SliderItemType} from "./ImageSlider/SliderItemType";
import ImageSliderItem from "./ImageSlider/ImageSliderItem";
import Carousel, {autoplayPlugin, slidesToShowPlugin} from '@brainhubeu/react-carousel';
import ImageSliderBox from "./ImageSlider/ImageSliderBox";
import cocoImage from "../assets/slider/pc_kv_1.png";
import jtcaImage from "../assets/slider/pc_kv_2.png";
import jtca1Image from "../assets/slider/pc_kv_3.png";
import jacaImage from "../assets/slider/pc_kv_4.png";
import jhmaImage from "../assets/slider/pc_kv_5.png";
import jhma1Image from "../assets/slider/pc_kv_6.png";
import eventImage from "../assets/slider/pc_kv02.png";
import xsCocoImage from "../assets/slider/sp_kv_1.png";
import xsJtcaImage from "../assets/slider/sp_kv_2.png";
import xsJtca1Image from "../assets/slider/sp_kv_3.png";
import xsJacaImage from "../assets/slider/sp_kv_4.png";
import xsJhmaImage from "../assets/slider/sp_kv_5.png";
import xsJhma1Image from "../assets/slider/sp_kv_6.png";
import xsEventImage from "../assets/slider/sp_kv02.png";

import Wrapper from "./ImageSlider/Wrapper";

const ImageSlider: React.FC = () => {

    const sliderItems = useMemo<SliderItemType[]>(() => {
        return [
            {
                image: cocoImage,
                xsImage: xsCocoImage,
            },
            {
                image: jtcaImage,
                xsImage: xsJtcaImage,
                url: 'https://jtca2020.or.jp/about-specialist/#aSpecialist' +
                '',
            },
            {
                image: jtca1Image,
                xsImage: xsJtca1Image,
                url: 'https://jtca2020.or.jp/specialist-advanced/' +
                '',
            },
            {
                image: jacaImage,
                xsImage: xsJacaImage,
                url: 'https://jaca2021.or.jp/license/' +
                '',
            },
            {
                image: jhmaImage,
                xsImage: xsJhmaImage,
                url: 'https://jhma.or.jp/home_care/' +
                '',
            },
            {
                image: jhma1Image,
                xsImage: xsJhma1Image,
                url: 'https://jhma.or.jp/home_nurse/' +
                '',
            },
            {
                image: eventImage,
                xsImage: xsEventImage,
                url: '/event_notices',
            },
        ];
    }, []);

    const plugins = useMemo(() => [
        'centered',
        'infinite',
        {
            resolve: autoplayPlugin,
            options: {
                interval: 5000,
            }
        },
        {
            resolve: slidesToShowPlugin,
            options: {
                numberOfSlides: 2
            }
        },
    ], []);

    return (
        <Wrapper>
            <ImageSliderBox>
                <Carousel
                    key={Math.random()}
                    plugins={plugins}>
                    {sliderItems.map((item, index) => (
                        <ImageSliderItem item={item} key={`carousel.${index}`}/>
                    ))}
                </Carousel>
            </ImageSliderBox>
        </Wrapper>
    );
};

export default ImageSlider;