import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import BankTransferRequest from "../interfaces/BankTransferRequest";

export type CheckUserBankState = {
    exists?: BankTransferRequest;
    isFetching: boolean;
    success: boolean;
}

const initialState: CheckUserBankState = {
    exists: undefined,
    isFetching: false,
    success: false
};

export const fetchCheckUserBankExists = createAsyncThunk<FetchDetailReturnType<BankTransferRequest | null>>(
    'check_user_bank',
    async () => {
        const {data} = await axios.get('bank_transfer_requests/exists');
        return data as FetchDetailReturnType<BankTransferRequest | null>;
    }
);

export const checkUserBankSlice = createSlice({
    name: 'check_user_bank',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCheckUserBankExists.fulfilled, (state, action) => ({
                exists: action.payload.data ?? undefined,
                isFetching: false,
                success: true
            }))
            .addCase(fetchCheckUserBankExists.pending, state => ({
                ...state,
                isFetching: true,
            }))
            .addCase(fetchCheckUserBankExists.rejected, state => ({
                ...state,
                isFetching: false,
                exists: undefined,
                success: false,
            }));
    }
});