import React, {useCallback, useEffect, useMemo, useState} from 'react';
import SnsHeader from "../../parts/SnsTab/SnsHeader";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {fetchChatMessages, resetRequestStatus, sendChatMessage} from "../../stores/chat_message";
import styled from "styled-components";
import ChatGroupHeader from "../../parts/ChatGroupThreadView/ChatGroupHeader";
import ChatGroupEmptyView from "../../parts/ChatGroupThreadView/ChatGroupThreadEmpty";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import toastr from 'toastr';
import {useHistory, useParams} from "react-router-dom";
import {fetchChatGroupDetail} from "../../stores/chat_group_detail";
import NotFound from "../NotFound";
import SendChatMessage from "./SendChatMessage";
import {Helmet} from "react-helmet-async";
import {ContentWrap, InfiniteYScroll} from "../../parts/AppStyles";
import MoreLoading from "../../parts/MoreLoading";
import ChatMessage from "../../parts/ChatMessage/ChatMessage";

const Screen = styled.div`
  background-color: #FFFFFF;
  position: relative;
  flex: 1;
`;

const ChatGroupThreadView: React.FC = () => {

    const {user, chat_message, chat_group_detail} = useSelector(state => ({
        user: state.user,
        chat_message: state.chat_message,
        chat_group_detail: state.chat_group_detail,
    }));

    const [selectId, setSelectId] = useState<number | null>(null);
    const [showPost, setShowPost] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const {id: chatGroupId} = useParams<{ id: string; }>();

    useEffect(() => {
        dispatch(fetchChatGroupDetail(chatGroupId));
        dispatch(fetchChatMessages({
            groupId: chatGroupId,
            page: 1
        }));
    }, [chatGroupId, dispatch]);

    useEffect(() => {
        if (selectId === null) {
            return;
        }
        if (chat_message.requestStatus === 'success') {
            toastr.success('コメントを投稿しました。');
            dispatch(resetRequestStatus());
        } else if (chat_message.requestStatus === 'failed') {
            toastr.error('コメントを投稿できませんでした。');
            dispatch(resetRequestStatus());
        }
    }, [chat_message.requestStatus]);

    const hasMore = useMemo(() => {
        if (chat_message.lastRequestMeta === undefined) {
            return false;
        }
        return chat_message.lastRequestMeta.last_page > chat_message.lastRequestMeta.current_page;
    }, [chat_message.lastRequestMeta]);

    const onEndReached = () => {
        if (chat_message.lastRequestMeta === undefined) {
            return;
        }

        if (chat_message.lastRequestMeta.last_page > chat_message.lastRequestMeta.current_page) {
            dispatch(fetchChatMessages({
                groupId: chatGroupId,
                page: chat_message.lastRequestMeta.current_page + 1
            }));
        }
    };

    const onPostShow = () => {
        setSelectId(null);
        setShowPost(true);
    };

    const onPost = (text: string, file: File | null) => {
        setShowPost(false);
        dispatch(sendChatMessage({
            text: text,
            parentId: undefined,
            groupId: chatGroupId,
            uploadFile: file,
        }));
    };

    const onSetting = useCallback(() => {
        history.push(`/sns/chat_groups/${chat_group_detail.item?.id}/users`);
    }, [chat_group_detail.item, history]);

    if (chat_group_detail.isFetching) {
        return <WithLoadingScreen loading={true}/>
    }

    if (chat_group_detail.item === null) {
        return <NotFound/>
    }

    return (
        <>
            <Helmet>
                <title>{chat_group_detail.item.name}</title>
            </Helmet>
            <WithLoadingScreen
                loading={chat_message.isFetching && chat_message.items.length === 0}>
                <SnsHeader user={user.user} canGoBack={true}/>
                <Screen>
                    <ChatGroupHeader group={chat_group_detail.item}
                                     isAdmin={chat_group_detail.item.is_admin}
                                     onPost={onPostShow}
                                     onSetting={onSetting}/>
                    <ContentWrap>
                        <InfiniteYScroll
                            next={onEndReached}
                            hasMore={hasMore}
                            loader={<MoreLoading/>}
                            endMessage={<></>}
                            dataLength={chat_message.items.length}>
                            {chat_message.items.length === 0 ? (
                                <ChatGroupEmptyView/>
                            ) : chat_message.items.map(item => (
                                <ChatMessage message={item} key={item.id} showPin={true}/>
                            ))}
                        </InfiniteYScroll>
                    </ContentWrap>
                    <SendChatMessage show={showPost}
                                     onClose={() => setShowPost(false)}
                                     onPost={onPost}/>
                </Screen>
            </WithLoadingScreen>
        </>
    );
};

export default ChatGroupThreadView;
