import React, {useEffect} from 'react';
import styled from "styled-components";
import NoticeType from "../../interfaces/NoticeType";
import VideoType from "../../interfaces/VideoType";
import EventType from "../../interfaces/EventType";
import QualificationType from "../../interfaces/QualificationType";
import {BodyTextBox, TitleText, TitleTextBox} from "../../parts/AppStyles";
import NoticeTypeIcon from "../../parts/NoticeListPage/NoticeTypeIcon";
import ChatGroupType from "../../interfaces/ChatGroupType";
import LinkText from "../../parts/LinkText";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import ModalView from "../../parts/ModalView";
import TouchableOpacity from "../../parts/TouchableOpacity";
import Image from "../../parts/Image";
import ModalHeader from "../../parts/Headers/ModalHeader";
import EventHtmlView from "../../parts/EventHtmlView";
import AutoLinkText from "../../parts/AutoLinkText";
import {useDispatch} from "react-redux";
import {setReadNotice} from "../../stores/notice";

const NotifyView = styled.div`
  background-color: #FFF;
  flex: 1;
  padding: 16px 8px;
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;

  @media screen and (min-width: 480px) {
    padding: 16px;
  }
`;

const TitleFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled(TitleText)`
  font-size: 20px;
  font-weight: bold;
  flex: 1;
`;

const Date = styled(TitleText)`
  text-align: right;
  color: ${({theme}) => theme.textGrayColor};
`;

const Description = styled(AutoLinkText)`
  margin-top: 24px;
  font-size: 16px;
  color: ${({theme}) => theme.textGrayColor};
`;

const ItemBox = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  border: 1px solid #C1C1C1;
  border-radius: 2px;
  padding: 4px;
  margin-top: 24px;
`;

const ItemImage = styled(Image)`
  width: 100px;
`;

const ItemInformationBox = styled.div`
  flex: 1;
  margin-left: 8px;
`;

const ItemTitle = styled(TitleTextBox)`
  font-size: 14px;
`;

const ItemLargeTitle = styled(TitleTextBox)`
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  margin: 8px;
`;

const ItemLargeCaption = styled(BodyTextBox)`
  font-size: 14px;
  font-weight: normal;
  color: ${({theme}) => theme.textGrayColor};
`;

type Props = {
    notice: NoticeType<any> | null;
    onClose: () => void;
}

const NotifyDetail: React.FC<Props> = ({notice, onClose}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (notice === null) {
            return;
        }
        dispatch(setReadNotice(notice));
    }, [dispatch, notice]);

    const history = useHistory();

    const onPressForeign = () => {
        if (notice === null) {
            return;
        }
        if (notice.type === 'video') {
            history.push(`/videos/${notice.foreign_item.id}`);
        }
        if (notice.type === 'event') {
            history.push(`/events/${notice.foreign_item.id}`);
        }
        if (notice.type === 'qualification') {
            history.push(`/qualification/${notice.foreign_item.id}/renew`);
        }
        if (notice.type === 'chat_group') {
            if (notice.title.endsWith('に参加依頼が届きました。')) {
                history.push(`/sns/chat_groups/${notice.foreign_item.id}/users`);
            } else {
                history.push(`/sns/chat_groups/${notice.foreign_item.id}`);
            }
        }
        if (notice.type === 'contact') {
            history.push('/contact');
        }
    };

    if (notice === null) {
        return <></>
    }

    return (
        <ModalView show={!!notice} onClose={onClose}>
            <ModalHeader title={'お知らせ'}
                         onClose={onClose}/>
            <NotifyView>
                <Date>{notice.published_at}</Date>
                <TitleFlex>
                    <NoticeTypeIcon notice={notice}/>
                    <Title>
                        {notice.important ? (
                            <ExclamationCircleFilled size={16} style={{color: 'red'}}/>
                        ) : <></>}
                        {notice.title}
                    </Title>
                </TitleFlex>
                {notice.type === 'event' ? (
                    <EventHtmlView html={notice.content}/>
                ) : (
                    <Description text={notice.content}/>
                )}
                {notice.type === 'video' ? (
                    <ItemBox onClick={onPressForeign}>
                        <ItemImage src={(notice as NoticeType<VideoType>).foreign_item.thumbnail_url}/>
                        <ItemInformationBox>
                            <ItemTitle>
                                {(notice as NoticeType<VideoType>).foreign_item.name}
                            </ItemTitle>
                        </ItemInformationBox>
                    </ItemBox>
                ) : <></>}
                {notice.type === 'event' ? (() => {
                    const asNotice = notice as NoticeType<EventType>;
                    return (
                        <ItemBox onClick={onPressForeign}>
                            {asNotice.foreign_item.thumbnail_url ? (
                                <ItemImage src={asNotice.foreign_item.thumbnail_url}/>
                            ) : <></>}
                            <ItemInformationBox>
                                <ItemTitle>
                                    {asNotice.foreign_item.name}
                                </ItemTitle>
                            </ItemInformationBox>
                        </ItemBox>
                    )
                })() : <></>}

                {notice.type === 'chat_group' ? (() => {
                    const asNotice = notice as NoticeType<ChatGroupType>;
                    return (
                        <ItemBox onClick={onPressForeign}>
                            <ItemInformationBox>
                                <ItemLargeTitle>
                                    {asNotice.foreign_item.name}
                                    <ItemLargeCaption>（メンバー{asNotice.foreign_item.users_count}人）</ItemLargeCaption>
                                </ItemLargeTitle>
                            </ItemInformationBox>
                        </ItemBox>
                    )
                })() : <></>}

                {notice.type === 'qualification' ? (() => {
                    const asNotice = notice as NoticeType<QualificationType>;
                    return (
                        <></>
                    )
                })() : <></>}

                {notice.type === 'contact' ? (() => {
                    return (
                        <LinkText text={`お問い合わせへ`}
                                  style={{marginTop: 32}}
                                  onPress={onPressForeign}/>
                    )
                })() : <></>}
            </NotifyView>
        </ModalView>
    );
};

export default NotifyDetail;
