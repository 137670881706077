import React from 'react';
import styled from "styled-components";
import TouchableOpacity from "../TouchableOpacity";
import Image from "../Image";
import guideImage from '../../assets/buttons/contact_guide.png';

const Box = styled(TouchableOpacity)`
  overflow: hidden;
  text-align: center;
`;

const GuideImage = styled(Image)`
  width: 100%;
`;

interface Props {
    onPress: () => void;
}

const GuideButton: React.FC<Props> = ({onPress}) => {
    return (
        <Box onClick={onPress}>
            <GuideImage src={guideImage}/>
        </Box>
    )
};

export default GuideButton;
