import React, {useMemo} from 'react';
import EventType from "../../interfaces/EventType";
import styled from "styled-components";
import {BodyTextBox, TitleText, TitleTextBox} from "../AppStyles";
import {useSelector} from "../../store";
import TouchableOpacity from "../TouchableOpacity";

const Section = styled.div`
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px 8px 8px 0;

  @media screen and (min-width: 480px) {
    width: 33%;
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: auto;
  cursor: pointer;
`;

const Title = styled(TitleTextBox)`
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #212121;
  cursor: pointer;
`;

const Qualification = styled(BodyTextBox)`
  font-size: 14px;
  color: ${({theme}) => theme.textGrayColor};
`;

const DateTime = styled(BodyTextBox)`
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
`;

const Button = styled(TouchableOpacity)`
  margin-top: 8px;
  padding: 4px 8px;
  border: 1px solid #C1C1C1;
  border-radius: 8px;
`;

const ButtonText = styled(TitleText)`
  color: ${({theme}) => theme.textColor};
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

interface Props {
    event: EventType;
    isUser: boolean;
    onPress: () => void;
    index: number;
}

const EventListItem: React.FC<Props> = ({event, isUser, onPress, index}) => {
    const {auth} = useSelector(state => ({
        auth: state.auth
    }));
    const priceText = useMemo(() => {
        if (auth.authenticated) {
            if (event.price === 0) {
                return '無料';
            }
            return `¥${event.price.toLocaleString()}`;
        } else {
            if (event.price === 0) {
                return '無料';
            }
            return `¥${event.non_member_price.toLocaleString()}`;
        }
    }, [event, auth.authenticated]);
    return (
        <Section>
            {event.thumbnail_url !== null ? (
                <Image src={event.thumbnail_url} onClick={onPress}/>
            ) : <></>}
            <Title onClick={onPress}>{event.name}</Title>
            {event.qualifications.map(qualification => (
              <Qualification key={qualification.qualification_id}>
                  {qualification.qualification_name} {qualification.unit}単位
              </Qualification>
            ))}
            <DateTime>{event.published_at} {event.start_time}</DateTime>
            <Button onClick={onPress}>
                {event.length ? (
                    <ButtonText>{isUser ? '予約内容詳細' : `${priceText} / ${event.length}時間`}</ButtonText>
                ) : (
                    <ButtonText>{isUser ? '予約内容詳細' : priceText}</ButtonText>
                )}
            </Button>
        </Section>
    )
};

export default EventListItem;
