import React from "react";
import ChatGroupType from "../../interfaces/ChatGroupType";
import ChatGroupListItem from "./ChatGroupListItem";

type Props = {
    items: ChatGroupType[];
    onPress: (chatGroup: ChatGroupType) => void;
}

const ChatGroupOwnList: React.FC<Props> = ({items, onPress}) => (
    <>
        {items.map(item => (
            <ChatGroupListItem
                chatGroup={item}
                key={item.id}
                onPress={() => onPress(item)}/>
        ))}
    </>
);

export default ChatGroupOwnList;