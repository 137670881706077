import React, {ChangeEvent, useCallback, useMemo, useState} from 'react';
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Section = styled.section<{ hide: boolean, noBorder?: boolean }>`
  background-color: ${({theme}) => theme.whiteColor};
  border-top-width: ${({noBorder}) => noBorder ? 0 : '1px'};
  border-top-color: ${({theme}) => theme.borderColor};
  border-top-style: solid;
  padding: 16px 8px;
  display: ${({hide}) => hide ? 'none' : 'flex'};
  flex-direction: row;
  align-items: center;
  min-width: 0;

  @media screen and (min-width: 480px) {
    padding: 16px;
  }
`;

const InputBox = styled.textarea`
  padding: 10px;
  background-color: #F0F1F2;
  flex: 1;
  border-radius: 20px;
  font-size: 16px;
  line-height: 16px;
  box-shadow: none;
  border: none;
  min-width: 0;
  resize: none;
  outline: none;
`;

const SendButton = styled(TouchableOpacity)`
  padding: 10px 24px;
  background-color: ${({theme, disabled}) => disabled ? theme.disableColor : theme.mainColor};
  border-radius: 20px;
  margin-left: 8px;
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
`;

const SendButtonText = styled(TitleTextBox)`
  color: ${({theme}) => theme.whiteColor};
  font-size: 16px;
  font-weight: bold;
`;

interface Props {
    isShow: boolean;
    onSubmit: (message: string) => void;
    onFocus?: () => void;
    parentId: number | null;
    noBorder?: boolean;
}

const ChatResponseForm: React.FC<Props> = ({isShow, onSubmit, parentId, onFocus, noBorder}) => {

    const [message, setMessage] = useState<string>('');

    const onPress = useCallback((event) => {
        event?.preventDefault();
        if (message.trim().length === 0) {
            return;
        }
        onSubmit(message);
        setMessage('');
    }, [message, onSubmit]);

    const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    }, []);

    const lines = useMemo(() => {
        const g = (message.match(/\n/g)?.length ?? 0) + 1;
        return g > 5 ? 5 : g;
    }, [message]);

    if (parentId === null || !isShow) {
        return <Section hide={true}/>
    }

    return (
        <Section hide={false} noBorder={noBorder}>
            <InputBox value={message}
                      onChange={onChange}
                      onFocus={onFocus}
                      rows={lines}
                      placeholder={'コメントを入力…'}/>
            <SendButton disabled={message.trim().length === 0}
                        onClick={onPress}>
                <SendButtonText>送信</SendButtonText>
            </SendButton>
        </Section>
    );
};

export default ChatResponseForm;
