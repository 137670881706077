import ChatGroupType from "../interfaces/ChatGroupType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../http/axios";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import PaginateMeta from "../interfaces/PaginateMeta";

export type ChatGroupState = {
    ownItems: ChatGroupType[];
    recentItems: ChatGroupType[];
    isFetching: boolean;
    lastRequestMeta?: PaginateMeta;
}

const initialState: ChatGroupState = {
    ownItems: [],
    recentItems: [],
    isFetching: false,
    lastRequestMeta: undefined,
};

export const fetchOwnChatGroups = createAsyncThunk<FetchReturnType<ChatGroupType>>(
    'chatGroups/fetch/own',
    async () => {
        const {data} = await axios.get('user/chat_groups');
        return data as FetchReturnType<ChatGroupType>;
    }
);

export const fetchRecentChatGroups = createAsyncThunk<FetchReturnType<ChatGroupType>, { page: number }>(
    'chatGroups/fetch/recent',
    async ({page}) => {
        const {data} = await axios.get(`chat_groups?page=${page}`);
        return data as FetchReturnType<ChatGroupType>;
    }
);

export const chatGroupSlice = createSlice({
    name: 'chat_group',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchOwnChatGroups.fulfilled, (state, action) => ({
                ...state,
                ownItems: action.payload.data,
                isFetching: false,
            }))
            .addCase(fetchOwnChatGroups.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchOwnChatGroups.rejected, state => ({
                ...state,
                isFetching: false,
                ownItems: []
            }))
            .addCase(fetchRecentChatGroups.fulfilled, (state, action) => ({
                ...state,
                recentItems: action.meta.arg.page > 1 ? [...state.recentItems, ...action.payload.data] : action.payload.data,
                isFetching: false,
                lastRequestMeta: action.payload.meta,
            }))
            .addCase(fetchRecentChatGroups.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchRecentChatGroups.rejected, state => ({
                ...state,
                isFetching: false,
                recentItems: []
            }))
    }
});
