import React, {useCallback, useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {useSelector} from "../../store";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchChatGroupDetail} from "../../stores/chat_group_detail";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import NotFound from "../NotFound";
import ScreenView from "../../parts/ScreenView";
import SnsHeader from "../../parts/SnsTab/SnsHeader";
import {detachChatGroupUser, fetchChatGroupUsers} from "../../stores/chat_group_user";
import {allowJoinRequest, denyJoinRequest, fetchJoinRequests} from "../../stores/join_request";
import JoinRequestItem from "../../parts/ChatGroupUserListPage/JoinRequestItem";
import JoinRequestType from "../../interfaces/JoinRequestType";
import SectionLabel from "../../parts/SectionLabel";
import ChatGroupUserItem from "../../parts/ChatGroupUserListPage/ChatGroupUserItem";
import styled from "styled-components";
import UserListHeaderView from "../../parts/ChatGroupUserListPage/UserListHeaderView";
import {resetRequestStatus as resetTransferStatus, transferChatGroupAdmin} from "../../stores/chat_group_admin";
import {leaveChatGroup, resetRequestStatus as resetLeaveStatus} from "../../stores/chat_group_leave";
import toastr from "toastr";
import {confirm} from "../../utils/confirm";
import ChatUserType from "../../interfaces/ChatUserType";

const FirstHeader = styled(SectionLabel)`
  margin-bottom: 8px;
  margin-top: 16px;
`

const SecondHeader = styled(SectionLabel)`
  margin-bottom: 8px;
  margin-top: 16px;
`;

const ChatGroupUserListPage: React.FC = () => {

    const {
        chat_group_detail,
        user,
        chat_group_user,
        join_request,
        chat_group_leave,
        chat_group_admin
    } = useSelector(state => ({
        chat_group_detail: state.chat_group_detail,
        user: state.user,
        chat_group_user: state.chat_group_user,
        join_request: state.join_request,
        chat_group_leave: state.chat_group_leave,
        chat_group_admin: state.chat_group_admin,
    }));

    const dispatch = useDispatch();
    const history = useHistory();
    const {id: chatGroupId} = useParams<{ id: string; }>();

    useEffect(() => {
        dispatch(fetchChatGroupDetail(chatGroupId));
        dispatch(fetchChatGroupUsers(chatGroupId));
    }, [dispatch, chatGroupId]);

    useEffect(() => {
        if (chat_group_detail.item === null) {
            return;
        }
        if (chat_group_detail.item.is_admin) {
            dispatch(fetchJoinRequests(chatGroupId));
        }
    }, [chatGroupId, chat_group_detail.item, dispatch, user.user]);

    useEffect(() => {
        if (chat_group_leave.requestStatus === 'success') {
            dispatch(resetLeaveStatus());
            toastr.success('グループを脱退しました。');
            history.replace('/sns');
        }
        if (chat_group_leave.requestStatus === 'failed') {
            dispatch(resetLeaveStatus());
            toastr.success('グループを脱退できませんでした。');
        }
    }, [chat_group_leave.requestStatus, dispatch, history]);

    useEffect(() => {
        if (chat_group_admin.requestStatus === 'success') {
            dispatch(resetTransferStatus());
            toastr.success('管理者を移譲しました。');
        }
        if (chat_group_admin.requestStatus === 'failed') {
            dispatch(resetTransferStatus());
            toastr.success('管理者を移譲できませんでした。');
        }
    }, [chat_group_admin.requestStatus, dispatch]);

    const onAllow = useCallback(async (request: JoinRequestType) => {
        if (!await confirm(`${request.user.nickname}さんの参加を承認しますか？`)) {
            return
        }
        dispatch(allowJoinRequest({
            chatGroupId: chatGroupId,
            requestId: request.id
        }));
    }, [chatGroupId, dispatch]);

    const onDeny = useCallback(async (request: JoinRequestType) => {
        if (!await confirm(`${request.user.nickname}さんの参加を拒否しますか？`)) {
            return
        }
        dispatch(denyJoinRequest({
            chatGroupId: chatGroupId,
            requestId: request.id
        }));
    }, [chatGroupId, dispatch]);

    const onTransfer = useCallback(async (item: ChatUserType) => {
        if (!await confirm(`${item.nickname}さんに管理者を譲りますか？`)) {
            return;
        }
        dispatch(transferChatGroupAdmin({
            chatGroupId: chatGroupId,
            userId: item.id
        }))
    }, [chatGroupId, dispatch]);

    const onKick = useCallback(async (item: ChatUserType) => {
        if (!await confirm(`${item.nickname}さんを除名しますか？`)) {
            return;
        }
        dispatch(detachChatGroupUser({
            chatGroupId: chatGroupId,
            userId: item.id
        }))
    }, [chatGroupId, dispatch]);

    const onLeave = useCallback(async () => {
        if (!await confirm('このグループから脱退しますか？')) {
            return;
        }
        dispatch(leaveChatGroup(chatGroupId));
    }, [chatGroupId, dispatch]);

    if (chat_group_detail.isFetching) {
        return <WithLoadingScreen loading={true}/>
    }
    if (chat_group_detail.item === null) {
        return <NotFound/>
    }

    const chatGroup = chat_group_detail.item;

    return (
        <>
            <Helmet>
                <title>{chatGroup.name}メンバー一覧</title>
            </Helmet>
            <SnsHeader user={user.user} canGoBack={true}/>
            <WithLoadingScreen loading={chat_group_user.isFetching || join_request.isFetching}>
                <UserListHeaderView group={chatGroup}/>
                <ScreenView>
                    {join_request.items.length > 0 ? (
                        <FirstHeader title={'参加希望者'}/>
                    ) : <></>}
                    {join_request.items.map(item => (
                        <JoinRequestItem item={item}
                                         key={item.id}
                                         onAllow={() => onAllow(item)}
                                         onDeny={() => onDeny(item)}/>
                    ))}
                    <SecondHeader title={'グループメンバー'}/>
                    {chat_group_user.items.map(item => (
                        <ChatGroupUserItem item={item}
                                           key={item.id}
                                           isAdmin={chatGroup.is_admin}
                                           isOwn={item.id === user.user?.id}
                                           onTransfer={() => onTransfer(item)}
                                           onKick={() => onKick(item)}
                                           onLeave={onLeave}/>
                    ))}
                </ScreenView>
            </WithLoadingScreen>
        </>
    );
};

export default ChatGroupUserListPage;