import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import FlatButton from "../FlatButton";
import {useSelector} from "../../store";

const Button = styled(FlatButton)`
  margin-top: 24px;
  margin-bottom: 32px;
  flex: 1;

  @media screen and (min-width: 480px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const EmptyView = styled.div`
  margin-top: 24px;
`;

interface Props {
    onPress: () => void;
}

const HistoryButton: React.FC<Props> = ({onPress}) => {
    const theme = useContext(ThemeContext);
    const {auth} = useSelector(state => ({
        auth: state.auth
    }));

    if (!auth.authenticated) {
        return <EmptyView/>
    }

    return (
        <Button text={'視聴動画の履歴'}
                backgroundColor={theme.whiteColor}
                textColor={theme.mainColor}
                borderColor={theme.mainColor}
                rightAllow
                onPress={onPress}/>
    );
};

export default HistoryButton
