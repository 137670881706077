import React from 'react';
import ExamAnswerType from "../../interfaces/ExamAnswerType";
import styled from "styled-components";
import {BodyText} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Section = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
`;

const CheckBox = styled.div`
  background-color: #E3E4E4;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Check = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.mainColor};
`;

const AnswerText = styled(BodyText)`
  margin-left: 8px;
  flex: 1;
`;

interface Props {
    answer: ExamAnswerType;
    selected: boolean;
    onSelect: () => void;
}

const ExamAnswerView: React.FC<Props> = ({answer, selected, onSelect}) => {
    return (
        <Section onClick={onSelect}>
            <CheckBox>
                {selected ? <Check/> : <></>}
            </CheckBox>
            <AnswerText>{answer.text}</AnswerText>
        </Section>
    )
};

export default ExamAnswerView;
