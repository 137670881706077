import React from "react";
import replace from "react-string-replace";
import {BodyText} from "./AppStyles";
import nl2br from "../utils/nl2br";

const regExp = /(https?:\/\/\S+)/g;

type Props = {
    text: string;
    className?: string;
}

const AutoLinkText: React.FC<Props> = ({text, className}) => {
    return <BodyText className={className}>{replace(nl2br(text), regExp, ((match, index) => (
        <a href={match} target={'_blank'} rel={'noreferrer'} key={`link.${index}`}>{match}</a>
    )))}</BodyText>
};

export default AutoLinkText;