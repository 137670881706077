import React from "react";
import ChatMessageType from "../../interfaces/ChatMessageType";
import styled from "styled-components";
import AutoLinkText from "../AutoLinkText";

const Description = styled.div`
  font-size: 14px;
  margin-top: 8px;
`;

interface Props {
    message: ChatMessageType;
}

const ChatInformation: React.FC<Props> = ({message}) => (
    <Description>
        <AutoLinkText text={message.text}/>
    </Description>
);

export default ChatInformation;