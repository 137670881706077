import React from "react";
import styled from "styled-components";
import welcomeLogoImage from '../../assets/welcome_logo.png';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

const Image = styled.img`
  width: 170px;
  height: 140px;
`;

const AppIcon: React.FC = () => {
    return (
        <Section>
            <Image src={welcomeLogoImage}/>
        </Section>
    )
};

export default AppIcon;