import React, {useEffect} from "react";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {fetchChatMessageReactions} from "../../stores/chat_message_reaction";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import SnsHeader from "../../parts/SnsTab/SnsHeader";
import {fetchUser} from "../../stores/user";
import SectionLabel from "../../parts/SectionLabel";
import ChatMessageReactionList from "../../parts/ChatMessageReactionListPage/ChatMessageReactionList";
import styled from "styled-components";

const ScreenView = styled.div`
  background-color: #FFF;
  flex: 1;
  padding: 24px 8px;
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;

  @media screen and (min-width: 480px) {
    padding: 24px 0;
  }
`;

const ChatMessageReactionListPage: React.FC = () => {
    const {chat_message_reaction, user} = useSelector(state => ({
        chat_message_reaction: state.chat_message_reaction,
        user: state.user,
    }));
    const dispatch = useDispatch();
    const {id: chatMessageId} = useParams<{ id: string }>();

    useEffect(() => {
        dispatch(fetchChatMessageReactions(chatMessageId));
        dispatch(fetchUser());
    }, [chatMessageId, dispatch]);

    return (
        <>
            <Helmet>
                <title>リアクション一覧</title>
            </Helmet>
            <SnsHeader user={user.user} canGoBack={true}/>
            <WithLoadingScreen loading={chat_message_reaction.isFetching}>
                <ScreenView>
                    <SectionLabel title={'リアクション一覧'} fontSize={22}/>
                    <ChatMessageReactionList reactions={chat_message_reaction.items}/>
                </ScreenView>
            </WithLoadingScreen>
        </>
    )
};

export default ChatMessageReactionListPage;