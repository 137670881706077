export default function imageCompress(file: File, maxSize: number = 1024) {
    return new Promise<File>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            let img = new Image();
            img.onload = () => {
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxSize) {
                        const ratio = height / width;
                        height = maxSize * ratio;
                        width = maxSize;
                    }
                } else {
                    if (height > maxSize) {
                        const ratio = width / height;
                        height = maxSize;
                        width = maxSize * ratio;
                    }
                }

                let canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                let ctx = canvas.getContext('2d');
                if (ctx === null) {
                    reject("failed create canvas");
                    return;
                }
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                    if (blob) {
                        const imageFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now()
                        });
                        resolve(imageFile);
                    } else {
                        reject("failed create blob");
                    }
                }, file.type, 1);
            };
            if (e.target?.result) {
                img.src = e.target.result as string;
            } else {
                reject();
            }
        };
    });
}