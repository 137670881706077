import React, {useContext} from 'react';
import ExamAnswerListType from "../../interfaces/ExamAnswerListType";
import styled, {ThemeContext} from "styled-components";
import FlatButton from "../FlatButton";
import ExamType from "../../interfaces/ExamType";
import {TitleTextBox} from "../AppStyles";

const Section = styled.div`
  margin-top: 16px;
  padding: 16px 0;
`;

const TotalResultBox = styled.div`
  border: 1px solid #212121;
  border-radius: 2px;
  padding: 16px;
`;

const TotalResultStartLabel = styled(TitleTextBox)`
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
`;

const TotalResultValue = styled(TitleTextBox)`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-top: 16px;
  color: ${({theme}) => theme.mainColor};
`;

const TotalResultEndLabel = styled(TitleTextBox)`
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
  color: ${({theme}) => theme.mainColor};
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const AnswerBox = styled.div`
  margin-top: 24px;
  width: 20%;
`;

const AnswerNumber = styled(TitleTextBox)`
  text-align: center;
  font-size: 16px;
`;

const AnswerResult = styled(TitleTextBox)`
  text-align: center;
  font-size: 24px;
  color: ${({theme}) => theme.mainColor};
`;

const ButtonBox = styled.div`
  margin: 24px;
`;

const RetryButton = styled(FlatButton)`

`;

const GoBackButton = styled(FlatButton)`
  margin-top: 24px;
`;

interface Props {
    exam: ExamType;
    answers: ExamAnswerListType;
    onRetry: () => void;
    onGoBack: () => void;
}

const ExamResultView: React.FC<Props> = ({exam, answers, onRetry, onGoBack}) => {

    const theme = useContext(ThemeContext);

    const grade = exam.items.reduce((grade, item) => {
        if (answers[item.id].is_correct) {
            return grade + item.grade;
        } else {
            return grade;
        }
    }, 0);

    return (
        <Section>
            <TotalResultBox>
                <TotalResultStartLabel>
                    あなたの回答は
                </TotalResultStartLabel>
                <TotalResultValue>
                    {grade}
                </TotalResultValue>
                <TotalResultEndLabel>
                    点でした（合格点: {exam.passing_grade}点）
                </TotalResultEndLabel>
            </TotalResultBox>
            <FlexBox>
                {exam.items.map((item, index) => (
                    <AnswerBox key={`answers.${index}`}>
                        <AnswerNumber>問題{index + 1}</AnswerNumber>
                        <AnswerResult>{answers[item.id].is_correct ? '○' : '×'}</AnswerResult>
                    </AnswerBox>
                ))}
            </FlexBox>
            <ButtonBox>
                <RetryButton text={'もう一度受ける'}
                             rightAllow
                             backgroundColor={theme.whiteColor}
                             borderColor={theme.subColor}
                             textColor={theme.subColor}
                             onPress={onRetry}/>
                <GoBackButton text={'動画ページトップに戻る'}
                              backgroundColor={theme.textColor}
                              leftAllow
                              borderRadius={4}
                              onPress={onGoBack}/>
            </ButtonBox>
        </Section>
    )
};

export default ExamResultView;
