import React from "react";
import {Link} from "react-router-dom";
import ScreenView from "../../parts/ScreenView";
import styled from "styled-components";
import {Helmet} from "react-helmet-async";

const Title = styled.h1`
`;

const Description = styled.div`
`;

const BackLinkBox = styled.div`
  margin-top: 24px;
`;

const PaymentCancel: React.FC = () => {
    return (
        <ScreenView>
            <Helmet>
                <title>お支払いのキャンセル</title>
            </Helmet>
            <Title>お支払いのキャンセル</Title>
            <Description>
                お支払いがキャンセルされました。<br/>
                トップページに戻ってください。
            </Description>
            <BackLinkBox>
                <Link to={'/home'}>トップページへ</Link>
            </BackLinkBox>
        </ScreenView>
    )
};

export default PaymentCancel;