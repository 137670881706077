import React from 'react';
import styled from "styled-components";
import GuestMemberCard from "../../parts/GuestAccountTab/GuestMemberCard";
import {useSelector} from "../../store";
import TabHeader from "../../parts/TabHeader";
import accountIcon from '../../assets/icons/account.png';
import {Helmet} from "react-helmet-async";

const Screen = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

const GuestAccountTab: React.FC = () => {
    const {serial_number} = useSelector(state => ({
        serial_number: state.serial_number
    }));

    return (
        <>
            <Helmet>
                <title>会員情報</title>
            </Helmet>
            <TabHeader source={accountIcon} title={'ゲスト会員情報'}/>
            <Screen>
                <GuestMemberCard serial_number={serial_number.item}/>
            </Screen>
        </>
    );
};

export default GuestAccountTab;
