import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import NonMemberEventParams from "../interfaces/NonMemberEventParams";
import {RootState} from "../store";

export type PaymentEventState = {
    result?: object;
    requestStatus: RequestStatusType;
    errors: object;
}

const initialState: PaymentEventState = {
    result: undefined,
    requestStatus: 'init',
    errors: {},
};

export const getPaymentEvent = createAsyncThunk<FetchDetailReturnType<object>, number>(
    'payment_event/get',
    async (eventId) => {
        const {data} = await axios.post(`events/${eventId}/payment`);
        return data as FetchDetailReturnType<object>;
    }
);

type WithParams = {
    eventId: number;
    params: NonMemberEventParams;
};

export const getPaymentEventWithParams = createAsyncThunk<FetchDetailReturnType<object>, WithParams>(
    'payment_event/get_with_params',
    async (params, thunk) => {
        try {
            const {serial_number} = thunk.getState() as RootState;
            const {data} = await axios.post(`events/${params.eventId}/payment`, {
                ...params.params,
                code: serial_number.item.device_code,
            });
            return data as FetchDetailReturnType<object>;
        } catch (e) {
            thunk.dispatch(setErrors(e.response?.data?.errors || {}));
            throw e;
        }
    }
);

export const paymentEventSlice = createSlice({
    name: 'payment_event',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
        setErrors: (state, action: PayloadAction<Object>) => ({
            ...state,
            errors: action.payload
        })
    },
    extraReducers: builder => {
        builder
            .addCase(getPaymentEvent.fulfilled, (state, action) => ({
                ...state,
                result: action.payload.data,
                requestStatus: 'success'
            }))
            .addCase(getPaymentEvent.pending, state => ({
                ...state,
                result: undefined,
                requestStatus: 'pending'
            }))
            .addCase(getPaymentEvent.rejected, state => ({
                ...state,
                result: undefined,
                requestStatus: 'failed'
            }))
            .addCase(getPaymentEventWithParams.fulfilled, (state, action) => ({
                ...state,
                result: action.payload.data,
                requestStatus: 'success'
            }))
            .addCase(getPaymentEventWithParams.pending, state => ({
                ...state,
                result: undefined,
                requestStatus: 'pending',
                errors: {}
            }))
            .addCase(getPaymentEventWithParams.rejected, state => ({
                ...state,
                result: undefined,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus, setErrors} = paymentEventSlice.actions;
