import React, {useEffect} from 'react';
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {getPaymentUserCreditChange, resetRequestStatus} from "../../stores/payment_user_credit_change";
import PaymentWebView from "../../parts/PaymentWebView";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import toastr from 'toastr';
import {useHistory} from "react-router-dom";

const ChangeCreditExec: React.FC = () => {

    const {payment} = useSelector(state => ({
        payment: state.payment_user_credit_change,
    }));
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getPaymentUserCreditChange());
    }, []);

    useEffect(() => {
        if (payment.requestStatus === 'success') {
            dispatch(resetRequestStatus());
        }
        if (payment.requestStatus === 'failed') {
            toastr.error('お支払い情報の取得に失敗しました。');
            dispatch(resetRequestStatus());

            history.goBack();
        }
    }, [dispatch, history, payment.requestStatus]);

    return (
        <WithLoadingScreen
            loading={payment.requestStatus === 'pending'}>
            <PaymentWebView
                params={payment.result}/>
        </WithLoadingScreen>
    );
};

export default ChangeCreditExec;
