import React from "react";
import ChatMessageType from "../../interfaces/ChatMessageType";
import ModalView from "../../parts/ModalView";
import styled from "styled-components";
import {BodyTextBox, TitleText, TitleTextBox} from "../../parts/AppStyles";
import TouchableOpacity from "../../parts/TouchableOpacity";

const Section = styled.div`
  padding: 16px;
`;

const DeleteHeader = styled(TitleTextBox)`
  font-size: 18px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  text-align: center;
`;

const WarningText = styled(BodyTextBox)`
  margin-top: 16px;
  font-size: 14px;
  text-align: center;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};

  @media screen and (min-width: 480px) {
    flex-direction: row;
    border-bottom: none;
    margin-left: 0;
    margin-right: 0;
  }
`;

const EditButton = styled(TouchableOpacity)`
  padding: 8px;
  text-align: center;
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.borderColor};

  @media screen and (min-width: 480px) {
    border-radius: 5px;
    background-color: #edeeef;
    margin-right: 8px;
    width: 150px;
  }
`;

const EditButtonText = styled(TitleText)`
  font-weight: bold;
  font-size: 16px;

  @media screen and (min-width: 480px) {
    font-size: 14px;
  }
`;

const DeleteButton = styled(TouchableOpacity)`
  padding: 8px;
  text-align: center;
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.borderColor};

  @media screen and (min-width: 480px) {
    border-radius: 5px;
    background-color: white;
    border: 1px solid ${({theme}) => theme.dangerColor};
    margin-left: 8px;
    width: 150px;
  }
`;

const DeleteButtonText = styled(TitleText)`
  font-weight: bold;
  font-size: 16px;
  color: ${({theme}) => theme.dangerColor};

  @media screen and (min-width: 480px) {
    font-size: 14px;
  }
`;

const CancelButton = styled(TouchableOpacity)`
  border-radius: 24px;
  background-color: white;
  border: 1px solid ${({theme}) => theme.mainColor};
  padding: 4px;
  text-align: center;
  width: 100%;
  margin: 24px auto 8px;

  @media screen and (min-width: 480px) {
    width: 150px;
  }
`;

const CancelButtonText = styled(TitleText)`
  font-weight: bold;
  font-size: 16px;
  color: ${({theme}) => theme.mainColor};

  @media screen and (min-width: 480px) {
    font-size: 12px;
  }
`;

const Inline = styled.span`
  display: inline-block;
`;

const DummyEdit = styled.div`
  @media screen and (min-width: 480px) {
    margin-left: -8px;
  }
`;

type Props = {
    show: boolean;
    onClose: () => void;
    onDelete: () => void;
    onEdit: (chatMessage: ChatMessageType) => void;
    deleteItem: ChatMessageType | null;
}

const DeleteChatMessage: React.FC<Props> = ({show, onClose, onDelete, deleteItem, onEdit}) => {
    if (!show || deleteItem === null) {
        return <></>
    }

    return (
        <ModalView show={show} onClose={onClose} width={600}>
            <Section>
                <DeleteHeader>投稿を削除しますか？</DeleteHeader>
                <WarningText>
                    <Inline>この投稿は削除され、</Inline><Inline>見ることができなくなります。</Inline><br/>
                    {deleteItem.is_own ? '削除せずに投稿を編集することもできます。' : ''}
                </WarningText>
                <ButtonBox>
                    {deleteItem.is_own ? (
                        <EditButton onClick={() => onEdit(deleteItem)}>
                            <EditButtonText>投稿の編集</EditButtonText>
                        </EditButton>
                    ) : <DummyEdit/>}
                    <DeleteButton onClick={onDelete}>
                        <DeleteButtonText>投稿を削除</DeleteButtonText>
                    </DeleteButton>
                </ButtonBox>
                <CancelButton onClick={onClose}>
                    <CancelButtonText>キャンセル</CancelButtonText>
                </CancelButton>
            </Section>
        </ModalView>
    )
};

export default DeleteChatMessage;
