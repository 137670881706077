import React, { useEffect } from 'react';
import { useSelector } from "../../store";
import VideoWatchHistoryListItem from "../../parts/VideoWatchHistoryPage/VideoWatchHistoryListItem";
import VideoWatchLogType from "../../interfaces/VideoWatchLogType";
import PageHeader from "../../parts/Headers/PageHeader";
import { useDispatch } from "react-redux";
import { fetchVideoWatchLog } from "../../stores/video_watch_log";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const VideoWatchHistoryPage: React.FC = () => {
    const { video_watch_log } = useSelector(state => ({
        video_watch_log: state.video_watch_log
    }));

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchVideoWatchLog());
    }, [dispatch]);

    const onHistoryPress = (watchLog: VideoWatchLogType) => {
        if (watchLog.position + 5000 > watchLog.duration) {
            history.push(`/videos/${watchLog.video_id}`);
        } else {
            history.push(`/videos/${watchLog.video_id}?t=${watchLog.position}`)
        }
    };

    return (
        <>
            <Helmet>
                <title>視聴動画の履歴</title>
            </Helmet>
            <PageHeader title={'視聴動画の履歴'} />
            {video_watch_log.items.map(item => (
                <VideoWatchHistoryListItem log={item}
                    key={item.id}
                    onPress={() => onHistoryPress(item)} />
            ))}
        </>
    )
};

export default VideoWatchHistoryPage;
