import React, {useContext} from 'react';
import VideoType from "../../interfaces/VideoType";
import styled, {ThemeContext} from "styled-components";
import {TitleText} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";
import NextVideoItem from "./NextVideoItem";
import TouchableOpacity from "../TouchableOpacity";

const Section = styled.div`
  margin-top: 48px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${({theme}) => theme.borderColor};
`;

const NextVideoLink = styled(TouchableOpacity)`
  padding: 16px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.borderColor};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;
`;

const NextVideoText = styled(TitleText)`
  color: ${({theme}) => theme.mainColor};
  font-size: 16px;
  font-weight: bold;
  flex: 1;
`;

interface Props {
    nextVideos?: VideoType[];
    onPress: (video: VideoType) => void;
}

const NextVideoBox: React.FC<Props> = ({nextVideos, onPress}) => {
    const theme = useContext(ThemeContext);

    if (nextVideos === undefined || nextVideos.length === 0) {
        return <></>
    }
    return (
        <Section>
            <NextVideoLink onClick={() => onPress(nextVideos[0])}>
                <Row>
                    <NextVideoText>次の動画を見る</NextVideoText>
                    <ArrowIcon size={16} color={theme.mainColor}/>
                </Row>
            </NextVideoLink>
            {nextVideos.map(video => (
                <NextVideoItem video={video}
                               onPress={() => onPress(video)}
                               key={`next_videos.${video.id}`}/>
            ))}
        </Section>
    );
};

export default NextVideoBox;
