import React from 'react';
import styled from "styled-components";
import {TitleText} from "../AppStyles";
import Image from "../Image";
import examIcon from '../../assets/icons/exam.png';

const Header = styled.div`
  background-color: ${({theme}) => theme.subColor};
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderIcon = styled.div`
  width: 20px;
  height: 20px;
`;

const HeaderIconImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const HeaderText = styled(TitleText)`
  color: ${({theme}) => theme.whiteColor};
  font-weight: bold;
  font-size: 20px;
  margin-left: 8px;
`;

const ExamHeader: React.FC = () => {
    return (
        <Header>
            <HeaderIcon>
                <HeaderIconImage src={examIcon} color={'#FFFFFF'}/>
            </HeaderIcon>
            <HeaderText>ミニテスト</HeaderText>
        </Header>
    );
};

export default ExamHeader;
