import ExamType from "../interfaces/ExamType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchNullableDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type ExamState = {
    item?: ExamType;
    isProcessing: boolean;
    selectVideoId?: number;
};

const initialState: ExamState = {
    item: undefined,
    isProcessing: false,
    selectVideoId: undefined
};

export const fetchExam = createAsyncThunk<FetchNullableDetailReturnType<ExamType>, any>(
    'exam/fetch',
    async (videoId) => {
        const {data} = await axios.get(`videos/${videoId}/exam`);
        return data as FetchNullableDetailReturnType<ExamType>;
    }
);

export const examSlice = createSlice({
    name: 'exam',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchExam.fulfilled, (state, action) => ({
                ...state,
                item: action.payload?.data,
                isProcessing: false,
            }))
            .addCase(fetchExam.pending, (state, action) => ({
                ...state,
                item: undefined,
                selectVideoId: action.meta.arg,
                isProcessing: true,
            }))
            .addCase(fetchExam.rejected, state => ({
                ...state,
                item: undefined,
                isProcessing: false,
                selectVideoId: undefined,
            }))
    }
});
