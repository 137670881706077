import RequestStatusType from "../interfaces/RequestStatusType";
import RequestErrorType from "../interfaces/RequestErrorType";
import UserType from "../interfaces/UserType";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import {RootState} from "../store";
import axios from "../http/axios";
import {setAuthenticate} from "./auth";

export type RegisterInformationState = {
    requestStatus: RequestStatusType;
    errors: RequestErrorType;
    registerUser?: UserType;
}

const initialState: RegisterInformationState = {
    requestStatus: 'init',
    errors: {},
    registerUser: undefined,
};

type RegisterInformationParams = {
    birthday: string | null;
    industry: string | null;
    attachment: string | null;
    tel: string | null;
    nickname: string | null;
    email: string | null;
};

export const fetchRegisterUser = createAsyncThunk<FetchDetailReturnType<UserType>>(
    'register/fetchRegisterUser',
    async (arg, thunk) => {
        const {register} = thunk.getState() as RootState;
        const {data} = await axios.get('user', {
            headers: {
                Authorization: `Bearer ${register.token}`
            }
        });
        return data as FetchDetailReturnType<UserType>
    }
);

export const execRegisterInformation = createAsyncThunk<void, RegisterInformationParams>(
    'register/execRegisterInformation',
    async (request, thunk) => {
        const {register} = thunk.getState() as RootState;
        await axios.put('user', request, {
            headers: {
                Authorization: `Bearer ${register.token}`
            }
        }).catch(error => {
            thunk.dispatch(setErrors(error.response?.data?.errors || {}));
            throw error
        });
        thunk.dispatch(setAuthenticate(register.token));
    }
);

export const registerInformationSlice = createSlice({
    name: 'register_information',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
        setErrors: (state, action: PayloadAction<RequestErrorType>) => ({...state, errors: action.payload})
    },
    extraReducers: builder => {
        builder
            .addCase(execRegisterInformation.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(execRegisterInformation.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(execRegisterInformation.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
            .addCase(fetchRegisterUser.fulfilled, (state, action) => ({
                ...state,
                registerUser: action.payload.data,
            }))
            .addCase(fetchRegisterUser.pending, state => ({
                ...state,
                registerUser: undefined,
            }))
            .addCase(fetchRegisterUser.rejected, state => ({
                ...state,
            }))
    }
});

export const {resetRequestStatus, setErrors} = registerInformationSlice.actions;
