import React from 'react';
import EventType from "../../interfaces/EventType";
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Box = styled.div`
  flex-direction: row;
  margin-bottom: 8px;
`;

const Link = styled(TouchableOpacity)`

`;

const LinkText = styled(TitleTextBox)`
  text-decoration: underline;
  text-decoration-color: ${({theme}) => theme.linkColor};
  color: ${({theme}) => theme.linkColor};
`;

interface Props {
    event: EventType;
    onPress: () => void;
}

const ApplyEventListItem: React.FC<Props> = ({event, onPress}) => {
    return (
        <Box>
            <Link onClick={onPress}>
                <LinkText>
                  {event.name}: {event.published_at}
                  ({event.qualifications.map(q => `${q.qualification_name}: ${q.unit}`).join(", ")})
                </LinkText>
            </Link>
        </Box>
    )
};

export default ApplyEventListItem;
