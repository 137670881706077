import React from "react";
import styled from "styled-components";
import {BodyTextBox, TitleTextBox} from "../../parts/AppStyles";
import FlatButton from "../../parts/FlatButton";
import {useHistory} from "react-router-dom";
import ScreenView from "../../parts/ScreenView";

const Title = styled(TitleTextBox)`
  font-size: 20px;
  font-weight: bold;
`;

const Description = styled(BodyTextBox)`
  margin-top: 24px;
  font-size: 14px;
`;

const LoginButton = styled(FlatButton)`
  margin-top: 24px;
`;

const ExpiredScreen: React.FC = () => {
    const history = useHistory();
    return (
        <ScreenView>
            <Title>会員の有効期限が切れています</Title>
            <Description>
                会員の有効期限が切れています。<br/>
                継続をご希望の場合はお手数ですがココリンク運営事務局（cocolink_support@astick0115.com）までお問合せください。
            </Description>
            <Description>
                もしお支払いいただいたにも関わらずこの画面が表示された場合は、アクセス集中による決済の反映待ちが発生している可能性がございます。<br/>
                その場合は恐れ入りますがしばらく経ってから再度ログインいただくか、お問い合わせをお願いいたします。
            </Description>
            <LoginButton text={'再ログイン'}
                         textColor={'#FFF'}
                         backgroundColor={'#616161'}
                         onPress={() => history.replace('/')}/>
        </ScreenView>
    )
};

export default ExpiredScreen;