import RequestStatusType from "../interfaces/RequestStatusType";
import RequestErrorType from "../interfaces/RequestErrorType";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "../http/axios";

export type ForgotPasswordState = {
    requestStatus: RequestStatusType;
    errors: RequestErrorType;
}

const initialState: ForgotPasswordState = {
    requestStatus: "init",
    errors: {},
}

export const sendForgotRequest = createAsyncThunk<void, string>(
    'forgot_password/send',
    async (email, thunk) => {
        await axios
            .post('password/remind', {email})
            .catch(error => {
                thunk.dispatch(setErrors(error.response?.data?.errors || {}));
                throw error
            });
    }
)

export const forgotPasswordSlice = createSlice({
    name: 'forgot_password',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
        setErrors: (state, action: PayloadAction<RequestErrorType>) => ({...state, errors: action.payload})
    },
    extraReducers: builder => {
        builder
            .addCase(sendForgotRequest.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(sendForgotRequest.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(sendForgotRequest.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus, setErrors} = forgotPasswordSlice.actions;