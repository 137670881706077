import React from "react";
import ChatMessageReactionType from "../../interfaces/ChatMessageReactionType";
import styled from "styled-components";
import Image from "../Image";
import {TitleTextBox} from "../AppStyles";

const Section = styled.div`
  border-top: 1px solid ${({theme}) => theme.borderColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
`;

const UserBox = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserIcon = styled(Image)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const UserInformation = styled.div`

`;

const UserName = styled(TitleTextBox)`
  color: ${({theme}) => theme.textColor};
`;

const UserIndustry = styled(TitleTextBox)`
  color: ${({theme}) => theme.textGrayColor};
  margin-top: 4px;
`;

const ReactionIcon = styled.img`
  margin-left: auto;
  width: 26px;
  height: 26px;
`;

interface Props {
    reaction: ChatMessageReactionType;
}

const ChatMessageReactionItem: React.FC<Props> = ({reaction}) => {

    return (
        <Section>
            <UserBox>
                {reaction.user.profile_url ? (
                    <UserIcon src={reaction.user.profile_url}/>
                ) : <></>}
                <UserInformation>
                    <UserName>{reaction.user.nickname}</UserName>
                    <UserIndustry>{reaction.user.industry}</UserIndustry>
                </UserInformation>
            </UserBox>
            <ReactionIcon src={reaction.reaction.file_url} alt={reaction.reaction.name}/>
        </Section>
    )
};

export default ChatMessageReactionItem;