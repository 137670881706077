import React, {CSSProperties, useCallback} from 'react';
import styled from "styled-components";
import {TitleTextBox} from "./AppStyles";
import TouchableOpacity from "./TouchableOpacity";

const LinkButtonText = styled(TitleTextBox)<{ disabled: boolean }>`
  text-align: center;
  color: ${({theme}) => theme.linkColor};
  text-decoration: underline;
  text-decoration-color: ${({theme}) => theme.linkColor};
`;

interface Props {
    text: string;
    onPress?: () => void;
    url?: string;
    disabled?: boolean;
    className?: string;
    style?: CSSProperties;
}

const LinkText: React.FC<Props> = ({text, onPress, url, style, className, disabled = false}) => {

    const onPressText = useCallback(async () => {
        if (onPress === undefined) {
            if (url !== undefined) {
                window.open(url)
            }
        } else {
            onPress();
        }
    }, [url, onPress]);

    return (
        <TouchableOpacity onClick={onPressText} className={className} style={style} disabled={disabled}>
            <LinkButtonText disabled={disabled}>{text}</LinkButtonText>
        </TouchableOpacity>
    );
};

export default LinkText;
