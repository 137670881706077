import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {resetRequestStatus, sendForgotRequest, setErrors} from '../../stores/forgot_password';
import {Helmet} from "react-helmet-async";
import PageHeader from "../../parts/Headers/PageHeader";
import ScreenView from "../../parts/ScreenView";
import styled from "styled-components";
import InformationBox from "../../parts/RegisterInformation/InformationBox";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";
import Footer from "../../parts/Footer";
import {Link} from 'react-router-dom';

const Information = styled.div`
  text-align: center;
`;

const SuccessInformation = styled.div`
  text-align: center;
  margin-top: 16px;
`;

const Form = styled.form`
  margin-top: 24px;
`;

const SubmitButton = styled.button`
  outline: none;
  border: none;
  box-shadow: none;
  background-color: ${({theme}) => theme.mainColor};
  width: 100%;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  
  &:disabled {
    background-color: ${({theme}) => theme.disableColor};
  }
`;

const ForgotPassword: React.FC = () => {

    const {forgot_password} = useSelector(state => ({
        forgot_password: state.forgot_password
    }));
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetRequestStatus());
        dispatch(setErrors({}));
    }, [dispatch]);

    useEffect(() => {
        if (forgot_password.requestStatus === 'success') {
            setSuccess(true);
            setEmail('');
            dispatch(resetRequestStatus());
        } else if (forgot_password.requestStatus === "failed") {
            dispatch(resetRequestStatus());
        }
    }, [dispatch, forgot_password.requestStatus]);

    const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSuccess(false);
        dispatch(sendForgotRequest(email));
    }, [dispatch, email]);

    return (
        <>
            <Helmet>
                <title>パスワードの再発行</title>
            </Helmet>
            <NoAuthHeader/>
            <PageHeader title={'パスワードの再発行'}/>
            <ScreenView>
                <Information>
                    ご登録のメールアドレスを入力してください。<br/>
                    メールアドレスが不明な場合または未登録のお客様はお手数ですが
                    <Link to={'/contact'}>お問い合わせ</Link>
                    ください。
                </Information>
                {success ? (
                    <SuccessInformation>
                        パスワード再発行のご案内をメールアドレス宛に送信しました。<br/>
                        しばらく経っても届かない場合は再度やり直してください。
                    </SuccessInformation>
                ) : <></>}
                <Form onSubmit={onSubmit}>
                    <InformationBox label={'ご登録のメールアドレス'}
                                    value={email}
                                    editable={true}
                                    errors={forgot_password.errors}
                                    name={'email'}
                                    onInput={email => setEmail(email)}/>
                    <SubmitButton
                        type={"submit"}
                        disabled={forgot_password.requestStatus === 'pending' || email.length === 0}>
                        送信
                    </SubmitButton>
                </Form>
            </ScreenView>
            <Footer/>
        </>
    );
};

export default ForgotPassword;