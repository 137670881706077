import React, {useContext} from 'react';
import VideoType from "../../interfaces/VideoType";
import styled, {ThemeContext} from "styled-components";
import {BodyTextBox, ContentWrap, TitleTextBox} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";
import CheckIcon from "../CheckIcon";
import TouchableOpacity from "../TouchableOpacity";
import videoBackground from '../../assets/video_list_bg.png';
import nl2br from "../../utils/nl2br";

const Background = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.borderColor};
  border-bottom-style: solid;
  background-image: url(${videoBackground});
  background-size: cover;
  width: 100%;
`;

const Section = styled(TouchableOpacity)`
  padding: 16px;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: 100px;
`;

const Title = styled(TitleTextBox)`
  font-size: 20px;
  font-weight: bold;
  padding: 0 8px;
  color: ${({theme}) => theme.mainColor};
  flex: 1;
`;

const Description = styled(BodyTextBox)`
  margin-top: 8px;
  padding: 8px 0;
`;

interface Props {
    video: VideoType;
    onPress: () => void;
}

const VideoListItem: React.FC<Props> = ({video, onPress}) => {
    const theme = useContext(ThemeContext);
    return (
        <Background>
            <ContentWrap>
                <Section onClick={onPress}>
                    <RowFlex>
                        <Image src={video.thumbnail_url}/>
                        <CheckIcon show={video.is_finish}/>
                        <Title>{video.name}</Title>
                        <ArrowIcon size={16} color={theme.subColor}/>
                    </RowFlex>
                    <Description>{nl2br(video.description ?? '')}</Description>
                </Section>
            </ContentWrap>
        </Background>
    )
};

export default VideoListItem;
