import React from 'react';
import UserType from "../../interfaces/UserType";
import styled from "styled-components";
import {BodyTextBox} from "../AppStyles";
import {Link} from "react-router-dom";

const Section = styled.div`
  margin-top: 20px;
`;

const Label = styled(BodyTextBox)`
  margin-bottom: 10px;
  color: ${({theme}) => theme.textGrayColor};
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChangeLink = styled(Link)`
  margin-left: 20px;
`;

const ChangeButtonFlex = styled.div`
  display: block;
  flex-direction: row;

  @media screen and (min-width: 480px) {
    display: flex;
  }
`;

const ChangeButton = styled(Link)`
  display: block;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.mainColor};
  color: white;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }

  &:hover, &:active, &:focus, &:visited {
    color: white;
  }
  
  &:nth-of-type(2) {
    margin-top: 16px;
  }

  @media screen and (min-width: 480px) {
    &:nth-of-type(2) {
      margin-top: 0;
      margin-left: 16px;
    }
  }
`;

interface Props {
    user: UserType;
}

const MemberInformation: React.FC<Props> = ({user}) => {

    return (
        <Section>
            <Label>{user.name}</Label>
            <Label>{user.birthday}</Label>
            <Label>{user.qualifications_text}</Label>
            <Label>{user.attachment}</Label>
            <Flex>
                <Label>{user.email}</Label>
                <ChangeLink to={'/user/update'}>
                    変更する {'>'}
                </ChangeLink>
            </Flex>
            <Label>{user.tel}</Label>
            <ChangeButtonFlex>
                <ChangeButton to={'/user/update'}>
                    登録情報を変更する
                </ChangeButton>
                {user.membership_method === 'credit' ? (
                    <ChangeButton to={'/payment/change'}>
                        クレジットカード番号の変更
                    </ChangeButton>
                ) : <></>}
            </ChangeButtonFlex>
        </Section>
    )
};

export default MemberInformation;
