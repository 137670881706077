import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import {TitleText} from "./AppStyles";
import Image from "./Image";
import ArrowIcon from "./ArrowIcon";

const Header = styled.header`
  background-color: ${({theme}) => theme.mainColor};
  padding: 12px 16px;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const HeaderIconImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const HeaderText = styled(TitleText)`
  color: ${({theme}) => theme.whiteColor};
  font-weight: bold;
  font-size: 20px;
  margin-left: 8px;
`;

const BackButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface Props {
    source: string;
    title: string;
    back?: boolean;
    onBack?: () => void;
}

const TabHeader: React.FC<Props> = ({source, title, back = false, onBack}) => {
    const theme = useContext(ThemeContext);
    return (
        <Header>
            <Content>
                {back ? (
                    <BackButton onClick={onBack}>
                        <ArrowIcon color={theme.whiteColor} size={16} leftArrow/>
                    </BackButton>
                ) : (
                    <HeaderIcon>
                        <HeaderIconImage src={source} color={theme.whiteColor}/>
                    </HeaderIcon>
                )}
                <HeaderText>{title}</HeaderText>
            </Content>
        </Header>
    )
};

export default TabHeader;
