import React, {HTMLProps, useContext} from 'react';
import CategoryType from "../../interfaces/CategoryType";
import styled, {ThemeContext} from "styled-components";
import {TitleText} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";
import TouchableOpacity from "../TouchableOpacity";
import categoryBackground from '../../assets/category_button_bg.png';

const FlexBox = styled.div`
  padding: 8px;
  box-sizing: border-box;
  width: 50%;

  @media screen and (min-width: 480px) {
    width: 33%;

    &:nth-of-type(3n+1) {
      padding-left: 0;
    }

    &:nth-of-type(3n+2) {
      padding-left: 4px;
      padding-right: 4px;
    }

    &:nth-of-type(3n) {
      padding-right: 0;
    }
  }
`;

const Background = styled(TouchableOpacity)`
  background-image: url(${categoryBackground});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  &:before {
    content: "";
    display: block;
    padding-top: 52%;
  }
`;

const Section = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px 8px;
  overflow: hidden;

  @media screen and (min-width: 480px) {
    padding: 16px;
  }
`;

const Title = styled(TitleText)`
  font-size: 16px;
  font-weight: 600;
  color: ${({theme}) => theme.whiteColor};
  margin-right: auto;
  flex: 1;
  height: 100%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 480px) {
    font-size: 20px;
    -webkit-line-clamp: 3;
  }
`;

const PcArrow = styled(ArrowIcon)`
  display: none;

  @media screen and (min-width: 480px) {
    display: block;
  }
`;

const MobileArrow = styled(ArrowIcon)`
  display: block;

  @media screen and (min-width: 480px) {
    display: none;
  }
`;

interface Props extends HTMLProps<HTMLDivElement> {
    category: CategoryType;
    onPress: () => void;
}

const CategoryListItem: React.FC<Props> = (props) => {
    const {category, onPress} = props;
    const theme = useContext(ThemeContext);

    return (
        <FlexBox className={props.className}>
            <Background onClick={onPress}>
                <Section>
                    <Title>{category.name}</Title>
                    <PcArrow size={20} color={theme.whiteColor}/>
                    <MobileArrow size={16} color={theme.whiteColor}/>
                </Section>
            </Background>
        </FlexBox>
    );
};

export default CategoryListItem;
