import React, {useContext, useEffect, useState} from 'react';
import ExamItemType from "../../interfaces/ExamItemType";
import ExamAnswerType from "../../interfaces/ExamAnswerType";
import styled, {ThemeContext} from "styled-components";
import ExamType from "../../interfaces/ExamType";
import ExamAnswerView from "./ExamAnswerView";
import FlatButton from "../FlatButton";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import nl2br from "../../utils/nl2br";

const Section = styled.div`
  margin-top: 16px;
  padding: 16px 0;
`;

const ItemHeaderText = styled(TitleTextBox)`
  font-size: 14px;
  color: ${({theme}) => theme.mainColor};
`;

const ItemText = styled(BodyTextBox)`
  border: 1px solid ${({theme}) => theme.borderColor};
  color: ${({theme}) => theme.textColor};
  padding: 8px;
  margin-top: 8px;
  font-size: 14px;
`;

const AnswerHeadText = styled(TitleTextBox)`
  margin-top: 32px;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
`;

const ButtonBox = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: row;
`;

const BackButton = styled(FlatButton)`
  flex: 1;
`;

const Specer = styled.div`
  width: 16px;
`;

const AnswerButton = styled(FlatButton)`
  flex: 1;
`;

interface Props {
    exam: ExamType;
    page: number;
    item: ExamItemType;
    initialAnswer?: ExamAnswerType;
    onGoBack: () => void;
    onAnswer: (answer: ExamAnswerType) => void;
}

const ExamItemView: React.FC<Props> = ({exam, page, item, initialAnswer, onGoBack, onAnswer}) => {

    const [selectAnswer, setSelectAnswer] = useState<ExamAnswerType>();
    const theme = useContext(ThemeContext);

    useEffect(() => {
        if (initialAnswer) {
            setSelectAnswer(initialAnswer);
        } else {
            setSelectAnswer(undefined);
        }
    }, [initialAnswer, item]);

    return (
        <Section>
            <ItemHeaderText>問題{page + 1} / {exam.items.length}</ItemHeaderText>
            <ItemText>{nl2br(item.text)}</ItemText>
            <AnswerHeadText>以下から当てはまるものを１つ選んでください。</AnswerHeadText>
            {item.answers.map(answer => (
                <ExamAnswerView answer={answer}
                                key={`answers.${answer.id}`}
                                selected={selectAnswer?.id === answer.id}
                                onSelect={() => setSelectAnswer(answer)}/>
            ))}
            <ButtonBox>
                {page !== 0 ? (
                    <>
                        <BackButton text={'前の問題へ'}
                                    backgroundColor={theme.whiteColor}
                                    borderColor={theme.subColor}
                                    textColor={theme.subColor}
                                    leftAllow={true}
                                    onPress={onGoBack}/>
                        <Specer/>
                    </>
                ) : <></>}
                <AnswerButton text={exam.items.length > (page + 1) ? '次の問題へ' : '終了'}
                              backgroundColor={theme.subColor}
                              disabled={selectAnswer === undefined}
                              rightAllow={true}
                              onPress={() => onAnswer(selectAnswer!!)}/>
            </ButtonBox>
        </Section>
    )
};

export default ExamItemView;
