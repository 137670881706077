import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ChatGroupType from "../../interfaces/ChatGroupType";
import MoreLoading from "../MoreLoading";
import ChatGroupListItem from "./ChatGroupListItem";

type Props = {
    items: ChatGroupType[];
    onPress: (chatGroup: ChatGroupType) => void;
    hasMore: boolean;
    onEndReached: () => void;
}

const ChatGroupAllList: React.FC<Props> = ({items, onPress, hasMore, onEndReached}) => {

    return (
        <InfiniteScroll
            next={onEndReached}
            hasMore={hasMore}
            loader={<MoreLoading/>}
            endMessage={<></>}
            dataLength={items.length}>
            {items.map(item => (
                <ChatGroupListItem
                    chatGroup={item}
                    key={item.id}
                    onPress={() => onPress(item)}/>
            ))}
        </InfiniteScroll>
    );
};

export default ChatGroupAllList;