import React, {useCallback} from "react";
import {SliderItemType} from "./SliderItemType";
import styled from "styled-components";
import {useHistory} from "react-router-dom";

const ImageSliderItemBox = styled.div`
  cursor: pointer;
  margin: 0 5px;
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  max-height: 100%;
  display: none;

  @media screen and (min-width: 480px) {
    display: block;
  }
`;

const XsImage = styled.img`
  width: 100%;
  max-height: 100%;

  @media screen and (min-width: 480px) {
    display: none;
  }
`;

type Props = {
    item: SliderItemType;
};

const ImageSliderItem: React.FC<Props> = ({item}) => {
    const history = useHistory();

    const onClick = useCallback(() => {
        if (item.onClick) {
            item.onClick();
        } else if (item.url) {
            if (item.url.startsWith('http://') || item.url.startsWith('https://')) {
                window.open(item.url);
            } else {
                history.push(item.url);
            }
        }
    }, [history, item]);

    return (
        <ImageSliderItemBox
            onClick={onClick}>
            <Image src={item.image}/>
            <XsImage src={item.xsImage}/>
        </ImageSliderItemBox>
    )
};

export default ImageSliderItem;

