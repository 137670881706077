import React, {useCallback, useEffect} from 'react';
import PageHeader from "../../parts/Headers/PageHeader";
import ScreenView from "../../parts/ScreenView";
import ContactConfirmForm from "../../parts/ContactConfirm/ContactConfirmForm";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {resetRequestStatus, sendContact} from "../../stores/send_contact";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import ContactParams from "../../interfaces/ContactParams";
import * as toastr from "toastr";
import mailIcon from '../../assets/icons/mail.png';

const ContactConfirm: React.FC = () => {

    const {send} = useSelector(state => ({
        send: state.send_contact,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const {state: {contact} = {contact: undefined}} = useLocation<{ contact: ContactParams }>();

    useEffect(() => {
        if (send.requestStatus === 'success') {
            history.push('/contact/success');
            dispatch(resetRequestStatus());
        }
        if (send.requestStatus === 'failed') {
            if (Object.keys(send.errors).length > 0) {
                toastr.error('入力内容をご確認ください。');
            } else {
                toastr.error('エラーが発生しました。');
            }
            history.goBack();
            dispatch(resetRequestStatus());
        }
    }, [send.requestStatus, send.errors, history, dispatch]);

    const onSubmit = useCallback(() => {
        dispatch(sendContact(contact!!));
    }, [contact, dispatch]);

    const onCancel = useCallback(() => {
        history.push('/contact', {old: contact});
    }, [contact, history]);

    if (contact === undefined) {
        return <Redirect to={'/contact'}/>;
    }

    return (
        <>
            <PageHeader title={'お問い合わせ内容の確認'}
                        iconSource={mailIcon}/>
            <ScreenView scroll={true}>
                <ContactConfirmForm contact={contact}
                                    onSubmit={onSubmit}
                                    onCancel={onCancel}
                                    isProcessing={send.requestStatus === 'pending'}/>
            </ScreenView>
        </>
    );
};

export default ContactConfirm;
