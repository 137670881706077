import React, {HTMLProps} from 'react';
import styled from "styled-components";

const Section = styled.div`
  background-color: ${({theme}) => theme.lightColor};
  margin: 0;
  padding: 16px;
`;

const VideoSearchBox: React.FC<HTMLProps<HTMLDivElement>> = (props) => {
    return (
        <Section className={props.className}>
            {props.children}
        </Section>
    )
};

export default VideoSearchBox;
