import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {createChatGroup, resetRequestStatus, setErrors} from "../../stores/chat_group_create";
import ScreenView from "../../parts/ScreenView";
import SnsHeader from "../../parts/SnsTab/SnsHeader";
import ChatGroupCreateForm from "../../parts/ChatGroupCreate/ChatGroupCreateForm";
import styled, {ThemeContext} from "styled-components";
import FlatButton from "../../parts/FlatButton";
import ChatGroupCreateConfirm from "../../parts/ChatGroupCreate/ChatGroupCreateConfirm";
import SectionLabel from "../../parts/SectionLabel";
import {useHistory} from "react-router-dom";
import toastr from "toastr";
import {Helmet} from "react-helmet-async";

const SubmitButton = styled(FlatButton)`
  flex: 1;
  margin-top: 24px;
`;

const CancelButton = styled(FlatButton)`
  flex: 1;
  margin-top: 24px;
`;

const ButtonFlex = styled.div`
  flex-direction: row;
  
  @media screen and (min-width: 480px) {
    display: flex;
  }
`;

const ChatGroupCreate: React.FC = () => {

    const theme = useContext(ThemeContext);
    const {request, user} = useSelector(state => ({
        request: state.chat_group_create,
        user: state.user,
    }));
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [confirm, setConfirm] = useState<boolean>(false);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setErrors({}));
    }, [dispatch]);

    useEffect(() => {
        if (request.requestStatus === 'success') {
            dispatch(resetRequestStatus());
            toastr.success('グループの作成を申請しました。');
            history.goBack();
        }
        if (request.requestStatus === 'failed') {
            if (Object.keys(request.errors).length > 0) {
                toastr.error('入力内容をご確認ください。');
                setConfirm(!confirm)
            } else {
                toastr.error('エラーが発生しました。');
            }
            dispatch(resetRequestStatus());
        }
    }, [confirm, dispatch, history, request.errors, request.requestStatus]);

    const sendCreateRequest = useCallback(() => {
        dispatch(createChatGroup({name, description}));
        dispatch(setErrors({}));
    }, [dispatch, name, description]);

    if (confirm) {
        return (
            <>
                <Helmet>
                    <title>新規グループ作成確認</title>
                </Helmet>
                <SnsHeader user={user.user} canGoBack={true}/>
                <ScreenView scroll={true}>
                    <SectionLabel title={'新規グループ作成確認'}/>
                    <ChatGroupCreateConfirm name={name}
                                            description={description}/>
                    <ButtonFlex>
                        <SubmitButton text={'このグループの作成を申請する'}
                                      onPress={sendCreateRequest}
                                      disabled={request.requestStatus === 'pending'}
                                      rightAllow/>
                        <CancelButton text={'入力内容の修正'}
                                      leftAllow
                                      onPress={() => setConfirm(!confirm)}
                                      disabled={request.requestStatus === 'pending'}
                                      borderColor={theme.mainColor}
                                      backgroundColor={theme.whiteColor}
                                      textColor={theme.mainColor}/>
                    </ButtonFlex>
                </ScreenView>
            </>
        );
    } else {
        return (
            <>
                <Helmet>
                    <title>新規グループ作成</title>
                </Helmet>
                <SnsHeader user={user.user} canGoBack={true}/>
                <ScreenView scroll={true}>
                    <SectionLabel title={'新規グループ作成'}/>
                    <ChatGroupCreateForm name={name}
                                         description={description}
                                         errors={request.errors}
                                         setName={setName}
                                         setDescription={setDescription}/>
                    <ButtonFlex>
                        <SubmitButton text={'入力内容の確認画面へ'}
                                      onPress={() => setConfirm(!confirm)}
                                      disabled={name.length === 0 || description.length === 0}
                                      rightAllow/>
                        <CancelButton text={'作成をやめる'}
                                      onPress={() => history.goBack()}
                                      borderColor={theme.mainColor}
                                      backgroundColor={theme.whiteColor}
                                      textColor={theme.mainColor}/>
                    </ButtonFlex>
                </ScreenView>
            </>
        );
    }
};

export default ChatGroupCreate;
