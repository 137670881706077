import React from 'react';
import styled from "styled-components";
import {BodyTextBox} from "../AppStyles";

const Section = styled.div`
  margin-top: 32px;
`;

const Description = styled(BodyTextBox)`
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 27px;
  color: ${({theme}) => theme.textGrayColor};

  @media screen and (min-width: 480px) {
    font-size: 16px;
    line-height: 27px;
  }
`;

const NoBr = styled.span`
  display: inline-block;
`;

const AppTitle: React.FC = () => {
    return (
        <Section>
            <Description>
                <NoBr>医療系資格更新システム</NoBr><br/>
                <NoBr>ココリンク１つで様々な</NoBr>
                資格試験の更新が完結します
            </Description>
        </Section>
    )
};

export default AppTitle;
