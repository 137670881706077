import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../http/axios";
import {fetchOwnChatGroups, fetchRecentChatGroups} from "./chat_group";

export type ChatGroupLeaveState = {
    requestStatus: RequestStatusType;
}

const initialState: ChatGroupLeaveState = {
    requestStatus: 'init'
};

export const leaveChatGroup = createAsyncThunk<void, any>(
    `chatGroups/leave`,
    async (param, thunk) => {
        await axios.delete(`chat_groups/${param}/leave`);
        thunk.dispatch(fetchOwnChatGroups());
        thunk.dispatch(fetchRecentChatGroups({page: 1}));
    }
);

export const chatGroupLeaveSlice = createSlice({
    name: 'chat_group_leave',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
    },
    extraReducers: builder => {
        builder
            .addCase(leaveChatGroup.fulfilled, (state, action) => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(leaveChatGroup.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(leaveChatGroup.rejected, (state, action) => ({
                ...state,
                requestStatus: 'failed',
            }))
    }
})

export const {resetRequestStatus} = chatGroupLeaveSlice.actions