import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import ScreenView from "../../parts/ScreenView";
import styled, {ThemeContext} from "styled-components";
import EventInformation from "../../parts/EventPaymentConfirm/EventInformation";
import FlatButton from "../../parts/FlatButton";
import EventPaymentConfirmHeader from "../../parts/EventPaymentConfirm/EventPaymentConfirmHeader";
import {useSelector} from "../../store";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchEventDetail} from "../../stores/event_detail";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import NotFound from "../NotFound";
import {Helmet} from "react-helmet-async";

const ButtonBox = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 32px auto 0;
`;

const PurchaseButton = styled(FlatButton)`
  box-sizing: border-box;
`;

const CancelButton = styled(FlatButton)`
  margin-top: 16px;
  box-sizing: border-box;
`;

const EventPaymentConfirm: React.FC = () => {
    const theme = useContext(ThemeContext);
    const {auth, event_detail} = useSelector(state => ({
        auth: state.auth,
        event_detail: state.event_detail,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const {id: eventId} = useParams<{ id: string }>();

    useEffect(() => {
        dispatch(fetchEventDetail(eventId));
    }, [dispatch, eventId]);

    const onPressSelect = useCallback(() => {
        if (event_detail.item === null) {
            return;
        }
        if (auth.authenticated) {
            history.push(`/events/${event_detail.item.id}/payment`);
        } else {
            history.push(`/events/${event_detail.item.id}/payment/param`);
        }
    }, [auth.authenticated, event_detail.item, history]);

    const price = useMemo(() => {
        if (event_detail.item === null) {
            return null;
        }
        if (auth.authenticated) {
            return event_detail.item.price;
        } else {
            return event_detail.item.non_member_price;
        }
    }, [event_detail.item, auth.authenticated]);

    if (event_detail.isFetching) {
        return <WithLoadingScreen loading={true}/>
    }

    if (event_detail.item === null) {
        return <NotFound/>
    }

    return (
        <>
            <Helmet>
                <title>{event_detail.item.name}参加申込</title>
            </Helmet>
            <EventPaymentConfirmHeader onBack={() => history.goBack()}/>
            <ScreenView scroll={true}>
                <EventInformation event={event_detail.item}/>
                <ButtonBox>
                    <PurchaseButton text={price === 0 ? 'お申し込みへ' : 'お支払いへ'}
                                    backgroundColor={theme.mainColor}
                                    rightAllow
                                    onPress={onPressSelect}/>
                    <CancelButton text={'申し込みをやめる'}
                                  backgroundColor={theme.whiteColor}
                                  textColor={theme.mainColor}
                                  borderColor={theme.mainColor}
                                  leftAllow
                                  onPress={() => history.goBack()}/>
                </ButtonBox>
            </ScreenView>
        </>
    );
};

export default EventPaymentConfirm;
