import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import FlatButton from "../FlatButton";

const Section = styled.section`
  border-top-color: ${({theme}) => theme.borderColor};
  border-top-width: 1px;
  border-top-style: solid;
  padding: 48px 16px 0;
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  justify-content: center;
`;

const Buttons = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 500px;
`;

interface Props {
    onBackPress: () => void;
}

const BackButtonSection: React.FC<Props> = ({onBackPress}) => {
    const theme = useContext(ThemeContext);

    return (
        <Section>
            <Buttons>
                <FlatButton backgroundColor={theme.textGrayColor}
                            textColor={'#FFF'}
                            leftAllow={true}
                            borderRadius={4}
                            text={'前のページにもどる'}
                            onPress={onBackPress}/>
            </Buttons>
        </Section>
    )
};

export default BackButtonSection;
