import React, {useCallback, useContext} from 'react';
import UserType from "../../interfaces/UserType";
import styled, {ThemeContext} from "styled-components";
import {TitleTextBox} from "../AppStyles";
import Image from "../Image";
import Icon from "../Icon";
import TouchableOpacity from "../TouchableOpacity";
import {useHistory} from "react-router-dom";
import snsIcon from '../../assets/icons/sns.png';

const Box = styled.div`
  padding: 8px 16px;
  background-color: ${({theme}) => theme.mainColor};
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderIcon = styled(Image)`
  width: 24px;
  height: 24px;
`;

const Title = styled(TitleTextBox)`
  margin-right: auto;
  margin-left: 8px;
  font-size: 20px;
  font-weight: bold;
  color: ${({theme}) => theme.whiteColor};
`;

const UserBox = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
  margin-right: 8px;
`;

const UserImage = styled(Image)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const UserTextBox = styled.div`
  margin-left: 8px;
`;

const UserName = styled(TitleTextBox)`
  font-size: 16px;
`;

const UserQualification = styled(TitleTextBox)`
  font-size: 12px;
`;

const BackButton = styled(TouchableOpacity)`
  height: 32px;
  width: 32px;
`;

interface Props {
    user: UserType | null;
    canGoBack?: boolean;
}

const SnsHeader: React.FC<Props> = ({user, canGoBack = false}) => {
    const theme = useContext(ThemeContext);
    const history = useHistory();

    const onPressUser = useCallback(() => {
        history.push('/sns/users');
    }, [history]);

    if (user === null) {
        return <></>
    }

    return (
        <Box>
            <Content>
                {canGoBack ? (
                    <BackButton onClick={() => history.goBack()}>
                        <Icon name={'angle-left'} size={32} color={theme.whiteColor}/>
                    </BackButton>
                ) : <></>}
                <HeaderIcon src={snsIcon} color={theme.whiteColor}/>
                <Title>SNS交流</Title>
                <UserBox disabled={onPressUser === undefined} onClick={onPressUser}>
                    <UserImage src={user.profile_url || 'https://placehold.jp/100x100.png'}/>
                    <UserTextBox>
                        <UserName>{user.nickname}</UserName>
                        <UserQualification>{user.industry}</UserQualification>
                    </UserTextBox>
                </UserBox>
            </Content>
        </Box>
    );
};

export default SnsHeader;
