import React from 'react';
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const TabButton = styled(TouchableOpacity)<{ active: boolean }>`
  background-color: ${({active, theme}) => active ? theme.subColor : theme.whiteColor};
  padding: 16px 4px;
  border: 1px solid ${({theme}) => theme.subColor};
  border-radius: 100px;
  flex: 1;
`;

const ButtonText = styled(TitleTextBox)<{ active: boolean }>`
  color: ${({theme, active}) => active ? theme.whiteColor : theme.subColor};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export type SnsTabState = 'Own' | 'All';

interface Props {
    tab: SnsTabState;
    onTabChange: (tab: SnsTabState) => void;
}

const SnsTabView: React.FC<Props> = ({tab, onTabChange}) => {

    return (
        <Section>
            <TabButton active={tab === 'Own'} onClick={() => onTabChange('Own')} style={{marginRight: 8}}>
                <ButtonText active={tab === 'Own'}>所属グループ一覧</ButtonText>
            </TabButton>
            <TabButton active={tab === 'All'} onClick={() => onTabChange('All')} style={{marginLeft: 4}}>
                <ButtonText active={tab === 'All'}>全体グループ一覧</ButtonText>
            </TabButton>
        </Section>
    )
};

export default SnsTabView;
