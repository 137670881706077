import React from "react";
import ChatGroupType from "../../interfaces/ChatGroupType";
import icon from '../../assets/icons/chat_group.png';
import TouchableOpacity from "../TouchableOpacity";
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";

const Section = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

const GroupTitle = styled(TitleTextBox)`
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
`;

interface Props {
    chatGroup: ChatGroupType;
    onClick: (group: ChatGroupType) => void;
}

const ChatMessageGroupHeader: React.FC<Props> = ({chatGroup, onClick}) => {

    return (
        <Section onClick={() => onClick(chatGroup)}>
            <Icon src={icon} alt={chatGroup.name}/>
            <GroupTitle>{chatGroup.name}</GroupTitle>
        </Section>
    )
};

export default ChatMessageGroupHeader;
