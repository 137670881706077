import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {TitleText} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Box = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

const BoxClick = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

const Arrow = styled.i`
  color: ${({theme}) => theme.mainColor};
`;

const Text = styled(TitleText)`
  font-weight: bold;
  font-size: 14px;
  color: ${({theme}) => theme.textColor};
  margin: 8px;
`;

type Props = {
    text: string;
    to?: string;
    onClick?: () => void;
};

const LinkText: React.FC<Props> = ({text, to, onClick}) => {
    if (onClick !== undefined) {
        return (
            <BoxClick onClick={onClick}>
                <Arrow className={'fa fa-angle-right'}/>
                <Text>{text}</Text>
            </BoxClick>
        );
    } else if (to !== undefined) {
        return (
            <Box to={to}>
                <Arrow className={'fa fa-angle-right'}/>
                <Text>{text}</Text>
            </Box>
        );
    } else {
        return (
            <BoxClick>
                <Arrow className={'fa fa-angle-right'}/>
                <Text>{text}</Text>
            </BoxClick>
        )
    }
};

export default LinkText;