import React from 'react';
import styled from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";

const Section = styled.div`
  margin-top: 16px;
`;

const Label = styled(TitleTextBox)`
  color: ${({theme}) => theme.mainColor};
  font-size: 16px;
  font-weight: bold;
`;

const Value = styled(BodyTextBox)`
  color: ${({theme}) => theme.textColor};
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 24px;
`;

interface Props {
    name: string;
    description: string;
}

const ChatGroupCreateConfirm: React.FC<Props> = ({name, description}) => {
    return (
        <Section>
            <Label>グループ名</Label>
            <Value>{name}</Value>
            <Label>このグループに関する概要、情報</Label>
            <Value>{description}</Value>
        </Section>
    );
};

export default ChatGroupCreateConfirm;
