import React from 'react';
import styled from "styled-components";

const Screen = styled.div`
  flex: 1;
  min-height: 200px;
  display: flex;
  background-color: #FFFFFF;
  justify-content: center;
  align-items: center;
  --sk-size: 60px;
  --sk-color: ${({theme}) => theme.mainColor};
`;

interface Props {
    loading: boolean;
}

const WithLoadingScreen: React.FC<Props> = ({children, loading}) => {
    if (loading) {
        return (
            <Screen>
                <div className="sk-fold">
                    <div className="sk-fold-cube"/>
                    <div className="sk-fold-cube"/>
                    <div className="sk-fold-cube"/>
                    <div className="sk-fold-cube"/>
                </div>
            </Screen>
        )
    }

    return <>{children}</>;
};

export default WithLoadingScreen;
