import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ChatMessageType from "../interfaces/ChatMessageType";
import axios from "../http/axios";
import toastr from "toastr";
import {fetchChatTimeline} from "./chat_timeline";
import {fetchChatMessages} from "./chat_message";

export type ChatMessagePinState = {
    requestStatus: RequestStatusType;
}

const initialState: ChatMessagePinState = {
    requestStatus: "init"
};

export const pinChatMessage = createAsyncThunk<void, ChatMessageType>(
    'chat_messages/pin',
    async (arg, thunk) => {
        await axios.post(`chat_messages/${arg.id}/pin`);
        if (arg.pin) {
            toastr.success('メッセージの固定を解除しました。');
        } else {
            toastr.success('メッセージを固定しました。');
        }
        thunk.dispatch(fetchChatMessages({
            groupId: arg.group.id,
            page: 1
        }));
        thunk.dispatch(fetchChatTimeline({
            page: 1
        }));
    }
);

export const chatMessagePinSlice = createSlice({
    name: 'chat_message_pin',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(pinChatMessage.fulfilled, () => ({
                requestStatus: "success"
            }))
            .addCase(pinChatMessage.rejected, () => ({
                requestStatus: "failed"
            }))
            .addCase(pinChatMessage.pending, () => ({
                requestStatus: "pending"
            }))
    }
});