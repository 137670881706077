import React, {useEffect, useState} from 'react';
// @ts-ignore
import barcodes from 'jsbarcode/src/barcodes';
import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

type props = {
    value: string;
    format: string;
    width?: number;
    height?: number;
    text?: string;
    textColor?: string;
    lineColor?: string;
    background?: string;
    onError?: (error: Error) => any;
};

const Barcode = ({
                     value,
                     format = 'CODE128',
                     width = 2,
                     height = 100,
                     text,
                     textColor = '#000000',
                     lineColor = '#000000',
                     background = '#ffffff',
                     onError,
                 }: props) => {
    const [bars, setBars] = useState<string[]>([]);
    const [barCodeWidth, setBarCodeWidth] = useState(0);

    const props = {
        value,
        format,
        width,
        height,
        text,
        textColor,
        lineColor,
        background,
        onError,
    };

    useEffect(() => {
        update();
    }, [value]);

    const update = () => {
        const encoder = barcodes[format];
        let encoded = encode(value, encoder, props);

        if (encoded) {
            if (Array.isArray(encoded)) {
                encoded = encoded.reduce((p, n) => ({
                    data: `${p.data}${n.data || ''}`,
                    text: `${p.text}${n.text || ''}`
                }), {data: '', text: ''});
            }
            setBars(drawSvgBarCode(encoded));
            setBarCodeWidth(encoded.data.length * width);
        }
    };

    const drawSvgBarCode = (encoding: any) => {
        const rects: string[] = [];
        // binary data of barcode
        const binary = encoding.data;

        let barWidth = 0;
        let x = 0;
        let yFrom = 0;

        for (let b = 0; b < binary.length; b++) {
            x = b * width;
            if (binary[b] === '1') {
                barWidth++;
            } else if (barWidth > 0) {
                rects[rects.length] = drawRect(
                    x - width * barWidth,
                    yFrom,
                    width * barWidth,
                    height,
                );
                barWidth = 0;
            }
        }

        // Last draw is needed since the barcode ends with 1
        if (barWidth > 0) {
            rects[rects.length] = drawRect(
                x - width * (barWidth - 1),
                yFrom,
                width * barWidth,
                height,
            );
        }

        return rects;
    };

    const drawRect = (x: number, y: number, width: number, height: number) => {
        return `M${x},${y}h${width}v${height}h-${width}z`;
    };

    // encode() handles the Encoder call and builds the binary string to be rendered
    const encode = (text: string, Encoder: any, options: props) => {
        // If text is not a non-empty string, throw error.
        if (text.length === 0) {
            if (options.onError) {
                options.onError(new Error('Barcode value must be a non-empty string'));
                return;
            }
            throw new Error('Barcode value must be a non-empty string');
        }

        let encoder;

        try {
            encoder = new Encoder(text, options);
        } catch (error) {
            // If the encoder could not be instantiated, throw error.
            if (options.onError) {
                options.onError(new Error('Invalid barcode format.'));
                return;
            }
            throw new Error('Invalid barcode format.');
        }

        // If the input is not valid for the encoder, throw error.
        if (!encoder.valid()) {
            if (options.onError) {
                options.onError(new Error('Invalid barcode for selected format.'));
                return;
            }
            throw new Error('Invalid barcode for selected format.');
        }

        // Make a request for the binary data (and other infromation) that should be rendered
        // encoded stucture is {
        //  text: 'xxxxx',
        //  data: '110100100001....'
        // }
        return encoder.encode();
    };

    const backgroundStyle = {
        backgroundColor: background,
    };
    return (
        <Wrap style={backgroundStyle}>
            <svg height={height} width={barCodeWidth} fill={lineColor}>
                <path d={bars.join(' ')}/>
            </svg>
            {typeof text !== 'undefined' && (
                <div
                    style={{
                        color: textColor,
                        width: barCodeWidth,
                        textAlign: 'center',
                    }}
                >
                    {text}
                </div>
            )}
        </Wrap>
    );
};

export default Barcode;
