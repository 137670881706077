import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import FlatButton from "../FlatButton";
import renewBankEnabled from "../../utils/renewBankEnable";

const LoginButton = styled(FlatButton)`

`;

const GuestButton = styled(FlatButton)`
  margin-top: 16px;
`;

const Section = styled.div`
  margin-top: 30px;
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

interface Props {
    onSelectServicePress: () => void;
    onGuestPress: () => void;
}

const WelcomeLinks: React.FC<Props> = ({onSelectServicePress}) => {
    const themeContext = useContext(ThemeContext);

    return (
        <Section>
            <LoginButton text={'対象の資格をお持ちの方はこちら'}
                         textColor={'#FFFFFF'}
                         backgroundColor={themeContext.mainColor}
                         rightAllow={true}
                         onPress={onSelectServicePress}/>
        </Section>
    )
};

export default WelcomeLinks;
