import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../http/axios";
import {RootState} from "../store";

export type EventCancelState = {
    requestStatus: RequestStatusType;
}

const initialState: EventCancelState = {
    requestStatus: "init"
};

export const cancelEvent = createAsyncThunk<void, string | number>(
    'event_cancel/exec',
    async (arg, thunk) => {
        const {serial_number} = thunk.getState() as RootState;
        await axios.delete(`events/${arg}/cancel`, {
            headers: {
                'X-Device-ID': serial_number.item.device_code
            }
        });
    }
)

export const eventCancelSlice = createSlice({
    name: 'event_cancel',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(cancelEvent.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(cancelEvent.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(cancelEvent.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus} = eventCancelSlice.actions;