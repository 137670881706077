import React, {useEffect, useMemo} from 'react';
import styled from "styled-components";
import {useSelector} from "../../store";
import {useHistory} from "react-router-dom";
import headerLogo from '../../assets/header.png';
import bellIcon from '../../assets/icons/bell.png';
import guideIcon from '../../assets/icons/guide.png';
import mailIcon from '../../assets/icons/mail.png';
import menuIcon from '../../assets/icons/menu.png';
import closeIcon from '../../assets/icons/close.png';
import {useDispatch} from "react-redux";
import {setIsDrawerOpen} from '../../stores/drawer';
import TouchableOpacity from "../TouchableOpacity";
import {fetchUnreadNotice} from "../../stores/unread_notice";

const SafeArea = styled.div`
  background-color: white;
  width: 100%;
  max-width: 900px;
  margin: auto;
  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media screen and (min-width: 480px) {
    position: unset;
  }
`;

const Header = styled.div`
  padding: 8px 8px 8px 16px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (min-width: 480px) {
    padding: 8px;
  }
`;

const AppIcon = styled.img`
  width: 94px;
  max-width: 100%;

  @media screen and (min-width: 480px) {
    width: 140px;
  }
`;

const TitleSection = styled(TouchableOpacity)`
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const NotifyButton = styled(TouchableOpacity)`
  margin-left: 8px;
  cursor: pointer;
  position: relative;
`;

const MenuButton = styled(TouchableOpacity)`
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;

  @media screen and (min-width: 480px) {
    flex-direction: row;
    padding: 12px 8px;
  }
`;

const DrawerButton = styled(MenuButton)`
  @media screen and (min-width: 480px) {
    display: none;
  }
`;

const MenuButtonText = styled.div`
  margin-top: 8px;
  font-size: 8px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: ${({theme}) => theme.textGrayColor};

  @media screen and (min-width: 480px) {
    margin-top: 0;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const BellIconImage = styled.img`
  height: 24px;
  width: 24px;
`;

const ContactIconImage = styled.img`
  height: 16px;
  width: 16px;
`;

const IconImage = styled.img`
  height: 16px;
  width: 16px;
`;

const BellIconBox = styled.div`
  background-color: ${({theme}) => theme.mainColor};
  padding: 8px;
  border-radius: 100px;
`;

const UnReadBadge = styled.div`
  border-radius: 16px;
  background-color: red;
  position: absolute;
  right: -5px;
  top: -5px;
  line-height: 11px;
  min-width: 11px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  color: white;
`;

const AuthHeader: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {drawer, unread_notice} = useSelector(state => ({
        drawer: state.drawer,
        unread_notice: state.unread_notice
    }));

    const openNotify = () => {
        if (history.location.pathname === '/notices') {
            history.goBack();
        } else {
            history.push('/notices')
        }
    };

    const openGuide = () => {
        history.push('/guide');
    };

    const openContact = () => {
        if (history.location.pathname === '/contact') {
            history.goBack();
        } else {
            window.open('https://app.chatplus.jp/chat/visitor/1eae23a2_6?t=btn');
        }
    };

    const openMenu = () => {
        dispatch(setIsDrawerOpen(!drawer.isOpen));
    };

    const menuIcons = useMemo(() => [
        menuIcon,
        closeIcon,
    ], []);

    const unreadNoticeCount = useMemo(() => {
        return unread_notice.count;
    }, [unread_notice.count]);

    useEffect(() => {
        dispatch(fetchUnreadNotice());
    }, [dispatch]);

    return (
        <SafeArea>
            <Header>
                <TitleSection onClick={() => history.push('/home')}>
                    <AppIcon src={headerLogo}/>
                </TitleSection>
                <NotifyButton onClick={openNotify}>
                    <BellIconBox>
                        <BellIconImage src={bellIcon}/>
                        {unreadNoticeCount > 0 ? (
                            <UnReadBadge>{unreadNoticeCount}</UnReadBadge>
                        ) : <></>}
                    </BellIconBox>
                </NotifyButton>
                <MenuButton onClick={openGuide}>
                    <IconImage src={guideIcon}/>
                    <MenuButtonText>使い方ガイド</MenuButtonText>
                </MenuButton>
                <MenuButton onClick={openContact}>
                    <ContactIconImage src={mailIcon}/>
                    <MenuButtonText>お問い合わせ</MenuButtonText>
                </MenuButton>
                <DrawerButton onClick={openMenu}>
                    <IconImage src={menuIcons[drawer.isOpen ? 1 : 0]}/>
                    <MenuButtonText>メニュー</MenuButtonText>
                </DrawerButton>
            </Header>
        </SafeArea>
    )
};

export default AuthHeader;
