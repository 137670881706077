import React from "react";
import styled from "styled-components";
import {Helmet} from "react-helmet-async";
import PageHeader from "../../parts/Headers/PageHeader";
import guideTopBanner from "../../assets/guide_top_banner.png";
import Image from "../../parts/Image";
import ScreenView from "../../parts/ScreenView";
import {Link} from "react-router-dom";

const Screen = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;


const GuideBanner = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
`;

const GuideImage = styled(Image)`
`;

const GuideLinks = styled.ul`
  margin: 0;
  padding: 0 0 0 16px;
`;

const GuideLinkBox = styled.li`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const GuideLink = styled(Link)`

`;

const Help: React.FC = () => {

    return (
        <>
            <Helmet>
                <title>ココリンク使い方ガイド</title>
            </Helmet>
            <Screen>
                <PageHeader title={'ココリンク使い方ガイド'}/>
                <GuideBanner>
                    <GuideImage src={guideTopBanner}/>
                </GuideBanner>
                <ScreenView>
                    <GuideLinks>
                        <GuideLinkBox>
                            <GuideLink to={"/guide/show/guide_3.pdf"}>
                                ココリンク機能一覧・会員情報ページについて
                            </GuideLink>
                        </GuideLinkBox>
                        <GuideLinkBox>
                            <GuideLink to={"/guide/show/guide_4.pdf"}>
                                動画視聴について
                            </GuideLink>
                        </GuideLinkBox>
                        <GuideLinkBox>
                            <GuideLink to={"/guide/show/guide_6.pdf"}>
                                SNS機能について
                            </GuideLink>
                        </GuideLinkBox>
                    </GuideLinks>
                </ScreenView>
            </Screen>
        </>
    )
};

export default Help;
