import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import ArrowIcon from "../ArrowIcon";
import {BodyText, TitleText} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Box = styled(TouchableOpacity)`
  margin: 24px 24px 0;
  border: 2px solid ${({theme}) => theme.whiteColor};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  position: relative;
`;

const ButtonTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled(TitleText)`
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.whiteColor};
`;

const Description = styled(BodyText)`
  margin-top: 4px;
  font-size: 10px;
  font-weight: bold;
  color: ${({theme}) => theme.whiteColor};
`;

const ArrowIconRight = styled(ArrowIcon)`
  margin-left: 16px;
  position: absolute;
  right: 16px;
`;

interface Props {
    onPress: () => void;
}

const ContactButton: React.FC<Props> = ({onPress}) => {
    const theme = useContext(ThemeContext);
    return (
        <Box onClick={onPress}>
            <ButtonTextBox>
                <Title>お問い合わせ</Title>
                <Description>ご質問やご意見はこちらから</Description>
            </ButtonTextBox>
            <ArrowIconRight size={16} color={theme.whiteColor}/>
        </Box>
    )
};

export default ContactButton;
