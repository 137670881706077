import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

export const TitleText = styled.span`
  letter-spacing: ${({theme}) => theme.titleLetterSpace}px;
  margin: 0;
`;

export const BodyText = styled.span`
  letter-spacing: ${({theme}) => theme.bodyLetterSpace}px;
  margin: 0;
`;

export const TitleTextBox = styled.div`
  letter-spacing: ${({theme}) => theme.titleLetterSpace}px;
  margin: 0;
`;

export const BodyTextBox = styled.div`
  letter-spacing: ${({theme}) => theme.bodyLetterSpace}px;
  margin: 0;
`;

export const ContentWrap = styled.div`
  width: 100%;
  max-width: 900px;
  margin: auto;
`;

export const InfiniteYScroll = styled(InfiniteScroll)`
  overflow: inherit !important;
  overflow-x: visible;
  overflow-y: visible;
`;