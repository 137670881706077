import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import videojs, { VideoJsPlayer } from 'video.js';
import VideoType from "../../interfaces/VideoType";
import styled from "styled-components";
import { BodyText } from "../AppStyles";
import { useDispatch } from "react-redux";
import { sendVideoWatchLog } from "../../stores/video_watch_log";
import { useSelector } from "../../store";

const VideoScreen = styled.div`
  position: relative;
`;

const OnVideoView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

const SoundOnlyText = styled(BodyText)`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #FF0000;
  line-height: 32px;
`;

interface Props {
    video: VideoType;
    position?: number;
    audioOnly: boolean;
    onFinished: () => void;
}

const VideoPlayer: React.FC<Props> = ({ video, position, audioOnly, onFinished }) => {
    const { auth } = useSelector(state => ({
        auth: state.auth
    }));
    const videoRef = useRef<VideoJsPlayer | null>(null);
    const nodeRef = useRef<HTMLVideoElement>(null);
    const [status, setStatus] = useState<{ position: number, duration: number; }>();
    const [key, setKey] = useState<number>(Math.random());
    const dispatch = useDispatch();

    const src = useMemo(() => {
        return audioOnly ? video.audio_url : video.hls_url;
    }, [audioOnly, video]);

    const onReady = useCallback(async () => {
        if (position !== undefined) {
            videoRef.current?.currentTime(position / 1000);
        }
        await videoRef.current?.play();
    }, [position]);

    const onTimeUpdate = useCallback(() => {
        if (videoRef.current === null) {
            return;
        }
        const position = Math.floor(videoRef.current.currentTime() * 1000);
        const duration = Math.floor(videoRef.current.duration() * 1000);
        if ((status?.position || 0) + 5000 > position) {
            return;
        }
        setStatus({
            position,
            duration,
        });
        if (auth.authenticated) {
            dispatch(sendVideoWatchLog({
                videoId: video.id,
                position,
                duration,
            }));
        }
    }, [auth.authenticated, dispatch, status?.position, video.id]);

    useEffect(() => {
        return () => {
            videoRef.current?.dispose();
            videoRef.current = null;
        };
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.src(src);
            if (status) {
                videoRef.current?.currentTime(status.position / 1000);
            }
            videoRef.current?.play();
        } else {
            videoRef.current = videojs(nodeRef.current, {
                autoplay: false,
                controls: true,
                sources: [{
                    src
                }]
            }, onReady);
        }
    }, [nodeRef, onReady, src]);

    useEffect(() => {
        videoRef.current?.on('timeupdate', onTimeUpdate);
        videoRef.current?.on('ended', onFinished);
        return () => {
            videoRef.current?.off('timeupdate');
            videoRef.current?.off('ended');
        }
    }, [onFinished, onTimeUpdate]);

    return (
        <VideoScreen key={key}>
            <div data-vjs-player>
                <video ref={nodeRef}
                    id={`video-${key}`}
                    playsInline={true}
                    autoPlay={true}
                    className={'video-js vjs-16-9 vjs-big-play-centered'} />
            </div>
            {audioOnly ? (
                <OnVideoView>
                    <SoundOnlyText>SOUND{'\n'}ONLY</SoundOnlyText>
                </OnVideoView>
            ) : <></>}
        </VideoScreen>
    );
};

export default VideoPlayer;
