import React, {ChangeEvent, useCallback, useMemo} from "react";
import styled from "styled-components";
import moment from "moment";
import range from "../utils/range";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
`;

const InputBox = styled.div`
  position: relative;
`;

const Input = styled.select`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  padding: 0.5em 2em 0.5em 1em;
  box-sizing: border-box;
  font-size: 1em;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 8px;
  background: #fff;
`;

const Arrow = styled.div`
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 14px;
  border-bottom: ${({theme}) => theme.mainColor} 2px solid;
  border-right: ${({theme}) => theme.mainColor} 2px solid;
  transform: rotate(45deg) translateY(-30%);
  pointer-events: none;
`;

type Props = {
    value: string;
    onChange: (newValue: string) => void;
    className?: string;
}

const DateFlexPicker: React.FC<Props> = ({value, onChange, className}) => {

    const years = useMemo(() => {
        const end = moment().add(-18, 'year').year();
        const start = moment().add(-100, 'year').year();
        return range(start, end);
    }, []);

    const months = useMemo(() => {
        return range(1, 12);
    }, []);

    const days = useMemo(() => {
        return range(1, 31);
    }, []);

    const selectDate = useMemo((): moment.Moment | null => {
        if (value) {
            return moment(value, 'YYYY-MM-DD');
        } else {
            return null;
        }
    }, [value]);

    const yearChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        const baseDate = selectDate ? selectDate : moment();
        const newValue = baseDate.year(parseInt(e.target.value)).format('YYYY-MM-DD');
        if (newValue) {
            onChange(newValue);
        }
    }, [onChange, selectDate]);

    const monthChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        const baseDate = selectDate ? selectDate : moment();
        const newValue = baseDate.month(parseInt(e.target.value) - 1).format('YYYY-MM-DD');
        if (newValue) {
            onChange(newValue);
        }
    }, [onChange, selectDate]);

    const dayChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        const baseDate = selectDate ? selectDate : moment();
        const newValue = baseDate.date(parseInt(e.target.value)).format('YYYY-MM-DD');
        if (newValue) {
            onChange(newValue);
        }
    }, [onChange, selectDate]);

    return (
        <Flex className={className}>
            <InputBox>
                <Input value={selectDate?.year()} onChange={yearChange}>
                    {years.map(year => (
                        <option value={year}>{year}</option>
                    ))}
                </Input>
                <Arrow/>
            </InputBox>
            <Label>年</Label>
            <InputBox>
                <Input value={selectDate?.format('M')} onChange={monthChange}>
                    {months.map(month => (
                        <option value={month}>{month}</option>
                    ))}
                </Input>
                <Arrow/>
            </InputBox>
            <Label>月</Label>
            <InputBox>
                <Input value={selectDate?.format('D')} onChange={dayChange}>
                    {days.map(day => (
                        <option value={day}>{day}</option>
                    ))}
                </Input>
                <Arrow/>
            </InputBox>
            <Label>日</Label>
        </Flex>
    )
};

export default DateFlexPicker;