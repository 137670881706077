import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import pcButton from '../../assets/buttons/pc_btn_event.png';
import spButton from '../../assets/buttons/sp_btn_event.png';

const Background = styled.div`
  margin-top: -16px;
`;

const Section = styled.div`
  padding: 16px 8px;
  width: 100%;
  max-width: 900px;
  margin: 24px auto 0;
  box-sizing: border-box;
  text-align: center;

  @media screen and (min-width: 480px) {
    padding: 16px 0;
  }
`;

const NoticeLink = styled(Link)`
  font-weight: bold;
  border-radius: 1000px;
`;

const Image = styled.img`
  max-width: 100%;
  display: none;

  @media screen and (min-width: 480px) {
    display: inline;
  }
`;

const XsImage = styled.img`
  max-width: 100%;

  @media screen and (min-width: 480px) {
    display: none;
  }
`;

const EventNoticeLink: React.FC = () => {
    return (
        <Background>
            <Section>
                <NoticeLink to={'/event_notices'}>
                    <Image src={pcButton}/>
                    <XsImage src={spButton}/>
                </NoticeLink>
            </Section>
        </Background>
    );
};

export default EventNoticeLink;