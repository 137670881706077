import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "./store";
import {Location} from "history";
import {ConfigContext} from "./config";
import styled from "styled-components";
import query from "query-string";

const GtagImage = styled.img`
  width: 0;
  height: 0;
  opacity: 0;
`;

const Analytics: React.FC = () => {

    const history = useHistory();
    const config = useContext(ConfigContext);
    const [firstRun, setFirstRun] = useState(true);
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const {user, auth, serial_number} = useSelector(state => ({
        user: state.user,
        auth: state.auth,
        serial_number: state.serial_number,
    }));

    const onLocationChange = useCallback((location: Location) => {
        if (!config.analyticsId) {
            return;
        }
        setTimeout(() => {
            setImageUrl(query.stringifyUrl({
                url: 'https://www.google-analytics.com/collect',
                query: {
                    v: 1,
                    tid: config.analyticsId,
                    cid: serial_number.item.device_code,
                    t: 'pageview',
                    dh: 'cocolink.jp',
                    dp: location.pathname,
                    dt: document.title,
                    uid: auth.authenticated ? user.user?.name : undefined,
                }
            }));
        }, 1000);
    }, [auth, config.analyticsId, user, serial_number]);

    useEffect(() => {
        if (history === undefined) {
            return;
        }
        if (firstRun) {
            if (auth.authenticated) {
                if (user.user) {
                    onLocationChange(history.location);
                    setFirstRun(false);
                }
            } else {
                onLocationChange(history.location);
                setFirstRun(false);
            }
        }
        const unregister = history.listen(location => {
            onLocationChange(location);
        });
        return () => {
            unregister();
        };
    }, [history, firstRun, onLocationChange, auth, user.user]);

    if (imageUrl) {
        return <GtagImage src={imageUrl}/>;
    } else {
        return <></>
    }
};

export default Analytics;