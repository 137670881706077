import React, {useContext} from 'react';
import ChatGroupType from "../../interfaces/ChatGroupType";
import styled, {ThemeContext} from "styled-components";
import FlatButton from "../FlatButton";
import {TitleTextBox} from "../AppStyles";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Border = styled.div`
  margin-top: 16px;
  height: 1px;
  margin-left: -16px;
  margin-right: -16px;
  background-color: ${({theme}) => theme.borderColor};
`;

const Title = styled(TitleTextBox)`
  font-size: 24px;
  color: ${({theme}) => theme.mainColor};
  text-align: center;
  font-weight: bold;
  margin-top: 8px;
`;

const MemberCount = styled(TitleTextBox)`
  font-size: 14px;
  color: ${({theme}) => theme.textGrayColor};
  text-align: center;
  margin-top: 4px;
`;

const ApplyButton = styled(FlatButton)`
  margin: 24px 16px 0;
  width: 100%;
  max-width: 768px;
`;

interface Props {
    chatGroup: ChatGroupType;
    onPressApply: () => void;
    isFetching: boolean;
}

const ApplyChatGroupForm: React.FC<Props> = ({chatGroup, onPressApply, isFetching}) => {
    const theme = useContext(ThemeContext);
    return (
        <Section>
            <Title>{chatGroup.name}</Title>
            <MemberCount>(メンバー{chatGroup.users_count}人)</MemberCount>
            <ApplyButton text={'このグループに参加申請をする'}
                         rightAllow
                         disabled={isFetching}
                         onPress={onPressApply}
                         textColor={theme.whiteColor}
                         backgroundColor={theme.subColor}/>
            <Border/>
        </Section>
    )
};

export default ApplyChatGroupForm;
