import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import PageHeader from "../../parts/Headers/PageHeader";
import {useDispatch} from "react-redux";
import {useSelector} from "../../store";
import ExamType from "../../interfaces/ExamType";
import {fetchExam} from "../../stores/exam";
import VideoPlayer from "../../parts/VideoPage/VideoPlayer";
import {resetRequestStatus, sendFinishVideo} from "../../stores/finish_video";
import AudioOnlyButton from "../../parts/VideoPage/AudioOnlyButton";
import ExamButton from "../../parts/VideoPage/ExamButton";
import VideoInformation from "../../parts/VideoPage/VideoInformation";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {fetchVideoDetail} from "../../stores/video_detail";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import query from 'query-string';
import {Helmet} from "react-helmet-async";
import {ContentWrap} from "../../parts/AppStyles";
import VideoType from "../../interfaces/VideoType";
import NextVideoBox from "../../parts/VideoPage/NextVideoBox";

const Screen = styled.div`
  width: 100%;
  flex: 1;
  background-color: ${({theme}) => theme.whiteColor};
`;

const Scroll = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  box-sizing: border-box;
`;

const debounceCacheMap: Record<string, ReturnType<typeof setTimeout>> = {};

export function useDebounce(func: Function, key: string, delay: number) {
  return (...args: any) => {
    if (debounceCacheMap[key]) {
      clearTimeout(debounceCacheMap[key]);
    }
    debounceCacheMap[key] = setTimeout(() => {
      func(...args);
      delete debounceCacheMap[key];
    }, delay);
  };
}

const VideoPage: React.FC = () => {
    const {auth} = useSelector(state => ({
        auth: state.auth
    }));
    const history = useHistory();
    const location = useLocation();
    const params = query.parse(location.search);
    const {state: {nextVideos} = {nextVideos: undefined}} = useLocation<{ nextVideos?: VideoType[] }>();

    const [finished, setFinished] = useState<boolean>(false);
    const [audioOnly, setAudioOnly] = useState<boolean>(false);
    const [exam, setExam] = useState<ExamType>();
    const dispatch = useDispatch();
    const {id: videoId} = useParams<{ id: string }>();

    const {exam: examState, video_detail} = useSelector(state => ({
        exam: state.exam,
        video_detail: state.video_detail,
    }));

    useEffect(() => {
        dispatch(fetchVideoDetail(videoId));
    }, [dispatch, videoId]);

    useEffect(() => {
        if (video_detail.item) {
            if (auth.authenticated) {
                dispatch(fetchExam(video_detail.item.id));
            }
            dispatch(resetRequestStatus());
        }
    }, [auth.authenticated, dispatch, video_detail.item]);

    useEffect(() => {
        if (examState.isProcessing || examState.selectVideoId === undefined) {
            return;
        }
        // テストがなければそのまま視聴済み登録する
        if (finished && exam === undefined) {
            onFinish();
        }
    }, [examState, finished]);

    useEffect(() => {
        if (examState.selectVideoId === video_detail.item?.id && !examState.isProcessing) {
            setExam(examState.item);
            // 既に動画が終了していてテストがなければそのまま視聴済み登録する
            if (finished && examState.item === undefined) {
                onFinish();
            }
        }
    }, [examState, finished, video_detail.item]);

    const position = useMemo(() => {
        return (params['t'] || undefined) as number | undefined;
    }, [params]);

    const onExam = () => {
        history.push(`/videos/${video_detail.item?.id}/exam`);
    };

    const onFinish = useDebounce(() => {
        if (video_detail.item && auth.authenticated) {
            dispatch(sendFinishVideo(video_detail.item.id));
        }
    }, 'VideoPage.onFinish', 1000);

    const onNextVideo = (item: VideoType) => {
        if (nextVideos === undefined) {
            return;
        }
        const index = nextVideos.findIndex(v => v.id === item.id);
        history.push(`/videos/${item.id}`, {
            nextVideos: nextVideos.filter((v, i) => i > index),
        });
    };

    if (video_detail.item === null) {
        return <WithLoadingScreen loading={true}/>
    }
    const video = video_detail.item;

    return (
        <Screen>
            <Helmet>
                <title>{video.name}</title>
            </Helmet>
            <PageHeader title={video.name}/>
            <ContentWrap>
                <VideoPlayer position={position}
                             video={video}
                             audioOnly={audioOnly}
                             onFinished={() => setFinished(true)}/>
            </ContentWrap>
            <Scroll>
                <ContentWrap>
                    <AudioOnlyButton audioOnly={audioOnly}
                                     onPress={() => setAudioOnly(!audioOnly)}/>
                    <VideoInformation video={video}/>
                    <ExamButton show={!!exam}
                                disabled={!finished}
                                onPress={onExam}/>
                </ContentWrap>
                {nextVideos ? (
                    <NextVideoBox onPress={onNextVideo}
                                  nextVideos={nextVideos}/>
                ) : <></>}
            </Scroll>
        </Screen>
    );
};

export default VideoPage;
