import React, { useEffect } from "react";
import styled from "styled-components";
import {Helmet} from "react-helmet-async";
import ScreenView from "../parts/ScreenView";
import PageHeader from "../parts/Headers/PageHeader";

const Title = styled.h1`
  margin: 0;
`;

const SectionTitle = styled.h4`
`;

const EventGuide: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // url が変更されてからスクロール量がリセットされる
    });

return (
    <>
        <Helmet>
            <title>イベント管理受講ガイドライン</title>
        </Helmet>
        <PageHeader title={'イベント管理受講ガイドライン'}/>
        <ScreenView>
            <Title>イベント講座受講ガイドライン</Title>
            <SectionTitle>利用者の受講に際する行為についてのガイドライン</SectionTitle>
            <p>アステッキホールディングス株式会社（以下、弊社）を介して講座の受講予約および参加を行う場合に該当するガイドラインになります。<br />
            「利用者」とは講座に対して受講予約をしたユーザーを指します。<br />
            「開催者」とは講座を企画・主催している各協会のことを指します。<br />
            「イベント」とは本システムにて申し込み及び参加料の支払いを行うイベントのみを対象とします。<br />
            無料イベントやお知らせにて告知したイベントは対象外となります。</p>
            <ol type="1">
                <li><p>受講予約申込みの確定及び確認について<br />
                    受講予約の確定は、弊社からの予約確認通知メールの到達およびマイページ画面上での[申し込み中イベント一覧]での表示を持って確定し、受講権を得るものとします。<br />
                    但し、利用者の責めに帰すべき事由により当該予約確認通知メールが到達しなかった場合、通常到達すべき時点において到達したものとみなします。<br />
                    なお、決済および[予約の状況]画面上での予約ステータスの確認は受講前に利用者側が責任を持って行うものとし、これを怠り損害が発生した場合についても、弊社および開催者は一切の責任を負わないものとし、利用者が決済を伴う予約申込みについて弊社は返金義務を負わないものとします。<br />
                    受講予約申込みをした利用者が参加せず、他の利用者が受講するなどの場合は、事前に開催者の承諾を得てから受講するものとします。</p></li>
                <li><p>利用者の受講権と情報の取り扱いについて<br />
                    利用者は講座の予約をした際に、開催者に対して、弊社にご登録の登録ユーザー名、職種、保持資格、性別、年齢が開示されることに同意します。<br/>
                    また、当日受講権を享受するにあたり、開催者から求められた場合は本人確認書類（運転免許証、健康保険証など）の提示が必要になることに、あらかじめ同意するものとします。</p></li>
                <li><p>予約の日程振替について<br />
                    受講予約は開催日程毎に申し込みを必要とし、同じ講座であっても別の日程への振替は原則行なうことができないものとします。<br/>
                    別の日程での参加を希望する場合は、一旦参加不可になった日程での予約をキャンセルし、原則参加可能な日程で再度予約申し込みをする必要があるものとします。<br/>
                    万が一、開催者の理由により講座の振替実施などの対応をする場合は、その一切の対応は、開催者に委ねるものとし、その実施に伴ういかなる争いや損害が発生した場合についても、弊社は一切の責任を負わず不介入の立場をとるものとします。</p></li>
                <li><p>予約締切日時より前の受講予約のキャンセルについて<br />
                    予約締切日時は全講座において開催日の1日前となります。<br/>
                    利用者は予約締切日時までは、自由に予約をキャンセルすることができますが、それ以降はできないものとします。弊社側の理由により予約締切日時までにキャンセルが実行できなかった場合を除いて、返金を伴う予約のキャンセルは、利用者自身がサイト上でキャンセルの実行を行い、キャンセル確定の自動通知メールと、［予約の状況］画面にて当該講座が予約済みの講座にない旨を確認する必要があります。 利用者側からの任意の連絡のみでは予約のキャンセルは成立したものとはみなされませんのでご留意ください。</p></li>
                <li><p>利用者側の理由による予約締切日時経過後の受講予約のキャンセル・返金について<br />
                    予約締切日時（開催日の7日前）以降は、利用者は原則として返金を伴う予約キャンセルをすることはできないものとし、開催者に返金や振替対応の義務は発生しないものとします。<br/>
                    万が一、開催者が自らの判断において返金や講座の振替実施などの対応を実施する場合については、その一切の対応は、開催者に委ねるものとし、その実施に伴ういかなる争いや損害が発生した場合についても、弊社は一切の責任を負わず不介入の立場をとるものとしますので、予約締切日時以降の返金や振替実施などの一切の対応は、開催者に個別にお問い合わせ下さい。</p></li>
                <li><p>開催者側の理由による開催のキャンセルについて<br />
                    開催者は日程を公開したとき、やむを得ない理由を除いては、責任を持って告知した内容の通りに日程を開催する義務を負うこととします。<br/>
                    万が一やむを得ない理由により開催者側から開催のキャンセルをしなければいけない事態が発生した場合の一切の対応は、開催者に委ねるものとし、その実施に伴ういかなる争いや損害が発生した場合についても、弊社は一切の責任を負わず不介入の立場をとるものとします。</p></li>
                <li><p>当日の参加および参加中の立ち振る舞いについて<br />
                    利用者は、他の参加者の迷惑とならないよう、講座が開始する5分前には開催場所に到着もしくはオンラインにて接続して待機しているよう、最大限の努力を果たすものとします。<br/>
                    万が一時間通りにたどり着けない場合はサイトを通じて取得した弊社または開催者の緊急連絡先に速やかに連絡するものとします。<br/>
                    講座受講中は、開催者の指示に従うものとし、他の参加者に迷惑になる行為については控えるものとします。<br/>
                    仮に利用者につき受講を妨害する行為その他迷惑になる行為があったと開催者が判断した場合には、開催者には当該利用者に退場を命じる権利があります。<br/>
                    この場合、利用者は、受講料の返金を請求することはできないものとします。 利用者は講座へは、スキルを学ぶ・体験することを主な目的として参加するものとします。<br/>
                    もし講座の開催者が、利用者がそれ以外の目的(例えば別のイベント・団体への勧誘や競合調査など)で参加したということが明らかであると判断した場合は、利用者による講座への参加を参加の途中であっても断る権利を持っています。<br/>
                    この場合、利用者は、受講料の返金を請求することはできないものとします。</p></li>
                <li><p>直接取引の禁止<br />
                手段に関わらず、利用者と開催者側の間での直接の受講料の支払いは禁止します。<br/>
                支払が完了していない場合における当日参加は出来かねますので、「1.受講予約申込みの確定及び確認について」の通り、利用者は、決済および[予約の状況]画面上での予約ステータスが受講料支払済みであることの確認を受講前に利用者側が自己の責任で行ってください。<br/>
                これを怠り損害が発生した場合についても、弊社・開催者は一切の責任を負わないものとします。</p></li>
                <li><p>オンライン講座の受講について</p></li>
                    <p>オンライン講座とは、ビデオ会議ツール・ビデオチャットツール（以下ビデオ会議ツール）を使ったオンライン形式の講座を指します。</p>
                        <ol type="1">
                            <li><p>オンライン講座への参加について<br />
                                オンライン講座を受講する利用者は、以下について同意し遵守するものとします。<br />
                                1）受講予約の際、開催者が指定するツールを利用することを承諾することとする<br />
                                2）使用する接続ツールの事前登録、アプリ等のインストール、設定は利用者自身でおこなうものとする<br />
                                3）オンライン講座を受講するために必要な機材（PC、カメラ、スマートフォン等）や通信費は利用者自身の負担とする<br />
                                4）利用者は、周囲の騒音が少なく、通信が安定している環境で参加するものとし、他の参加者・講座の進行の妨げにならない環境づくりに努めるものする<br />
                                5）弊社から配布された参加URLは、予約をしていない第三者が不正に参加することがないように取り扱いに注意する（URL以外にも画面キャプチャなどSNSへの投稿時は注意してください）<br />
                                6）受講開始時刻をすぎても利用者からの接続が確認できない・連絡がない場合は、開催者は開催を取りやめることができるものとする<br />
                                7）利用者側の接続環境や通信環境により講座の進行が中断・継続できなくなった場合、原則、講師および弊社は返金・振替または講座の延長の責任は負わないものとする<br />
                                8）開催者の許可なく、講座の録画・録音、写真、画面キャプチャー等の撮影はしないものとし、許可なく撮影した場合は、速やかに該当データを削除するものとする</p></li>
                            <li><p>接続サポート・トラブル対応について</p></li>
                        </ol>
                        <p>当日の接続サポートは弊社では対応しかねますので、事前に接続テストをおこない、スムーズに受講できるよう十分に準備をおこなってください。<br />
                            また、開催者においても他の参加者がいるなどを理由に、開催時刻を過ぎても問題が解決されない場合、サポートを中止し、講座の進行を優先する権利があります。</p>
                        <ol type="1">
                            <li><p>開催の中止・受講の取り止めについて<br />
                            講座開始前・開催中において、利用者側の機器または通信トラブルにより講座の受講が困難となった場合、開催者もしくは弊社は、受講料の返金や日程の振替対応の義務は原則発生しないことを前提として、受講の中止について開催者と協議するものしてください。<br/>
                            また、講座中に発生した機器・通信トラブルの原因の特定、責任の所在について、弊社は一切の判断ができかねますので予めご了承ください。</p></li>
                        </ol>
                <li><p>役務提供の異議申し立てについて</p></li>
                    <p>弊社は、講座の開催終了日時をもって、講座開催における役務提供がなされたとみなします。<br />
                    もし講座が告知通りに開催されなかった場合、講座を受講予約した利用者は、弊社に違反行為の指摘および報告が出来ることとします。<br />
                    指摘および報告を受けた後、弊社は講座開催の真偽についてヒアリング調査を行い、確認されるまでは開催者への支払いを止める権利を持つこととします。<br/>
                    最終的に開催者の違反行為とみなされるかは、弊社が最終判断を持つこととします。</p>
            </ol>

        </ScreenView>
    </>
)
};

export default EventGuide;