import React, { useState } from "react";
import styled from "styled-components";
import labelClosed from "../../assets/login/target_qualifications_label_closed.png";
import labelOpened from "../../assets/login/target_qualifications_label_opened.png";
import syumatsukiIcon from "../../assets/login/syumatsuki-icon-36x36.png";
import kyuseikiIcon from "../../assets/login/kyuseiki-icon-36x36.png";
import zaitakuIcon from "../../assets/login/zaitaku-icon-36x36.png";

const Section = styled.div`
  border-width: 1px 0 0 0;
  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.mainColor};
  max-width: 400px;
  margin: 30px auto;
`;

const Content = styled.div`
  box-sizing: border-box;
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LabelView = styled.img`
`;

const SyumatsukiIcon = styled.img`
    width: 22px;
    height: 22px;
    margin: 0 8px 0 25%;
`;

const KyuseikiIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: 0 10px 0 26%;
`;
const ZaitakuIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: 0 11px 0 25.5%;
`;

const QualificationPart = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  margin: 0 0 8px;
`;

const ExpandView = styled.div`
  box-sizing: border-box;
  width: calc(88% + 1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 0 0 8px 8px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 0 0 0 ;
`;

const TargetQualifications: React.FC = () => {
    const [isOpen, setView] = useState<boolean>(false);

    const changeView = () => setView(!isOpen);

    return (
        <Section>
            <Content onClick={changeView}>
                {isOpen ?
                    <LabelView src={labelOpened} alt="対象の資格" />
                    : <LabelView src={labelClosed} alt="対象の資格" />}
                {isOpen ?
                    <ExpandView>
                        <QualificationPart>
                            <SyumatsukiIcon src={syumatsukiIcon} alt="終末期ケア専門士"  />
                            終末期ケア専門士
                        </QualificationPart>
                        <QualificationPart>
                            <SyumatsukiIcon src={syumatsukiIcon} alt="終末期ケア上級専門士"  />
                            終末期ケア上級専門士
                        </QualificationPart>
                        <QualificationPart>
                            <SyumatsukiIcon src={syumatsukiIcon} alt="家族ケア専門士"  />
                            家族ケア専門士
                        </QualificationPart>
                        <QualificationPart>
                            <KyuseikiIcon src={kyuseikiIcon} alt="急性期ケア専門士"  />
                            急性期ケア専門士
                        </QualificationPart>
                        <QualificationPart>
                            <ZaitakuIcon src={zaitakuIcon} alt="在宅看護指導士"  />
                            在宅看護指導士
                        </QualificationPart>
                        <QualificationPart>
                            <ZaitakuIcon src={zaitakuIcon} alt="在宅介護指導士"  />
                            在宅介護指導士
                        </QualificationPart>
                    </ExpandView>
                    : <></>}
            </Content>
        </Section>
    )
};

export default TargetQualifications;
