import React from "react";
import styled from "styled-components";
import TouchableOpacity from "../TouchableOpacity";
import {TitleTextBox} from "../AppStyles";
import ChatMessageType from "../../interfaces/ChatMessageType";
import ReactionType from "../../interfaces/ReactionType";
import ReactionView from "./ReactionView";

const ButtonBox = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const Button = styled(TouchableOpacity)`
  border: 2px solid ${({theme}) => theme.mainColor};
  border-radius: 4px;
  padding: 8px;
`;

const ButtonText = styled(TitleTextBox)`
  color: ${({theme}) => theme.mainColor};
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`;

const ShowComment = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.mainColor};
  color: ${({theme}) => theme.mainColor};
  margin: 0 8px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`;

const CommentWrap = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface Props {
    message: ChatMessageType;
    onShowComment?: () => void;
    showForm: boolean;
    onShowForm: (set: boolean) => void;
    onReaction: (reaction?: ReactionType) => void;
    onShowReactions: () => void;
}

const ChatCommentButtonBox: React.FC<Props> = ({
                                                   message,
                                                   onShowComment,
                                                   showForm,
                                                   onShowForm,
                                                   onReaction,
                                                   onShowReactions
                                               }) => {
    return (
        <ButtonBox>
            <ReactionView message={message}
                          onReaction={onReaction}
                          onShowReactions={onShowReactions}/>
            <CommentWrap>
                <Button onClick={() => onShowForm(!showForm)}>
                    <ButtonText>コメントする</ButtonText>
                </Button>
                {onShowComment && message.children.length > 0 ? (
                    <ShowComment onClick={onShowComment}>
                        コメント({message.children.length})
                    </ShowComment>
                ) : <></>}
            </CommentWrap>
        </ButtonBox>
    )
};

export default ChatCommentButtonBox;