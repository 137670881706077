import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlatButton from "../../parts/FlatButton";
import {reportChatMessage, resetRequestStatus} from "../../stores/report_chat_message";
import ChatMessageView from "../../parts/ChatMessageView";
import {useDispatch} from "react-redux";
import {useSelector} from "../../store";
import SelectReason from "../../parts/ChatMessageReport/SelectReason";
import {BodyTextBox} from "../../parts/AppStyles";
import ChatMessageType from "../../interfaces/ChatMessageType";
import ModalView from "../../parts/ModalView";
import ModalHeader from "../../parts/Headers/ModalHeader";
import {alert} from "../../utils/confirm";

const ConfirmText = styled(BodyTextBox)`
  margin-top: 24px;
  font-size: 14px;
`;

const Body = styled.div`
  padding: 8px;
`;

const ReportButton = styled(FlatButton)`
  margin-top: 24px;
`;

type Props = {
    message: ChatMessageType | null;
    onClose: () => void;
};

const ChatMessageReport: React.FC<Props> = ({message, onClose}) => {

    const [reason, setReason] = useState<string>();
    const dispatch = useDispatch();
    const {report_chat_message} = useSelector(state => ({
        report_chat_message: state.report_chat_message
    }));

    useEffect(() => {
        if (message === null) {
            setReason(undefined);
        }
    }, [message]);

    useEffect(() => {
        if (report_chat_message.requestStatus === 'success') {
            alert('違反報告を送信しました。なお報告内容へのご返信は行っておりませんのでご了承ください。').then(() => {
                onClose();
            });
            dispatch(resetRequestStatus());
        } else if (report_chat_message.requestStatus === 'failed') {
            toastr.error('違反報告を送信できませんでした。');
            dispatch(resetRequestStatus());
        }
    }, [dispatch, onClose, report_chat_message.requestStatus]);

    const onReport = async () => {
        if (reason === undefined || message === null) {
            return;
        }
        dispatch(reportChatMessage({
            chatMessageId: message.id,
            reason
        }));
    };

    return (
        <ModalView show={!!message} onClose={onClose}>
            <ModalHeader title={'違反報告'} onClose={onClose}/>
            <Body>
                <ChatMessageView item={message!!}/>
                <ConfirmText>
                    このメッセージを違反報告しますか？{'\n'}
                    違反内容を選択してください。
                </ConfirmText>
                <SelectReason
                    value={reason}
                    onChange={value => setReason(value)}/>
                <ReportButton
                    onPress={onReport}
                    text={'違反報告送信'}
                    textColor={'#FFFFFF'}
                    backgroundColor={'#212121'}
                    disableColor={'#C1C1C1'}
                    disabled={reason === undefined || report_chat_message.requestStatus === 'pending'}
                />
            </Body>
        </ModalView>
    );
};

export default ChatMessageReport;
