import React from 'react';
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";

const Section = styled.div`
  padding: 16px;
`;

const Text = styled(TitleTextBox)`
  text-align: center;
  color: ${({theme}) => theme.textGrayColor};
`;

const ChatGroupEmptyView: React.FC = () => {
    return (
        <Section>
            <Text>
                現在メッセージがありません。{'\n'}
                是非投稿をお願いします！
            </Text>
        </Section>
    )
};

export default ChatGroupEmptyView;
