import React, {useEffect} from 'react';
import {useSelector} from "../store";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchUser} from "../stores/user";

const CheckAvailable: React.FC = () => {

    const {auth, user} = useSelector(state => ({
        auth: state.auth,
        user: state.user
    }));
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (auth.authenticated && user.user === null) {
            dispatch(fetchUser());
        }
    }, [auth.authenticated, dispatch, user.user]);

    useEffect(() => {
        if (auth.authenticated && user.user) {
            if (user.user.available_membership === null) {
                history.push('/purchase');
            }
        }
    }, [auth, history, user]);

    return <></>
};

export default CheckAvailable;
