import React from 'react';
import AccordionView from "./AccordionView";
import styled from "styled-components";
import UnitHistoryType from "../../interfaces/UnitHistoryType";
import UnitHistoryItem from "./UnitHistoryItem";

const Section = styled(AccordionView)`
  margin-bottom: 16px;
`;

interface Props {
    histories: UnitHistoryType[];
    onRefresh: () => void;
}

const UnitHistory: React.FC<Props> = ({histories, onRefresh}) => {

    return (
        <Section labelText={'取得単位履歴'} onOpenChange={open => open ? onRefresh() : null}>
            {histories.map(history => (
                <UnitHistoryItem key={history.id} history={history}/>
            ))}
        </Section>
    )
};

export default UnitHistory;
