import React from 'react';
import InstructorType from "../../interfaces/InstructorType";
import styled from "styled-components";
import SectionLabel from "../SectionLabel";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import Image from "../Image";
import nl2br from "../../utils/nl2br";

const Box = styled.div`
  margin-bottom: 24px;
  padding: 16px;
`;

const ProfileIcon = styled(Image)`
  width: 150px;
  height: 150px;
  border-radius: 75px;
  margin: 16px auto 0 auto;
`;

const Name = styled(TitleTextBox)`
  font-size: 24px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  text-align: center;
  margin: 16px auto 0 auto;
`;

const Description = styled(BodyTextBox)`
  margin: 16px auto 0 auto;
`;

interface Props {
    instructor: InstructorType | null;
}

const InstructorBox: React.FC<Props> = ({instructor}) => {

    if (instructor === null) {
        return <></>
    }
    return (
        <Box>
            <SectionLabel title='講師プロフィール'/>
            <ProfileIcon src={instructor.file_url}/>
            <Name>{instructor.name}</Name>
            <Description>{nl2br(instructor.description)}</Description>
        </Box>
    );
};

export default InstructorBox;
