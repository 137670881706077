import React from 'react';
import useSWR from 'swr';
import axios from '../../http/axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Banner = styled.div`
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 12px;
    margin-bottom: 24px;
    border-radius: 4px;
`;

const Description = styled.div`
    margin-bottom: 8px;
`;

const ChangeLink = styled(Link)`
    color: #007bff;
    text-decoration: underline;
    font-weight: bold;
    
    &:hover {
        color: #0056b3;
    }
`

export function CardExpiredBanner() {
  const {data: expires} = useSWR('CardExpiredBanner', async () => {
    const {data} = await axios.get<{ data: {data: string | null} }>('card_expires');
    return data.data;
  });

  if (expires === null || expires === undefined) {
    return null;
  }

  return (
    <Banner>
      <Description>
        ご登録いただいているクレジットカードの有効期限が近づいています。<br/>
        お手数ですが、有効期限が切れる前に新しいクレジットカード情報をご登録いただきますようお願いいたします。
      </Description>
      <ChangeLink to="/payment/change">クレジットカード情報の変更はこちら</ChangeLink>
    </Banner>
  );
}
