import React from "react";
import SendForm from "../../parts/SendChatMessage/SendForm";
import ModalView from "../../parts/ModalView";
import ModalHeader from "../../parts/Headers/ModalHeader";
import ChatMessageType from "../../interfaces/ChatMessageType";

type Props = {
    show: boolean;
    onClose: () => void;
    onPost: (text: string, file: File | null) => void;
    editItem?: ChatMessageType | null;
}

const SendChatMessage: React.FC<Props> = ({show, onClose, onPost, editItem}) => {
    if (!show) {
        return <></>
    }

    return (
        <ModalView show={show} onClose={onClose}>
            <ModalHeader title={editItem ? '投稿を編集' : '投稿を作成'} onClose={onClose}/>
            <SendForm onSubmit={onPost} editItem={editItem}/>
        </ModalView>
    );
};

export default SendChatMessage;