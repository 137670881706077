import React from 'react';
import styled from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";
import Icon from "../Icon";

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  background-color: ${({theme}) => theme.mainColor};
  box-sizing: border-box;
`;

const TextBox = styled.div`

`;

const BackButton = styled(TouchableOpacity)`
  width: 30px;
  height: 30px;
`;

const BackIcon = styled(Icon)`
  height: 28px;
  color: ${({theme}) => theme.whiteColor};
`;

const Step = styled(BodyTextBox)`
  font-size: 14px;
  color: ${({theme}) => theme.whiteColor};
`;

const Title = styled(TitleTextBox)`
  font-size: 24px;
  font-weight: bold;
  margin-top: 4px;
  color: ${({theme}) => theme.whiteColor};
`;

interface Prop {
    onBack: () => void;
}

const EventPaymentConfirmHeader: React.FC<Prop> = ({onBack}) => {
    return (
        <Box>
            <BackButton onClick={onBack}>
                <BackIcon name={'angle-left'} size={24}/>
            </BackButton>
            <TextBox>
                <Step>ステップ1/3</Step>
                <Title>予約内容の確認</Title>
            </TextBox>
        </Box>
    )
};

export default EventPaymentConfirmHeader;
