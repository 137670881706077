import RequestStatusType from "../interfaces/RequestStatusType";
import RequestErrorType from "../interfaces/RequestErrorType";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "../http/axios";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";

export type RegisterState = {
    requestStatus: RequestStatusType;
    errors: RequestErrorType;
    token: string;
};

const initialState: RegisterState = {
    requestStatus: 'init',
    errors: {},
    token: ''
};

type RegisterParams = {
    id: string,
    password: string
};

type TokenResult = {
    token: string;
};

export const execRegister = createAsyncThunk<FetchDetailReturnType<TokenResult>, RegisterParams>(
    'register/execRegister',
    async (request, thunk) => {
        const {data} = await axios.post('auth/register', request).catch(error => {
            thunk.dispatch(setErrors(error.response?.data?.errors || {}));
            throw error
        });
        return data as FetchDetailReturnType<TokenResult>;
    }
);

export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
        setErrors: (state, action: PayloadAction<RequestErrorType>) => ({...state, errors: action.payload})
    },
    extraReducers: builder => {
        builder
            .addCase(execRegister.fulfilled, (state, action) => ({
                ...state,
                requestStatus: 'success',
                token: action.payload.data.token,
            }))
            .addCase(execRegister.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(execRegister.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus, setErrors} = registerSlice.actions;
