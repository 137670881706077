import React, {useCallback} from "react";
import ChatMessageType from "../../interfaces/ChatMessageType";
import styled from "styled-components";
import Image from "../Image";
import {TitleTextBox} from "../AppStyles";
import ChatContextMenu from "./ChatContextMenu";
import daysAgo from "../../utils/daysAgo";
import {useHistory} from "react-router-dom";
import TouchableOpacity from "../TouchableOpacity";

const HeaderFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
`;

const UserImage = styled(Image)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-right: 8px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const UserName = styled(TitleTextBox)`
  font-size: 18px;
`;

const Industry = styled(TitleTextBox)`
  margin-top: 2px;
  font-size: 14px;
  color: ${({theme}) => theme.textGrayColor};
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

const DateTime = styled.div`
  color: #616161;
  font-size: 12px;

  @media screen and (min-width: 480px) {
    margin-right: 8px;
  }
`;

const UserTouchable = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserBox = styled.div`
  flex: 1;
`;

interface Props {
    message: ChatMessageType;
    onEdit: (message: ChatMessageType) => void;
    onDelete: (message: ChatMessageType) => void;
    onPin: (message: ChatMessageType) => void;
    onReport: (message: ChatMessageType) => void;
}

const CommentMessageHeader: React.FC<Props> = ({message, onEdit, onDelete, onPin, onReport}) => {

    const history = useHistory();

    const onPressUser = useCallback(() => {
        history.push(`/sns/users/${message.user_id}`);
    }, [history, message.user_id]);

    return (
        <HeaderFlex>
            <UserTouchable onClick={onPressUser}>
                <UserImage src={message.user.profile_url || 'https://placehold.jp/100x100.png'}/>
                <UserBox>
                    <UserName>{message.user.nickname}</UserName>
                    <Industry>{message.user.industry}</Industry>
                </UserBox>
            </UserTouchable>
            <RightBox>
                <DateTime>{daysAgo(message.created_at_timestamp)}</DateTime>
                <ChatContextMenu message={message}
                                 onEdit={onEdit}
                                 onDelete={onDelete}
                                 onPin={onPin}
                                 onReport={onReport}/>
            </RightBox>
        </HeaderFlex>
    );
};

export default CommentMessageHeader;