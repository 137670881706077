import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import QualificationType from "../interfaces/QualificationType";

export type QualificationState = {
    items: QualificationType[];
    isFetching: boolean;
}

const initialState: QualificationState = {
    items: [],
    isFetching: false
};

export const fetchQualifications = createAsyncThunk<FetchReturnType<QualificationType>>(
    'qualifications/fetch',
    async () => {
        const {data} = await axios.get('qualifications');
        return data as FetchReturnType<QualificationType>;
    }
);

export const qualificationSlice = createSlice({
    name: 'qualifications',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchQualifications.fulfilled, (state, action) => ({
                ...state,
                items: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchQualifications.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchQualifications.rejected, state => ({
                ...state,
                items: [],
                isFetching: false
            }))
    }
});
