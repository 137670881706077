import React, {useCallback, useEffect} from 'react';
import PageHeader from "../../parts/Headers/PageHeader";
import styled from "styled-components";
import ApplyForm from "../../parts/EventPage/ApplyForm";
import InstructorBox from "../../parts/EventPage/InstructorBox";
import EventInformation from "../../parts/EventPage/EventInformation";
import {useSelector} from "../../store";
import Image from "../../parts/Image";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchEventDetail} from "../../stores/event_detail";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import NotFound from "../NotFound";
import {Helmet} from "react-helmet-async";
import ScreenView from "../../parts/ScreenView";
import {alert, confirm} from "../../utils/confirm";
import * as toastr from "toastr";
import {cancelEvent, resetRequestStatus} from "../../stores/event_cancel";
import EventCancelBox from "../../parts/EventPage/EventCancelBox";

const Scroll = styled.div`
  overflow-y: auto;
`;

const ThumbImage = styled(Image)`
  width: 100%;
`;

const EventPage: React.FC = () => {

    const {auth, event_detail, event_cancel} = useSelector(state => ({
        auth: state.auth,
        event_detail: state.event_detail,
        event_cancel: state.event_cancel,
    }));
    const {id: eventId} = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchEventDetail(eventId));
    }, [dispatch, eventId]);

    useEffect(() => {
        if (event_cancel.requestStatus === 'success') {
            toastr.success('イベント申し込みをキャンセルしました。');
            dispatch(fetchEventDetail(eventId));
            dispatch(resetRequestStatus());
        } else if (event_cancel.requestStatus === 'failed') {
            toastr.error('イベント申し込みをキャンセルできませんでした。');
            dispatch(resetRequestStatus());
        }
    }, [dispatch, eventId, event_cancel]);

    const onPressApply = useCallback(async () => {
        if (auth.authenticated) {
            history.push(`/events/${eventId}/confirm`);
        } else {
            alert("会員価格でのお申し込みには会員ログインが必要です。\nもし既に会員である場合はログインしてください。").catch();
        }
    }, [auth.authenticated, eventId, history]);

    const onPressNonMember = useCallback(() => {
        history.push(`/events/${eventId}/confirm`);
    }, [eventId, history]);

    const onCancelEvent = useCallback(async () => {
        if (await confirm("このイベントへの申し込みをキャンセルしてもよろしいですか？")) {
            dispatch(cancelEvent(eventId));
        }
    }, [dispatch, eventId]);

    if (event_detail.isFetching) {
        return <WithLoadingScreen loading={true}/>
    }
    if (event_detail.item === null) {
        return <NotFound/>
    }
    const event = event_detail.item;

    return (
        <>
            <Helmet>
                <title>{event.name}</title>
            </Helmet>
            <PageHeader title={event.name}/>
            <ScreenView>
                <Scroll>
                    {event.thumbnail_url !== null ? (
                        <ThumbImage src={event.thumbnail_url}/>
                    ) : <></>}
                    <EventInformation event={event}/>
                    <InstructorBox instructor={event.instructor}/>
                    <EventCancelBox event={event}
                                    onCancel={onCancelEvent}
                                    disabled={event_cancel.requestStatus === "pending"}/>
                </Scroll>
                <ApplyForm event={event}
                           onPress={onPressApply}
                           onPressNonMember={onPressNonMember}/>
            </ScreenView>
        </>
    );
};

export default EventPage;
