import React from "react";
import styled from "styled-components";
import FlatButton from "../../parts/FlatButton";
import ScreenView from '../../parts/ScreenView';
import {BodyTextBox, TitleTextBox} from "../../parts/AppStyles";
import {useHistory} from "react-router-dom";

const Title = styled(TitleTextBox)`
  font-size: 20px;
  font-weight: bold;
`;

const Description = styled(BodyTextBox)`
  margin-top: 24px;
  font-size: 14px;
`;

const LoginButton = styled(FlatButton)`
  margin-top: 24px;
`;

const BannedScreen: React.FC = () => {
    const history = useHistory();
    return (
        <ScreenView>
            <Title>ログアウトされました</Title>
            <Description>
                ユーザー権限が停止されているか、一時的な問題により現在ログインできません。<br/>
                詳細はメールをご確認いただくかお問い合わせください。
            </Description>
            <LoginButton text={'再ログイン'}
                         textColor={'#FFF'}
                         backgroundColor={'#616161'}
                         onPress={() => history.replace('/')}/>
        </ScreenView>
    )
};

export default BannedScreen;
