import React, {useContext} from 'react';
import VideoType from "../../interfaces/VideoType";
import styled, {ThemeContext} from "styled-components";
import {BodyTextBox, TitleText} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";
import CheckIcon from "../CheckIcon";
import TouchableOpacity from "../TouchableOpacity";
import backgroundImage from '../../assets/video_list_bg.png';
import nl2br from "../../utils/nl2br";
import Image from "../Image";

const Background = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.borderColor};
  border-bottom-style: solid;
  background-image: url(${backgroundImage});
  background-size: cover;
  padding: 16px;
`;

const Section = styled(TouchableOpacity)`
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ThumbnailImage = styled(Image)`
  width: 150px;
`;

const Title = styled(TitleText)`
  font-size: 20px;
  font-weight: bold;
  padding: 0 8px;
  color: ${({theme}) => theme.mainColor};
  flex: 1;
`;

const Description = styled(BodyTextBox)`
  margin-top: 8px;
  padding: 8px 0;
`;

interface Props {
    video: VideoType;
    onPress: () => void;
}

const NextVideoItem: React.FC<Props> = ({video, onPress}) => {
    const theme = useContext(ThemeContext);
    return (
        <Background>
            <Section onClick={onPress}>
                <RowFlex>
                    <ThumbnailImage src={video.thumbnail_url}/>
                    <CheckIcon show={video.is_finish}/>
                    <Title>{video.name}</Title>
                    <ArrowIcon size={16} color={theme.subColor}/>
                </RowFlex>
                <Description>{nl2br(video.description ?? '')}</Description>
            </Section>
        </Background>
    )
};

export default NextVideoItem;
