import React from 'react'
import {Route, RouteProps} from 'react-router-dom'
import _ from 'lodash'
import {useSelector} from "../store";
import {RouteComponentProps} from "react-router";

interface Props extends RouteProps {
    guestComponent: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const SwitchRoute: React.FC<Props> = props => {
    const {auth} = useSelector(state => ({
        auth: state.auth
    }));

    const rest = _.omit(props, ['component'])
    return (
        <Route
            {...rest}
            render={innerProps =>
                auth.authenticated ? (
                    <Route {...props} />
                ) : (
                    <Route {...props} component={props.guestComponent}/>
                )
            }
        />
    )
}

export default SwitchRoute;
