import React, {useState, useRef, useEffect, useCallback} from 'react';
import styled from "styled-components";
import FlatButton from "../FlatButton";
import ErrorText from "../ErrorText";
import Agreement from '../../screens/Agreement';

const Section = styled.div`
  padding: 16px 0;
`;

const IdInput = styled.input`
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 2px;
  padding: 12px 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const PasswordInput = styled.input`
  margin-top: 16px;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 2px;
  padding: 12px 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const SubmitButton = styled(FlatButton)`
  margin-top: 32px;
`;

const AgreementFrame = styled.iframe`
  width: 100%;
  height: 300px;
  border: 1px solid ${({theme}) => theme.borderColor};
  margin-top: 16px;
  box-sizing: border-box;
`;

const AgreementLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
`;

const Checkbox = styled.input`
  margin-right: 4px;
`;

interface Props {
    userId: string;
    password: string;
    isProcessing: boolean;
    onUserIdChange: (text: string) => void;
    onPasswordChange: (text: string) => void;
    onSubmitPress: () => void;
    errors: {};
}

const RegisterLoginForm: React.FC<Props> = ({
                                                userId,
                                                password,
                                                isProcessing,
                                                onUserIdChange,
                                                onPasswordChange,
                                                onSubmitPress,
                                                errors
                                            }) => {

    const [agree, setAgree] = useState<boolean>(false);
    const [isCompletedReadAgreement, setIsCompletedReadAgreement] = useState<boolean>(false);
    const isRunning = useRef(false);
    const AgreementFrameRef = useRef<HTMLIFrameElement>(null);

      // リスナに登録する関数
    const isScrollToggle = useCallback(() => {
      if (isRunning.current) return
      isRunning.current = true

      if (!AgreementFrameRef.current) return
      if (!AgreementFrameRef.current.contentWindow) return
      if (!AgreementFrameRef.current.contentDocument) return
      if (!AgreementFrameRef.current.contentDocument.body) return

      const AgreementContentInnerHeight  = AgreementFrameRef.current.contentWindow.innerHeight
      const AgreementContentScrollHeight = AgreementFrameRef.current.contentDocument.body.scrollHeight
      const AgreementContentScrollY      = AgreementFrameRef.current.contentWindow.scrollY
      

      if ( AgreementContentInnerHeight + AgreementContentScrollY >= AgreementContentScrollHeight - 50 ) {
        setIsCompletedReadAgreement(true)
      }

      isRunning.current = false

    }, [])

    // 登録と後始末
    useEffect(() => {
      if (!AgreementFrameRef.current) return
      if (!AgreementFrameRef.current.contentWindow) return
        AgreementFrameRef.current.contentWindow.addEventListener('scroll', isScrollToggle)
      return () => {
        if (!AgreementFrameRef.current) return
        if (!AgreementFrameRef.current.contentWindow) return  
        AgreementFrameRef.current.contentWindow.removeEventListener('scroll', isScrollToggle)
      }
    }, [isScrollToggle])


    const onSubmit = () => {
        if (isProcessing || userId === '' || password === '' || !agree) {
            return;
        }
        onSubmitPress();
    };

    return (
        <Section>
            <IdInput value={userId}
                     placeholder={'ID'}
                     onChange={(e) => onUserIdChange(e.target.value)}/>
            <PasswordInput value={password}
                           placeholder={'パスワード'}
                           type={'password'}
                           onChange={e => onPasswordChange(e.target.value)}/>
            <AgreementFrame
                src={'/agreement'}
                ref={AgreementFrameRef}
                />
            <AgreementLabel>
                <Checkbox type={'checkbox'}
                          checked={agree}
                          onChange={e => setAgree(e.target.checked)}
                          disabled={!isCompletedReadAgreement}/>
                利用規約・個人情報の取扱い　に同意します
            </AgreementLabel>
            <SubmitButton text={'会員登録へ進む'}
                          rightAllow={true}
                          disabled={isProcessing || userId === '' || password === '' || !agree}
                          onPress={onSubmit}/>
            <ErrorText errors={errors} name={'id'}/>
            <ErrorText errors={errors} name={'password'}/>
        </Section>
    );
};

export default RegisterLoginForm;
