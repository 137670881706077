import React, {useCallback, useEffect} from 'react';
import ScreenView from '../../parts/ScreenView';
import SnsHeader from "../../parts/SnsTab/SnsHeader";
import {useSelector} from "../../store";
import ApplyChatGroupForm from "../../parts/ApplyChatGroup/ApplyChatGroupForm";
import ApplyChatGroupInformation from "../../parts/ApplyChatGroup/ApplyChatGroupInformation";
import {useDispatch} from "react-redux";
import {joinChatGroup, resetRequestStatus} from "../../stores/chat_group_join";
import {useHistory, useParams} from "react-router-dom";
import {fetchChatGroupDetail} from "../../stores/chat_group_detail";
import * as toastr from "toastr";
import NotFound from "../NotFound";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {Helmet} from "react-helmet-async";
import {alert, confirm} from "../../utils/confirm";

const ApplyChatGroup: React.FC = () => {

    const {user, joinRequest, chat_group_detail} = useSelector(state => ({
        user: state.user,
        joinRequest: state.chat_group_join,
        chat_group_detail: state.chat_group_detail,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const {id: chatGroupId} = useParams<{ id: string; }>();

    useEffect(() => {
        dispatch(fetchChatGroupDetail(chatGroupId));
    }, [chatGroupId, dispatch]);

    useEffect(() => {
        if (joinRequest.requestState === 'success') {
            alert('グループに参加しました！').then(() => {
                history.push(`/sns/chat_groups/${chatGroup.id}`);
            });
            dispatch(resetRequestStatus());
        }
        if (joinRequest.requestState === 'failed') {
            toastr.error('エラーが発生しました。');
            dispatch(resetRequestStatus());
        }
    }, [dispatch, history, joinRequest.requestState]);

    const sendJoinRequest = useCallback(() => {
        if (chat_group_detail.item === null) {
            return;
        }
        const chatGroup = chat_group_detail.item;
        dispatch(joinChatGroup(chatGroup.id));
    }, [chat_group_detail.item, dispatch]);

    const onPressApply = useCallback(async () => {
        if (chat_group_detail.item === null) {
            return;
        }
        const chatGroup = chat_group_detail.item;
        if (await confirm(`「${chatGroup.name}」\nこのグループに参加申請をしますか？`)) {
            sendJoinRequest();
        }
    }, [chat_group_detail.item, sendJoinRequest]);

    if (chat_group_detail.isFetching) {
        return <WithLoadingScreen loading={true}/>
    }
    if (chat_group_detail.item === null) {
        return <NotFound/>;
    }
    const chatGroup = chat_group_detail.item;
    return (
        <>
            <Helmet>
                <title>グループ参加申請</title>
            </Helmet>
            <SnsHeader user={user.user} canGoBack={true}/>
            <ScreenView scroll={true}>
                <ApplyChatGroupForm chatGroup={chatGroup}
                                    onPressApply={onPressApply}
                                    isFetching={joinRequest.requestState === 'pending'}/>
                <ApplyChatGroupInformation chatGroup={chatGroup}
                                           onPressApply={onPressApply}
                                           isFetching={joinRequest.requestState === 'pending'}/>
            </ScreenView>
        </>
    );
};

export default ApplyChatGroup;
