import React, {FormEvent, useRef} from 'react';
import styled from "styled-components";
import FlatButton from "../FlatButton";
import ErrorText from "../ErrorText";
import RegisterLink from "./RegisterLink";
import {Link} from "react-router-dom";

const Section = styled.form`
  padding: 16px 0;
  box-sizing: border-box;
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
`;

const IdInput = styled.input`
  border: 1px solid ${({theme}) => theme.subColor};
  border-radius: 2px;
  padding: 12px 16px;
  display: block;
  box-sizing: border-box;
  width: 100%;
`;

const PasswordInput = styled.input`
  margin-top: 16px;
  margin-bottom: 8px;
  border: 1px solid ${({theme}) => theme.subColor};
  border-radius: 2px;
  padding: 12px 16px;
  display: block;
  box-sizing: border-box;
  width: 100%;
`;

const SubmitButton = styled(FlatButton)`
  margin: 32px auto;
`;

const ForgotLink = styled.p`
  text-align: right;
  font-size: 12px;
  margin: 24px 0 0 0;
`;

const DisableText = styled.div`
  color: ${({theme}) => theme.dangerColor};
  font-weight: bold;
  text-align: center;
`;

interface Props {
    userId: string;
    password: string;
    isProcessing: boolean;
    onUserIdChange: (text: string) => void;
    onPasswordChange: (text: string) => void;
    onSubmitPress: () => void;
    onRegisterPress: () => void;
    errors: {};
}

const LoginForm: React.FC<Props> = ({
                                        userId,
                                        password,
                                        isProcessing,
                                        onUserIdChange,
                                        onPasswordChange,
                                        onSubmitPress,
                                        onRegisterPress,
                                        errors
                                    }) => {

    const passwordInputRef = useRef<HTMLInputElement>(null);

    const onSubmit = (event?: FormEvent) => {
        if (isProcessing || userId === '' || password === '') {
            event?.preventDefault();
            return;
        }
        onSubmitPress();
        event?.preventDefault();
    };

    return (
        <Section onSubmit={onSubmit}>
            <IdInput value={userId}
                     placeholder={'ID'}
                     onChange={(e) => onUserIdChange(e.target.value)}/>
            <PasswordInput value={password}
                           ref={passwordInputRef}
                           placeholder={'パスワード'}
                           type={'password'}
                           onChange={(e) => onPasswordChange(e.target.value)}/>
            <ErrorText errors={errors} name={'id'}/>
            <ErrorText errors={errors} name={'password'}/>
            <RegisterLink errors={errors} onPress={onRegisterPress}/>
            <SubmitButton text={'ログイン'}
                          disabled={isProcessing || userId === '' || password === ''}
                          rightAllow={true}
                          onPress={onSubmit}/>
            <ForgotLink><Link to={'/password/forgot'}>パスワードを忘れた方 ＞</Link></ForgotLink>
        </Section>
    );
};

export default LoginForm;
