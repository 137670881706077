import SerialNumberType from "../interfaces/SerialNumberType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import {RootState} from "../store";
import axios from "../http/axios";
import generateUuid from "../utils/uuid";

export type SerialNumberState = {
    item: SerialNumberType;
};

const initialState: SerialNumberState = {
    item: {
        id: null,
        device_code: generateUuid(),
    },
};

export const sendDeviceCode = createAsyncThunk<FetchDetailReturnType<SerialNumberType>, void>(
    'serial_number/send',
    async (arg, thunk) => {
        const {serial_number} = thunk.getState() as RootState;
        const {data} = await axios.post('serial_numbers', {device_code: serial_number.item.device_code});
        return data as FetchDetailReturnType<SerialNumberType>;
    }
);

export const serialNumberSlice = createSlice({
    name: 'serial_number',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(sendDeviceCode.fulfilled, (state, action) => ({
                ...state,
                item: action.payload.data,
            }))
    }
});
