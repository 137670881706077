import React from 'react';
import styled from "styled-components";
import {TitleTextBox} from "./AppStyles";

const Section = styled.div`
  padding: 4px 0;
`;

const Text = styled(TitleTextBox)`
  color: ${({theme}) => theme.dangerColor};
`;

interface Props {
    errors: any;
    name: string;
}

const ErrorText: React.FC<Props> = ({errors, name}) => {
    if (!errors.hasOwnProperty(name) || !(errors[name] instanceof Array)) {
        return <></>
    }

    const TextList = errors[name].reduce((array: Array<JSX.Element>, item: string) => {
        array.push(<Text key={item}>{item}</Text>);
        return array;
    }, []);

    return (
        <Section>
            {TextList}
        </Section>
    )
};

export default ErrorText;
