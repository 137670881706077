import React from 'react';
import styled from "styled-components";
import icon from '../assets/icons/check.png';

const Image = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 16px;
`;

interface Props {
    show: boolean;
}

const CheckIcon: React.FC<Props> = ({show}) => {
    if (!show) {
        return <></>;
    }
    return (
        <Image src={icon}/>
    );
};

export default CheckIcon;
