import React from 'react';
import NoticeType from "../../interfaces/NoticeType";
import styled from "styled-components";
import SectionHeader from "./SectionHeader";
import NoticeOverviewItem from "./NoticeOverviewItem";

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.lightBackgroundColor};
`;

const Section = styled.div`
  padding: 16px 32px;
  width: 100%;
  max-width: 900px;
  margin: 24px auto 0;
  box-sizing: border-box;
`;

interface Props {
    items: NoticeType<any>[];
    onPress: (notice: NoticeType<any>) => void;
}

const NoticeOverview: React.FC<Props> = ({items, onPress}) => {
    return (
        <Wrapper>
            <Section>
                <SectionHeader title={'お知らせ'}/>
                {items.slice(0, 2).map(notice => (
                    <NoticeOverviewItem notice={notice} key={`${notice.id}`} onPress={() => onPress(notice)}/>
                ))}
            </Section>
        </Wrapper>
    )
};

export default NoticeOverview;
