import React from "react";
import styled from "styled-components";
import {ContentWrap, TitleText} from "../AppStyles";
import cancelIcon from "../../assets/icons/cancel_sub.png";
import TouchableOpacity from "../TouchableOpacity";

const ButtonBox = styled.div`
  margin-top: 24px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const CancelButton = styled(TouchableOpacity)<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
`;

const CancelImg = styled.img`
  width: 18px;
  height: 18px;
`;

const CancelButtonText = styled(TitleText)`
  margin-left: 8px;
  color: ${({theme}) => theme.subColor};
  font-weight: bold;
  border-bottom: 1px solid ${({theme}) => theme.subColor};
`;

const SubmitButton = styled.button<{ disabled: boolean }>`
  cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
  background-color: ${({theme, disabled}) => disabled ? theme.disableColor : theme.subColor};
  border-radius: 20px;
  padding: 8px 24px;
  text-align: center;
  margin-left: 16px;
  outline: none;
  box-shadow: none;
  border: none;
`;

const SubmitButtonText = styled(TitleText)`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: white;
`;

interface Props {
    onCancel: () => void;
    disabled: boolean;
}

const ChatProfileSubmitButtons: React.FC<Props> = ({onCancel, disabled}) => {
    return (
        <ContentWrap>
            <ButtonBox>
                <CancelButton disabled={disabled} onClick={onCancel}>
                    <CancelImg src={cancelIcon}/>
                    <CancelButtonText>キャンセルする</CancelButtonText>
                </CancelButton>
                <SubmitButton disabled={disabled}>
                    <SubmitButtonText>設定を保存</SubmitButtonText>
                </SubmitButton>
            </ButtonBox>
        </ContentWrap>
    )
};

export default ChatProfileSubmitButtons;