import React from "react";
import AppLayout from "./layouts/AppLayout";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import GuestRoute from "./navigations/GuestRoute";
import Welcome from "./screens/Login/Welcome";
import SelectService from "./screens/Login/SelectService";
import LoginScreen from "./screens/Login/LoginScreen";
import PrivateRoute from "./navigations/PrivateRoute";
import HomeTab from "./screens/Main/HomeTab";
import EventTab from "./screens/Main/EventTab";
import PurchaseSelect from "./screens/PurchaseMember/PurchaseSelect";
import PurchaseMemberCredit from "./screens/PurchaseMember/PurchaseMemberCredit";
import PaymentSuccess from "./screens/Payment/PaymentSuccess";
import PaymentCancel from "./screens/Payment/PaymentCancel";
import VideoTab from "./screens/Main/VideoTab";
import CategoryPage from "./screens/Main/CategoryPage";
import VideoPage from "./screens/Main/VideoPage";
import NotFound from "./screens/NotFound";
import EventPage from "./screens/Main/EventPage";
import EventPaymentConfirm from "./screens/Main/EventPaymentConfirm";
import EventPaymentExec from "./screens/Main/EventPaymentExec";
import SnsTab from "./screens/Main/SnsTab";
import ChatGroupThreadView from "./screens/Main/ChatGroupThreadView";
import ChatGroupCreate from "./screens/Main/ChatGroupCreate";
import ApplyChatGroup from "./screens/Main/ApplyChatGroup";
import ExamPage from "./screens/Main/ExamPage";
import AccountTab from "./screens/Main/AccountTab";
import UpdateUserInformation from "./screens/Main/UpdateUserInformation";
import NotifyListPage from "./screens/Main/NotifyListPage";
import VideoWatchHistoryPage from "./screens/Main/VideoWatchHistoryPage";
import QualificationRenew from "./screens/Main/QualificationRenew";
import Help from "./screens/Main/Help";
import Contact from "./screens/Main/Contact";
import ContactConfirm from "./screens/Main/ContactConfirm";
import ContactSuccess from "./screens/Main/ContactSuccess";
import BannedScreen from "./screens/Login/BannedScreen";
import LogoutScreen from "./screens/Login/LogoutScreen";
import Register from "./screens/Login/Register";
import RegisterInformation from "./screens/Login/RegisterInformation";
import RegisterConfirm from "./screens/Login/RegisterConfirm";
import RegisterSuccess from "./screens/Login/RegisterSuccess";
import SwitchRoute from "./navigations/SwitchRoute";
import GuestHomeTab from "./screens/Guest/GuestHomeTab";
import GuestAccountTab from "./screens/Guest/GuestAccountTab";
import GuestSnsTab from "./screens/Guest/GuestSnsTab";
import EventPaymentSetParam from "./screens/Guest/EventPaymentSetParam";
import ChatGroupUserListPage from "./screens/Main/ChatGroupUserListPage";
import Agreement from "./screens/Agreement";
import ForgotPassword from "./screens/Login/ForgotPassword";
import ResetPassword from "./screens/Login/ResetPassword";
import ChangeCredit from "./screens/Main/ChangeCredit";
import ChangeCreditExec from "./screens/Main/ChangeCreditExec";
import EventGuide from "./screens/EventGuide";
import AgreementPage from "./screens/AgreementPage";
import SnsGuide from "./screens/SnsGuide";
import Analytics from "./analytics";
import GuideShow from "./screens/Main/GuideShow";
import ChatGroupTabPage from "./screens/Main/ChatGroupTabPage";
import ChatMessageReactionListPage from "./screens/Main/ChatMessageReactionListPage";
import ChatMessageDetail from "./screens/Main/ChatMessageDetail";
import ChatProfilePage from "./screens/Main/ChatProfilePage";
import ChatProfileEdit from "./screens/Main/ChatProfileEdit";
import SubmitBankTransaction from "./screens/PurchaseMember/SubmitBankTransaction";
import PurchaseSelectNext from "./screens/PurchaseMember/PurchaseSelectNext";
import ExpiredScreen from "./screens/Login/ExpiredScreen";

const Router: React.FC = () => (
    <BrowserRouter>
        <AppLayout>
            <Switch>
                <GuestRoute exact path={'/'} component={Welcome} />
                <GuestRoute exact path={'/select_service'} component={SelectService} />
                <GuestRoute exact path={'/login'} component={LoginScreen} />
                <Route exact path={'/banned'} component={BannedScreen} />
                <Route exact path={'/logout'} component={LogoutScreen} />
                <Route exact path={'/expired'} component={ExpiredScreen} />
                <GuestRoute exact path={'/register'} component={Register} />
                <Route exact path={'/register/information'} component={RegisterInformation} />
                <Route exact path={'/register/confirm'} component={RegisterConfirm} />
                <Route exact path={'/register/success'} component={RegisterSuccess} />
                <GuestRoute exact path={'/password/forgot'} component={ForgotPassword} />
                <Route exact path={'/password/reset'} component={ResetPassword} />
                <SwitchRoute exact path={'/home'} component={HomeTab}
                    guestComponent={GuestHomeTab} />
                <Route exact path={'/event'} component={EventTab} />
                <PrivateRoute exact path={'/purchase'} component={PurchaseSelect} />
                <PrivateRoute exact path={'/purchase/next'} component={PurchaseSelectNext} />
                <PrivateRoute exact path={'/purchase/credit'} component={PurchaseMemberCredit} />
                <PrivateRoute exact path={'/purchase/bank'} component={SubmitBankTransaction} />
                <Route exact path={'/payment/success'} component={PaymentSuccess} />
                <Route exact path={'/payment/cancel'} component={PaymentCancel} />
                <Route exact path={'/video'} component={VideoTab} />
                <Route exact path={'/categories/:id'} component={CategoryPage} />
                <Route exact path={'/videos/:id'} component={VideoPage} />
                <Route exact path={'/events/:id'} component={EventPage} />
                <Route exact path={'/events/:id/confirm'} component={EventPaymentConfirm} />
                <Route exact path={'/events/:id/payment'} component={EventPaymentExec} />
                <GuestRoute exact path={'/events/:id/payment/param'} component={EventPaymentSetParam} />
                <SwitchRoute exact path={'/sns'} component={SnsTab}
                    guestComponent={GuestSnsTab} />
                <PrivateRoute exact path={'/sns/chat_groups'} component={ChatGroupTabPage} />
                <PrivateRoute exact path={'/sns/chat_groups/:id'} component={ChatGroupThreadView} />
                <PrivateRoute exact path={'/sns/create_chat_group'} component={ChatGroupCreate} />
                <PrivateRoute exact path={'/sns/apply_chat_groups/:id'} component={ApplyChatGroup} />
                <PrivateRoute exact path={'/sns/chat_groups/:id/users'} component={ChatGroupUserListPage} />
                <PrivateRoute exact path={'/sns/reactions/:id'} component={ChatMessageReactionListPage} />
                <PrivateRoute exact path={'/sns/chat_messages/:id'} component={ChatMessageDetail} />
                <PrivateRoute exact path={'/sns/users/:id?'} component={ChatProfilePage} />
                <PrivateRoute exact path={'/sns/profile/edit'} component={ChatProfileEdit} />
                <PrivateRoute exact path={'/videos/:id/exam'} component={ExamPage} />
                <SwitchRoute exact path={'/account'} component={AccountTab}
                    guestComponent={GuestAccountTab} />
                <PrivateRoute exact path={'/payment/change'} component={ChangeCredit} />
                <PrivateRoute exact path={'/payment/change/exec'} component={ChangeCreditExec} />
                <PrivateRoute exact path={'/user/update'} component={UpdateUserInformation} />
                <Route exact path={'/notices'} component={NotifyListPage} />
                <Route exact path={'/event_notices'} component={NotifyListPage} />
                <PrivateRoute exact path={'/video_watch_histories'} component={VideoWatchHistoryPage} />
                {/* <PrivateRoute exact path={'/qualification/:id/renew'} component={QualificationRenew}/> */}
                <Route exact path={'/guide'} component={Help} />
                <Route exact path={'/contact'} component={Contact} />
                <Route exact path={'/contact/confirm'} component={ContactConfirm} />
                <Route exact path={'/contact/success'} component={ContactSuccess} />
                <Route exact path={'/agreement'} component={Agreement} />
                <Route exact path={'/guide/agreement'} component={AgreementPage} />
                <Route exact path={'/guide/event'} component={EventGuide} />
                <Route exact path={'/guide/sns'} component={SnsGuide} />
                <Route exact path={'/guide/show/:fileName'} component={GuideShow} />
                <Route exact path={''} component={NotFound} />
            </Switch>
        </AppLayout>
        <Analytics />
    </BrowserRouter>
);

export default Router;