import EventType from "../interfaces/EventType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import {RootState} from "../store";

export type EventDetailState = {
    item: EventType | null;
    isFetching: boolean;
}

const initialState: EventDetailState = {
    item: null,
    isFetching: false
}

export const fetchEventDetail = createAsyncThunk<FetchDetailReturnType<EventType>, any>(
    'event.detail',
    async (param, thunk) => {
        const {serial_number} = thunk.getState() as RootState;
        const {data} = await axios.get(`events/${param}`, {
            headers: {
                'X-Device-ID': serial_number.item.device_code
            }
        });
        return data as FetchDetailReturnType<EventType>;
    }
)

export const eventDetailSlice = createSlice({
    name: 'event_detail',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchEventDetail.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                item: action.payload.data
            }))
            .addCase(fetchEventDetail.rejected, state => ({
                ...state,
                isFetching: false,
            }))
            .addCase(fetchEventDetail.pending, state => ({
                ...state,
                isFetching: true,
                item: null
            }))
    }
})