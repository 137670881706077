import React, { useState } from 'react';
import QualificationType from "../../interfaces/QualificationType";
import styled from "styled-components";
import QualificationSelect from "./QualificationSelect";
import VideoSearchBox from "./VideoSearchBox";
import VideoSearchText from "./VideoSearchText";
import QualificationDetail from "./QualificationDetail";
import HistoryButton from "./HistoryButton";
import IntroduceQualification from "./IntroduceQualification";

const ListHeader = styled.div`

`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const Flex = styled.div`
  width: 100%;
  max-width: 900px;

  @media screen and (min-width: 480px) {
    display: flex;
    flex-direction: row;
    margin: 24px auto 32px;
  }
`;

interface Props {
    qualifications: QualificationType[];
    selectQualification?: QualificationType;
    lastSearchText: string;

    onSelectQualification: (qualification: QualificationType) => void;
    onSearch: (text: string) => void;
    onPressHistory: () => void;
}

const VideoListHeader: React.FC<Props> = (
    { selectQualification, lastSearchText, qualifications, onSelectQualification, onSearch, onPressHistory }
) => {

    const [selectIntroduceQualification, setIntroduceQualification] = useState<QualificationType | null>(null);

    return (
        <ListHeader>
            <VideoSearchBox>
                <Content>
                    <QualificationSelect qualifications={qualifications}
                        selectQualification={selectQualification}
                        onSelect={id => onSelectQualification(id)}
                        setIntroduceQualification={setIntroduceQualification} />
                    <VideoSearchText onSearch={onSearch} initialSearchText={lastSearchText} />
                </Content>
            </VideoSearchBox>
            {lastSearchText.length > 0 ? <></> : (
                <Flex>
                    <QualificationDetail qualification={selectQualification} />
                    <HistoryButton onPress={onPressHistory} />
                </Flex>
            )}
            <IntroduceQualification
                qualification={selectIntroduceQualification}
                onClose={() => setIntroduceQualification(null)} />
        </ListHeader>
    );
};

export default VideoListHeader;
