import React from 'react';
import styled from "styled-components";
import {BodyText} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";
import registerEnable from "../../utils/registerEnable";

const Box = styled.div`
  display: flex;
  flex-direction: row;
`;

const Link = styled(TouchableOpacity)`
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.linkColor};
  border-bottom-style: solid;
  cursor: pointer;
`;

const LinkText = styled(BodyText)`
  color: ${({theme}) => theme.linkColor};
`;

const NormalText = styled(BodyText)`
  color: ${({theme}) => theme.textGrayColor};
`;

interface Props {
    errors: any;
    onPress: () => void;
}

const RegisterLink: React.FC<Props> = ({errors, onPress}) => {

    if (!errors.hasOwnProperty('id') || !(errors['id'] instanceof Array) || errors['id'].length === 0) {
        return <></>
    }

    if (errors['id'][0] !== '※こちらのIDはまだユーザー登録が完了していません。') {
        return <></>
    }

    if (registerEnable()) {
        return (
            <Box>
                <Link onClick={onPress}>
                    <LinkText>新規登録</LinkText>
                </Link>
                <NormalText>
                    はこちら
                </NormalText>
            </Box>
        );
    } else {
        return (
            <Box>
                <NormalText>
                    新規登録期間外です。
                </NormalText>
            </Box>
        )
    }
};

export default RegisterLink;
