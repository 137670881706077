import React from "react";
import {confirmAlert} from 'react-confirm-alert';
import ConfirmDialog from "../parts/ConfirmDialog";

export async function alert(message: string) {
    return await new Promise<void>(resolve => {
        confirmAlert({
            message,
            customUI: ({onClose}) => (
                <ConfirmDialog
                    message={message}
                    onClick={() => {
                        onClose();
                        resolve();
                    }}/>
            ),
            onClickOutside: () => resolve(),
        })
    })
}

export async function confirm(message: string) {
    return await new Promise<boolean>(resolve => {
        confirmAlert({
            message,
            customUI: ({onClose}) => (
                <ConfirmDialog
                    message={message}
                    onClick={() => {
                        onClose();
                        resolve(true);
                    }}
                    onCancel={() => {
                        onClose();
                        resolve(false);
                    }}/>
            ),
            onClickOutside: () => resolve(false),
        })
    })
}