import ChatMessageType from "../interfaces/ChatMessageType";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type ChatMessageDetailState = {
    item: ChatMessageType | null;
    isFetching: boolean;
}

const initialState: ChatMessageDetailState = {
    item: null,
    isFetching: false
}

export const fetchChatMessageDetail = createAsyncThunk<FetchDetailReturnType<ChatMessageType>, any>(
    'chat_message_detail/fetch',
    async (arg) => {
        const {data} = await axios.get(`chat_messages/${arg}`);
        return data as FetchDetailReturnType<ChatMessageType>;
    }
)

export const chatMessageDetailSlice = createSlice({
    name: 'chat_message_detail',
    initialState,
    reducers: {
        updateItem: (state, action: PayloadAction<ChatMessageType>) => ({
            ...state,
            item: action.payload
        })
    },
    extraReducers: builder => {
        builder
            .addCase(fetchChatMessageDetail.fulfilled, (state, action) => ({
                ...state,
                item: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchChatMessageDetail.rejected, state => ({
                ...state,
                item: null,
                isFetching: false
            }))
            .addCase(fetchChatMessageDetail.pending, (state, action) => ({
                ...state,
                item: state.item?.id === action.meta.arg ? state.item : null,
                isFetching: true
            }))
    }
});

export const {updateItem} = chatMessageDetailSlice.actions;