import React from 'react';
import EventType from "../../interfaces/EventType";
import styled from "styled-components";
import SectionHeader from "./SectionHeader";
import EventOverviewItem from "./EventOverviewItem";

const Section = styled.div`
  padding: 0 16px;
  margin-top: 24px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface Props {
    items: EventType[];
    onPress: (event: EventType) => void;
}

const EventOverview: React.FC<Props> = ({items, onPress}) => {
    return (
        <Section>
            <SectionHeader title={'イベント'}/>
            <Horizontal>
                {items.map(item => (
                    <EventOverviewItem
                        key={item.id}
                        event={item} onPress={() => onPress(item)}/>
                ))}
            </Horizontal>
        </Section>
    )
};

export default EventOverview;
