import React from 'react';
import styled from "styled-components";
import {TitleText} from "./AppStyles";

const Label = styled.div`
  border-left-width: 4px;
  border-left-color: ${({theme}) => theme.mainColor};
  border-left-style: solid;
  padding-left: 8px;
`;

const LabelText = styled(TitleText)`
  color: ${({theme}) => theme.textColor};
  font-size: 20px;
  font-weight: bold;
`;

interface Props {
    title: string;
    mini?: boolean;
    className?: string;
    fontSize?: number;
}

const SectionLabel: React.FC<Props> = (props) => {
    return (
        <Label className={props.className}>
            <LabelText style={{fontSize: props.fontSize || (props.mini ? 16 : 20)}}>{props.title}</LabelText>
        </Label>
    )
};

export default SectionLabel;
