import React from "react";
import styled from "styled-components";
import configIcon from "../../assets/icons/config.png";
import {TitleTextBox} from "../AppStyles";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const Title = styled(TitleTextBox)`
  font-size: 18px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  margin-left: 8px;
`;

const ChatProfileEditHeader: React.FC = () => (
    <Header>
        <Icon src={configIcon}/>
        <Title>SNS交流プロフィール編集</Title>
    </Header>
);

export default ChatProfileEditHeader;