import React, {useEffect} from "react";
import styled from "styled-components";
import {TitleTextBox} from "../../parts/AppStyles";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";
import LoginButtons from "../../parts/SelectService/LoginButtons";
import BackButtonSection from "../../parts/SelectService/BackButtonSection";
import {useHistory} from 'react-router-dom';
import Footer from "../../parts/Footer";
import {Helmet} from "react-helmet-async";
import registerEnable from "../../utils/registerEnable";

const Flex = styled.div`
  flex: 1;
  justify-content: center;
`;

const Title = styled(TitleTextBox)`
  margin-top: 48px;
  font-size: 24px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  line-height: 32px;
  text-align: center;
`;

const SelectService: React.FC = () => {

    const history = useHistory();

    useEffect(() => {
        if (!registerEnable()) {
            history.replace('/login');
        }
    }, [history]);

    return (
        <>
            <Helmet>
                <title>ログイン</title>
            </Helmet>
            <NoAuthHeader/>
            <Flex>
                <Title>下記のうち、{'\n'}どちらかをお選びください</Title>
                <LoginButtons
                    onLoginPress={() => history.push('/login')}
                    onRegisterPress={() => history.push('/register')}/>
                <BackButtonSection onBackPress={() => history.goBack()}/>
            </Flex>
            <Footer/>
        </>
    )
};

export default SelectService;