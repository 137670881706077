import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type PaymentUserCreditState = {
    result?: object;
    requestStatus: RequestStatusType;
};

const initialState: PaymentUserCreditState = {
    result: undefined,
    requestStatus: 'init'
};

export const getPaymentUserCredit = createAsyncThunk<FetchDetailReturnType<object>>(
    'payment_user_credit',
    async () => {
        const {data} = await axios.post('user/payments/credit');
        return data as FetchDetailReturnType<object>;
    }
);

export const paymentUserCreditSlice = createSlice({
    name: 'payment_user_credit',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(getPaymentUserCredit.fulfilled, (state, action) => ({
                ...state,
                result: action.payload.data,
                requestStatus: 'success'
            }))
            .addCase(getPaymentUserCredit.pending, state => ({
                ...state,
                result: undefined,
                requestStatus: 'pending'
            }))
            .addCase(getPaymentUserCredit.rejected, state => ({
                ...state,
                result: undefined,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus} = paymentUserCreditSlice.actions;
