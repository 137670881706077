import React, {useCallback, useContext, useEffect} from 'react';
import ScreenView from '../../parts/ScreenView';
import styled, {ThemeContext} from "styled-components";
import FlatButton from "../../parts/FlatButton";
import {useDispatch} from "react-redux";
import ConfirmInformationBox from "../../parts/RegisterConfirm/ConfirmInformationBox";
import {execRegisterInformation, resetRequestStatus, setErrors} from '../../stores/register_information';
import {useSelector} from "../../store";
import StepHeader from "../../parts/Headers/StepHeader";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import UserType from "../../interfaces/UserType";
import * as toastr from "toastr";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";
import Footer from "../../parts/Footer";

const ButtonBox = styled.div`
  margin: 32px 16px 0;
  display: flex;
  flex-direction: row;
`;

const Button = styled(FlatButton)`
  flex: 1;
  margin: 0 8px;
`;

const RegisterConfirm: React.FC = () => {
    const theme = useContext(ThemeContext);

    const {register_information} = useSelector(state => ({
        register_information: state.register_information
    }));

    const dispatch = useDispatch();
    const history = useHistory();
    const {state: {user} = {user: undefined}} = useLocation<{ user: UserType; }>();

    const goBack = useCallback(() => {
        history.push('/register/information', {old: user!!});
    }, [history, user]);

    useEffect(() => {
        if (register_information.requestStatus === 'success') {
            history.push('/register/success');
            dispatch(resetRequestStatus());
        } else if (register_information.requestStatus === 'failed') {
            if (Object.keys(register_information.errors).length > 0) {
                toastr.error('入力内容をご確認ください。');
                goBack();
            } else {
                toastr.error('エラーが発生しました。');
            }
            dispatch(resetRequestStatus());
        }
    }, [dispatch, goBack, history, register_information.errors, register_information.requestStatus]);

    const onSubmit = async () => {
        dispatch(setErrors({}));
        dispatch(execRegisterInformation(user!!));
    };

    if (user === undefined) {
        return <Redirect to={'/register'}/>;
    }

    return (
        <>
            <NoAuthHeader/>
            <StepHeader title={'登録内容の確認'} step={'ステップ2/3'}/>
            <ScreenView scroll={true}>
                <ConfirmInformationBox
                    label={'名前'}
                    value={user.name}/>
                <ConfirmInformationBox
                    label={'かな'}
                    value={user.kana}/>
                <ConfirmInformationBox
                    label={'取得資格'}
                    value={user.qualifications_text || 'なし'}/>
                <ConfirmInformationBox
                    label={'生年月日'}
                    value={user.birthday}/>
                <ConfirmInformationBox
                    label={'職種'}
                    value={user.industry}/>
                <ConfirmInformationBox
                    label={'所属先(任意)'}
                    value={user.attachment}/>
                <ConfirmInformationBox
                    label={'郵便番号'}
                    value={user.postal}/>
                <ConfirmInformationBox
                    label={'住所'}
                    value={user.address}/>
                <ConfirmInformationBox
                    label={'メールアドレス'}
                    value={user.email}/>
                <ConfirmInformationBox
                    label={'電話番号 ※ハイフンなしで入力してください'}
                    value={user.tel}/>
                <ConfirmInformationBox
                    label={'パスワード'}
                    value={'********'}/>
                <ButtonBox>
                    <Button text={'修正する'}
                            leftAllow
                            textColor={theme.mainColor}
                            backgroundColor={theme.whiteColor}
                            borderColor={theme.mainColor}
                            disabled={register_information.requestStatus === 'pending'}
                            onPress={() => goBack()}/>
                    <Button text={'登録'}
                            rightAllow
                            disabled={register_information.requestStatus === 'pending'}
                            onPress={onSubmit}/>
                </ButtonBox>
            </ScreenView>
            <Footer/>
        </>
    );
};

export default RegisterConfirm;
