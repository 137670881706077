import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../http/axios";
import {fetchChatGroupDetail} from "./chat_group_detail";

export type ChatGroupAdminState = {
    requestStatus: RequestStatusType;
}

const initialState: ChatGroupAdminState = {
    requestStatus: 'init'
};

type Request = {
    chatGroupId: any,
    userId: string
}

export const transferChatGroupAdmin = createAsyncThunk<void, Request>(
    'transfer_chat_group_admin',
    async (param, thunk) => {
        await axios.post(`chat_groups/${param.chatGroupId}/admin/${param.userId}`);
        thunk.dispatch(fetchChatGroupDetail(param.chatGroupId));
    }
);

export const chatGroupAdminSlice = createSlice({
    name: 'chat_group_admin',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
    },
    extraReducers: builder => {
        builder
            .addCase(transferChatGroupAdmin.fulfilled, (state, action) => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(transferChatGroupAdmin.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(transferChatGroupAdmin.rejected, (state, action) => ({
                ...state,
                requestStatus: 'failed',
            }))
    }
})

export const {resetRequestStatus} = chatGroupAdminSlice.actions