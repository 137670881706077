import React, {useContext} from 'react';
import ChatGroupType from "../../interfaces/ChatGroupType";
import styled, {ThemeContext} from "styled-components";
import FlatButton from "../FlatButton";
import SectionLabel from "../SectionLabel";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import Image from "../Image";

const Section = styled.div`
  padding: 8px 0;
`;

const Label = styled(SectionLabel)`
  margin-top: 24px;
`;

const ValueText = styled(BodyTextBox)`
  margin-top: 8px;
  color: ${({theme}) => theme.textGrayColor};
`;

const UserBox = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserIcon = styled(Image)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const UserInformation = styled.div`

`;

const UserName = styled(TitleTextBox)`
  color: ${({theme}) => theme.textColor};
`;

const UserIndustry = styled(TitleTextBox)`
  color: ${({theme}) => theme.textGrayColor};
  margin-top: 4px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApplyButton = styled(FlatButton)`
  margin: 24px 16px 0;
  width: 100%;
  max-width: 768px;
`;

interface Props {
    chatGroup: ChatGroupType;
    onPressApply: () => void;
    isFetching: boolean;
}

const ApplyChatGroupInformation: React.FC<Props> = ({chatGroup, isFetching, onPressApply}) => {
    const theme = useContext(ThemeContext);

    return (
        <Section>
            <Label title={'概要・情報'} mini/>
            <ValueText>{chatGroup.description}</ValueText>
            <Label title={'履歴'} mini/>
            <ValueText>このグループは{chatGroup.created_at}に作成されました。</ValueText>
            <Label title={'管理者'} mini/>
            <UserBox>
                {chatGroup.admin_user.profile_url ? (
                    <UserIcon src={chatGroup.admin_user.profile_url}/>
                ) : <></>}
                <UserInformation>
                    <UserName>{chatGroup.admin_user.nickname}</UserName>
                    <UserIndustry>{chatGroup.admin_user.industry}</UserIndustry>
                </UserInformation>
            </UserBox>
            <Flex>
                <ApplyButton text={'このグループに参加申請をする'}
                             rightAllow
                             disabled={isFetching}
                             onPress={onPressApply}
                             textColor={theme.whiteColor}
                             backgroundColor={theme.subColor}/>
            </Flex>
        </Section>
    );
};

export default ApplyChatGroupInformation;
