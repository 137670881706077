import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type PaymentUserCreditChangeState = {
    result?: object;
    requestStatus: RequestStatusType;
};

const initialState: PaymentUserCreditChangeState = {
    result: undefined,
    requestStatus: 'init'
};

export const getPaymentUserCreditChange = createAsyncThunk<FetchDetailReturnType<object>>(
    'payment_user_credit_change',
    async () => {
        const {data} = await axios.put('user/payments/credit');
        return data as FetchDetailReturnType<object>;
    }
);

export const paymentUserCreditChangeSlice = createSlice({
    name: 'payment_user_credit_change',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(getPaymentUserCreditChange.fulfilled, (state, action) => ({
                ...state,
                result: action.payload.data,
                requestStatus: 'success'
            }))
            .addCase(getPaymentUserCreditChange.pending, state => ({
                ...state,
                result: undefined,
                requestStatus: 'pending'
            }))
            .addCase(getPaymentUserCreditChange.rejected, state => ({
                ...state,
                result: undefined,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus} = paymentUserCreditChangeSlice.actions;
