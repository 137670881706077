import React, {useCallback, useEffect, useState} from 'react';
import PageHeader from "../../parts/Headers/PageHeader";
import {useDispatch} from "react-redux";
import {fetchVideos} from "../../stores/video";
import {useSelector} from "../../store";
import VideoListItem from "../../parts/CategoryPage/VideoListItem";
import CategoryHeader from "../../parts/CategoryPage/CategoryHeader";
import styled from "styled-components";
import VideoType from "../../interfaces/VideoType";
import CategoryType from "../../interfaces/CategoryType";
import CategoryChildItem from "../../parts/CategoryPage/CategoryChildItem";
import CategoryPageEmpty from "../../parts/CategoryPage/CategoryPageEmpty";
import {useHistory, useParams} from 'react-router-dom';
import {fetchCategoryDetail} from "../../stores/category_detail";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import NotFound from "../NotFound";
import {Helmet} from "react-helmet-async";

const Screen = styled.div`
  flex: 1;
  background-color: #FFF;
`;

const CategoryPage: React.FC = () => {

    const {video, detail} = useSelector(state => ({
        video: state.video,
        detail: state.category_detail,
    }));

    const {id: categoryId} = useParams<{ id: string }>();

    const history = useHistory();
    const dispatch = useDispatch();
    const [listItems, setListItems] = useState<(CategoryType | VideoType)[]>([]);

    useEffect(() => {
        dispatch(fetchCategoryDetail(categoryId));
    }, [categoryId, dispatch]);

    useEffect(() => {
        if (detail.item === null) {
            return;
        }
        dispatch(fetchVideos(detail.item.id));
    }, [detail.item, dispatch]);

    useEffect(() => {
        if (detail.item === null) {
            return;
        }
        setListItems([...detail.item.children, ...video.items]);
    }, [detail.item, video.items]);

    const onVideoPress = useCallback((item: VideoType) => {
        const index = video.items.findIndex(v => v.id === item.id);
        history.push(`/videos/${item.id}`, {
            nextVideos: video.items.filter((v, i) => i > index),
        });
    }, [history, video.items]);

    if (detail.isFetching) {
        return <WithLoadingScreen loading={true}/>
    }

    if (detail.item === null) {
        return <NotFound/>
    }

    const category = detail.item;

    return (
        <Screen>
            <Helmet>
                <title>{category.name}</title>
            </Helmet>
            <PageHeader title={category.name}/>
            <CategoryHeader category={category}/>

            {listItems.length === 0 ? (
                <CategoryPageEmpty/>
            ) : (
                listItems.map(item => item.hasOwnProperty('exam_id') ? (
                    <VideoListItem
                        key={`videos.${item.id}`}
                        video={item as VideoType}
                        onPress={() => onVideoPress(item as VideoType)}/>
                ) : (
                    <CategoryChildItem
                        key={`categories.${item.id}`}
                        category={item as CategoryType}
                        index={category.children.indexOf(item as CategoryType)}
                        parent={category}
                        onPress={() => history.push(`/categories/${item.id}`)}/>
                ))
            )}
        </Screen>
    );
};

export default CategoryPage;
