import React, {useEffect} from 'react';
import {useSelector} from "../store";
import {useDispatch} from "react-redux";
import {sendDeviceCode} from "../stores/serial_number";

const SerialNumberResolver: React.FC = () => {
    const {auth} = useSelector(state => ({
        serial_number: state.serial_number,
        auth: state.auth,
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sendDeviceCode());
    }, [auth.authenticated, dispatch]);

    return <></>;
};

export default SerialNumberResolver;
