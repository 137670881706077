import React from 'react';
import styled from "styled-components";
import {BodyTextBox} from "../../parts/AppStyles";
import FlatButton from "../../parts/FlatButton";
import {useHistory} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: white;
  padding: 16px;
`;

const Information = styled(BodyTextBox)`
  text-align: center;
  font-size: 16px;
`;

const LoginButton = styled(FlatButton)`
  max-width: 400px;
  margin: 32px auto;
`;

const GuestSnsTab: React.FC = () => {
    const history = useHistory();
    return (
        <>
            <Helmet>
                <title>SNS交流</title>
            </Helmet>
            <Screen>
                <Information>
                    このページは会員専用です。{'\n'}
                    是非この機会にご利用ください！
                </Information>
                <LoginButton text={'ログイン画面に戻る'}
                             onPress={() => history.push('/')}/>
            </Screen>
        </>
    );
};

export default GuestSnsTab;
