import React, { CSSProperties, useContext } from 'react';
import styled, { ThemeContext } from "styled-components";
import { TitleText } from "./AppStyles";
import nl2br from "../utils/nl2br";
import ArrowIcon from "./ArrowIcon";

const Button = styled.button`
  outline: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
`;

const ButtonBase = styled.div`
  min-height: 20px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;

  @media screen and (min-width: 480px) {
    min-height: 40px;
  }
`;

const ButtonText = styled(TitleText)`
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  font-size: 13px;
  flex: 1;

  @media screen and (min-width: 480px) {
    font-size: 18px;
  }
`;

const LeftAllowIcon = styled(ArrowIcon)`
  position: absolute;
  left: 16px;
  top: auto;
  bottom: auto;
`;

const RightAllowIcon = styled(ArrowIcon)`
  position: absolute;
  right: 16px;
  top: auto;
  bottom: auto;
`;

interface Props {
    text: string;
    textColor?: string;
    backgroundColor?: string;
    borderColor?: string;
    onPress: () => void;
    disabled?: boolean;
    disableColor?: string;
    disableTextColor?: string;
    disableBorderColor?: string;
    rightAllow?: boolean;
    leftAllow?: boolean;
    borderRadius?: number;
    className?: string;
    style?: CSSProperties;
    innerStyle?: CSSProperties;
}

const FlatButton: React.FC<Props> = (props) => {
    const theme = useContext(ThemeContext);

    const {
        text,
        textColor = theme.whiteColor,
        backgroundColor = theme.mainColor,
        borderColor,
        onPress,
        className,
        disabled = false,
        disableColor = theme.disableColor,
        disableTextColor = theme.whiteColor,
        disableBorderColor = disableColor,
        rightAllow = false,
        leftAllow = false,
        borderRadius = 1000,
        style,
        innerStyle,
    } = props;

    return (
        <Button onClick={onPress} className={className} disabled={disabled} style={style}>
            <ButtonBase style={{
                backgroundColor: disabled ? disableColor : backgroundColor,
                borderColor: disabled ? disableBorderColor : borderColor,
                borderWidth: borderColor === undefined ? 0 : 1,
                borderRadius: borderRadius,
                borderStyle: "solid",
                padding: innerStyle?.padding
            }}>
                {leftAllow ? (
                    <LeftAllowIcon color={disabled ? disableTextColor : textColor} size={16} />
                ) : <></>}
                <ButtonText style={{ color: disabled ? disableTextColor : textColor }}>{nl2br(text)}</ButtonText>
                {rightAllow ? (
                    <RightAllowIcon color={disabled ? disableTextColor : textColor} size={16} />
                ) : <></>}
            </ButtonBase>
        </Button>
    )
};

export default FlatButton;
