import React, {useMemo} from 'react';
import EventType from "../../interfaces/EventType";
import styled from "styled-components";
import {useSelector} from "../../store";
import {BodyTextBox, TitleText, TitleTextBox} from "../AppStyles";
import Image from "../Image"

const Section = styled.div`
  margin-top: 8px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.borderColor};
  padding: 16px 0;
`;

const Thumbnail = styled(Image)`
  width: 150px;
  margin-right: 8px;
`;

const Title = styled(TitleTextBox)`
  font-size: 16px;
  font-weight: bold;
`;

const FlexList = styled(Flex)`
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;

const Label = styled(TitleTextBox)`
  font-size: 16px;
  flex: 1;
`;

const Value = styled(BodyTextBox)`
  font-size: 18px;
  font-weight: bold;
  color: ${({theme}) => theme.textColor};
  text-align: right;
`;

const BigValue = styled(Value)`
  font-size: 24px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
`;

const FlexInstructor = styled(Flex)`
  align-items: center;
`;

const InstructorImage = styled(Image)`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;

const InstructorName = styled(TitleText)`
  font-size: 16px;
  margin-left: 16px;
  color: ${({theme}) => theme.textColor};
  font-weight: bold;
`;

interface Props {
    event: EventType;
}

const EventInformation: React.FC<Props> = ({event}) => {

    const {auth} = useSelector(state => ({
        auth: state.auth
    }));
    const priceText = useMemo(() => {
        if (auth.authenticated) {
            return event.price.toLocaleString();
        } else {
            return event.non_member_price.toLocaleString();
        }
    }, [event, auth.authenticated]);

    return (
        <Section>
            <Flex>
                {event.thumbnail_url !== null ? (
                    <Thumbnail src={event.thumbnail_url}/>
                ) : <></>}
                <Title>{event.name}</Title>
            </Flex>
            {event.instructor ? (
                <FlexInstructor>
                    <InstructorImage src={event.instructor.file_url}/>
                    <InstructorName>{event.instructor.name} 先生</InstructorName>
                </FlexInstructor>
            ) : <></>}
            <FlexList>
                <Label>日時</Label>
                <Value>
                    {event.published_at} {event.start_time}-{event.end_time}
                    {event.start_time !== event.opening_time ? (
                        <><br/>(開場時間 {event.opening_time})</>
                    ) : <></>}
                </Value>
            </FlexList>
            <FlexList>
                <Label>開催方法</Label>
                <Value>
                    {event.event_type}
                </Value>
            </FlexList>
            <FlexList>
                <Label>場所</Label>
                <Value>
                    {event.place}<br/>
                    {event.place_address}
                </Value>
            </FlexList>
            {event.place_tel ? (
                <FlexList>
                    <Label>電話番号</Label>
                    <Value>
                        {event.place_tel}
                    </Value>
                </FlexList>
            ) : <></>}
            {event.place_person ? (
                <FlexList>
                    <Label>担当者名</Label>
                    <Value>
                        {event.place_person}
                    </Value>
                </FlexList>
            ) : <></>}
            {event.place_email ? (
                <FlexList>
                    <Label>メール</Label>
                    <Value>
                        {event.place_email}
                    </Value>
                </FlexList>
            ) : <></>}
            {event.place_organizer ? (
                <FlexList>
                    <Label>主催者</Label>
                    <Value>
                        {event.place_organizer}
                    </Value>
                </FlexList>
            ) : <></>}
            <FlexList>
                <Label>取得単位</Label>
                <Value>
                  {event.qualifications.map(qualification => (
                    <div key={qualification.qualification_id}>
                      {qualification.qualification_name} {qualification.unit}単位
                    </div>
                  ))}
                </Value>
            </FlexList>
            <FlexList>
                <Label>参加費</Label>
                <BigValue>
                    {priceText === '0' ? '無料' : `${priceText} 円`}
                </BigValue>
            </FlexList>
        </Section>
    )
};

export default EventInformation;
