import React from 'react';
import QualificationType from "../../interfaces/QualificationType";
import styled from "styled-components";
import UnitPieChart from "./UnitPieChart";
import {BodyText, TitleText} from "../AppStyles";

const Section = styled.div`
  background-color: ${({theme}) => theme.accentColor};
  padding: 8px 32px;
  margin: 16px 8px 0;
  border-radius: 16px;
  box-shadow: 0 1px 2px #C1C1C1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  @media screen and (min-width: 480px) {
    margin: 0;
  }
`;

const Label = styled(TitleText)`
  font-size: 14px;
  flex: 1;
`;

const PercentText = styled(BodyText)`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const MiniText = styled(BodyText)`
  font-size: 14px;
  font-weight: normal;
`;

interface Props {
    qualification?: QualificationType;
}

const QualificationDetail: React.FC<Props> = ({qualification}) => {
    if (qualification === undefined || !qualification.is_owned) {
        return <></>
    }

    return (
        <Section>
            <Label>資格更新までの達成率</Label>
            <PercentText>
                {Math.floor(qualification.has_unit / qualification.require_unit * 100)} <MiniText>%</MiniText>
            </PercentText>
            <UnitPieChart value={qualification.has_unit} max={qualification.require_unit}/>
        </Section>
    )
};

export default QualificationDetail;
