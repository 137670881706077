import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type UnreadNoticeState = {
    count: number;
}

const initialState: UnreadNoticeState = {
    count: 0,
};

export const fetchUnreadNotice = createAsyncThunk<FetchDetailReturnType<number>>(
    'fetch/unread_notice',
    async () => {
        const {data} = await axios.get('unread_notices');
        return data as FetchDetailReturnType<number>;
    }
);

export const unreadNoticeSlice = createSlice({
    name: 'unread_notice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUnreadNotice.fulfilled, (state, action) => ({
                count: action.payload.data,
            }));
    }
});