import React from "react";
import ChatMessageType from "../../interfaces/ChatMessageType";
import styled from "styled-components";
import backArrow from "../../assets/icons/back_arrow.png";
import {ContentWrap, TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";
import Image from "../Image";
import 'moment/locale/ja';
import daysAgo from "../../utils/daysAgo";
import ChatContextMenu from "../ChatMessage/ChatContextMenu";

const Header = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  padding: 16px 8px;

  @media screen and (min-width: 480px) {
    padding: 16px 0;
  }
`;

const Flex = styled(ContentWrap)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BackButton = styled(TouchableOpacity)`
  margin-left: 8px;
  margin-right: 16px;
`;

const BackButtonImage = styled.img`
`;

const UserIcon = styled(Image)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const UserName = styled(TitleTextBox)`
  color: ${({theme}) => theme.textColor};
  font-weight: bold;
  margin-left: 16px;
`;

const UserIndustry = styled(TitleTextBox)`
  color: ${({theme}) => theme.textGrayColor};
  margin-left: 16px;
`;

const DateTime = styled.div`
  color: #616161;
  font-size: 12px;
  margin-left: auto;
  margin-right: 16px;
`;

interface Props {
    message: ChatMessageType;
    onEdit: (message: ChatMessageType) => void;
    onDelete: (message: ChatMessageType) => void;
    onPin: (message: ChatMessageType) => void;
    onReport: (message: ChatMessageType) => void;
    onGoBack: () => void;
}

const ChatMessageDetailHeader: React.FC<Props> = ({message, onEdit, onDelete, onPin, onReport, onGoBack}) => {

    return (
        <Header>
            <Flex>
                <BackButton onClick={onGoBack}>
                    <BackButtonImage src={backArrow} alt={'戻る'}/>
                </BackButton>
                {message.user.profile_url ? (
                    <UserIcon src={message.user.profile_url}/>
                ) : <></>}
                <UserName>{message.user.nickname}</UserName>
                <UserIndustry>{message.user.industry}</UserIndustry>
                <DateTime>{daysAgo(message.created_at_timestamp)}</DateTime>
                <ChatContextMenu message={message}
                                 onEdit={onEdit}
                                 onDelete={onDelete}
                                 onPin={onPin}
                                 onReport={onReport}/>
            </Flex>
        </Header>
    );
};

export default ChatMessageDetailHeader;