import React, {useContext, useMemo} from 'react';
import {ThemeContext} from "styled-components";

interface Props {
    html: string | null;
}

const EventHtmlView: React.FC<Props> = ({html}) => {

    const theme = useContext(ThemeContext);

    const styles = useMemo(() => `
    <style>
    .__html_view {
        font-size: 14px;
        font-family: "Helvetica Neue",
            Arial,
            "Hiragino Kaku Gothic ProN",
            "Hiragino Sans",
            Meiryo,
            sans-serif;
        padding: 10px;
        background-color: ${theme.lightBackgroundColor};
        white-space: pre-wrap;
    }
    .__html_view h2 {
        border-left: 2px solid ${theme.mainColor};
        padding-left: 8px;
        font-size: 20px;
        font-weight: bold;
    }
    .__html_view img {
        max-width: 100%;
    }
    </style>
    `, [theme]);

    const replaceText = useMemo(() => {
        const pattern = /^((?:https?|ftp):\/\/[-_.!~*'()a-zA-Z0-9;\/?:@&=+$,%#]+)/mg;
        const replace = '<a href="$1" target="_blank" rel="noreferrer">$1</a>';
        return html?.replace(pattern, replace);
    }, [html]);

    if (html == null) {
        return <></>
    }

    return (
        <div dangerouslySetInnerHTML={{__html: `${styles}<div class="__html_view">${replaceText}</div>`}}/>
    );
};

export default EventHtmlView;
