import React from "react";
import styled from "styled-components";

const Part = styled.div`
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  margin-left: 8px;
`;

const MoreLoading: React.FC = () => {
    return (
        <Part>
            <i className={'fa fa-spin fa-spinner'}/>
            <Text>読み込み中...</Text>
        </Part>
    )
};

export default MoreLoading;