import React from 'react';
import ChatMessageType from "../../interfaces/ChatMessageType";
import styled from "styled-components";
import ChatMessageImage from "./ChatMessageImage";
import ChatMessageText from "./ChatMessageText";
import CommentMessageHeader from "./CommentMessageHeader";
import ReactionType from "../../interfaces/ReactionType";
import ReactionView from "./ReactionView";

const Wrapper = styled.div<{ lastItem: boolean }>`
  border-bottom: ${({lastItem, theme}) => lastItem ? 'none' : `1px solid ${theme.borderColor}`};
`;

const Section = styled.div<{ lastItem: boolean }>`
  padding: ${({lastItem}) => lastItem ? '0 0 16px 8px' : '0 0 0 8px'};
  margin: ${({lastItem}) => lastItem ? '16px 0 0 0' : '16px 0'};
`;

const Padding = styled.div`
  padding-left: 40px;
`;

const ReactionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
`;

interface Props {
    item: ChatMessageType;
    lastItem: boolean;
    onEdit: (message: ChatMessageType) => void;
    onDelete: (message: ChatMessageType) => void;
    onPin: (message: ChatMessageType) => void;
    onReport: (message: ChatMessageType) => void;
    onReaction: (reaction?: ReactionType) => void;
    onShowReactions: () => void;
}

const ChatMessageChildItem: React.FC<Props> = ({
                                                   item,
                                                   lastItem,
                                                   onEdit,
                                                   onDelete,
                                                   onPin,
                                                   onReport,
                                                   onReaction,
                                                   onShowReactions
                                               }) => {
    return (
        <Wrapper lastItem={lastItem}>
            <Section lastItem={lastItem}>
                <CommentMessageHeader message={item}
                                      onEdit={onEdit}
                                      onDelete={onDelete}
                                      onPin={onPin}
                                      onReport={onReport}/>
                <Padding>
                    <ChatMessageText message={item}/>
                    <ChatMessageImage message={item}/>
                </Padding>
                <ReactionBox>
                    <ReactionView message={item}
                                  onReaction={onReaction}
                                  onShowReactions={onShowReactions}/>
                </ReactionBox>
            </Section>
        </Wrapper>
    )
};

export default ChatMessageChildItem;
