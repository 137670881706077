import React, {HTMLProps, useContext} from 'react';
import styled, {ThemeContext} from "styled-components";

const Bar = styled.div`
  height: 3px;
  width: 100%;
`;

const ActiveTint = styled.div`
  height: 3px;
`;

interface Props extends HTMLProps<HTMLDivElement> {
    max: number;
    value: number;
    activeColor?: string;
    inactiveColor?: string;
}

const ProgressBar: React.FC<Props> = (props) => {
    const theme = useContext(ThemeContext);
    const {
        activeColor = theme.mainColor,
        inactiveColor = theme.disableColor,
        max,
        value,
        className
    } = props;
    return (
        <Bar className={className} style={{backgroundColor: inactiveColor}}>
            <ActiveTint style={{
                backgroundColor: activeColor,
                width: `${value / max * 100}%`
            }}/>
        </Bar>
    )
};

export default ProgressBar;
