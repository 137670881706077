import React, {ReactElement} from 'react';
import styled from "styled-components";
import {TitleText} from "../AppStyles";

const Box = styled.div`
  border-bottom-color: ${({theme}) => theme.whiteColor};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 20px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Title = styled(TitleText)`
  margin-left: 16px;
  color: ${({theme}) => theme.whiteColor};
  font-weight: bold;
  font-size: 16px;
`;

interface Props {
    title: string;
    onPress: () => void;
    icon: ReactElement;
}

const DrawerItem: React.FC<Props> = ({title, onPress, icon}) => {
    return (
        <Box onClick={onPress}>
            {icon}
            <Title>{title}</Title>
        </Box>
    )
};

export default DrawerItem;
