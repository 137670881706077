import React from 'react';
import styled from "styled-components";
import ChatMessageType from "../interfaces/ChatMessageType";
import {TitleTextBox} from "./AppStyles";
import AutoLinkText from "./AutoLinkText";

const ChatMessageBox = styled.div`
  border: 1px solid #212121;
  border-radius: 2px;
  padding: 8px;
`;

const UserName = styled(TitleTextBox)`
  font-size: 18px;
`;

const Industry = styled(TitleTextBox)`
  font-size: 14px;
  color: ${({theme}) => theme.textGrayColor};
`;

const Message = styled.div`
  margin-top: 16px;
`;

interface Props {
    item: ChatMessageType;
}

const ChatMessageView: React.FC<Props> = ({item}) => {
    return (
        <ChatMessageBox>
            <UserName>{item.user.nickname}</UserName>
            <Industry>{item.user.industry}</Industry>
            <Message>
                <AutoLinkText text={item.text}/>
            </Message>
        </ChatMessageBox>
    )
};

export default ChatMessageView;
