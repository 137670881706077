import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import {TitleText} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";
import TouchableOpacity from "../TouchableOpacity";
import buttonImage from '../../assets/icons/audio_only.png';

const Wrapper = styled.div`
  margin: 16px auto 0;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;

  @media screen and (min-width: 480px) {
    margin: 30px auto 14px;
  }
`;

const ButtonBox = styled(TouchableOpacity)`
  background-color: ${({theme}) => theme.mainColor};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin: 0 16px;
`;

const IconImage = styled.img`
  width: 16px;
  height: 14px;
`;

const Text = styled(TitleText)`
  color: ${({theme}) => theme.whiteColor};
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  margin-left: 8px;
`;

interface Props {
    audioOnly: boolean;
    onPress: () => void;
}

const AudioOnlyButton: React.FC<Props> = ({audioOnly, onPress}) => {
    const theme = useContext(ThemeContext);
    return (
        <Wrapper>
            <ButtonBox onClick={onPress}>
                <IconImage src={buttonImage}/>
                <Text>{audioOnly ? '動画を視聴' : '音声のみ視聴'}</Text>
                <ArrowIcon size={14} color={theme.whiteColor}/>
            </ButtonBox>
        </Wrapper>
    )
};

export default AudioOnlyButton;
