import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {fetchNotices, setAllReadNotice} from "../../stores/notice";
import NoticeListItem from "../../parts/NoticeListPage/NoticeListItem";
import NoticeType from "../../interfaces/NoticeType";
import styled from "styled-components";
import PageHeader from "../../parts/Headers/PageHeader";
import NoticeListHeader from "../../parts/NoticeListPage/NoticeListHeader";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import bellIcon from '../../assets/icons/bell.png';
import InfiniteScroll from "react-infinite-scroll-component";
import NotifyDetail from "./NotifyDetail";
import {Helmet} from "react-helmet-async";
import {ContentWrap} from "../../parts/AppStyles";
import MoreLoading from "../../parts/MoreLoading";
import {useLocation} from "react-router-dom";
import query from "query-string";
import {fetchEventNotices} from "../../stores/event_notice";

const Screen = styled.div`
  padding: 0;
  background-color: #FFF;
  flex: 1;
`;

const NotifyListPage: React.FC = () => {

    const {notice, event_notice} = useSelector(state => ({
        notice: state.notice,
        event_notice: state.event_notice,
    }));

    const dispatch = useDispatch();
    const location = useLocation();
    const [selectNotice, setSelectNotice] = useState<NoticeType<any> | null>(null);

    const execFetch = useCallback((arg: {page: number}) => {
        if (location.pathname === '/event_notices') {
            dispatch(fetchEventNotices(arg));
        } else{
            dispatch(fetchNotices(arg));
        }
    }, [dispatch, location.pathname]);

    const _notices = useMemo(() => {
        if (location.pathname === '/event_notices') {
            return event_notice;
        } else {
            return notice;
        }
    }, [event_notice, location.pathname, notice]);

    useEffect(() => {
        execFetch({page: 1});
        dispatch(setAllReadNotice());
    }, [dispatch, execFetch]);

    const onEndReached = () => {
        if (_notices.lastRequestMeta === undefined) {
            return;
        }

        if (_notices.lastRequestMeta.last_page > _notices.lastRequestMeta.current_page) {
            execFetch({page: _notices.lastRequestMeta.current_page + 1});
        }
    };

    const hasMore = useMemo(() => {
        if (_notices.lastRequestMeta === undefined) {
            return false;
        }
        return _notices.lastRequestMeta.last_page > _notices.lastRequestMeta.current_page;
    }, [_notices.lastRequestMeta]);

    const onPressNotice = (notice: NoticeType<any>) => {
        setSelectNotice(notice);
    };

    return (
        <>
            <Helmet>
                <title>お知らせ</title>
            </Helmet>
            <WithLoadingScreen
                loading={_notices.isFetching && _notices.items.length === 0}>
                <PageHeader title={location.pathname === '/event_notices' ? 'イベントお知らせ' : 'お知らせ'}
                            iconSource={bellIcon}/>
                <Screen>
                    <ContentWrap>
                        <NoticeListHeader/>
                    </ContentWrap>
                    <InfiniteScroll
                        next={onEndReached}
                        hasMore={hasMore}
                        loader={<MoreLoading/>}
                        endMessage={<></>}
                        dataLength={_notices.items.length}>
                        {_notices.items.map(item => (
                            <NoticeListItem notice={item}
                                            key={item.id}
                                            onPress={() => onPressNotice(item)}/>
                        ))}
                    </InfiniteScroll>
                </Screen>
                <NotifyDetail notice={selectNotice}
                              onClose={() => setSelectNotice(null)}/>
            </WithLoadingScreen>
        </>
    );
};

export default NotifyListPage;
