import React, {FormEvent, useCallback, useMemo, useState} from "react";
import BankEntryParam from "../../interfaces/BankEntryParam";
import styled from "styled-components";
import RequestErrorType from "../../interfaces/RequestErrorType";
import InformationBox from "../RegisterInformation/InformationBox";

const Section = styled.form`
`;

const SubmitButton = styled.button`
  display: block;
  box-shadow: none;
  border: none;
  width: 100%;
  background-color: ${({theme, disabled}) => disabled ? theme.disableColor : theme.mainColor};
  border-radius: 8px;
  text-align: center;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-top: 24px;
`;

const BankInformation = styled.p`
  margin-top: 4px;
`;

type Props = {
    onSubmit: (entry: BankEntryParam) => void;
    disabled: boolean;
    errors: RequestErrorType;
}

const BankEntryForm: React.FC<Props> = ({onSubmit, disabled, errors}) => {
    const [entry, setEntry] = useState<BankEntryParam>({
        bank_code: '',
        branch_code: '',
        account_type: '1',
        account_number: '',
        account_name: ''
    });

    const submitDisabled = useMemo(() => {
        if (disabled) {
            return true;
        }
        return entry.bank_code.length === 0 ||
            entry.branch_code.length === 0 ||
            entry.account_number.length === 0 ||
            entry.account_name.length === 0;
    }, [entry, disabled]);

    const onFormSubmit = useCallback((event: FormEvent) => {
        if (!submitDisabled) {
            onSubmit(entry);
        }
        event?.preventDefault();
    }, [entry, onSubmit, submitDisabled]);

    return (
        <Section onSubmit={onFormSubmit}>
            <InformationBox label={'金融機関コード'}
                            value={entry.bank_code}
                            onInput={t => setEntry({...entry, bank_code: t})}
                            editable={true}
                            errors={errors}
                            name={'bank_code'}/>
            <InformationBox label={'支店コード（店番）'}
                            value={entry.branch_code}
                            onInput={t => setEntry({...entry, branch_code: t})}
                            editable={true}
                            errors={errors}
                            name={'branch_code'}/>
            <BankInformation>
                金融機関コード・支店コードがご不明な場合は、
                <a href={'https://www.zenginkyo.or.jp/shop/'} target='_blank' rel={'noreferrer'}>全国銀行協会のサイト</a>
                からお調べいただくか、お使いの金融機関にお問い合わせください。
            </BankInformation>
            <InformationBox label={'預金区分'}
                            type={'select'}
                            value={entry.account_type}
                            onInput={t => setEntry({...entry, account_type: t})}
                            editable={true}
                            errors={errors}
                            options={[
                                {
                                    value: '1',
                                    label: '普通'
                                },
                                {
                                    value: '2',
                                    label: '当座'
                                }
                            ]}
                            name={'account_type'}/>
            <InformationBox label={'口座番号'}
                            value={entry.account_number}
                            onInput={t => setEntry({...entry, account_number: t})}
                            editable={true}
                            errors={errors}
                            name={'account_number'}/>
            <InformationBox label={'口座名義（全角カナ）'}
                            value={entry.account_name}
                            onInput={t => setEntry({...entry, account_name: t})}
                            editable={true}
                            errors={errors}
                            name={'account_name'}/>
            <SubmitButton type={'submit'} disabled={submitDisabled} onClick={() => onSubmit(entry)}>
                口座登録
            </SubmitButton>
        </Section>
    )
};

export default BankEntryForm;