import React from 'react';
import VideoWatchLogType from "../../interfaces/VideoWatchLogType";
import styled from "styled-components";
import SectionHeader from "./SectionHeader";
import VideoWatchLogItem from "./VideoWatchLogItem";

const Section = styled.div`
  padding: 0 16px;
  margin-top: 16px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`;

const Separator = styled.div`
  width: 16px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
`;

interface Props {
    items: VideoWatchLogType[];
    onPress: (watchLog: VideoWatchLogType) => void;
}

const VideoWatchLogSlider: React.FC<Props> = ({items, onPress}) => {
    return (
        <Section>
            <SectionHeader title={'視聴動画の履歴'}/>
            <Horizontal>
                {items.map(item => (
                    <Flex key={item.id}>
                        <VideoWatchLogItem videoWatchLog={item}
                                           onPress={() => onPress(item)}/>
                        <Separator/>
                    </Flex>
                ))}
            </Horizontal>
        </Section>
    )
};

export default VideoWatchLogSlider;
