import React, { useEffect } from "react";
import styled from "styled-components";
import {Helmet} from "react-helmet-async";
import ScreenView from "../parts/ScreenView";
import PageHeader from "../parts/Headers/PageHeader";
import {Link} from 'react-router-dom';

const Title = styled.h1`
  margin: 0;
`;

const SectionTitle = styled.h4`
`;

const SnsGuide: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // url が変更されてからスクロール量がリセットされる
    });

return (
    <>
        <Helmet>
            <title>SNS機能ガイドライン</title>
        </Helmet>
        <PageHeader title={'SNS機能ガイドライン'}/>
        <ScreenView>
            <Title>SNS機能ガイドライン</Title>
            <p>SNS機能は情報共有したいことや、学びたいことについて資格保持者同士でグループを作成し、情報交換が可能です。</p>
            <p>この機能を活用することで、同じ関心事のある仲間や、職種、地域等でコミュニティづくりができるので、人と人をつなぐシステムとなります。</p>
            <p>本規約は、アステッキホールディングス株式会社（以下：弊社）が提供するアプリケーションのご利用に適用されます。</p>
            <SectionTitle>第 1 条（SNS機能の目的について）</SectionTitle>
            <p>SNS機能は、情報共有したいことや、学びたいことについて資格保持者同士でグループを作成し、情報交換を行うことが目的です。</p>
            <p>医療・介護分野における情報交換を原則とするため、私的な内容・本システムの悪用や他人に危害を与えるような書き込みやアップロードは禁止とさせていただきます</p>
            <SectionTitle>第 2 条（保有データの使用について）</SectionTitle>
            <p>弊社は、本システムを利用者に合わせパーソナライズするために、利用者が築いたつながり、選択や設定の内容、本システムの内外で、どのような行動をとったかなどの情報について当協会が保有するデータを使用します。</p>
            <SectionTitle>第 3 条（新規グループ作成・グループ申請について）</SectionTitle>
            <ol type="1">
                <li><p>本システムでは、新規グループの作成が行えます。弊社に新規グループ作成申請を行い、弊社が作成の容認を行うことにより新規グループが作成できます。<br />
                    新規に作成するグループ名・グループに関する概要、情報などを指定のフォームに入力し申請してください。弊社にて内容を精査しグループの作成容認の可否を行います</p></li>
                <li><p>本システムでは、所属を希望するグループに所属申請を行い、該当グループ管理者より容認を得ることにより新たにグループに参加することが出来ます。</p></li>
            </ol>
            <SectionTitle>第 4 条（禁止行為、誹謗中傷その他不適切な行為について）</SectionTitle>
            <p>弊社では、本システムの悪用や他人に危害を与えるような行為、システム利用目的の範囲を超えた書き込み・アップロードなどの行為が見つかった場合、サポートの提供、コンテンツの削除、特定機能へのアクセスの停止・制限、アカウントの停止、法執行機関への相談など、適切な処置を講じます。本システムの悪用や、本システムを利用した有害な行為が発覚した場合、法的処置を検討いたします。 </p>
            <p>利用者は次の行為を行わないこと、および、他者による同様の行為を助長または支援しないことに同意していただきます。</p>
            <ol type="1">
                <li><p>以下の行為、または以下のコンテンツの共有を目的として本システムを利用することを禁止します。</p>
                    <ul>
                        <li><blockquote>
                            <p>本規約、利用者による本システムの利用に適用される<Link to={'/guide/agreement'}>その他の規約やポリシー</Link>に違反する行為や情報。</p>
                        </blockquote></li>
                        <li><blockquote>
                            <p>違法行為、誤解を招く行為、差別的行為、または不正行為。</p>
                        </blockquote></li>
                        <li><blockquote>
                            <p>知的財産権などの他者の権利を侵害する行為。</p>
                        </blockquote></li>
                        <li><blockquote>
                            <p>私的な内容など医療・介護分野以外の内容を書き込みまたはアップロードする行為</p>
                        </blockquote></li>
                    </ul></li>
                <li><p>コンピューターウイルスもしくは悪意あるコードをアップロードする、または弊社のシステムの正常な機能もしくは表示を停止させる、過負荷をかける、もしくは損傷させる行為は禁止されています。</p></li>
                <li><p>弊社から事前の許可を得ることなく、自動化手段を用いて本システムのデータにアクセスしたり、データを取得したりすること、またはアクセス許可のないデータへのアクセスを試みることは禁止されています。</p></li>
            </ol>
            <SectionTitle>第 5 条（コンテンツの削除、違反コンテンツ・行為の報告について）</SectionTitle>
            <p>弊社は、本規定に違反するコンテンツを削除し、そのコンテンツへのアクセスを制限することができます。</p>
            <ol type="1">
                <li><p>利用者が弊社のコミュニティ規定に違反して共有したコンテンツを弊社が削除する場合、弊社は利用者にその旨を通知なしに、対象のコンテンツを削除します。</p></li>
                <li><p>より良いコミュニティを築くため、ご自身の権利(知的財産権を含む)を侵害するコンテンツ、または弊社の規約やポリシーに違反するコンテンツ・行為を発見された場合は、弊社までご報告ください。</p></li>
                <li><p>また、弊社は、本システムに対する法的または規制上の悪影響を回避または低減するために合理的に必要であると弊社が判断する場合、利用者のコンテンツやサービス、情報へのアクセスを削除または制限することができます。</p></li>
            </ol>
        </ScreenView>
    </>
)
};

export default SnsGuide;