import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {useSelector} from "../../store";
import Image from "../Image";
import SelectImageButton from "./SelectImageButton";
import TouchableOpacity from "../TouchableOpacity";
import ChatMessageType from "../../interfaces/ChatMessageType";

const Section = styled.div`
  padding: 16px;
`;

const InputFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

const Area = styled.textarea`
  border: 0;
  font-size: 16px;
  width: 100%;
  height: 300px;
  padding: 8px;
  box-sizing: border-box;
  outline: none;
  resize: none;
`;

const UserImage = styled(Image)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-right: 8px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const ButtonFlex = styled.div`
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (min-width: 480px) {
    display: flex;
  }
`;

const SubmitButton = styled(TouchableOpacity)<{ disabled: boolean }>`
  cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
  background-color: ${({disabled, theme}) => disabled ? theme.disableColor : theme.mainColor};
  color: #FFFFFF;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 8px;
  flex: 1;
  text-align: center;
  margin-top: 8px;

  @media screen and (min-width: 480px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

type Props = {
    onSubmit: (text: string, file: File | null) => void;
    editItem?: ChatMessageType | null;
}

const SendForm: React.FC<Props> = ({onSubmit, editItem}) => {

    const {user} = useSelector(state => ({
        user: state.user
    }));

    const [text, setText] = useState<string>(editItem?.text ?? '');
    const [image, setImage] = useState<File | null>(null);

    const onPress = useCallback(() => {
        if (text.length > 0) {
            onSubmit(text, image);
        }
    }, [onSubmit, text, image]);

    return (
        <Section>
            <InputFlex>
                <UserImage src={user.user?.profile_url || 'https://placehold.jp/100x100.png'}/>
                <Area value={text}
                      placeholder={'内容を入力してください'}
                      onChange={(e) => setText(e.target.value)}/>
            </InputFlex>
            <ButtonFlex>
                <SelectImageButton
                    selected={image !== null}
                    onSelect={(image) => setImage(image)}/>
                <SubmitButton onClick={onPress} disabled={text.length === 0}>
                    {(editItem === undefined || editItem === null) ? '投稿する' : '編集する'}
                </SubmitButton>
            </ButtonFlex>
        </Section>
    )
};

export default SendForm;