import React from "react";
import ChatMessageReactionType from "../../interfaces/ChatMessageReactionType";
import styled from "styled-components";
import ChatMessageReactionItem from "./ChatMessageReactionItem";

const List = styled.div`
  margin-top: 16px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;

interface Props {
    reactions: ChatMessageReactionType[];
}

const ChatMessageReactionList: React.FC<Props> = ({reactions}) => {
    return (
        <List>
            {reactions.map(item => (
                <ChatMessageReactionItem reaction={item}
                                         key={item.id}/>
            ))}
        </List>
    )
};

export default ChatMessageReactionList;