import React, {useEffect} from "react";
import ReactionType from "../../interfaces/ReactionType";
import styled from "styled-components";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {fetchReactions} from "../../stores/reaction";
import {BodyTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const MenuBox = styled.div`
  background: #f0f9f4;
  z-index: 1000;
  display: flex;
  left: -10px;
  top: -66px;
  max-width: calc(100vw - 60px);
  position: absolute;
  border-radius: 28px;
  height: 56px;
  padding: 0 24px;
  align-items: center;
  box-shadow: 1px 1px 2px 2px #D1D1D1;

  &:before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 28px;
    width: 10px; /* 影をつけるために線幅で設定せずにboxを使用する */
    height: 10px;
    transform: rotate(45deg); /* 回転 */
    background: #f0f9f4;
    box-shadow: 1px 1px 2px 0 #D1D1D1;
    display: block;
  }
`;

const ReactionButton = styled(TouchableOpacity)`
  min-width: 60px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ReactionImage = styled.img`
  width: 26px;
  height: 26px;
`;

const ReactionName = styled(BodyTextBox)`
  font-size: 10px;
`;

interface Props {
    onSelect: (reaction: ReactionType) => void;
    show: boolean;
}

const ReactionMenu: React.FC<Props> = ({onSelect, show}) => {

    const {reaction} = useSelector(state => ({
        reaction: state.reaction,
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        if (show) {
            dispatch(fetchReactions());
        }
    }, [dispatch, show]);

    if (!show) {
        return <></>;
    }

    return (
        <MenuBox>
            {reaction.items.map(item => (
                <ReactionButton key={item.id} onClick={() => onSelect(item)}>
                    <ReactionImage src={item.file_url} alt={item.name}/>
                    <ReactionName>{item.name}</ReactionName>
                </ReactionButton>
            ))}
        </MenuBox>
    )
};

export default ReactionMenu;