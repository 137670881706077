import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {persistCombineReducers, persistStore} from 'redux-persist';
import storage from 'localforage';
import {TypedUseSelectorHook, useSelector as rawUseSelector} from 'react-redux';
import {authSlice} from "./stores/auth";
import {categorySlice} from "./stores/category";
import {videoSlice} from "./stores/video";
import {userSlice} from "./stores/user";
import {userQualificationSlice} from "./stores/user_qualification";
import {chatGroupSlice} from "./stores/chat_group";
import {eventSlice} from "./stores/event";
import {noticeSlice} from "./stores/notice";
import {chatMessageSlice} from "./stores/chat_message";
import {drawerSlice} from "./stores/drawer";
import {videoWatchLogSlice} from "./stores/video_watch_log";
import {qualificationSlice} from "./stores/qualification";
import {examSlice} from "./stores/exam";
import {registerSlice} from "./stores/register";
import {reportChatMessageSlice} from "./stores/report_chat_message";
import {registerInformationSlice} from "./stores/register_information";
import {finishVideoSlice} from "./stores/finish_video";
import {searchVideoSlice} from "./stores/search_video";
import {paymentUserSelectSlice} from "./stores/payment_user_select";
import {paymentUserCreditSlice} from "./stores/payment_user_credit";
import {paymentUserBankSlice} from "./stores/payment_user_bank";
import {paymentEventSlice} from "./stores/payment_event";
import {chatGroupCreateSlice} from "./stores/chat_group_create";
import {chatGroupJoinSlice} from "./stores/chat_group_join";
import {unitHistorySlice} from "./stores/unit_history";
import {paymentRenewQualificationSlice} from "./stores/payment_renew_qualification";
import {sendContactSlice} from "./stores/send_contact";
import {serialNumberSlice} from "./stores/serial_number";
import {tabSlice} from "./stores/tab";
import {categoryDetailSlice} from "./stores/category_detail";
import {videoDetailSlice} from "./stores/video_detail";
import {eventDetailSlice} from "./stores/event_detail";
import {chatGroupDetailSlice} from "./stores/chat_group_detail";
import {qualificationDetailSlice} from "./stores/qualification_detail";
import {chatGroupUserSlice} from "./stores/chat_group_user";
import {joinRequestSlice} from "./stores/join_request";
import {chatGroupAdminSlice} from "./stores/chat_group_admin";
import {chatGroupLeaveSlice} from "./stores/chat_group_leave";
import {forgotPasswordSlice} from "./stores/forgot_password";
import {resetPasswordSlice} from "./stores/reset_password";
import {paymentUserCreditChangeSlice} from "./stores/payment_user_credit_change";
import {checkUserBankSlice} from "./stores/check_user_bank";
import {eventCancelSlice} from "./stores/event_cancel";
import {invoiceSlice} from "./stores/invoice";
import {chatTimelineSlice} from "./stores/chat_timeline";
import {reactionSlice} from "./stores/reaction";
import {sendReactionSlice} from "./stores/send_reaction";
import {chatMessageReactionSlice} from "./stores/chat_message_reaction";
import {chatMessageDetailSlice} from "./stores/chat_message_detail";
import {chatMessagePinSlice} from "./stores/chat_message_pin";
import {chatUserProfileSlice} from "./stores/chat_user_profile";
import {eventNoticeSlice} from "./stores/event_notice";
import {unreadNoticeSlice} from "./stores/unread_notice";
import {bankTransactionSlice} from "./stores/bank_transaction";

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth', 'serial_number'],
    debug: true
};

const reducer = persistCombineReducers(persistConfig, {
    auth: authSlice.reducer,
    category: categorySlice.reducer,
    video: videoSlice.reducer,
    user: userSlice.reducer,
    user_qualification: userQualificationSlice.reducer,
    chat_group: chatGroupSlice.reducer,
    event: eventSlice.reducer,
    notice: noticeSlice.reducer,
    chat_message: chatMessageSlice.reducer,
    drawer: drawerSlice.reducer,
    video_watch_log: videoWatchLogSlice.reducer,
    qualification: qualificationSlice.reducer,
    exam: examSlice.reducer,
    register: registerSlice.reducer,
    register_information: registerInformationSlice.reducer,
    report_chat_message: reportChatMessageSlice.reducer,
    finish_video: finishVideoSlice.reducer,
    search_video: searchVideoSlice.reducer,
    payment_user_select: paymentUserSelectSlice.reducer,
    payment_user_credit: paymentUserCreditSlice.reducer,
    payment_user_credit_change: paymentUserCreditChangeSlice.reducer,
    payment_user_bank: paymentUserBankSlice.reducer,
    payment_event: paymentEventSlice.reducer,
    chat_group_create: chatGroupCreateSlice.reducer,
    chat_group_join: chatGroupJoinSlice.reducer,
    unit_history: unitHistorySlice.reducer,
    payment_renew_qualification: paymentRenewQualificationSlice.reducer,
    send_contact: sendContactSlice.reducer,
    serial_number: serialNumberSlice.reducer,
    tab: tabSlice.reducer,
    category_detail: categoryDetailSlice.reducer,
    video_detail: videoDetailSlice.reducer,
    event_detail: eventDetailSlice.reducer,
    chat_group_detail: chatGroupDetailSlice.reducer,
    qualification_detail: qualificationDetailSlice.reducer,
    chat_group_user: chatGroupUserSlice.reducer,
    join_request: joinRequestSlice.reducer,
    chat_group_admin: chatGroupAdminSlice.reducer,
    chat_group_leave: chatGroupLeaveSlice.reducer,
    forgot_password: forgotPasswordSlice.reducer,
    reset_password: resetPasswordSlice.reducer,
    check_user_bank: checkUserBankSlice.reducer,
    event_cancel: eventCancelSlice.reducer,
    invoice: invoiceSlice.reducer,
    chat_timeline: chatTimelineSlice.reducer,
    reaction: reactionSlice.reducer,
    send_reaction: sendReactionSlice.reducer,
    chat_message_reaction: chatMessageReactionSlice.reducer,
    chat_message_detail: chatMessageDetailSlice.reducer,
    chat_message_pin: chatMessagePinSlice.reducer,
    chat_user_profile: chatUserProfileSlice.reducer,
    event_notice: eventNoticeSlice.reducer,
    unread_notice: unreadNoticeSlice.reducer,
    bank_transaction: bankTransactionSlice.reducer,
});

export const store = configureStore({
    reducer,
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: false,
        })
    ]
});

export const persistor = persistStore(store, {}, () => {

});

export type RootState = ReturnType<typeof store.getState>;

export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
