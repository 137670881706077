import React from 'react';
import styled from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";

const Box = styled.div`
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.mainColor};
`;

const Step = styled(BodyTextBox)`
  font-size: 14px;
  color: ${({theme}) => theme.whiteColor};
`;

const Title = styled(TitleTextBox)`
  font-size: 24px;
  font-weight: bold;
  margin-top: 4px;
  color: ${({theme}) => theme.whiteColor};
`;

interface Props {
    title: string;
    step: string;
}

const StepHeader: React.FC<Props> = ({title, step}) => {
    return (
        <Box>
            <Step>{step}</Step>
            <Title>{title}</Title>
        </Box>
    )
};

export default StepHeader;
