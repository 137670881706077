import React, {useCallback} from 'react';
import {useSelector} from "../store";
import Footer from "../parts/Footer";
import AuthHeader from "../parts/Headers/AuthHeader";
import MainDrawer from "../screens/Main/MainDrawer";
import styled from "styled-components";
import {useHistory, useLocation} from "react-router-dom";
import TopTab from "../parts/TopTab";
import {changeTab, TabMode} from "../stores/tab";
import {useDispatch} from "react-redux";
import BottomTab from "../parts/BottomTab";
import CheckAvailable from "../screens/CheckAvailable";
import query from "query-string";

const Screen = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @media screen and (min-width: 480px) {
    display: block;
  }
`;

const Wrap = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 480px) {
    min-height: 100%;
  }
`;

const Content = styled.div`
  flex: 1;
  margin-top: 68px;
  margin-bottom: 59px;

  @media screen and (min-width: 480px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const AppLayout: React.FC = ({children}) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const params = query.parse(location.search);
    const fullscreen = params.fullscreen ?? 0;
    const {tab} = useSelector(state => ({
        tab: state.tab,
    }));
    const fullScreenPath = [
        '/',
        '/select_service',
        '/login',
        '/banned',
        '/expired',
        '/logout',
        '/register',
        '/register/information',
        '/register/confirm',
        '/register/success',
        '/purchase',
        '/purchase/next',
        '/purchase/credit',
        '/purchase/bank',
        '/payment/success',
        '/payment/cancel',
        '/agreement',
        '/password/forgot',
        '/password/reset',
        '/guide/show/guide_1.pdf',
        '/guide/show/guide_2.pdf',
        '/guide/show/guide_3.pdf',
        '/guide/show/guide_4.pdf',
        '/guide/show/guide_5.pdf',
        '/guide/show/guide_6.pdf',
    ];

    const onTabChange = useCallback((newTab: TabMode) => {
        if (tab.tab !== newTab) {
            dispatch(changeTab(newTab));
        }
        switch (newTab) {
            case 'home':
                if (location.pathname !== '/home') {
                    history.push('/home');
                }
                break;
            case "video":
                if (location.pathname !== '/video') {
                    history.push('/video');
                }
                break;
            case "event":
                if (location.pathname !== '/event') {
                    history.push('/event');
                }
                break;
            case "sns":
                if (location.pathname !== '/sns') {
                    history.push('/sns');
                }
                break;
            case "account":
                if (location.pathname !== '/account') {
                    history.push('/account');
                }
                break;
        }
    }, [dispatch, history, tab.tab, location.pathname]);

    if (!fullScreenPath.includes(location.pathname) && !fullscreen) {
        return (
            <Screen>
                <AuthHeader/>
                <Wrap>
                    <MainDrawer/>
                    <TopTab tab={tab.tab} onTabChange={onTabChange}/>
                    <Content>
                        {children}
                    </Content>
                    <BottomTab tab={tab.tab} onTabChange={onTabChange}/>
                    <Footer/>
                </Wrap>
                <CheckAvailable/>
            </Screen>
        )
    } else {
        return <>{children}</>
    }
};

export default AppLayout;