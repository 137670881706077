import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../http/axios";

export type FinishVideo = {
    requestStatus: RequestStatusType;
};

const initialState: FinishVideo = {
    requestStatus: 'init'
};

export const sendFinishVideo = createAsyncThunk<void, any>(
    'finish_video/send',
    async (videoId) => {
        await axios.post(`videos/${videoId}/finish`)
    }
);

export const finishVideoSlice = createSlice({
    name: 'finish_video',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(sendFinishVideo.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(sendFinishVideo.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(sendFinishVideo.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus} = finishVideoSlice.actions;
