import React from "react";
import ChatMessageReactionType from "../../interfaces/ChatMessageReactionType";
import TouchableOpacity from "../TouchableOpacity";
import styled from "styled-components";
import {BodyText} from "../AppStyles";

const ReactionLink = styled(TouchableOpacity)`
  border-bottom: 1px solid ${({theme}) => theme.mainColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
`;

const ReactionImage = styled.img`
  width: 16px;
  height: 16px;
`;

const ReactionCountText = styled(BodyText)`
  font-size: 14px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
`;

interface Props {
    reactions: ChatMessageReactionType[];
    reaction_count: number;
    onShowReactions: () => void;
}

const ShowRecentReaction: React.FC<Props> = ({reactions, reaction_count, onShowReactions}) => {
    if (reaction_count === 0) {
        return <></>
    }

    return (
        <ReactionLink onClick={onShowReactions}>
            {reactions.map(item => (
                <ReactionImage src={item.reaction.file_url}
                               alt={item.reaction.name}
                               key={item.id}/>
            ))}
            <ReactionCountText>
                ({reaction_count})
            </ReactionCountText>
        </ReactionLink>
    )
};

export default ShowRecentReaction;