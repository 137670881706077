import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../http/axios";
import {fetchChatTimeline, updateItem as updateTimelineItem} from "./chat_timeline";
import {fetchChatMessages, updateItem as updateGroupItem} from "./chat_message";
import {fetchChatMessageDetail, updateItem as updateDetailItem} from "./chat_message_detail";
import ChatMessageType from "../interfaces/ChatMessageType";
import {RootState} from "../store";

export type SendReactionState = {
    requestStatus: RequestStatusType;
}

const initialState: SendReactionState = {
    requestStatus: "init"
}

type SendReactionParams = {
    chatMessage: ChatMessageType;
    groupId: number;
    reactionId: number | undefined;
};

export const sendReaction = createAsyncThunk<void, SendReactionParams>(
    'send_reaction',
    async (args, thunk) => {
        try {
            const state = thunk.getState() as RootState;
            const chatMessage = {
                ...args.chatMessage,
                has_own_reaction: !args.chatMessage.has_own_reaction
            };
            if (args.chatMessage.parent_id === null) {
                thunk.dispatch(updateGroupItem(chatMessage));
                thunk.dispatch(updateTimelineItem(chatMessage));
                if (state.chat_message_detail.item?.id === args.chatMessage.id) {
                    thunk.dispatch(updateDetailItem(chatMessage));
                }
            } else if (state.chat_message_detail.item?.id === args.chatMessage.parent_id) {
                const newDetail = {
                    ...state.chat_message_detail.item,
                    children: state.chat_message_detail.item.children.map(c => {
                        if (c.id === args.chatMessage.id) {
                            return {
                                ...c,
                                has_own_reaction: !args.chatMessage.has_own_reaction
                            };
                        } else {
                            return c;
                        }
                    })
                };
                thunk.dispatch(updateDetailItem(newDetail));
            }
            if (args.chatMessage.has_own_reaction) {
                await axios.delete(`reactions/chat_message/${args.chatMessage.id}`);
            } else {
                await axios.post(`reactions/chat_message`, {
                    chat_message_id: args.chatMessage.id,
                    reaction_id: args.reactionId
                });
            }
            if (args.chatMessage.parent_id === null) {
                thunk.dispatch(fetchChatTimeline({page: 1}));
                thunk.dispatch(fetchChatMessages({
                    groupId: args.groupId,
                    page: 1
                }));
                if (state.chat_message_detail.item?.id === args.chatMessage.id) {
                    thunk.dispatch(fetchChatMessageDetail(args.chatMessage.id));
                }
            } else if (state.chat_message_detail.item?.id === args.chatMessage.parent_id) {
                thunk.dispatch(fetchChatMessageDetail(args.chatMessage.parent_id));
            }
        } catch (e) {
            const chatMessage = {...args.chatMessage};
            thunk.dispatch(updateGroupItem(chatMessage));
            thunk.dispatch(updateTimelineItem(chatMessage));
            throw e;
        }
    }
);

export const sendReactionSlice = createSlice({
    name: 'send_reaction',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(sendReaction.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(sendReaction.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(sendReaction.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
    }
});