import styled from "styled-components";

const ImageSliderBox = styled.div`
  margin: 16px -25%;

  @media screen and (min-width: 480px) {
    margin: 16px 0;
  }
`;

export default ImageSliderBox;