import React, {ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState} from "react";
import UserType from "../../interfaces/UserType";
import styled from "styled-components";
import Image from "../Image";
import {ContentWrap, TitleTextBox} from "../AppStyles";
import Icon from "../Icon";
import ErrorText from "../ErrorText";
import PrefPicker from "./PrefPicker";
import ChatProfileSubmitButtons from "./ChatProfileSubmitButtons";

const ProfileImageBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-color: ${({theme}) => theme.borderColor};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 16px;
`;

const ProfileImage = styled(Image)`
  width: 34px;
  height: 34px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const ProfileImageButton = styled.label`
  margin-left: 116px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const ProfileImageFile = styled.input`
  display: none;
`;

const ProfileImageButtonText = styled(TitleTextBox)`
  text-decoration: underline;
  text-decoration-color: ${({theme}) => theme.linkColor};
  color: ${({theme}) => theme.linkColor};
`;

const AllowIcon = styled(Icon)`
  margin-left: 8px;
  color: ${({theme}) => theme.linkColor};
`;

const SimpleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-color: ${({theme}) => theme.borderColor};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 16px;
`;

const SimpleLabel = styled(TitleTextBox)`
  width: 150px;
  font-weight: bold;
  font-size: 16px;
  color: ${({theme}) => theme.mainColor};
`;

const SimpleTextView = styled.div`
  flex: 1;
`;

const SimpleTextBox = styled.input`
  color: #616161;
  flex: 1;
  border-bottom-width: 1px;
  border-bottom-color: #D1D1D1;
  border-bottom-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 4px;
  display: block;
  width: 100%;
  font-size: 14px;
`;

const SimpleTextArea = styled.textarea`
  color: #616161;
  flex: 1;
  border-bottom-width: 1px;
  border-bottom-color: #D1D1D1;
  border-bottom-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 4px;
  display: block;
  width: 100%;
  font-size: 14px;
`;

const Wrap = styled.form`
  border-top: 1px solid ${({theme}) => theme.borderColor};
  margin-top: 24px;
`;

const FlexContent = styled(ContentWrap)<{ alignItems?: string }>`
  display: flex;
  flex-direction: row;
  align-items: ${({alignItems}) => alignItems ?? 'center'};
  justify-content: flex-start;
  padding: 0 8px;

  @media screen and (min-width: 480px) {
    padding: 0;
  }
`;

export type EditParams = {
    file: File | null;
    industry: string;
    nickname: string;
    pref: string | null;
    introduction: string | null;
}

interface Props {
    onSubmit: (params: EditParams) => void;
    user: UserType;
    isProcessing: boolean;
    errors: object;
    onCancel: () => void;
}

const ChatProfileEditForm: React.FC<Props> = ({onSubmit, user, errors, isProcessing, onCancel}) => {
    const [file, setFile] = useState<File | null>(null);
    const [industry, setIndustry] = useState<string>('');
    const [nickname, setNickname] = useState<string>('');
    const [pref, setPref] = useState<string | null>(null);
    const [introduction, setIntroduction] = useState<string | null>(null);

    useEffect(() => {
        setIndustry(user.industry ?? '');
        setNickname(user.nickname ?? '');
        setPref(user.pref);
        setIntroduction(user.introduction);
    }, [user]);

    const onChangeFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0])
        }
    }, []);

    const profileImage = useMemo(() => {
        if (file) {
            return URL.createObjectURL(file);
        } else if (user.profile_url) {
            return user.profile_url;
        } else {
            return 'https://placehold.jp/100x100.png';
        }
    }, [file, user.profile_url]);

    const onSubmitForm = useCallback((e: FormEvent) => {
        onSubmit({
            file,
            nickname,
            industry,
            pref,
            introduction
        });
        e.preventDefault();
    }, [file, industry, introduction, nickname, onSubmit, pref]);

    return (
        <Wrap onSubmit={onSubmitForm}>
            <ProfileImageBox>
                <FlexContent>
                    <ProfileImage src={profileImage}/>
                    <ProfileImageButton htmlFor={'profile-file'}>
                        <ProfileImageButtonText>画像を変更する</ProfileImageButtonText>
                        <AllowIcon name={'angle-right'} size={18}/>
                    </ProfileImageButton>
                    <ProfileImageFile type={'file'}
                                      accept={'.png,.jpeg,.jpg'}
                                      id={'profile-file'}
                                      onChange={onChangeFile}/>
                </FlexContent>
            </ProfileImageBox>
            <SimpleBox>
                <FlexContent>
                    <SimpleLabel>職種</SimpleLabel>
                    <SimpleTextView>
                        <SimpleTextBox value={industry}
                                       placeholder={'入力してください'}
                                       onChange={e => setIndustry(e.target.value)}/>
                        <ErrorText errors={errors} name={'industry'}/>
                    </SimpleTextView>
                </FlexContent>
            </SimpleBox>
            <SimpleBox>
                <FlexContent>
                    <SimpleLabel>ニックネーム</SimpleLabel>
                    <SimpleTextView>
                        <SimpleTextBox value={nickname}
                                       placeholder={'入力してください'}
                                       onChange={e => setNickname(e.target.value)}/>
                        <ErrorText errors={errors} name={'pref'}/>
                    </SimpleTextView>
                </FlexContent>
            </SimpleBox>
            <SimpleBox>
                <FlexContent>
                    <SimpleLabel>都道府県（任意）</SimpleLabel>
                    <SimpleTextView>
                        <PrefPicker value={pref} onChange={setPref}/>
                        <ErrorText errors={errors} name={'nickname'}/>
                    </SimpleTextView>
                </FlexContent>
            </SimpleBox>
            <SimpleBox>
                <FlexContent alignItems={'start'}>
                    <SimpleLabel>自己紹介</SimpleLabel>
                    <SimpleTextView>
                        <SimpleTextArea value={introduction ?? ''}
                                        rows={10}
                                        placeholder={'入力してください'}
                                        onChange={e => setIntroduction(e.target.value)}/>
                        <ErrorText errors={errors} name={'introduction'}/>
                    </SimpleTextView>
                </FlexContent>
            </SimpleBox>
            <ChatProfileSubmitButtons onCancel={onCancel} disabled={isProcessing}/>
        </Wrap>
    )
};

export default ChatProfileEditForm;