import React from "react";
import styled from "styled-components";
import ChatMessageType from "../../interfaces/ChatMessageType";
import ChatMessageChildItem from "./ChatMessageChildItem";
import ReactionType from "../../interfaces/ReactionType";

const CommentBox = styled.div`
  border-top: 1px solid ${({theme}) => theme.borderColor};
  background-color: #f8fffd;
`;

const CommentTitle = styled.h4`
  border-left: solid 2px ${({theme}) => theme.mainColor};
  padding-left: 8px;
  margin: 24px 24px 8px 24px;
`;

const Wrap = styled.div`
  padding: 0 8px;
  flex: 1;
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;

  @media screen and (min-width: 480px) {
    padding: 0;
  }
`;

interface Props {
    message: ChatMessageType;
    onEdit: (message: ChatMessageType) => void;
    onDelete: (message: ChatMessageType) => void;
    onPin: (message: ChatMessageType) => void;
    onReport: (message: ChatMessageType) => void;
    onReaction: (message: ChatMessageType, reaction?: ReactionType) => void;
    onShowReactions: (message: ChatMessageType) => void;
}

const ChatMessageChildList: React.FC<Props> = ({
                                                   message,
                                                   onEdit,
                                                   onDelete,
                                                   onPin,
                                                   onReport,
                                                   onReaction,
                                                   onShowReactions
                                               }) => {
    return (
        <CommentBox>
            <Wrap>
                <CommentTitle>コメント</CommentTitle>
                {message.children.map((item, index) => (
                    <ChatMessageChildItem
                        item={item}
                        lastItem={message.children.length === index + 1}
                        key={`children.${item.id}`}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onPin={onPin}
                        onReport={onReport}
                        onReaction={(reaction) => onReaction(item, reaction)}
                        onShowReactions={() => onShowReactions(item)}/>
                ))}
            </Wrap>
        </CommentBox>
    )
};

export default ChatMessageChildList;