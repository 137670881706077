import React from 'react';
import NoticeType from "../../interfaces/NoticeType";
import styled from "styled-components";
import {BodyText} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Box = styled(TouchableOpacity)`
  margin-bottom: 16px;
`;

const Text = styled(BodyText)`
  color: ${({theme}) => theme.linkColor};
  text-decoration: underline;
  text-decoration-color: ${({theme}) => theme.linkColor};
  text-overflow: ellipsis;
`;

interface Props {
    notice: NoticeType<any>;
    onPress: () => void;
}

const NoticeOverviewItem: React.FC<Props> = ({notice, onPress}) => {
    return (
        <Box onClick={onPress}>
            <Text>{notice.published_at} {notice.title}</Text>
        </Box>
    )
};

export default NoticeOverviewItem;
