import React, {useCallback} from "react";
import styled from "styled-components";
import horizontalLogoImage from "../assets/horizontal_logo.png";
import {BodyText} from "./AppStyles";
import LinkText from "./Footer/LinkText";
import LoginButton from "./Footer/LoginButton";
import btnJtca from "../assets/btn_jtca.png";
import btnJaca from "../assets/btn_jaca.png";
import btnGuide from "../assets/btn_guide.png";
import {useSelector} from "../store";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {removeAuthenticate} from '../stores/auth';

const Section = styled.footer`
  background-color: #F8F9FA;
  padding: 32px 16px;
  display: none;

  @media screen and (min-width: 480px) {
    display: block;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 900px;
  margin: auto;

  @media screen and (min-width: 480px) {
    justify-content: center;
  }
`;

const CocoLinkBox = styled.div`
  width: 100%;
  max-width: 100%;

  @media screen and (min-width: 480px) {
    width: 300px;
  }
`;

const CocoLinkImage = styled.img`
  display: block;
`;

const CocoLinkText = styled(BodyText)`
  font-weight: bold;
  font-size: 12px;
`;

const LinkBox = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0 16px;
  margin-top: 16px;
  @media screen and (min-width: 480px) {
    border-right: 1px solid ${({theme}) => theme.borderColor};
    width: 300px;
    margin-top: 0;
  }
`;

const AuthBox = styled.div`
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
  @media screen and (min-width: 480px) {
    border-right: 1px solid ${({theme}) => theme.borderColor};
    width: 200px;
    margin-top: 0;
  }
`;

const AuthText = styled.div`
`;

const BannerBox = styled.div`
  width: 100%;
  margin-top: 16px;
  @media screen and (min-width: 480px) {
    padding: 16px;
    width: 200px;
    margin-top: 0;
  }
`;

const AstickBox = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AstickText = styled(BodyText)`
  font-weight: bold;
  font-size: 12px;
  color: #8A8A8A;
`;

const Footer: React.FC = () => {

    const {auth} = useSelector(state => ({
        auth: state.auth
    }));

    const dispatch = useDispatch();
    const history = useHistory();

    const onLogout = useCallback(() => {
        dispatch(removeAuthenticate());
        history.push('/');
    }, [dispatch, history]);

    return (
        <Section>
            <Flex>
                <CocoLinkBox>
                    <CocoLinkImage src={horizontalLogoImage}/>
                    <CocoLinkText>
                    医療系資格更新システム<br/>
                    ココリンク１つで様々な<br/>
                    資格試験の更新が完結します
                    </CocoLinkText>
                </CocoLinkBox>
                {auth.authenticated ? (
                    <LinkBox>
                        <LinkText text={'ホーム'} to={'/home'}/>
                        <LinkText text={'動画視聴'} to={'/video'}/>
                        <LinkText text={'イベント'} to={'/event'}/>
                        <LinkText text={'SNS交流'} to={'/sns'}/>
                        <LinkText text={'会員情報'} to={'/account'}/>
                        <LinkText text={'ログアウト'} onClick={onLogout}/>
                        <LinkText text={'利用規約'} to={'/guide/agreement'}/>
                        <LinkText text={'イベント管理受講ガイドライン '} to={'/guide/event'}/>
                        <LinkText text={'SNS機能ガイドライン'} to={'/guide/sns'}/>
                    </LinkBox>
                ) : (
                    <AuthBox>
                        <AuthText>
                            ログインはこちら
                            <LoginButton/>
                        </AuthText>
                    </AuthBox>
                )}
                <BannerBox>
                    <p>
                        <a href={'/guide'} target='_blank' rel='noreferrer'>
                            <img src={btnGuide} alt={'使い方ガイド'}/>
                        </a>
                    </p>
                    <p style={{marginBottom:'8px'}}>
                        <a href={'https://jtca2020.or.jp'} target='_blank' rel='noreferrer'>
                            <img src={btnJtca} alt={'jtca'}/>
                        </a>
                    </p>
                    <p style={{marginTop:'8px'}}>
                        <a href={'https://jaca2021.or.jp'} target='_blank' rel='noreferrer'>
                            <img src={btnJaca} alt={'jaca'}/>
                        </a>
                    </p>
                    <AstickBox>
                      <AstickText>アステッキホールディングス</AstickText>
                    </AstickBox>
                </BannerBox>
            </Flex>
        </Section>
    )
};

export default Footer;