import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import FlatButton from "../FlatButton";

const Section = styled.section`
  margin-top: 32px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
`;

const Buttons = styled.div`
  width: 100%;
  max-width: 700px;
`;

const RegisterButton = styled(FlatButton)`
  margin-top: 24px;
`;

interface Props {
    onLoginPress: () => void;
    onRegisterPress: () => void;
}

const LoginButtons: React.FC<Props> = ({onLoginPress, onRegisterPress}) => {
    const theme = useContext(ThemeContext);

    return (
        <Section>
            <Buttons>
                <FlatButton textColor={'#FFF'}
                            backgroundColor={theme.mainColor}
                            rightAllow={true}
                            text={'ログイン'}
                            onPress={onLoginPress}/>
                <RegisterButton textColor={theme.mainColor}
                                borderColor={theme.mainColor}
                                backgroundColor={'#FFFFFF'}
                                rightAllow={true}
                                text={'新規登録'}
                                onPress={onRegisterPress}/>
            </Buttons>
        </Section>
    )
};

export default LoginButtons;
