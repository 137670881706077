import React from "react";
import Agreement from "./Agreement";
import PageHeader from "../parts/Headers/PageHeader";

const AgreementPage: React.FC = () => {
    return (
        <>
            <PageHeader title={'利用規約'}/>
            <Agreement/>
        </>
    )
};

export default AgreementPage;