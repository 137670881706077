import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import NonMemberEventParams from "../../interfaces/NonMemberEventParams";
import styled, {ThemeContext} from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import FlatButton from "../FlatButton";
import ErrorText from "../ErrorText";

const Section = styled.div`

`;

const Label = styled(TitleTextBox)`
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  margin-top: 24px;
`;

const TextBox = styled.input`
  margin-top: 8px;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const WarnText = styled(BodyTextBox)`
  color: ${({theme}) => theme.dangerColor};
  border: 1px solid ${({theme}) => theme.dangerColor};
  background-color: ${({theme}) => theme.dangerBackgroundColor};
  padding: 8px;
  margin-top: 8px;
`;

const SubmitButton = styled(FlatButton)`
  margin-top: 24px;
`;

interface Props {
    onSubmit: (params: NonMemberEventParams) => void;
    errors: Object;
    oldParams: NonMemberEventParams | undefined;
    isFree: boolean;
}

const EventPaymentSetParamForm: React.FC<Props> = ({onSubmit, errors, oldParams, isFree}) => {
    const theme = useContext(ThemeContext);
    const [params, setParams] = useState<NonMemberEventParams>({
        name: '',
        kana: '',
        tel: '',
        email: ''
    });

    useEffect(() => {
        if (oldParams) {
            setParams(oldParams);
        }
    }, [oldParams]);

    const available = useMemo(() => {
        return params.name.length > 0 && params.kana.length > 0 && params.tel.length > 0 && params.email.length > 0;
    }, [params]);

    const onSubmitPress = useCallback(() => {
        if (available) {
            onSubmit(params);
        }
    }, [available, onSubmit, params]);

    return (
        <Section>
            <Label>氏名</Label>
            <TextBox
                value={params.name}
                onChange={e => setParams({...params, name: e.target.value})}
            />
            <ErrorText errors={errors} name={'name'}/>
            <Label>カナ</Label>
            <TextBox
                value={params.kana}
                onChange={e => setParams({...params, kana: e.target.value})}
            />
            <ErrorText errors={errors} name={'kana'}/>
            <Label>電話番号</Label>
            <TextBox
                value={params.tel}
                onChange={e => setParams({...params, tel: e.target.value})}
            />
            <ErrorText errors={errors} name={'tel'}/>
            <Label>メールアドレス</Label>
            <TextBox
                value={params.email}
                onChange={e => setParams({...params, email: e.target.value})}
            />
            <WarnText>
                ※入力ミスにご注意ください。<br/>
                ※携帯会社メールアドレスの場合、返信が届かない場合がございます。
            </WarnText>
            <ErrorText errors={errors} name={'email'}/>
            <SubmitButton text={isFree ? 'お申し込み' : 'お支払いへ進む'}
                          backgroundColor={theme.subColor}
                          disabled={!available}
                          onPress={onSubmitPress}/>
        </Section>
    );
};

export default EventPaymentSetParamForm;
