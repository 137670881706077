import React from 'react';
import styled from "styled-components";
import DatePicker, {registerLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja';
import ErrorText from "../ErrorText";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import DateFlexPicker from "../DateFlexPicker";

const Section = styled.div`
  padding: 8px 0;
`;

const Label = styled(TitleTextBox)`
  color: ${({theme}) => theme.mainColor};
  font-weight: bold;
  font-size: 14px;
`;

const ValueLabel = styled(BodyTextBox)`
  margin-top: 4px;
  font-size: 20px;
`;

const Input = styled.input`
  margin-top: 4px;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 8px;
  padding: 8px;
  font-size: 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const Date = styled(DatePicker)`
  margin-top: 4px;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 8px;
  padding: 8px;
  font-size: 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const Select = styled.select`
  margin-top: 4px;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 8px;
  padding: 8px;
  font-size: 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

interface Props {
    label: string;
    value: any | null;
    editable: boolean;
    onInput?: (value: string) => void;
    type?: string;
    errors: object;
    name: string;
    options?: { label: string, value: string }[];
}

registerLocale('ja', ja);

const InformationBox: React.FC<Props> = ({
                                             label,
                                             value,
                                             editable,
                                             onInput,
                                             type,
                                             errors,
                                             name,
                                             options
                                         }) => {
    let ValueBox = <ValueLabel>{value}</ValueLabel>;
    if (editable && onInput) {
        if (type === 'date') {
            ValueBox = <DateFlexPicker value={value}
                                       onChange={date => onInput(date)}/>
        } else if (type === 'select') {
            ValueBox = (
                <Select value={value} onChange={e => onInput(e.target.value)}>
                    {options?.map(s => (
                        <option value={s.value} key={s.value}>{s.label}</option>
                    ))}
                </Select>
            )
        } else {
            ValueBox = <Input
                autoComplete={type === 'password' ? 'new-password' : 'off'}
                type={type}
                name={name}
                value={value || ''}
                onChange={e => onInput(e.target.value)}/>;
        }
    }

    return (
        <Section>
            <Label>{label}</Label>
            {ValueBox}
            <ErrorText errors={errors} name={name}/>
        </Section>
    );
};

export default InformationBox;
