import NoticeType from "../interfaces/NoticeType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import PaginateMeta from "../interfaces/PaginateMeta";
import {setReadNotice} from "./notice";

export type EventNoticeState = {
    items: NoticeType<any>[];
    isFetching: boolean;
    lastRequestMeta?: PaginateMeta;
};

const initialState: EventNoticeState = {
    items: [],
    isFetching: false,
    lastRequestMeta: undefined,
};

export const fetchEventNotices = createAsyncThunk<FetchReturnType<NoticeType<any>>, { page: number }>(
    'event_notices/fetch',
    async ({page}) => {
        const {data} = await axios.get(`notices?page=${page}&event_flag=1`);
        return data as FetchReturnType<NoticeType<any>>;
    }
);

export const eventNoticeSlice = createSlice({
    name: 'event_notices',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchEventNotices.fulfilled, (state, action) => ({
                ...state,
                items: action.meta.arg.page > 1 ? [...state.items, ...action.payload.data] : action.payload.data,
                isFetching: false,
                lastRequestMeta: action.payload.meta,
            }))
            .addCase(fetchEventNotices.pending, state => ({
                ...state,
                isFetching: true,
            }))
            .addCase(fetchEventNotices.rejected, state => ({
                ...state,
                isFetching: false,
            }))
            .addCase(setReadNotice.fulfilled, (state, action) => ({
                ...state,
                items: state.items.map(notice => {
                    if (action.meta.arg.id === notice.id) {
                        return {...notice, is_read: true};
                    } else {
                        return notice;
                    }
                })
            }))
    }
});
