import React, {useCallback, useEffect, useState} from 'react';
import ScreenView from "../../parts/ScreenView";
import ImageSlider from "../../parts/ImageSlider";
import {useSelector} from "../../store";
import {fetchUserEvents} from "../../stores/event";
import {fetchNotices} from "../../stores/notice";
import {useDispatch} from "react-redux";
import NoticeType from "../../interfaces/NoticeType";
import EventType from "../../interfaces/EventType";
import {useHistory} from "react-router-dom";
import HomeLink from "../../parts/HomeTab/HomeLink";
import NoticeOverview from "../../parts/HomeTab/NoticeOverview";
import EventOverview from "../../parts/HomeTab/EventOverview";
import styled from "styled-components";
import {changeTab} from "../../stores/tab";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {Helmet} from "react-helmet-async";
import NotifyDetail from "../Main/NotifyDetail";

const UnderLink = styled(HomeLink)`
  margin-bottom: 16px;
`;

const HomeTab: React.FC = () => {
    const {notice, event} = useSelector(state => ({
        notice: state.notice,
        event: state.event,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectNotice, setSelectNotice] = useState<NoticeType<any> | null>(null);

    const updateInformation = useCallback(() => {
        dispatch(fetchNotices({page: 1}));
        dispatch(fetchUserEvents());
    }, [dispatch]);

    useEffect(() => {
        updateInformation();
        dispatch(changeTab('home'));
    }, [updateInformation, dispatch]);

    const onPressNotice = useCallback((notice: NoticeType<any>) => {
        setSelectNotice(notice);
    }, []);

    const onPressEvent = useCallback((event: EventType) => {
        history.push(`/events/${event.id}`);
    }, [history]);

    return (
        <>
            <Helmet>
                <title>ホーム</title>
            </Helmet>
            <WithLoadingScreen loading={notice.isFetching || event.isFetching}>
                <ImageSlider/>
                <ScreenView>
                    <NoticeOverview items={notice.items} onPress={onPressNotice}/>
                    <EventOverview items={event.userItems} onPress={onPressEvent}/>
                    <UnderLink title={'イベント情報はこちら'}
                               url={'/event'}/>
                </ScreenView>
                <NotifyDetail notice={selectNotice}
                              onClose={() => setSelectNotice(null)}/>
            </WithLoadingScreen>
        </>
    );
};

export default HomeTab;