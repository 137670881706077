import UserQualificationType from "../interfaces/UserQualificationType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../http/axios";
import {FetchReturnType} from "../interfaces/FetchReturnType";

export type UserQualificationState = {
    items: UserQualificationType[];
    isFetching: boolean;
};

const initialState: UserQualificationState = {
    items: [],
    isFetching: false
};

export const fetchUserQualifications = createAsyncThunk<FetchReturnType<UserQualificationType>>(
    'user/qualifications/fetch',
    async () => {
        const {data} = await axios.get('user/qualifications');
        return data as FetchReturnType<UserQualificationType>;
    }
);

export const userQualificationSlice = createSlice({
    name: 'qualification',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUserQualifications.fulfilled, (state, action) => ({
                ...state,
                items: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchUserQualifications.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchUserQualifications.rejected, state => ({
                ...state,
                items: [],
                isFetching: false
            }))
    }
});
