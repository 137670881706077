import React from "react";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";
import styled from "styled-components";
import AppIcon from "../../parts/Welcome/AppIcon";
import AppTitle from "../../parts/Welcome/AppTitle";
import WelcomeLinks from "../../parts/Welcome/WelcomeLinks";
import TargetQualifications from "../../parts/Welcome/TargetQualifications";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import welcomeBG from "../../assets/welcome_background.png";

const Screen = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;

const Flex = styled.div`
  flex: 1;
  justify-content: center;
`;

const AstickBox = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AstickText = styled.p`
  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.mainColor};
  `;
  
  const SpecifiedCommercialBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  `
  const SpecifiedCommercialLink = styled.a`
  font-weight: bold;
  font-size: 10px;
  text-decoration: none;
  color: ${({ theme }) => theme.whiteColor};
  `

const ComingSoonTextArea = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`

const ComingSoonText = styled.p`
  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.dangerColor};
`

const Welcome: React.FC = () => {

    const history = useHistory();

    return (
        <>
            <Helmet>
                <title>ココリンクへようこそ</title>
            </Helmet>
            <NoAuthHeader />
            <Screen style={{
                backgroundImage: `url(${welcomeBG})`
            }}>
                <Flex>
                    <AppIcon />
                    <AppTitle />
                    <WelcomeLinks
                        onSelectServicePress={() => history.push('/select_service')}
                        onGuestPress={() => history.push('/home')} />
                        {/* <ComingSoonTextArea>
                          <ComingSoonText>
                            第１回急性期ケア専門士の新規登録は9月1日から開始となります。<br/>
                            登録開始までしばらくお待ちください。
                          </ComingSoonText>
                        </ComingSoonTextArea> */}
                </Flex>
                <TargetQualifications />
                <AstickBox>
                    <AstickText>アステッキホールディングス</AstickText>
                </AstickBox>
                <SpecifiedCommercialBox>
                  <SpecifiedCommercialLink
                    href={'https://www.astick0115.com/low/'}
                    target={'_blank'} 
                    rel={'noreferrer'}
                    style={{color: "#C1C1C1"}}
                    >
                    特定商取引法に関する記述
                  </SpecifiedCommercialLink>
                </SpecifiedCommercialBox>
            </Screen>
        </>
    )
};

export default Welcome;