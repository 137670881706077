import {PersistGate} from "redux-persist/integration/react";
import {Provider} from 'react-redux';
import {persistor, store} from "./store";
import config, {ConfigContext} from "./config";
import {ThemeProvider} from "styled-components";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import theme from "./theme";
import Router from "./router";
import SerialNumberResolver from "./screens/SerialNumberResolver";

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={<></>} persistor={persistor}>
                <ConfigContext.Provider value={config}>
                    <ThemeProvider theme={theme}>
                        <HelmetProvider>
                            <Helmet
                                titleTemplate="%s - ココリンク">
                            </Helmet>
                            <Router/>
                            <SerialNumberResolver/>
                        </HelmetProvider>
                    </ThemeProvider>
                </ConfigContext.Provider>
            </PersistGate>
        </Provider>
    );
}

export default App;
