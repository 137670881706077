import React, {useCallback, useEffect} from 'react';
import ScreenView from '../../parts/ScreenView';
import GuideButton from "../../parts/Contact/GuideButton";
import styled from "styled-components";
import {BodyTextBox} from "../../parts/AppStyles";
import PageHeader from "../../parts/Headers/PageHeader";
import ContactForm from "../../parts/Contact/ContactForm";
import ContactParams from "../../interfaces/ContactParams";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {fetchUser} from "../../stores/user";
import {setErrors} from "../../stores/send_contact";
import {useHistory, useLocation} from "react-router-dom";
import mailIcon from '../../assets/icons/mail.png';
import {Helmet} from "react-helmet-async";

const GuideText = styled(BodyTextBox)`
  color: ${({theme}) => theme.textGrayColor};
  font-size: 12px;
  margin-top: 8px;
  text-align: center;
`;

const Contact: React.FC = () => {

    const {user, auth, send} = useSelector(state => ({
        user: state.user,
        auth: state.auth,
        send: state.send_contact,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const {state: {old} = {old: undefined}} = useLocation<{ old?: ContactParams }>();

    useEffect(() => {
        dispatch(setErrors({}));
    }, [dispatch]);

    useEffect(() => {
        if (auth.authenticated) {
            dispatch(fetchUser());
        }
    }, [auth, dispatch]);

    const onSubmit = useCallback((param: ContactParams) => {
        history.push('/contact/confirm', {contact: param});
    }, [history]);

    const onCancel = useCallback(() => {
        history.push('/home');
    }, [history]);

    return (
        <>
            <Helmet>
                <title>お問い合わせ</title>
            </Helmet>
            <PageHeader title={'お問い合わせ'}
                        iconSource={mailIcon}/>
            <ScreenView scroll={true}>
                <GuideButton onPress={() => history.push('/guide')}/>
                <GuideText>
                    ココリンクの使い方に関しては上のリンクをご覧ください。
                </GuideText>
                <ContactForm onSubmit={onSubmit}
                             onCancel={onCancel}
                             user={auth.authenticated ? user.user : null}
                             oldParams={old}
                             errors={send.errors}/>
            </ScreenView>
        </>
    )
};

export default Contact
