import React from "react";
import {TabMode} from "../stores/tab";
import styled from "styled-components";
import TouchableOpacity from "./TouchableOpacity";

const Wrap = styled.div`
  display: none;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};

  @media screen and (min-width: 480px) {
    display: block;
  }
`;

const Section = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: auto;
`;

const TabItem = styled(TouchableOpacity)<{ selected: boolean }>`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
  flex: 1;
  transition: all 0.2s;
  color: ${({selected, theme}) => selected ? theme.textColor : '#616161'};
  border-right: 1px solid ${({theme}) => theme.borderColor};
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${({theme, selected}) => selected ? theme.mainColor : 'transparent'};
  
  &:nth-of-type(1) {
    border-left: 1px solid ${({theme}) => theme.borderColor};
  }
`;

type Props = {
    tab: TabMode;
    onTabChange: (tab: TabMode) => void;
};

const TopTab: React.FC<Props> = ({tab, onTabChange}) => {
    return (
        <Wrap>
            <Section>
                <TabItem onClick={() => onTabChange('home')} selected={tab === 'home'}>
                    ホーム
                </TabItem>
                <TabItem onClick={() => onTabChange('video')} selected={tab === 'video'}>
                    動画視聴
                </TabItem>
                <TabItem onClick={() => onTabChange('event')} selected={tab === 'event'}>
                    イベント
                </TabItem>
                <TabItem onClick={() => onTabChange('sns')} selected={tab === 'sns'}>
                    SNS交流
                </TabItem>
                <TabItem onClick={() => onTabChange('account')} selected={tab === 'account'}>
                    会員情報
                </TabItem>
            </Section>
        </Wrap>
    )
};

export default TopTab;