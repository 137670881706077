import React, {useCallback, useEffect} from 'react';
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {setErrors} from "../../stores/payment_event";
import EventPaymentSetParamForm from "../../parts/EventPaymentSetParam/EventPaymentSetParamForm";
import NonMemberEventParams from "../../interfaces/NonMemberEventParams";
import ScreenView from "../../parts/ScreenView";
import PageHeader from "../../parts/Headers/PageHeader";
import styled from "styled-components";
import {BodyTextBox} from "../../parts/AppStyles";
import {useHistory, useLocation, useParams} from "react-router-dom";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {fetchEventDetail} from "../../stores/event_detail";
import NotFound from "../NotFound";

const Information = styled(BodyTextBox)`

`;

const EventPaymentSetParam: React.FC = () => {

    const {payment, event_detail} = useSelector(state => ({
        payment: state.payment_event,
        event_detail: state.event_detail,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const {id: eventId} = useParams<{ id: string; }>();
    const {state: {old} = {old: undefined}} = useLocation<{ old: NonMemberEventParams; }>();

    useEffect(() => {
        dispatch(fetchEventDetail(eventId))
    }, [dispatch, eventId]);

    useEffect(() => {
        if (old === undefined) {
            dispatch(setErrors({}));
        }
    }, [dispatch, old]);

    const onSubmit = useCallback((params: NonMemberEventParams) => {
        if (event_detail.item === null) {
            return;
        }
        history.push(`/events/${event_detail.item.id}/payment`, {
            params
        });
    }, [event_detail, history]);

    if (event_detail.isFetching) {
        return <WithLoadingScreen loading={true}/>;
    }
    if (event_detail.item === null) {
        return <NotFound/>;
    }

    return (
        <>
            <PageHeader title={'追加情報の入力'}/>
            <ScreenView scroll={true}>
                <Information>
                    非会員の方のお申し込みには追加の情報が必要です。<br/>
                    イベントに参加される方の情報をご入力ください。
                </Information>
                <EventPaymentSetParamForm errors={payment.errors}
                                          oldParams={old}
                                          onSubmit={onSubmit}
                                          isFree={event_detail.item.non_member_price === 0}/>
            </ScreenView>
        </>
    );
};

export default EventPaymentSetParam;
