import React from 'react';
import UnitHistoryType from "../../interfaces/UnitHistoryType";
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";
import Image from "../Image";
import qualificationIcon from '../../assets/icons/qualification.png';

const Section = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QualificationIcon = styled(Image)`
  width: 16px;
  height: 16px;
`;

const HistoryText = styled(TitleTextBox)`
  font-size: 16px;
  margin-left: 4px;
`;

interface Props {
    history: UnitHistoryType;
}

const UnitHistoryItem: React.FC<Props> = ({history}) => {

    return (
        <Section>
            <QualificationIcon src={qualificationIcon}/>
            <HistoryText>{history.from}: {history.unit}単位 ({history.created_at})</HistoryText>
        </Section>
    )
};

export default UnitHistoryItem;
