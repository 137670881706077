import React from "react";
import CategoryType from "../../interfaces/CategoryType"
import styled from "styled-components";
import {BodyTextBox, ContentWrap, TitleTextBox} from "../AppStyles";
import backgroundImage from '../../assets/category_list_bg.png';
import TouchableOpacity from "../TouchableOpacity";

const BackgroundView = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.borderColor};
  border-bottom-style: solid;
  background-image: url(${backgroundImage});
`;

const Section = styled(TouchableOpacity)`
  padding: 16px;
`;

const Index = styled(TitleTextBox)`
  font-size: 12px;
  color: ${({theme}) => theme.textGrayColor};
`;

const Title = styled(TitleTextBox)`
  margin-top: 4px;
  font-size: 20px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor}
`;

const Description = styled(BodyTextBox)`
  padding: 8px 0;
  text-overflow: ellipsis;
`;

interface Props {
    category: CategoryType;
    onPress: () => void;
    index: number;
    parent: CategoryType;
}

const CategoryChildItem: React.FC<Props> = ({category, onPress, index, parent}) => {
    return (
        <BackgroundView>
            <Section onClick={onPress}>
                <ContentWrap>
                    <Index>{parent.name}-{index + 1}</Index>
                    <Title>「{category.name}」</Title>
                    <Description>{category.description}</Description>
                </ContentWrap>
            </Section>
        </BackgroundView>
    )
};

export default CategoryChildItem;
