import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import query from "query-string";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {resetRequestStatus, sendResetRequest, setErrors} from '../../stores/reset_password';
import * as toastr from "toastr";
import {Helmet} from "react-helmet-async";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";
import PageHeader from "../../parts/Headers/PageHeader";
import ScreenView from "../../parts/ScreenView";
import styled from "styled-components";
import InformationBox from "../../parts/RegisterInformation/InformationBox";
import ErrorText from "../../parts/ErrorText";

const Information = styled.div`
  text-align: center;
`;

const Form = styled.form`
  margin-top: 24px;
`;

const SubmitButton = styled.button`
  outline: none;
  border: none;
  box-shadow: none;
  background-color: ${({theme}) => theme.mainColor};
  width: 100%;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;

  &:disabled {
    background-color: ${({theme}) => theme.disableColor};
  }
`;

const ResetPassword: React.FC = () => {
    const {search} = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const params = query.parse(search);

    const [token] = useState<string>(params['t'] as string);
    const [email] = useState<string>(params['e'] as string);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const {reset_password} = useSelector(state => ({
        reset_password: state.reset_password,
    }));

    useEffect(() => {
        dispatch(resetRequestStatus());
        dispatch(setErrors({}));
    }, [dispatch]);

    useEffect(() => {
        if (reset_password.requestStatus === 'success') {
            toastr.success('パスワードをリセットしました。');
            history.push('/login');
            dispatch(resetRequestStatus());
        } else if (reset_password.requestStatus === "failed") {
            toastr.error('パスワードをリセットできませんでした。')
            dispatch(resetRequestStatus());
        }
    }, [dispatch, history, reset_password.requestStatus]);

    const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(sendResetRequest({
            email,
            token,
            password,
            password_confirmation: passwordConfirmation
        }))
    }, [dispatch, email, password, passwordConfirmation, token])

    return (
        <>
            <Helmet>
                <title>パスワードリセット</title>
            </Helmet>
            <NoAuthHeader/>
            <PageHeader title={'パスワードリセット'} disableGoBack={true}/>
            <ScreenView>
                <Information>
                    新しいパスワードを入力してください。
                </Information>
                <Form onSubmit={onSubmit}>
                    <ErrorText errors={reset_password.errors} name={'token'}/>
                    <ErrorText errors={reset_password.errors} name={'email'}/>
                    <InformationBox label={'新パスワード'}
                                    value={password}
                                    onInput={password => setPassword(password)}
                                    editable={true}
                                    errors={reset_password.errors}
                                    name={'password'}
                                    type={'password'}/>
                    <InformationBox label={'新パスワード（確認）'}
                                    value={passwordConfirmation}
                                    onInput={password => setPasswordConfirmation(password)}
                                    editable={true}
                                    errors={reset_password.errors}
                                    name={'passwordConfirmation'}
                                    type={'password'}/>
                    <SubmitButton
                        type={'submit'}
                        disabled={reset_password.requestStatus === "pending" ||
                        password.length === 0 ||
                        passwordConfirmation.length === 0}>
                        パスワードリセット
                    </SubmitButton>
                </Form>
            </ScreenView>
        </>
    )
};

export default ResetPassword;