import React from 'react';
import VideoType from "../../interfaces/VideoType";
import styled from "styled-components";
import { BodyText, BodyTextBox, TitleText, TitleTextBox } from "../AppStyles";
import iconImage from '../../assets/icons/qualification.png';
import nl2br from "../../utils/nl2br";

const Box = styled.div`
  padding: 16px;
`;

const Title = styled(TitleTextBox)`
  color: ${({ theme }) => theme.mainColor};
  font-size: 26px;
  font-weight: bold;
`;

const Description = styled(BodyTextBox)`
  margin-top: 8px;
  font-size: 14px;
`;

const Qualification = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QualificationIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const QualificationText = styled(TitleText)`
  font-size: 14px;
  color: ${({ theme }) => theme.textGrayColor};
  margin-left: 4px;
`;

const QualificationInformation = styled(BodyText)`
  margin-top: 8px;
  font-size: 14px;
`;

interface Props {
    video: VideoType;
}

const VideoInformation: React.FC<Props> = ({ video }) => {
    return (
        <Box>
            <Title>{video.name}</Title>
            <Description>{nl2br(video.description ?? '')}</Description>
            {video.unit_group ? (
                <>
                    {video.unit_group.qualifications.map(qualification => {
                        return (
                            <div key={qualification.qualification_id}>
                                <Qualification>
                                    <QualificationIcon src={iconImage} />
                                    <QualificationText>
                                        {qualification.qualification_name} {qualification.grant_unit}単位
                                    </QualificationText>
                                </Qualification>
                                <QualificationInformation>
                                    この動画は{qualification.qualification_name}の更新単位を獲得できます。
                                </QualificationInformation>
                            </div>
                        )
                    })}
                </>
            ) : <></>}
        </Box>
    )
};

export default VideoInformation;
