import React, { useCallback, useEffect, useState } from 'react';
import ScreenView from "../../parts/ScreenView";
import ImageSlider from "../../parts/ImageSlider";
import { useSelector } from "../../store";
import { fetchUserEvents } from "../../stores/event";
import { fetchNotices } from "../../stores/notice";
import { fetchVideoWatchLog } from "../../stores/video_watch_log";
import { useDispatch } from "react-redux";
import VideoWatchLogType from "../../interfaces/VideoWatchLogType";
import NoticeType from "../../interfaces/NoticeType";
import EventType from "../../interfaces/EventType";
import { useHistory } from "react-router-dom";
import VideoWatchLogSlider from "../../parts/HomeTab/VideoWatchLogSlider";
import HomeLink from "../../parts/HomeTab/HomeLink";
import NoticeOverview from "../../parts/HomeTab/NoticeOverview";
import EventOverview from "../../parts/HomeTab/EventOverview";
import styled from "styled-components";
import { changeTab } from "../../stores/tab";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import { Helmet } from "react-helmet-async";
import NotifyDetail from "./NotifyDetail";
import { fetchInvoice } from "../../stores/invoice";
import InvoiceAlert from "../../parts/HomeTab/InvoiceAlert";
import BankRequestButton from "../../parts/BankRequestButton";
import GetUnitReminderAlert from "../../parts/GetUnitReminderAlert";
import { fetchQualifications } from "../../stores/qualification";
import {fetchUserQualifications} from "../../stores/user_qualification";
import { CardExpiredBanner } from '../../parts/HomeTab/CardExpiredBanner';

const UnderLink = styled(HomeLink)`
  margin-bottom: 24px;
`;

const HomeTab: React.FC = () => {
    const { video_watch_log, notice, event, invoice } = useSelector(state => ({
        video_watch_log: state.video_watch_log,
        notice: state.notice,
        event: state.event,
        invoice: state.invoice,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectNotice, setSelectNotice] = useState<NoticeType<any> | null>(null);

    const updateInformation = useCallback(() => {
        dispatch(fetchVideoWatchLog());
        dispatch(fetchNotices({ page: 1 }));
        dispatch(fetchUserEvents());
        dispatch(fetchInvoice());
        dispatch(fetchUserQualifications());
        dispatch(fetchQualifications());
    }, [dispatch]);

    useEffect(() => {
        updateInformation();
        dispatch(changeTab('home'));
    }, [updateInformation, dispatch]);

    const onPressVideoWatchLog = useCallback((watchLog: VideoWatchLogType) => {
        if (watchLog.position + 5000 > watchLog.duration) {
            history.push(`/videos/${watchLog.video_id}`);
        } else {
            history.push(`/videos/${watchLog.video_id}?t=${watchLog.position}`);
        }
    }, [history]);

    const onPressNotice = useCallback((notice: NoticeType<any>) => {
        setSelectNotice(notice);
    }, []);

    const onPressEvent = useCallback((event: EventType) => {
        history.push(`/events/${event.id}`);
    }, [history]);

    return (
        <>
            <Helmet>
                <title>ホーム</title>
            </Helmet>
            <WithLoadingScreen loading={video_watch_log.isFetching || notice.isFetching || event.isFetching}>
                <ImageSlider />
                <ScreenView>
                    <InvoiceAlert invoice={invoice.invoice} />
                    <CardExpiredBanner/>
                    <BankRequestButton />
                    <GetUnitReminderAlert />
                    <VideoWatchLogSlider items={video_watch_log.items}
                        onPress={onPressVideoWatchLog} />
                    <HomeLink title={'動画視聴の履歴'}
                        url={'/video_watch_histories'} />
                </ScreenView>
                <NoticeOverview items={notice.items} onPress={onPressNotice} />
                <ScreenView>
                    <EventOverview items={event.userItems} onPress={onPressEvent} />
                    <UnderLink title={'イベント情報はこちら'}
                        url={'/event'} />
                </ScreenView>
                <NotifyDetail notice={selectNotice}
                    onClose={() => setSelectNotice(null)} />
            </WithLoadingScreen>
        </>
    );
};

export default HomeTab;
