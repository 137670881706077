import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import {TitleTextBox} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";
import {useHistory, useLocation} from "react-router-dom";
import query from "query-string";

const Section = styled.div`
  margin-right: auto;
  padding: 12px 16px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({theme}) => theme.mainColor};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: auto;
`;

const BackButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Title = styled(TitleTextBox)`
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  padding-right: 30px;
  margin-left: 8px;
  color: ${({theme}) => theme.whiteColor};
`;

const HeaderIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

interface Props {
    title: string;
    disableGoBack?: boolean;
    iconSource?: any;
}

const PageHeader: React.FC<Props> = ({title, disableGoBack = false, iconSource}) => {
    const history = useHistory();
    const {search} = useLocation();
    const params = query.parse(search);
    const fullscreen = params.fullscreen ?? 0;
    const openTop = () => {
        history.goBack();
    };
    const theme = useContext(ThemeContext);

    if (fullscreen) {
        return <></>
    }

    return (
        <Section>
            <Content>
                {!disableGoBack ? (
                    <BackButton onClick={openTop}>
                        <ArrowIcon color={theme.whiteColor} size={16} leftArrow/>
                    </BackButton>
                ) : <></>}
                {iconSource ? (
                    <HeaderIcon src={iconSource}/>
                ) : <></>}
                <Title>{title}</Title>
            </Content>
        </Section>
    )
};

export default PageHeader;
