import ReactionType from "../interfaces/ReactionType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type ReactionState = {
    items: ReactionType[];
    isFetching: boolean;
}

const initialState: ReactionState = {
    items: [],
    isFetching: false
};

export const fetchReactions = createAsyncThunk<FetchReturnType<ReactionType>>(
    'reactions/fetch',
    async () => {
        const {data} = await axios.get('reactions');
        return data as FetchReturnType<ReactionType>;
    }
);

export const reactionSlice = createSlice({
    name: 'reaction',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchReactions.fulfilled, (state, action) => ({
                ...state,
                items: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchReactions.rejected, state => ({
                ...state,
                isFetching: false
            }))
            .addCase(fetchReactions.pending, state => ({
                ...state,
                isFetching: false
            }))
    }
});