import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ScreenView from '../../parts/ScreenView';
import styled from "styled-components";
import InformationBox from "../../parts/RegisterInformation/InformationBox";
import FlatButton from "../../parts/FlatButton";
import {useSelector} from "../../store";
import {fetchRegisterUser, resetRequestStatus, setErrors} from "../../stores/register_information";
import UserType from "../../interfaces/UserType";
import {useDispatch} from "react-redux";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import StepHeader from "../../parts/Headers/StepHeader";
import {BodyText} from "../../parts/AppStyles";
import {useHistory, useLocation} from "react-router-dom";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";
import Footer from "../../parts/Footer";
import {usePostalToAddress} from "../../utils/postalToAddress";

const SubmitButton = styled(FlatButton)`
  margin: 32px 16px 0;
`;

const WarningText = styled(BodyText)`
  color: ${({theme}) => theme.textGrayColor};
  margin-bottom: 16px;
`;

const RegisterInformation: React.FC = () => {

    const {register_information} = useSelector(state => ({
        register_information: state.register_information
    }));

    const [user, setUser] = useState<UserType | undefined>(register_information.registerUser);

    const dispatch = useDispatch();
    const history = useHistory();
    const {resolvePostCode} = usePostalToAddress()
    const {state: {old} = {old: undefined}} = useLocation<{ old: UserType | undefined; }>();

    useEffect(() => {
        if (old !== undefined) {
            setUser(old);
        }
    }, [old]);

    useEffect(() => {
        if (old === undefined) {
            dispatch(setErrors({}));
            dispatch(fetchRegisterUser());
        }
        dispatch(resetRequestStatus());
    }, [dispatch, old]);

    useEffect(() => {
        if (old === undefined) {
            setUser(register_information.registerUser);
        }
    }, [old, register_information.registerUser]);

    const onResolve = useCallback(async () => {
        const postal = user?.postal
        if (user && postal) {
            const result = await resolvePostCode(postal);
            setUser({...user, address: `${result.region}${result.locality}${result.street}${result.extended}`})
        }
    }, [user, resolvePostCode])

    const enabled = useMemo(() => {
        if (user === undefined) {
            return false;
        }
        return user.name.length > 0 &&
            (user.nickname?.length ?? 0) > 0 &&
            (user.birthday?.length ?? 0) > 0 &&
            (user.industry?.length ?? 0) > 0 &&
            (user.email?.length ?? 0) > 0 &&
            (user.tel?.length ?? 0) > 0 &&
            (user.password?.length ?? 0) > 0 &&
            (user.password_confirmation?.length ?? 0) > 0;
    }, [user]);

    const onSubmit = async () => {
        history.push('/register/confirm', {user: user!!});
    };

    if (user === undefined) {
        return <WithLoadingScreen loading={true}/>
    }

    return (
        <>
            <NoAuthHeader/>
            <StepHeader title={'新規登録'} step={'ステップ1/3'}/>
            <ScreenView scroll={true}>
                <InformationBox
                    label={'名前'}
                    value={user.name}
                    errors={register_information.errors}
                    name={'name'}
                    editable={false}/>
                <InformationBox
                    label={'かな'}
                    value={user.kana}
                    errors={register_information.errors}
                    name={'kana'}
                    editable={false}/>
                <InformationBox
                    label={'資格情報'}
                    value={user.qualifications_text}
                    errors={register_information.errors}
                    name={'qualifications_text'}
                    editable={false}/>
                <InformationBox
                    label={'ニックネーム(SNS交流で使用します)'}
                    value={user.nickname}
                    errors={register_information.errors}
                    name={'nickname'}
                    editable={true}
                    onInput={nickname => setUser({...user, nickname})}/>
                <InformationBox
                    label={'生年月日'}
                    value={user.birthday}
                    errors={register_information.errors}
                    name={'birthday'}
                    type={'date'}
                    editable={true}
                    onInput={birthday => setUser({...user, birthday})}/>
                <InformationBox
                    label={'職種'}
                    value={user.industry}
                    errors={register_information.errors}
                    name={'industry'}
                    editable={true}
                    onInput={industry => setUser({...user, industry})}/>
                <InformationBox
                    label={'所属先(任意)'}
                    value={user.attachment}
                    errors={register_information.errors}
                    name={'attachment'}
                    editable={true}
                    onInput={attachment => setUser({...user, attachment})}/>
                <InformationBox
                    label={'郵便番号'}
                    value={user.postal}
                    errors={register_information.errors}
                    name={'postal'}
                    editable={true}
                    onInput={postal => setUser({...user, postal})}/>
                <button type='button' onClick={onResolve}>
                    郵便番号から住所を入力
                </button>
                <InformationBox
                    label={'住所'}
                    value={user.address}
                    errors={register_information.errors}
                    name={'address'}
                    editable={true}
                    onInput={address => setUser({...user, address})}/>
                <InformationBox
                    label={'メールアドレス'}
                    value={user.email}
                    errors={register_information.errors}
                    name={'email'}
                    editable={true}
                    onInput={email => setUser({...user, email})}/>
                <WarningText>
                    ※入力間違いのないようにお気をつけください
                </WarningText>
                <InformationBox
                    label={'電話番号　※ハイフンなしで入力してください'}
                    value={user.tel}
                    errors={register_information.errors}
                    name={'tel'}
                    editable={true}
                    onInput={tel => setUser({...user, tel})}/>
                <InformationBox
                    label={'パスワード'}
                    value={user.password ?? ''}
                    errors={register_information.errors}
                    name={'password'}
                    editable={true}
                    type={'password'}
                    onInput={password => setUser({...user, password})}/>
                <WarningText>
                    ※ご自身の覚えやすいパスワードに変更してください。<br/>
                    パスワードは6文字以上で英小文字・数字を含めてください。
                </WarningText>
                <InformationBox
                    label={'パスワード確認'}
                    value={user.password_confirmation ?? ''}
                    errors={register_information.errors}
                    name={'password_confirmation'}
                    editable={true}
                    type={'password'}
                    onInput={password_confirmation => setUser({...user, password_confirmation})}/>
                <SubmitButton
                    text={'登録内容の確認へ'}
                    disabled={!enabled || register_information.requestStatus === 'pending'}
                    onPress={onSubmit}/>
            </ScreenView>
            <Footer/>
        </>
    );
};

export default RegisterInformation;
