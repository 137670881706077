import React from "react";
import JoinRequestType from "../../interfaces/JoinRequestType";
import styled from "styled-components";
import Image from "../Image";
import {TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.borderColor};
  padding: 8px 0;
`;

const UserFlex = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
`;

const UserImage = styled(Image)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-right: 8px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const UserBox = styled.div`
  flex: 1;
`;

const UserName = styled(TitleTextBox)`
  font-size: 18px;
`;

const Industry = styled(TitleTextBox)`
  margin-top: 2px;
  font-size: 14px;
  color: ${({theme}) => theme.textGrayColor};
`;

const Button = styled(TouchableOpacity)`
  border-radius: 8px;
  padding: 8px 16px;
  color: white;
  font-weight: bold;
`;

const AllowButton = styled(Button)`
  background-color: ${({theme}) => theme.mainColor};
`;

const DenyButton = styled(Button)`
  margin-left: 8px;
  background-color: ${({theme}) => theme.dangerColor};
`;

type Params = {
    item: JoinRequestType;
    onAllow: () => void;
    onDeny: () => void;
};

const JoinRequestItem: React.FC<Params> = ({item, onAllow, onDeny}) => {
    return (
        <Section>
            <UserFlex>
                <UserImage src={item.user.profile_url || 'https://placehold.jp/100x100.png'}/>
                <UserBox>
                    <UserName>{item.user.nickname}</UserName>
                    <Industry>{item.user.industry}</Industry>
                </UserBox>
            </UserFlex>
            <AllowButton onClick={onAllow}>承認</AllowButton>
            <DenyButton onClick={onDeny}>拒否</DenyButton>
        </Section>
    )
};

export default JoinRequestItem;