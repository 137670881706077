import React from 'react';
import styled from "styled-components";
import {BodyText, TitleTextBox} from "../AppStyles";
import visaImage from '../../assets/credit/visa300px.gif';
import masterImage from '../../assets/credit/master300px.gif';
import amexImage from '../../assets/credit/amex300px.gif';
import jcbImage from '../../assets/credit/jcb134.gif';
import discoverImage from '../../assets/credit/discover93.gif';
import Image from "../Image";

const Section = styled.div`
  padding: 16px;
  margin: 0 auto;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled(TitleTextBox)`
  font-size: 16px;
  font-weight: bold;
`;

const CreditImage = styled(Image)`
  width: 30px;
  height: 30px;
  margin-left: 4px;
`;

const BrandText = styled(BodyText)`

`;

const CreditInformation: React.FC = () => (
    <Section>
        <TitleBox>
            <Title>クレジットカード</Title>
            <CreditImage src={visaImage}/>
            <CreditImage src={masterImage}/>
            <CreditImage src={amexImage}/>
            <CreditImage src={jcbImage}/>
            <CreditImage src={discoverImage}/>
        </TitleBox>
        <BrandText>
            Visa, MasterCard, American Express, JCB, Discover
        </BrandText>
    </Section>
);

export default CreditInformation;
