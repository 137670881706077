import React, {useContext, useMemo} from 'react';
import styled, {ThemeContext} from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import SerialNumberType from "../../interfaces/SerialNumberType";
import Barcode from "../AccountTab/Barcode";

const CardBox = styled.div`
  margin-top: 24px;
  border: 2px solid ${({theme}) => theme.accentColor};
  border-radius: 8px;
  overflow: hidden;
  min-width: calc(100vw - 48px);
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (min-width: 480px) {
    min-width: 480px;
  }
`;

const Title = styled(TitleTextBox)`
  background-color: ${({theme}) => theme.accentColor};
  padding: 8px;
  color: #212121;
  text-align: center;
  font-size: 18px;
`;

const Information = styled(BodyTextBox)`
  text-align: center;
`;

const CardBody = styled.div`
  background-color: #FFFFFF;
  padding: 24px 8px 8px 8px;
`;

const BarcodeBox = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;
  width: 100%;
  height: 50px;
  padding: 0 4px;
`;

interface Props {
    serial_number: SerialNumberType;
}

const GuestMemberCard: React.FC<Props> = ({serial_number}) => {
    const theme = useContext(ThemeContext);

    const barcodeNumber = useMemo(() => {
        return (`000000000000${serial_number.id}`).slice(-12);
    }, [serial_number.id]);

    return (
        <CardBox>
            <Title>ゲスト会員様</Title>
            <CardBody>
                <Information>
                    イベント参加の際は{'\n'}
                    こちらを提示してください。
                </Information>
                <BarcodeBox>
                    <Barcode value={barcodeNumber}
                             format={'EAN13'}
                             text={barcodeNumber}
                             textColor={theme.textGrayColor}
                             onError={error => console.log(error)}
                             height={30}/>
                </BarcodeBox>
            </CardBody>
        </CardBox>
    );
};

export default GuestMemberCard;
