import React from 'react';
import styled from "styled-components";
import {TitleText} from "../AppStyles";

const Box = styled.div`
  border-left-width: 2px;
  border-left-color: ${({theme}) => theme.mainColor};
  border-left-style: solid;
  padding-left: 8px;
  margin-bottom: 16px;
`;

const Title = styled(TitleText)`
  font-size: 16px;
  font-weight: bold;
`;

interface Props {
    title: string;
}

const SectionHeader: React.FC<Props> = ({title}) => {
    return (
        <Box>
            <Title>{title}</Title>
        </Box>
    )
};

export default SectionHeader;
