import React from 'react';
import EventType from "../../interfaces/EventType";
import styled from "styled-components";
import {BodyText, TitleText} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";
import qualificationImage from '../../assets/icons/qualification.png';

const Box = styled.div`
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 8px;
  padding-right: 8px;

  @media screen and (min-width: 480px) {
    width: 25%;
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: auto;
  cursor: pointer;
`;

const Title = styled(TitleText)`
  margin-top: 4px;
  font-weight: bold;
  font-size: 14px;
`;

const QualificationFlex = styled.div`
  margin-top: 4px;
  flex-direction: row;
  align-items: center;
`;

const QualificationIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

const Qualification = styled(BodyText)`
  font-size: 12px;
  color: ${({theme}) => theme.textGrayColor};
`;

const ShowButton = styled(TouchableOpacity)`
  margin-top: 8px;
  padding: 4px 8px;
  border: 1px solid ${({theme}) => theme.mainColor};
  border-radius: 4px;
`;

const ShowButtonText = styled(TitleText)`
  color: ${({theme}) => theme.mainColor};
  text-align: center;
  font-weight: bold;
`;

interface Props {
    event: EventType;
    onPress: () => void;
}

const EventOverviewItem: React.FC<Props> = ({event, onPress}) => {
    return (
        <Box>
            {event.thumbnail_url !== null ? (
                <Image src={event.thumbnail_url!!} onClick={onPress}/>
            ) : <></>}
            <Title>{event.name}</Title>
          {event.qualifications.map(qualification => (
            <QualificationFlex key={qualification.qualification_id}>
              <QualificationIcon src={qualificationImage}/>
              <Qualification>
                {qualification.qualification_name} {qualification.unit}単位
              </Qualification>
            </QualificationFlex>
          ))}
            <ShowButton onClick={onPress}>
                <ShowButtonText>予約内容詳細</ShowButtonText>
            </ShowButton>
        </Box>
    )
};

export default EventOverviewItem;
