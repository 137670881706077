import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import ChatUserType from "../interfaces/ChatUserType";
import axios from "../http/axios";

export type ChatUserProfileState = {
    item: ChatUserType | null;
    isFetching: boolean;
}

const initialState: ChatUserProfileState = {
    item: null,
    isFetching: false
}

export const fetchChatUserProfile = createAsyncThunk<FetchDetailReturnType<ChatUserType>, any>(
    'fetch/chat_user_profile',
    async (arg) => {
        const {data} = await axios.get(`users/${arg}`);
        return data as FetchDetailReturnType<ChatUserType>;
    }
);

export const chatUserProfileSlice = createSlice({
    name: "chat_user_profile",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchChatUserProfile.fulfilled, (state, action) => ({
                ...state,
                item: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchChatUserProfile.rejected, state => ({
                ...state,
                item: null,
                isFetching: false
            }))
            .addCase(fetchChatUserProfile.pending, state => ({
                ...state,
                item: null,
                isFetching: true
            }))
    }
})