import React, {useMemo} from "react";
import {ChatTimelineState} from "../../stores/chat_timeline";
import ChatGroupType from "../../interfaces/ChatGroupType";
import ChatMessageType from "../../interfaces/ChatMessageType";
import MoreLoading from "../MoreLoading";
import ChatGroupEmptyView from "../ChatGroupThreadView/ChatGroupThreadEmpty";
import ChatMessage from "../ChatMessage/ChatMessage";
import styled from "styled-components";
import SectionLabel from "../SectionLabel";
import ChatMessageGroupHeader from "./ChatMessageGroupHeader";
import {InfiniteYScroll} from "../AppStyles";

const TimelineHeader = styled(SectionLabel)`
  margin-top: 24px;
`;

const MessageWrap = styled.div`
`;

interface Props {
    chatTimeline: ChatTimelineState;
    onPressChatGroup: (chatGroup: ChatGroupType) => void;
    onPressChatMessage: (chatMessage: ChatMessageType) => void;
    onEndReached: () => void;
}

const ChatTimelineList: React.FC<Props> = ({chatTimeline, onPressChatGroup, onPressChatMessage, onEndReached}) => {

    const hasMore = useMemo(() => {
        if (chatTimeline.lastRequestMeta === undefined) {
            return false;
        }
        return chatTimeline.lastRequestMeta.last_page > chatTimeline.lastRequestMeta.current_page;
    }, [chatTimeline.lastRequestMeta]);

    return (
        <>
            <TimelineHeader title={'新着投稿'} fontSize={22}/>
            <InfiniteYScroll
                next={onEndReached}
                hasMore={hasMore}
                loader={<MoreLoading/>}
                endMessage={<></>}
                dataLength={chatTimeline.items.length}>
                {chatTimeline.items.length === 0 ? (
                    <ChatGroupEmptyView/>
                ) : chatTimeline.items.map(item => (
                    <MessageWrap key={item.id}>
                        <ChatMessageGroupHeader chatGroup={item.group} onClick={onPressChatGroup}/>
                        <ChatMessage message={item} showPin={false}/>
                    </MessageWrap>
                ))}
            </InfiniteYScroll>
        </>
    );
};

export default ChatTimelineList;