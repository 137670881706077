import React from 'react';
import VideoWatchLogType from "../../interfaces/VideoWatchLogType";
import styled from "styled-components";
import ProgressBar from "../ProgressBar";
import {TitleText} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";

const Box = styled(TouchableOpacity)`
`;

const ThumbnailBox = styled.div`
  position: relative;
`;

const PlayIconBox = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  left: 60px;
  top: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  background-color: ${({theme}) => theme.mainColor};
`;

const ImageBox = styled.div`
  width: 150px;
  height: 84px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 150px;
`;

const Title = styled(TitleText)`
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  color: ${({theme}) => theme.textColor};
  max-width: 150px;
  display: inline-block;
`;

interface Props {
    videoWatchLog: VideoWatchLogType;
    onPress: () => void;
}

const VideoWatchLogItem: React.FC<Props> = ({videoWatchLog, onPress}) => {

    return (
        <Box onClick={onPress}>
            <ThumbnailBox>
                <ImageBox>
                    <Image src={videoWatchLog.video.thumbnail_url}/>
                </ImageBox>
                <PlayIconBox>
                    <i className={'fa fa-play'} style={{
                        color: '#FFFFFF',
                        fontSize: 16,
                    }}/>
                </PlayIconBox>
            </ThumbnailBox>
            <ProgressBar max={videoWatchLog.duration} value={videoWatchLog.position}/>
            <Title>{videoWatchLog.video.name}</Title>
        </Box>
    )
};

export default VideoWatchLogItem;
