import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import {TitleText} from "../AppStyles";
import ArrowIcon from "../ArrowIcon";
import TouchableOpacity from "../TouchableOpacity";
import examImage from '../../assets/icons/exam.png';

const ButtonBox = styled(TouchableOpacity)`
  background-color: ${({theme, disabled}) => disabled ? theme.disableColor : theme.subColor};
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin: 16px 24px;
`;

const FlexBase = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  width: 16px;
  height: 20px;
`;

const ButtonText = styled(TitleText)`
  color: ${({theme}) => theme.whiteColor};
  font-size: 16px;
  margin-left: 8px;
`;

interface Props {
    show: boolean;
    disabled: boolean;
    onPress: () => void;
}

const ExamButton: React.FC<Props> = ({show, disabled, onPress}) => {
    const theme = useContext(ThemeContext);
    if (!show) {
        return <></>
    }

    return (
        <ButtonBox onClick={disabled ? () => {} : onPress} disabled={disabled}>
            <FlexBase>
                <IconImage src={examImage}/>
                <ButtonText>内容確認のためのミニテストへ</ButtonText>
            </FlexBase>
            <ArrowIcon size={16} color={theme.whiteColor}/>
        </ButtonBox>
    )
};

export default ExamButton;
