import UserType from "../interfaces/UserType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import toastr from "toastr";
import ChatUserType from "../interfaces/ChatUserType";

export type ChatGroupUserState = {
    items: ChatUserType[];
    isFetching: boolean;
    selectGroupId?: number;
};

const initialState: ChatGroupUserState = {
    items: [],
    isFetching: false,
    selectGroupId: undefined,
}

export const fetchChatGroupUsers = createAsyncThunk<FetchReturnType<UserType>, any>(
    'fetch/chat_group_users',
    async (param) => {
        const {data} = await axios.get(`chat_groups/${param}/users`);
        return data as FetchReturnType<UserType>;
    }
)

type DetachRequest = {
    chatGroupId: any;
    userId: string;
}

export const detachChatGroupUser = createAsyncThunk<void, DetachRequest>(
    'detach/chat_group_user',
    async (param, thunk) => {
        await axios.delete(`chat_groups/${param.chatGroupId}/users/${param.userId}`);
        toastr.success('メンバーを除名しました。');
        thunk.dispatch(fetchChatGroupUsers(param.chatGroupId));
    }
);

export const chatGroupUserSlice = createSlice({
    name: 'chat_group_user',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchChatGroupUsers.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                items: action.payload.data,
                selectGroupId: action.meta.arg,
            }))
            .addCase(fetchChatGroupUsers.pending, (state, action) => {
                if (action.meta.arg === state.selectGroupId) {
                    return {
                        ...state,
                        isFetching: true
                    }
                } else {
                    return {
                        ...state,
                        items: [],
                        isFetching: true,
                    }
                }
            })
            .addCase(fetchChatGroupUsers.rejected, state => ({
                ...state,
                items: [],
                isFetching: false
            }))
    }
})