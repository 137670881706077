import React, {useContext} from "react";
import {TabMode} from "../stores/tab";
import styled, {ThemeContext} from "styled-components";
import TouchableOpacity from "./TouchableOpacity";
import Image from "./Image";
import homeIcon from "../assets/icons/home.png";
import videoIcon from "../assets/icons/play.png";
import eventIcon from "../assets/icons/event.png";
import snsIcon from "../assets/icons/sns.png";
import accountIcon from "../assets/icons/account.png";
import {TitleText} from "./AppStyles";

const Section = styled.nav`
  display: flex;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 480px) {
    display: none;
    position: unset;
  }
`;

const TabItem = styled(TouchableOpacity)<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 4px;
  flex: 1;
  transition: all 0.2s;
  color: ${({selected, theme}) => selected ? theme.mainColor : theme.textColor};
`;

const TabImage = styled(Image)`
  height: 24px;
  width: 24px;
`;

const TabText = styled(TitleText)`
  margin-top: 4px;
  font-size: 12px;
  font-weight: normal;
`;

type Props = {
    tab: TabMode;
    onTabChange: (tab: TabMode) => void;
};

const BottomTab: React.FC<Props> = ({tab, onTabChange}) => {
    const theme = useContext(ThemeContext);
    return (
        <Section>
            <TabItem onClick={() => onTabChange('home')} selected={tab === "home"}>
                <TabImage src={homeIcon} color={tab === "home" ? theme.mainColor : theme.textColor}/>
                <TabText>ホーム</TabText>
            </TabItem>
            <TabItem onClick={() => onTabChange('video')} selected={tab === "video"}>
                <TabImage src={videoIcon} color={tab === "video" ? theme.mainColor : theme.textColor}/>
                <TabText>動画視聴</TabText>
            </TabItem>
            <TabItem onClick={() => onTabChange('event')} selected={tab === "event"}>
                <TabImage src={eventIcon} color={tab === "event" ? theme.mainColor : theme.textColor}/>
                <TabText>イベント</TabText>
            </TabItem>
            <TabItem onClick={() => onTabChange('sns')} selected={tab === "sns"}>
                <TabImage src={snsIcon} color={tab === "sns" ? theme.mainColor : theme.textColor}/>
                <TabText>SNS交流</TabText>
            </TabItem>
            <TabItem onClick={() => onTabChange('account')} selected={tab === "account"}>
                <TabImage src={accountIcon} color={tab === "account" ? theme.mainColor : theme.textColor}/>
                <TabText>会員情報</TabText>
            </TabItem>
        </Section>
    )
};

export default BottomTab;