import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";
import BankTransferRequest from "../interfaces/BankTransferRequest";

export type PaymentUserBankState = {
    result?: BankTransferRequest | null;
    requestStatus: RequestStatusType;
};

const initialState: PaymentUserBankState = {
    result: null,
    requestStatus: 'init',
};

export const getPaymentUserBank = createAsyncThunk<FetchDetailReturnType<BankTransferRequest>>(
    'payment_user_bank',
    async (params, thunk) => {
        const {data} = await axios.post('bank_transfer_requests');
        return data as FetchDetailReturnType<BankTransferRequest>;
    }
);

export const paymentUserBankSlice = createSlice({
    name: 'payment_user_bank',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
    },
    extraReducers: builder => {
        builder
            .addCase(getPaymentUserBank.fulfilled, (state, action) => ({
                ...state,
                result: action.payload.data,
                requestStatus: 'success'
            }))
            .addCase(getPaymentUserBank.pending, state => ({
                ...state,
                result: null,
                requestStatus: 'pending'
            }))
            .addCase(getPaymentUserBank.rejected, state => ({
                ...state,
                result: null,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus} = paymentUserBankSlice.actions;
