import React from "react";
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";
import Icon from "../Icon";
import TouchableOpacity from "../TouchableOpacity";

const Section = styled.div`
  background-color: ${({theme}) => theme.mainColor};
  padding: 8px;
  text-align: center;
  position: relative;
`;

const Title = styled(TitleTextBox)`
  color: #FFFFFF;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
`;

const CloseTouch = styled(TouchableOpacity)`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
`;

const Close = styled(Icon)`
  color: #FFFFFF;
`;

type Props = {
    title: string;
    onClose: () => void;
};

const ModalHeader: React.FC<Props> = ({title, onClose}) => {
    return (
        <Section>
            <Title>{title}</Title>
            <CloseTouch onClick={onClose}>
                <Close name={'times'}/>
            </CloseTouch>
        </Section>
    )
};

export default ModalHeader;