import React from 'react';
import styled from "styled-components";
import TouchableOpacity from "./TouchableOpacity";
import Image from "./Image";

const Touchable = styled(TouchableOpacity)`
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 2px;
  height: 100px;
  width: 100px;
`;

const ThumbImage = styled(Image)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
    className?: string;
    src: string;
}

const ThumbImageView: React.FC<Props> = (props) => {
    return (
        <Touchable {...props} onClick={() => window.open(props.src)}>
            <ThumbImage src={props.src}/>
        </Touchable>
    );
};

export default ThumbImageView;
