import React from 'react';
import CategoryType from "../../interfaces/CategoryType";
import styled from "styled-components";
import {BodyTextBox, ContentWrap, TitleText} from "../AppStyles";
import backgroundImage from "../../assets/category_head_bg.png";
import backgroundImageLong from "../../assets/category_head_bg_long.png";
import nl2br from "../../utils/nl2br";

const Section = styled.div`
  padding: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.borderColor};
  border-bottom-style: solid;
`;

const Background = styled.div`
  width: 100%;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;

  &:before {
    content: "";
    display: block;
    padding-top: 24.7%;
  }

  @media screen and (min-width: 480px) {
    background-image: url(${backgroundImageLong});

    &:before {
      content: "";
      display: block;
      padding-top: 11.1%;
    }
  }
`;

const Title = styled(TitleText)`
  font-size: 24px;
  font-weight: bold;
  color: ${({theme}) => theme.whiteColor};
`;

const Description = styled(BodyTextBox)`
  margin-top: 20px;
  margin-bottom: 24px;
  color: ${({theme}) => theme.descriptionColor};
  font-weight: bold;
  font-size: 16px;
`;

interface Props {
    category: CategoryType;
}

const CategoryHeader: React.FC<Props> = ({category}) => {
    return (
        <Section>
            <ContentWrap>
                <Background>
                    <Title>{category.name}</Title>
                </Background>
                <Description>{nl2br(category.description)}</Description>
            </ContentWrap>
        </Section>
    )
};

export default CategoryHeader;
