import React from "react";
import { useSelector } from "../store";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

const Background = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: #EF4642;
  text-align: center;
`;

const PurchaseLink = styled(Link)`
  color: #FDF5A7;
  font-weight: bold;
  font-size: 20px;

  &:active, &:visited, &:hover {
    color: #FDF5A7;
  }
`;

const WarningText = styled.p`
  color: white;
  margin-bottom: 0;
  line-height: 1.2em;
`;

const BankRequestButton: React.FC = () => {

    const { user } = useSelector(state => ({
        user: state.user,
    }));

    const expiredAt = user.user?.expired_at;
    
    if (expiredAt == null) {
        return <></>
    }
    const expiredAtDate = new Date(expiredAt);

    if (user.user == null) {
        return <></>
    }
    if (!user.user.require_bank_transaction) {
        return <></>
    }

    // 更新月は現在の月より1ヶ月前の月
    if (moment().month() !== 11) {
        // 12月以外の場合は、更新年が現在の年の場合のみ表示
        if(moment().year() !== expiredAtDate.getFullYear()) {
            return <></>
        }
        // 12月以外の場合は、更新月が現在の月の場合のみ表示
        if(moment().month() !== expiredAtDate.getMonth() - 1) {
            return <></>
        }
    } else if ( moment().month() == 11) {
        // 12月の場合は、更新年が来年の場合のみ表示
        if(expiredAtDate.getFullYear() !== moment().year() + 1) {
            return <></>
        }
        // 12月の場合は、更新月が1月の場合のみ表示
        if(expiredAtDate.getMonth() !== 0) {
            return <></>
        }
    }
    // 現在の月が1月ならば現在の日付が5日より小さい場合は表示しない。
    if (moment().month() == 0 && moment().date() <= 4) {
        return <></>
    }

    return (
        <Background>
            <PurchaseLink to={'/purchase/next'}>次年度利用料のお支払い＞</PurchaseLink>
            <WarningText>
                次年度の利用料の更新が近づいてきました。今月中にお手続きをお願いいたします。
            </WarningText>
        </Background>
    );
};

export default BankRequestButton;