import React, {useContext} from 'react';
import {ThemeContext} from "styled-components";
import {Cell, Pie, PieChart} from 'recharts';

interface Props {
    value: number;
    max: number;
}

const UnitPieChart: React.FC<Props> = ({value, max}) => {
    const theme = useContext(ThemeContext);
    return (
        <PieChart
            width={50}
            height={50}>
            <Pie
                data={[
                    {
                        name: '全体',
                        color: theme.mainColor,
                        population: max - value
                    },
                    {
                        name: '達成率',
                        color: theme.pieColor,
                        population: value
                    },
                ]}
                dataKey={"population"}
                legendType={'none'}
                fill={theme.pieColor}
            >
                <Cell fill={theme.pieColor}/>
                <Cell fill={theme.mainColor}/>
            </Pie>
        </PieChart>
    )
};

export default UnitPieChart;
