import React from "react";
import styled from "styled-components";
import nl2br from "../utils/nl2br";

const Dialog = styled.div`
  padding: 24px 16px;
  background-color: #27C6BC;
  border-radius: 2px;
  color: white;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px #A1A1A1;
`;

const Message = styled.h2`
  margin: 0;
  font-size: 20px;
`;

const ButtonFlex = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center
`;

const Button = styled.div`
  padding: 8px;
  margin-right: 8px;
  border: 1px solid #FFFFFF;
  border-radius: 2px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s;
  font-weight: bold;

  &:hover {
    background-color: #FFFFFF;
    color: #27C6BC;
  }
`;

type Props = {
    message: string;
    onClick: () => void;
    onCancel?: () => void;
}

const ConfirmDialog: React.FC<Props> = ({message, onClick, onCancel}) => {
    return (
        <Dialog>
            <Message>{nl2br(message)}</Message>
            <ButtonFlex>
                <Button onClick={onClick}>OK</Button>
                {onCancel ? (
                    <Button onClick={onCancel}>キャンセル</Button>
                ) : <></>}
            </ButtonFlex>
        </Dialog>
    )
};

export default ConfirmDialog;