import React, {useContext} from 'react';
import ContactParams from "../../interfaces/ContactParams";
import styled, {ThemeContext} from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import FlatButton from "../FlatButton";

const Section = styled.div`
  margin-top: -24px;
`;

const Label = styled(TitleTextBox)`
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  margin-top: 24px;
`;

const Value = styled(BodyTextBox)`
  font-size: 16px;
  margin-top: 8px;
`;

const WarnText = styled(BodyTextBox)`
  color: ${({theme}) => theme.dangerColor};
  border: 1px solid ${({theme}) => theme.dangerColor};
  background-color: ${({theme}) => theme.dangerBackgroundColor};
  padding: 8px;
  margin-top: 8px;
`;

const ButtonFlex = styled.div`
  flex-direction: row;
  @media screen and (min-width: 480px) {
    display: flex;
  }
`;

const SubmitButton = styled(FlatButton)`
  margin-top: 48px;

  @media screen and (min-width: 480px) {
    margin-top: 0;
  }
`;

const CancelButton = styled(FlatButton)`
  margin: 24px 0;
`;

interface Props {
    contact: ContactParams;
    onSubmit: () => void;
    onCancel: () => void;
    isProcessing: boolean;
}

const ContactConfirmForm: React.FC<Props> = ({contact, onSubmit, onCancel, isProcessing}) => {
    const theme = useContext(ThemeContext);

    return (
        <Section>
            <Label>名前</Label>
            <Value>{contact.name}</Value>
            <Label>メールアドレス</Label>
            <Value>{contact.email}</Value>
            <WarnText>
                ※入力ミスにご注意ください。<br/>
                ※携帯会社メールアドレスの場合、返信が届かない場合がございます。<br/>
                ※ご返信はココリンク内で各個人にお送りいたします。お知らせに表示されます。<br/>
                ※試験に関するお問い合わせは、各協会へお問い合わせください。
            </WarnText>
            <Label>お問い合わせ内容</Label>
            <Value>{contact.message}</Value>
            <ButtonFlex>
                <SubmitButton text={'この内容で送信する'}
                              rightAllow
                              disabled={isProcessing}
                              onPress={onSubmit}/>
                <CancelButton text={'入力内容の修正'}
                              disabled={isProcessing}
                              backgroundColor={theme.whiteColor}
                              borderColor={theme.mainColor}
                              textColor={theme.mainColor}
                              onPress={onCancel}/>
            </ButtonFlex>
        </Section>
    )
};

export default ContactConfirmForm;
