import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../http/axios";

export type ReportChatMessage = {
    requestStatus: RequestStatusType;
}

const initialState: ReportChatMessage = {
    requestStatus: 'init'
};


type ReportParams = {
    chatMessageId: number;
    reason: string;
};

export const reportChatMessage = createAsyncThunk<void, ReportParams>(
    'chat_messages/report',
    async (arg) => {
        await axios.post(`chat_messages/${arg.chatMessageId}/report`, {reason: arg.reason});
    }
);

export const reportChatMessageSlice = createSlice({
    name: 'report_chat_message',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(reportChatMessage.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(reportChatMessage.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(reportChatMessage.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus} = reportChatMessageSlice.actions;
