import UnitHistoryType from "../interfaces/UnitHistoryType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type UnitHistoryState = {
    items: UnitHistoryType[];
    isFetching: boolean;
}

const initialState: UnitHistoryState = {
    items: [],
    isFetching: false
};

export const fetchUnitHistories = createAsyncThunk<FetchReturnType<UnitHistoryType>, void>(
    'unit_histories',
    async () => {
        const {data} = await axios.get('user/unit_histories');
        return data as FetchReturnType<UnitHistoryType>;
    }
);

export const unitHistorySlice = createSlice({
    name: 'unit_histories',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUnitHistories.fulfilled, (state, action) => ({
                ...state,
                items: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchUnitHistories.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchUnitHistories.rejected, state => ({
                ...state,
                isFetching: false
            }))
    }
});