import React from 'react';
import ScreenView from '../../parts/ScreenView';
import styled from "styled-components";
import FlatButton from "../../parts/FlatButton";
import {BodyTextBox} from "../../parts/AppStyles";
import StepHeader from "../../parts/Headers/StepHeader";
import {useHistory} from "react-router-dom";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";
import Footer from "../../parts/Footer";

const Description = styled(BodyTextBox)`
  margin-top: 32px;
  font-size: 16px;
  line-height: 20px;
  color: ${({theme}) => theme.textGrayColor};
  text-align: center;
`;

const WarningText = styled(BodyTextBox)`
  color: ${({theme}) => theme.dangerColor};
  text-align: center;
  margin-top: 24px;
  font-size: 12px;
`;

const PurchaseButton = styled(FlatButton)`
  margin: 32px 16px;
`;

const RegisterSuccess: React.FC = () => {
    const history = useHistory();
    return (
        <>
            <NoAuthHeader/>
            <StepHeader title={'登録完了'} step={'ステップ2/3'}/>
            <ScreenView>
                <Description>
                    登録が完了いたしました。{'\n'}
                    メールをご確認ください。
                </Description>
                <WarningText>
                    ※ 届かない場合は事務局へお問い合わせください。{'\n'}
                    公式Webサイトよりお問い合わせください。
                </WarningText>
                <PurchaseButton
                    text={'お支払いプランの選択へ'}
                    rightAllow
                    onPress={() => history.push('/purchase')}/>
            </ScreenView>
            <Footer/>
        </>
    )
};

export default RegisterSuccess;
