import React from 'react';
import PageHeader from "../../parts/Headers/PageHeader";
import ScreenView from "../../parts/ScreenView";
import styled from "styled-components";
import {BodyTextBox} from "../../parts/AppStyles";
import {useSelector} from "../../store";
import FlatButton from "../../parts/FlatButton";
import mailIcon from '../../assets/icons/mail.png';
import {useHistory} from "react-router-dom";

const Information = styled(BodyTextBox)`
  margin-top: 24px;
  text-align: center;
`;

const BackButton = styled(FlatButton)`
  margin-top: 48px;
`;

const ContactSuccess: React.FC = () => {

    const {auth} = useSelector(state => ({
        auth: state.auth
    }));
    const history = useHistory();

    return (
        <>
            <PageHeader title={'お問い合わせ送信完了'}
                        iconSource={mailIcon}
                        disableGoBack/>
            <ScreenView>
                {auth.authenticated ? (
                    <Information>
                        お問い合わせが完了しました。{'\n'}
                        確認後、お知らせとメールにて通知されます。
                    </Information>
                ) : (
                    <Information>
                        お問い合わせが完了しました。{'\n'}
                        確認後、メールにて通知されます。
                    </Information>
                )}
                <BackButton
                    text={'トップに戻る'}
                    leftAllow
                    borderRadius={8}
                    backgroundColor={'#3C3C3C'}
                    onPress={() => history.push('/home')}/>
            </ScreenView>
        </>
    )
};

export default ContactSuccess;
