import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type TabMode = 'home' | 'video' | 'event' | 'sns' | 'account';

export type TabState = {
    tab: TabMode;
};

const initialState: TabState = {
    tab: "home"
};

export const tabSlice = createSlice({
    name: 'tab',
    initialState,
    reducers: {
        changeTab: (state, action: PayloadAction<TabMode>) => ({
            ...state,
            tab: action.payload,
        }),
    }
});

export const {changeTab} = tabSlice.actions;