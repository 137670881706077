import React from 'react';
import styled from "styled-components";
import {BodyText, TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";
import Icon from "../Icon";

const Box = styled(TouchableOpacity)<{ selected: boolean }>`
  border: 1px solid ${({theme, selected}) => selected ? '#212121' : theme.borderColor};
  padding: 24px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
`;

const InformationBox = styled.div`
  flex: 1;
`;

const Title = styled(TitleTextBox)`
  font-size: 16px;
  color: ${({theme}) => theme.textGrayColor};
`;

const Price = styled(TitleTextBox)`
  margin-top: 8px;
  font-size: 24px;
`;

const MiniText = styled(BodyText)`
  font-size: 16px;
`;

const CheckBox = styled.div<{ selected: boolean }>`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid #212121;
  background-color: ${({selected}) => selected ? '#212121' : '#FFFFFF'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
`;

interface Props {
    title: string;
    price: number;
    isYear: boolean;
    selected: boolean;
    onSelect: () => void;
}

const MethodCheckBox: React.FC<Props> = ({title, price, isYear, selected, onSelect}) => {
    if (isNaN(price)) {
        return <></>
    }
    return (
        <Box onClick={onSelect} selected={selected}>
            <InformationBox>
                <Title>{title}</Title>
                <Price>{price.toLocaleString()}<MiniText>円（税込）</MiniText>/ {isYear ? '年' : '月'}</Price>
            </InformationBox>
            <CheckBox selected={selected}>
                <Icon name={'check'} color={'#FFFFFF'} size={20}/>
            </CheckBox>
        </Box>
    );
};

export default MethodCheckBox;
