import React from 'react';
import styled from "styled-components";

const Div = styled.div`
  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.6;
  }
`;

const TouchableOpacity: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => {

    return (
        // @ts-ignore
        <Div {...props}/>
    )
};

export default TouchableOpacity;