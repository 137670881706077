import React from 'react';
import NoticeType from "../../interfaces/NoticeType";
import styled from "styled-components";
import NoticeTypeIcon from "./NoticeTypeIcon";
import {BodyText, BodyTextBox, ContentWrap, TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";
import Icon from "../Icon";
import {ExclamationCircleFilled} from "@ant-design/icons";

const Section = styled(TouchableOpacity)<{ important: boolean }>`
  border-bottom-width: 1px;
  border-bottom-color: ${({theme}) => theme.borderColor};
  border-bottom-style: solid;
  padding: 16px;
  background-color: ${({theme, important}) => important ? theme.importantBackgroundColor : theme.whiteColor};
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const InformationBox = styled.div`
  flex: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(BodyTextBox)`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImportantMark = styled(ExclamationCircleFilled)`
  margin-right: 4px;
  color: red;
`;

const UnReadLabel = styled(TitleTextBox)`
  word-break: keep-all;
  font-size: 16px;
  color: white;
  background-color: ${({theme}) => theme.dangerColor};
  border-radius: 2px;
  padding: 2px 4px;
  margin-right: 4px;
`;

const ArrowBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Arrow = styled(Icon)`
  margin-left: 8px;
  color: ${({theme}) => theme.mainColor};
`;

const DateText = styled(BodyText)`
  margin-top: 8px;
  text-align: right;
  color: ${({theme}) => theme.textGrayColor};
  font-size: 12px;
`;

interface Props {
    notice: NoticeType<any>;
    onPress: () => void;
}

const NoticeListItem: React.FC<Props> = ({notice, onPress}) => {
    return (
        <Section onClick={onPress} important={notice.important}>
            <ContentWrap>
                <FlexRow>
                    <NoticeTypeIcon notice={notice}/>
                    <InformationBox>
                        <Title>
                            {notice.important ? (
                                <ImportantMark size={16}/>
                            ) : <></>}
                            {!notice.is_read ? (
                                <UnReadLabel>未読</UnReadLabel>
                            ) : <></>}
                            {notice.title}
                        </Title>
                    </InformationBox>
                    <ArrowBox>
                        <Arrow name={'angle-right'} size={30}/>
                    </ArrowBox>
                </FlexRow>
                <DateText>{notice.published_at}</DateText>
            </ContentWrap>
        </Section>
    );
};

export default NoticeListItem;
