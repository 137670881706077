import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import RegisterLoginForm from "../../parts/Register/RegisterLoginForm";
import ScreenView from '../../parts/ScreenView';
import BackButtonSection from "./../../parts/SelectService/BackButtonSection";
import {useDispatch} from "react-redux";
import {useSelector} from "../../store";
import {execRegister, resetRequestStatus, setErrors} from "../../stores/register";
import {BodyTextBox} from "../../parts/AppStyles";
import StepHeader from "../../parts/Headers/StepHeader";
import {useHistory} from "react-router-dom";
import * as toastr from "toastr";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";
import Footer from "../../parts/Footer";
import registerEnable from "../../utils/registerEnable";

const Description = styled(BodyTextBox)`
  margin-top: 24px;
  font-size: 14px;
  color: ${({theme}) => theme.textGrayColor};
`;

interface Request {
    id: string;
    password: string;
}

const Register: React.FC = () => {

    const {register} = useSelector(state => ({
        register: state.register
    }));

    const dispatch = useDispatch();
    const history = useHistory();

    const [request, setRequest] = useState<Request>({
        id: '',
        password: ''
    });

    useEffect(() => {
        dispatch(setErrors({}));
    }, []);

    useEffect(() => {
        if (!registerEnable()) {
            history.replace('/login');
        }
    }, [history]);

    useEffect(() => {
        if (register.requestStatus === 'success') {
            history.push('/register/information');
            dispatch(resetRequestStatus());
        } else if (register.requestStatus === 'failed') {
            toastr.error('新規登録できませんでした。');
            dispatch(resetRequestStatus());
        }
    }, [dispatch, history, register.requestStatus]);

    const onSubmit = async () => {
        dispatch(setErrors({}));
        dispatch(execRegister(request));
    };

    return (
        <>
            <NoAuthHeader/>
            <StepHeader title={'新規登録'} step={'ステップ1/3'}/>
            <ScreenView scroll={true}>
                <Description>
                    郵送されてきたＩＤ・パスワードを入力してください
                </Description>
                <RegisterLoginForm
                    userId={request.id}
                    password={request.password}
                    onUserIdChange={id => setRequest({...request, id})}
                    onPasswordChange={password => setRequest({...request, password})}
                    onSubmitPress={onSubmit}
                    errors={register.errors}
                    isProcessing={register.requestStatus === 'pending'}/>
                <BackButtonSection onBackPress={() => history.goBack()}/>
            </ScreenView>
            <Footer/>
        </>
    )
};

export default Register;
