import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {TitleText} from "../AppStyles";

const Button = styled(Link)`
  display: block;
  padding: 8px 16px;
  background-color: ${({theme}) => theme.mainColor};
  text-decoration: none;
  text-align: center;
  margin-top: 16px;
  border-radius: 8px;
`;

const Text = styled(TitleText)`
  color: white;
  font-weight: bold;
`;

const LoginButton: React.FC = () => {
return (
    <Button to={'/login'}>
        <Text>ログイン</Text>
    </Button>
)
};

export default LoginButton;