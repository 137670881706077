import React, {useCallback} from "react";
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";
import Icon from "../Icon";

const Section = styled.div`
  flex: 1;
  
  @media screen and (min-width: 480px) {
    margin-right: 10px;
  }
`;

const File = styled.input`
  display: none;
`;

const Button = styled.label<{ selected: boolean }>`
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 8px 16px;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${({selected}) => selected ? '#EDFFFB' : '#FFFFFF'};
`;

const ButtonIcon = styled(Icon)`
  color: ${({theme}) => theme.mainColor};
`;

const ButtonText = styled(TitleTextBox)`
  margin-left: 8px;
  color: ${({theme}) => theme.mainColor};
  font-weight: bold;
`;

type Props = {
    onSelect: (image: File) => void;
    selected: boolean;
}

const SelectImageButton: React.FC<Props> = ({onSelect, selected}) => {

    const onFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            onSelect(e.target.files[0])
        }
    }, [onSelect]);

    return (
        <Section>
            <File type={'file'}
                  onChange={onFileSelect}
                  id={'select-image'}
                  accept={'.png,.jpeg,.jpg'}/>
            <Button htmlFor={'select-image'} selected={selected}>
                <ButtonIcon name={'camera'}/>
                <ButtonText>画像を選択</ButtonText>
            </Button>
        </Section>
    )
};

export default SelectImageButton;
