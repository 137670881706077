import React, {useEffect, useState} from 'react';
import BackButtonSection from "../../parts/SelectService/BackButtonSection";
import LoginForm from "../../parts/LoginScreen/LoginForm";
import {useDispatch} from "react-redux";
import {execLogin, resetRequestStatus, setErrors} from '../../stores/auth';
import ScreenView from '../../parts/ScreenView';
import {useSelector} from "../../store";
import PageHeader from "../../parts/Headers/PageHeader";
import * as toastr from "toastr";
import {useHistory} from "react-router-dom";
import Footer from "../../parts/Footer";
import {Helmet} from "react-helmet-async";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";

interface LoginRequest {
    id: string;
    password: string;
}

const LoginScreen: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {auth} = useSelector(state => ({
        auth: state.auth
    }));

    const [request, setRequest] = useState<LoginRequest>({
        id: '',
        password: ''
    });

    useEffect(() => {
        dispatch(setErrors({}));
        return () => {
            dispatch(resetRequestStatus());
        }
    }, [dispatch]);

    useEffect(() => {
        if (auth.requestStatus === 'success') {
            history.push('/home');
            dispatch(resetRequestStatus());
        } else if (auth.requestStatus === 'failed') {
            toastr.error('ログインできませんでした。');
            dispatch(resetRequestStatus());
        }
    }, [auth.requestStatus, dispatch, history]);

    const onSubmit = async () => {
        dispatch(setErrors({}));
        dispatch(execLogin(request));
    };

    return (
        <>
            <Helmet>
                <title>ログイン</title>
            </Helmet>
            <NoAuthHeader/>
            <div>
                <PageHeader title={'ログイン'} disableGoBack={true}/>
            </div>
            <ScreenView>
                <LoginForm userId={request.id}
                           password={request.password}
                           isProcessing={auth.requestStatus === 'pending'}
                           errors={auth.errors}
                           onUserIdChange={id => setRequest({...request, id})}
                           onPasswordChange={password => setRequest({...request, password})}
                           onRegisterPress={() => history.push('/register')}
                           onSubmitPress={onSubmit}/>
                <BackButtonSection onBackPress={() => history.goBack()}/>
            </ScreenView>
            <Footer/>
        </>
    )
};

export default LoginScreen;
