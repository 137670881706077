import React, {useCallback, useMemo, useState} from "react";
import ChatMessageType from "../../interfaces/ChatMessageType";
import ReactionType from "../../interfaces/ReactionType";
import reactionActiveClose from "../../assets/icons/reaction_close_active.png";
import reactionClose from "../../assets/icons/reaction_close.png";
import reactionActiveIcon from "../../assets/icons/reaction_active.png";
import reactionIcon from "../../assets/icons/reaction.png";
import ReactionMenu from "./ReactionMenu";
import ShowRecentReaction from "./ShowRecentReaction";
import styled from "styled-components";
import TouchableOpacity from "../TouchableOpacity";
import Image from "../Image";

const ReactionButton = styled(TouchableOpacity)<{ active: boolean }>`
  background-color: ${({theme, active}) => active ? theme.mainColor : theme.whiteColor};
  border-width: 2px;
  border-style: solid;
  border-color: ${({theme, active}) => active ? theme.mainColor : theme.borderColor};
  border-radius: 8px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReactionImage = styled(Image)`
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReactionWrap = styled.div`
  position: relative;
`;

interface Props {
    message: ChatMessageType;
    onReaction: (reaction?: ReactionType) => void;
    onShowReactions: () => void;
}

const ReactionView: React.FC<Props> = ({message, onShowReactions, onReaction}) => {
    const [showReactionMenu, setShowReactionMenu] = useState<boolean>(false);

    const reactionButtonImage = useMemo(() => {
        if (showReactionMenu) {
            return message.has_own_reaction ? reactionActiveClose : reactionClose;
        } else {
            return message.has_own_reaction ? reactionActiveIcon : reactionIcon;
        }
    }, [message, showReactionMenu]);

    const onReactionMenu = useCallback(() => {
        if (showReactionMenu) {
            setShowReactionMenu(false);
        } else if (message.has_own_reaction) {
            onReaction();
        } else {
            setShowReactionMenu(true);
        }
    }, [message, onReaction, showReactionMenu]);

    const onReactionClick = useCallback((reaction: ReactionType) => {
        setShowReactionMenu(false);
        onReaction(reaction);
    }, [onReaction]);

    return (
        <>
            <ReactionWrap>
                <ReactionButton onClick={onReactionMenu}
                                active={message.has_own_reaction}>
                    <ReactionImage src={reactionButtonImage} alt={'リアクション'}/>
                </ReactionButton>
                <ReactionMenu onSelect={onReactionClick} show={showReactionMenu}/>
            </ReactionWrap>
            <ShowRecentReaction reactions={message.recent_reactions}
                                onShowReactions={onShowReactions}
                                reaction_count={message.reactions_count}/>
        </>
    )
};

export default ReactionView;