import React from 'react';
import EventType from "../../interfaces/EventType";
import styled from "styled-components";
import {BodyText, TitleTextBox} from "../AppStyles";
import {useSelector} from "../../store";
import TouchableOpacity from "../TouchableOpacity";
import NonMemberLink from "./NonMemberLink";

const SafeArea = styled.div`
  background-color: #FFFFFF;
`;

const Section = styled.div`
  padding: 16px 4px;
  border-top-width: 1px;
  border-top-color: ${({theme}) => theme.borderColor};
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 480px) {
    padding: 16px;
  }
`;

const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonBox = styled.div`
  flex: 1;
  margin-left: auto;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ApplyButton = styled(TouchableOpacity)<{ disabled: boolean }>`
  background-color: ${({theme, disabled}) => disabled ? theme.disableColor : theme.reserveButtonColor};
  border-radius: 8px;
  padding: 8px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
`;

const ApplyButtonLabel = styled(TitleTextBox)`
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

const MemberPriceBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const MemberPriceLabel = styled(TitleTextBox)`
  color: ${({theme}) => theme.mainColor};
  font-size: 14px;
  font-weight: bold;
  border: 1px solid ${({theme}) => theme.mainColor};
  padding: 4px;
`;

const MemberPriceText = styled(BodyText)`
  color: ${({theme}) => theme.mainColor};
  margin-left: 8px;
  font-weight: bold;
  font-size: 32px;
`;

const MemberPriceEn = styled(BodyText)`
  font-size: 16px;
`;

const NonMemberPrice = styled(BodyText)`
  font-size: 18px;
  text-align: center;
  margin-top: 4px;
`;

const PleaseLoginText = styled(BodyText)`
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-top: 4px;
`;

interface Props {
    event: EventType;
    onPress: () => void;
    onPressNonMember: () => void;
}

const ApplyForm: React.FC<Props> = ({event, onPress, onPressNonMember}) => {
    const {auth} = useSelector(state => ({
        auth: state.auth
    }));
    return (
        <SafeArea>
            <Section>
                <PriceBox>
                    <MemberPriceBox>
                        <MemberPriceLabel>
                            会員<br/>価格
                        </MemberPriceLabel>
                        {event.price === 0 ? (
                            <MemberPriceText>
                                無料
                            </MemberPriceText>
                        ) : (
                            <MemberPriceText>
                                {event.price.toLocaleString()}<MemberPriceEn>円</MemberPriceEn>
                            </MemberPriceText>
                        )}
                    </MemberPriceBox>
                    {event.non_member_price === 0 ? (
                        <NonMemberPrice>
                            (通常無料)
                        </NonMemberPrice>
                    ) : (
                        <NonMemberPrice>
                            (通常{event.non_member_price.toLocaleString()}円)
                        </NonMemberPrice>
                    )}
                </PriceBox>
                <ButtonBox>
                    <ApplyButton onClick={onPress}
                                 disabled={event.is_own || !event.can_apply || event.over_capacity}>
                        <ApplyButtonLabel>{(() => {
                            if (event.is_own) {
                                return '予約済み';
                            } else if (event.over_capacity) {
                                return '定員に達しました';
                            } else if (event.can_apply) {
                                return '予約へ進む';
                            } else {
                                return '予約期間外';
                            }
                        })()}</ApplyButtonLabel>
                        {auth.authenticated ? <></> : (
                            <PleaseLoginText>会員の方はログインしてください</PleaseLoginText>
                        )}
                    </ApplyButton>
                    {event.is_own ? <></> : (
                        <NonMemberLink onPress={onPressNonMember} event={event}/>
                    )}
                </ButtonBox>
            </Section>
        </SafeArea>
    )
};

export default ApplyForm;
