import React, {ChangeEvent, useCallback, useMemo} from "react";
import styled from "styled-components";

const Box = styled.div`
  position: relative;
  display: inline-block;
`;

const Select = styled.select`
  display: block;
  padding: 8px 32px 8px 16px;
  margin-top: 8px;
  appearance: none;
  outline: none;
  border: 1px solid ${({theme}) => theme.subColor};
`;

const Arrow = styled.div`
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 18px;
  border-bottom: ${({theme}) => theme.subColor} 2px solid;
  border-right: ${({theme}) => theme.subColor} 2px solid;
  transform: rotate(45deg) translateY(-30%);
  pointer-events: none;
`;

type Props = {
    value: string | undefined;
    onChange: (reason?: string) => void;
};

const SelectReason: React.FC<Props> = ({value, onChange}) => {

    const reasons = useMemo(() => {
        return [
            {value: "中傷・侮辱・名誉毀損", label: "中傷・侮辱・名誉毀損"},
            {value: "脅迫", label: "脅迫"},
            {value: "個人情報・プライバシー", label: "個人情報・プライバシー"},
            {value: "つきまとい・ストーカー", label: "つきまとい・ストーカー"},
            {value: "連投・負荷増大・無意味な羅列", label: "連投・負荷増大・無意味な羅列"},
            {value: "広告・スパム行為", label: "広告・スパム行為"},
            {value: "権利侵害", label: "権利侵害"},
            {value: "荒らし・嫌がらせ・混乱の元", label: "荒らし・嫌がらせ・混乱の元"},
            {value: "政治・宗教・民族", label: "政治・宗教・民族"},
            {value: "センシティブな内容", label: "センシティブな内容"},
        ];
    }, []);

    const onValueChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value) {
            onChange(e.target.value);
        } else {
            onChange(undefined);
        }
    }, [onChange]);

    return (
        <Box>
            <Select onChange={onValueChange} value={value}>
                <option value=''>選択してください</option>
                {reasons.map(reason => (
                    <option value={reason.value} key={reason.value}>{reason.label}</option>
                ))}
            </Select>
            <Arrow/>
        </Box>
    )
};

export default SelectReason;