import QualificationType from "../interfaces/QualificationType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

type QualificationDetailState ={
    item: QualificationType | null;
    isFetching: boolean;
}

const initialState: QualificationDetailState = {
    item: null,
    isFetching: false
};

export const fetchQualificationDetail = createAsyncThunk<FetchDetailReturnType<QualificationType>, any>(
    'qualification.detail',
    async (param) => {
        const {data} = await axios.get(`qualifications/${param}`);
        return data as FetchDetailReturnType<QualificationType>;
    }
);

export const qualificationDetailSlice = createSlice({
    name: 'qualification_detail',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchQualificationDetail.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                item: action.payload.data
            }))
            .addCase(fetchQualificationDetail.rejected, state => ({
                ...state,
                isFetching: false,
            }))
            .addCase(fetchQualificationDetail.pending, state => ({
                ...state,
                isFetching: true,
                item: null
            }))
    }
})
