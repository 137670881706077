import React from 'react';
import styled from "styled-components";
import {BodyTextBox, ContentWrap} from "../AppStyles";

const Information = styled(BodyTextBox)`
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.descriptionColor};
`;

const CategoryPageEmpty: React.FC = () => {
    return (
        <Information>
            <ContentWrap>
                このカテゴリには動画がありません。
            </ContentWrap>
        </Information>
    )
};

export default CategoryPageEmpty;
