import PaymentUserSelectType from "../interfaces/PaymentUserSelectType";
import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type PaymentUserSelectState = {
    result?: PaymentUserSelectType;
    requestStatus: RequestStatusType;
};

const initialState: PaymentUserSelectState = {
    result: undefined,
    requestStatus: "init"
};

export const getPaymentUserSelect = createAsyncThunk<FetchDetailReturnType<PaymentUserSelectType>>(
    'payment_user_select',
    async () => {
        const {data} = await axios.get('user/payments');
        return data as FetchDetailReturnType<PaymentUserSelectType>;
    }
);

export const paymentUserSelectSlice = createSlice({
    name: 'payment_user_select',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'})
    },
    extraReducers: builder => {
        builder
            .addCase(getPaymentUserSelect.fulfilled, (state, action) => ({
                ...state,
                result: action.payload.data,
                requestStatus: 'success'
            }))
            .addCase(getPaymentUserSelect.pending, state => ({
                ...state,
                requestStatus: 'pending',
                result: undefined
            }))
            .addCase(getPaymentUserSelect.rejected, state => ({
                ...state,
                requestStatus: 'failed',
                result: undefined
            }));
    }
});

export const {resetRequestStatus} = paymentUserSelectSlice.actions;
