import React, {useContext} from 'react';
import NoticeType from "../../interfaces/NoticeType";
import styled, {ThemeContext} from "styled-components";
import Image from "../Image";
import bellIcon from '../../assets/icons/bell.png';
import playIcon from '../../assets/icons/play.png';
import eventIcon from '../../assets/icons/event.png';
import qualificationIcon from '../../assets/icons/qualification.png';
import mailIcon from '../../assets/icons/mail.png';
import snsIcon from '../../assets/icons/sns.png';

const IconBox = styled.div`
  background-color: ${({theme}) => theme.noticeIconColor};
  border-radius: 30px;
  margin-right: 8px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconImage = styled(Image)`
  width: 24px;
  height: 24px;
`;

interface Props {
    notice: NoticeType<any>;
}

const NoticeTypeIcon: React.FC<Props> = ({notice}) => {

    const theme = useContext(ThemeContext);

    return (
        <IconBox>
            {notice.type === 'general' ? (
                <IconImage src={bellIcon} color={theme.subColor}/>
            ) : <></>}
            {notice.type === 'video' ? (
                <IconImage src={playIcon} color={theme.subColor}/>
            ) : <></>}
            {notice.type === 'event' ? (
                <IconImage src={eventIcon} color={theme.subColor}/>
            ) : <></>}
            {notice.type === 'qualification' ? (
                <IconImage src={qualificationIcon} color={theme.subColor}/>
            ) : <></>}
            {notice.type === 'contact' ? (
                <IconImage src={mailIcon} color={theme.subColor}/>
            ) : <></>}
            {notice.type === 'chat_group' ? (
                <IconImage src={snsIcon} color={theme.subColor}/>
            ) : <></>}
        </IconBox>
    )
};

export default NoticeTypeIcon;
