import React from 'react';
import styled from "styled-components";
import {TitleTextBox} from "../AppStyles";

const HeaderText = styled(TitleTextBox)`
  font-size: 16px;
  padding: 16px;
`;

const NoticeListHeader: React.FC = () => (
    <HeaderText>新着</HeaderText>
);

export default NoticeListHeader;
