import React, {useEffect} from 'react';
import PageHeader from "../../parts/Headers/PageHeader";
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {getPaymentEvent, getPaymentEventWithParams, resetRequestStatus} from "../../stores/payment_event";
import PaymentWebView from "../../parts/PaymentWebView";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import toastr from 'toastr';
import {useHistory, useLocation, useParams} from "react-router-dom";
import NonMemberEventParams from "../../interfaces/NonMemberEventParams";

const EventPaymentExec: React.FC = () => {

    const {payment} = useSelector(state => ({
        payment: state.payment_event,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const {id: eventId} = useParams<{ id: string; }>();
    const {state: {params} = {params: undefined}} = useLocation<{ params: NonMemberEventParams; }>();

    useEffect(() => {
        if (params) {
            dispatch(getPaymentEventWithParams({
                eventId: parseInt(eventId),
                params: params
            }));
        } else {
            dispatch(getPaymentEvent(parseInt(eventId)));
        }
    }, [dispatch]);

    useEffect(() => {
        if (payment.requestStatus === 'success') {
            dispatch(resetRequestStatus());
        }
        if (payment.requestStatus === 'failed') {
            toastr.error('お支払い情報の取得に失敗しました。');
            dispatch(resetRequestStatus());
            if (params) {
                history.push(`/events/${eventId}/payment/param`, {
                    old: params,
                });
            } else {
                history.goBack();
            }
        }
    }, [dispatch, history, payment.requestStatus]);

    return (
        <WithLoadingScreen
            loading={payment.requestStatus === 'pending'}>
            <PageHeader title={'お支払い'}/>
            <PaymentWebView
                params={payment.result}/>
        </WithLoadingScreen>
    );
};

export default EventPaymentExec;
