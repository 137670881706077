import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type DrawerState = {
    isOpen: boolean;
}

const initialState: DrawerState = {
    isOpen: false
};

export const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
            return {
                isOpen: action.payload
            }
        }
    }
});


export const {setIsDrawerOpen} = drawerSlice.actions;
