import React, {useCallback, useState} from "react";
import ChatMessageType from "../../interfaces/ChatMessageType";
import styled from "styled-components";
import menuIcon from '../../assets/icons/submenu.png';
import TouchableOpacity from "../TouchableOpacity";
import {TitleText} from "../AppStyles";
import editIcon from '../../assets/icons/sub_edit.png';
import deleteIcon from '../../assets/icons/sub_delete.png';
import pinIcon from '../../assets/icons/sub_pin.png';
import reportIcon from '../../assets/icons/sub_report.png';

const Wrap = styled.div`
  overflow: visible;

  @media screen and (min-width: 480px) {
    position: relative;
  }
`;

const MenuIcon = styled(TouchableOpacity)`
  height: 26px;
  width: 26px;
  border-radius: 13px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuIconImage = styled.img`
  height: 4px;
  width: 16px;
`;

const MenuBox = styled.div<{ show: boolean }>`
  display: block;
  background-color: white;
  border-radius: 8px 8px 0 0;
  z-index: 1000;
  position: fixed;
  top: auto;
  bottom: ${({show}) => show ? '0' : '-100%'};
  left: 0;
  right: 0;
  width: 100%;
  transition: all 200ms;

  @media screen and (min-width: 480px) {
    display: ${({show}) => show ? 'block' : 'none'};
    right: 0;
    top: 36px;
    bottom: auto;
    left: auto;
    width: 380px;
    max-width: calc(100vw - 24px);
    position: absolute;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 2px #D1D1D1;
  }

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: 8px;
    width: 10px; /* 影をつけるために線幅で設定せずにboxを使用する */
    height: 10px;
    transform: rotate(45deg); /* 回転 */
    background: white; /* 吹き出しの地と同じ色 */
    box-shadow: -1px -1px 2px 0 #D1D1D1;
    display: none;

    @media screen and (min-width: 480px) {
      display: block;
    }
  }
`;

const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const MenuListItem = styled.li`
  padding: 10px 16px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  cursor: pointer;
  transition: opacity 200ms;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }
`;

const MenuListItemIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const MenuListItemText = styled(TitleText)`
  margin-left: 8px;
`;

const CancelButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const CancelButton = styled(TouchableOpacity)`
  border: 1px solid ${({theme}) => theme.mainColor};
  border-radius: 20px;
  width: 200px;
  text-align: center;
  padding: 4px;
`;

const CancelButtonText = styled(TitleText)`
  color: ${({theme}) => theme.mainColor};
  font-weight: bold;
`;

const SpBlock = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);

  @media screen and (min-width: 480px) {
    display: none;
  }
`;

interface Props {
    message: ChatMessageType;
    onEdit: (message: ChatMessageType) => void;
    onDelete: (message: ChatMessageType) => void;
    onPin: (message: ChatMessageType) => void;
    onReport: (message: ChatMessageType) => void;
}

const ChatContextMenu: React.FC<Props> = ({message, onEdit, onDelete, onPin, onReport}) => {

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const onEditClick = useCallback(() => {
        setShowMenu(false);
        onEdit(message);
    }, [message, onEdit]);

    const onDeleteClick = useCallback(() => {
        setShowMenu(false);
        onDelete(message);
    }, [message, onDelete]);

    const onPinClick = useCallback(() => {
        setShowMenu(false);
        onPin(message);
    }, [message, onPin]);

    const onReportClick = useCallback(() => {
        setShowMenu(false);
        onReport(message);
    }, [message, onReport]);

    return (
        <Wrap>
            {showMenu ? (
                <SpBlock onClick={() => setShowMenu(false)}/>
            ) : <></>}
            <MenuIcon onClick={() => setShowMenu(!showMenu)}>
                <MenuIconImage src={menuIcon}/>
            </MenuIcon>
            <MenuBox show={showMenu}>
                <MenuList>
                    {message.is_own ? (
                        <MenuListItem onClick={onEditClick}>
                            <MenuListItemIcon src={editIcon}/>
                            <MenuListItemText>投稿の編集</MenuListItemText>
                        </MenuListItem>
                    ) : <></>}
                    {message.is_own || message.group.is_admin ? (
                        <MenuListItem onClick={onDeleteClick}>
                            <MenuListItemIcon src={deleteIcon}/>
                            <MenuListItemText>投稿を削除</MenuListItemText>
                        </MenuListItem>
                    ) : <></>}
                    {message.group.is_admin && message.parent_id === null ? (
                        <MenuListItem onClick={onPinClick}>
                            <MenuListItemIcon src={pinIcon}/>
                            <MenuListItemText>
                                {message.pin ? 'この投稿のトップ固定を解除する' : 'この投稿をグループのトップへ固定する'}
                            </MenuListItemText>
                        </MenuListItem>
                    ) : <></>}
                    <MenuListItem onClick={onReportClick}>
                        <MenuListItemIcon src={reportIcon}/>
                        <MenuListItemText>違反報告</MenuListItemText>
                    </MenuListItem>
                </MenuList>
                <CancelButtonBox>
                    <CancelButton onClick={() => setShowMenu(false)}>
                        <CancelButtonText>キャンセル</CancelButtonText>
                    </CancelButton>
                </CancelButtonBox>
            </MenuBox>
        </Wrap>
    );
};

export default ChatContextMenu;