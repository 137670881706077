import RequestStatusType from "../interfaces/RequestStatusType";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "../http/axios";
import RequestErrorType from "../interfaces/RequestErrorType";

export type ChatGroupCreateState = {
    requestStatus: RequestStatusType;
    errors: object;
}

const initialState: ChatGroupCreateState = {
    requestStatus: "init",
    errors: {},
};

type CreateChatGroupParams = {
    name: string;
    description: string;
};

export const createChatGroup = createAsyncThunk<void, CreateChatGroupParams>(
    'chat_group_create',
    async (parms, thunk) => {
        await axios.post('chat_groups', parms).catch(error => {
            thunk.dispatch(setErrors(error.response?.data?.errors || {}));
            throw error
        });
    }
);

export const chatGroupCreateSlice = createSlice({
    name: 'chat_group_create',
    initialState,
    reducers: {
        resetRequestStatus: state => ({...state, requestStatus: 'init'}),
        setErrors: (state, action: PayloadAction<RequestErrorType>) => ({...state, errors: action.payload})
    },
    extraReducers: builder => {
        builder
            .addCase(createChatGroup.fulfilled, state => ({
                ...state,
                requestStatus: 'success'
            }))
            .addCase(createChatGroup.pending, state => ({
                ...state,
                requestStatus: 'pending'
            }))
            .addCase(createChatGroup.rejected, state => ({
                ...state,
                requestStatus: 'failed'
            }))
    }
});

export const {resetRequestStatus, setErrors} = chatGroupCreateSlice.actions;
