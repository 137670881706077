import React, {useCallback, useContext, useEffect, useState} from 'react';
import ScreenView from '../../parts/ScreenView';
import {useSelector} from "../../store";
import InformationBox from "../../parts/RegisterInformation/InformationBox";
import FlatButton from "../../parts/FlatButton";
import {useDispatch} from "react-redux";
import {fetchUser, updateUser} from "../../stores/user";
import {ThemeContext} from "styled-components";
import {useHistory} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {usePostalToAddress} from "../../utils/postalToAddress";

const UpdateUserInformation: React.FC = () => {

    const {user} = useSelector(state => ({
        user: state.user
    }));

    const theme = useContext(ThemeContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const {resolvePostCode} = usePostalToAddress()

    const [attachment, setAttachment] = useState<string | null>(user.user?.attachment || null);
    const [email, setEmail] = useState<string>(user.user?.email || '');
    const [birthday, setBirthday] = useState<string | null>(user.user?.birthday || null);
    const [tel, setTel] = useState<string>(user.user?.tel || '');
    const [password, setPassword] = useState<string>('');
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const [oldProcessing, setOldProcessing] = useState<boolean>(false);
    const [postal, setPostal] = useState<string>(user.user?.postal || '');
    const [address, setAddress] = useState<string>(user.user?.address || '');

    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    useEffect(() => {
        if (oldProcessing && !user.isProcessing && Object.keys(user.errors).length === 0) {
            history.goBack();
        }
        setOldProcessing(user.isProcessing);
    }, [history, oldProcessing, user.errors, user.isProcessing]);

    const onResolve = useCallback(async () => {
        const result = await resolvePostCode(postal)
        setAddress(`${result.region}${result.locality}${result.street}${result.extended}`)
    }, [postal, resolvePostCode])

    const onSubmit = () => {
        dispatch(updateUser({
            ...user.user!!,
            attachment,
            email,
            birthday,
            tel,
            password,
            password_confirmation: passwordConfirmation,
            postal,
            address,
        }));
    };

    if (user.user === null) {
        return <></>;
    }

    return (
        <ScreenView scroll={true}>
            <Helmet>
                <title>会員情報変更</title>
            </Helmet>
            <InformationBox
                label={'生年月日'}
                value={birthday}
                errors={user.errors}
                name={'birthday'}
                editable={true}
                type={'date'}
                onInput={birthday => setBirthday(birthday)}/>
            <InformationBox
                label={'所属先(任意)'}
                value={attachment}
                errors={user.errors}
                name={'attachment'}
                editable={true}
                onInput={attachment => setAttachment(attachment)}/>
            <InformationBox
                label={'メールアドレス'}
                value={email}
                errors={user.errors}
                name={'email'}
                editable={true}
                type={'email'}
                onInput={email => setEmail(email)}/>
            <InformationBox
                label={'電話番号'}
                value={tel}
                errors={user.errors}
                name={'tel'}
                editable={true}
                onInput={tel => setTel(tel)}/>
            <InformationBox
                label={'郵便番号'}
                value={postal}
                errors={user.errors}
                name={'postal'}
                editable={true}
                onInput={postal => setPostal(postal)}/>
            <button type='button' onClick={onResolve}>
                郵便番号から住所を入力
            </button>
            <InformationBox
                label={'住所'}
                value={address}
                errors={user.errors}
                name={'address'}
                editable={true}
                onInput={address => setAddress(address)}/>
            <InformationBox
                label={'パスワード ※変更希望時のみ入力してください'}
                value={password}
                errors={user.errors}
                name={'password'}
                editable={true}
                type={'password'}
                onInput={password => setPassword(password)}/>
            <InformationBox
                label={'パスワード確認'}
                value={passwordConfirmation}
                errors={user.errors}
                name={'password_confirmation'}
                editable={true}
                type={'password'}
                onInput={password => setPasswordConfirmation(password)}/>
            <FlatButton
                text={'設定を保存'}
                style={{marginTop: 24}}
                disabled={user.isProcessing}
                backgroundColor={theme.subColor}
                onPress={onSubmit}/>
        </ScreenView>
    );
};

export default UpdateUserInformation;
