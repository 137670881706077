import React, {useEffect, useMemo} from 'react';
import {useSelector} from "../../store";
import {useDispatch} from "react-redux";
import {fetchEventTypes, fetchRecentEvents, fetchUserEvents} from "../../stores/event";
import EventList from "../../parts/EventTab/EventList";
import EventType from "../../interfaces/EventType";
import TabHeader from "../../parts/TabHeader";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import {useHistory} from "react-router-dom";
import {changeTab} from "../../stores/tab";
import {Helmet} from "react-helmet-async";
import eventIcon from '../../assets/icons/event.png';
import EventNoticeLink from "../../parts/EventTab/EventNoticeLink";

const EventTab: React.FC = () => {

    const history = useHistory();

    const {event} = useSelector(state => ({
        event: state.event
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeTab('event'));
        (async () => fetchEvents())();
    }, []);

    const fetchEvents = async () => {
        dispatch(fetchRecentEvents());
        dispatch(fetchUserEvents());
        dispatch(fetchEventTypes());
    };

    const onPressDetail = (event: EventType) => {
        history.push(`/events/${event.id}`);
    };

    const isLoading = useMemo(() => {
        return event.isFetching && event.userItems.length === 0 && event.recentItems.length === 0 && event.types.length === 0;
    }, [event.isFetching, event.userItems, event.recentItems, event.types]);

    return (
        <>
            <Helmet>
                <title>イベント</title>
            </Helmet>
            <WithLoadingScreen
                loading={isLoading}>
                <TabHeader source={eventIcon} title={'イベント一覧'}/>
                <EventNoticeLink/>
                <EventList event={event}
                           onPressItem={onPressDetail}/>
            </WithLoadingScreen>
        </>
    );
};

export default EventTab;
