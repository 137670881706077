import React from 'react';
import styled from "styled-components";
import AccordionView from "./AccordionView";
import EventType from "../../interfaces/EventType";
import ApplyEventListItem from "./ApplyEventListItem";
import {TitleTextBox} from "../AppStyles";

const Section = styled(AccordionView)`
  margin-bottom: 16px;
`;

const EmptyText = styled(TitleTextBox)`
  font-size: 16px;
  padding: 16px;
`;

interface Props {
    events: EventType[];
    onPressEvent: (event: EventType) => void;
    onRefresh: () => void;
}

const ApplyEventList: React.FC<Props> = ({events, onPressEvent, onRefresh}) => {
    return (
        <Section labelText={'申し込み中イベント一覧'} onOpenChange={open => open ? onRefresh() : null}>
            {events.length === 0 ? (
                <EmptyText>現在申し込み中のイベントはありません。</EmptyText>
            ) : <></>}
            {events.map(event => (
                <ApplyEventListItem
                    event={event}
                    key={`events.${event.id}`}
                    onPress={() => onPressEvent(event)}/>
            ))}
        </Section>
    )
};

export default ApplyEventList;
