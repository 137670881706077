import React, { useCallback, useEffect, useState } from 'react';
import styled from "styled-components";
import ScreenView from "../../parts/ScreenView";
import { useSelector } from "../../store";
import { useDispatch } from "react-redux";
import { getPaymentUserSelect, resetRequestStatus } from "../../stores/payment_user_select";
import MethodCheckBox from "../../parts/PurchaseMember/MethodCheckBox";
import CreditInformation from "../../parts/PurchaseMember/CreditInformation";
import FlatButton from "../../parts/FlatButton";
import WithLoadingScreen from "../../parts/WithLoadingScreen";
import StepHeader from "../../parts/Headers/StepHeader";
import { useHistory } from "react-router-dom";
import toastr from 'toastr';
import { Helmet } from "react-helmet-async";
import NoAuthHeader from "../../parts/Headers/NoAuthHeader";

const PurchaseButton = styled(FlatButton)`
  margin-top: 32px;
`;

const ContactComment = styled.p`
  margin-top: 32px;
  text-align: center;
`;

const PurchaseSelectNext: React.FC = () => {

    const { paymentSelect } = useSelector(state => ({
        paymentSelect: state.payment_user_select
    }));
    const history = useHistory();
    const dispatch = useDispatch();
    const [selectMethod, setSelectMethod] = useState<number>(0);

    useEffect(() => {
        dispatch(getPaymentUserSelect());
    }, []);

    useEffect(() => {
        if (paymentSelect.requestStatus === 'success') {
            dispatch(resetRequestStatus());
        }
        if (paymentSelect.requestStatus === 'failed') {
            toastr.error('お支払い情報を取得できませんでした')
            dispatch(resetRequestStatus());
        }
    }, [dispatch, paymentSelect.requestStatus]);

    const onPurchase = useCallback(() => {
        if (selectMethod === 0) {
            history.push('/payment/change/exec');
        }
    }, [history, selectMethod]);

    return (
        <>
            <Helmet>
                <title>お支払いプランの確認</title>
            </Helmet>
            <NoAuthHeader />
            <StepHeader title={'お支払いプランの確認'} step={'ステップ3/3'} />
            <WithLoadingScreen
                loading={paymentSelect.requestStatus === 'pending'}>
                <ScreenView scroll={true}>
                    <MethodCheckBox
                        title={'月額プラン（クレジットカードのみ）'}
                        price={paymentSelect.result?.credit || NaN}
                        isYear={false}
                        selected={selectMethod === 0}
                        onSelect={() => setSelectMethod(0)}
                    />
                    <CreditInformation />
                    <ContactComment>
                        支払いについてご不明な点がございましたら、ココリンク事務局（cocolink_support@astick0115.com）へお問い合わせ下さい。
                    </ContactComment>
                    <PurchaseButton
                        text={'お支払い手続きへ'}
                        onPress={onPurchase}
                        disabled={selectMethod === undefined} />
                </ScreenView>
            </WithLoadingScreen>
        </>
    );
};

export default PurchaseSelectNext;
