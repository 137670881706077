import React from "react";
import ChatUserType from "../../interfaces/ChatUserType";
import UserType from "../../interfaces/UserType";
import styled from "styled-components";
import Image from "../Image";
import {BodyTextBox, ContentWrap, TitleTextBox} from "../AppStyles";
import nl2br from "../../utils/nl2br";

const Section = styled.div`
`;

const UserIconBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
`;

const UserImage = styled(Image)`
  height: 90px;
  width: 90px;
  border-radius: 30px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 480px) {
    height: 60px;
    width: 60px;
  }
`;

const UserTextBox = styled.div`
  margin-top: 8px;
  
  @media screen and (min-width: 480px) {
    margin-top: 0;
    margin-left: 8px;
  }
`;

const UserName = styled(TitleTextBox)`
  font-size: 20px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
`;

const Wrap = styled.div`
  border-top: 1px solid ${({theme}) => theme.borderColor};
  margin-top: 24px;
`;

const BorderBox = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  padding: 16px 0;
`;

const FlexContent = styled(ContentWrap)<{ alignItems?: string }>`
  display: flex;
  flex-direction: row;
  align-items: ${({alignItems}) => alignItems ?? 'center'};
  padding: 0 8px;

  @media screen and (min-width: 480px) {
    padding: 0;
  }
`;

const Title = styled(TitleTextBox)`
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  width: 100px;
`;

const Content = styled(BodyTextBox)`
  font-size: 14px;
  color: ${({theme}) => theme.textColor};
`;

interface Props {
    user: ChatUserType | UserType;
}

const ChatProfileView: React.FC<Props> = ({user}) => {
    return (
        <Section>
            <ContentWrap>
                <UserIconBox>
                    <UserImage src={user.profile_url || 'https://placehold.jp/100x100.png'}/>
                    <UserTextBox>
                        <UserName>{user.nickname}</UserName>
                    </UserTextBox>
                </UserIconBox>
            </ContentWrap>
            <Wrap>
                <BorderBox>
                    <FlexContent>
                        <Title>職種</Title>
                        <Content>{user.industry}</Content>
                    </FlexContent>
                </BorderBox>
                <BorderBox>
                    <FlexContent>
                        <Title>都道府県</Title>
                        <Content>{user.pref ?? '未設定'}</Content>
                    </FlexContent>
                </BorderBox>
                <BorderBox>
                    <FlexContent alignItems={'flex-start'}>
                        <Title>自己紹介</Title>
                        <Content>{nl2br(user.introduction ?? '未設定')}</Content>
                    </FlexContent>
                </BorderBox>
            </Wrap>
        </Section>
    )
};

export default ChatProfileView;