import VideoWatchLogType from "../interfaces/VideoWatchLogType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type VideoWatchLogState = {
    items: VideoWatchLogType[];
    isFetching: boolean;
};

const initialState: VideoWatchLogState = {
    items: [],
    isFetching: false
};

export const fetchVideoWatchLog = createAsyncThunk<FetchReturnType<VideoWatchLogType>>(
    'video_watch_logs/fetch',
    async () => {
        const {data} = await axios.get('user/video_watch_logs');
        return data as FetchReturnType<VideoWatchLogType>;
    }
);

type SendVideoWatchLogParams = {
    videoId: number;
    position: number;
    duration: number;
};

export const sendVideoWatchLog = createAsyncThunk<void, SendVideoWatchLogParams>(
    'video_watch_logs/send',
    async ({videoId, position, duration}) => {
        await axios.post('user/video_watch_logs', {
            video_id: videoId,
            position: position,
            duration: duration
        });
    }
);

export const videoWatchLogSlice = createSlice({
    name: 'video_watch_log',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchVideoWatchLog.fulfilled, (state, action) => ({
                ...state,
                items: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchVideoWatchLog.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchVideoWatchLog.rejected, state => ({
                ...state,
                isFetching: false,
                items: []
            }))
    }
});
