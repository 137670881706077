import React from 'react';
import ChatGroupType from "../../interfaces/ChatGroupType";
import styled from "styled-components";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import TouchableOpacity from "../TouchableOpacity";
import Icon from "../Icon";

const Box = styled(TouchableOpacity)`
  border-top-width: 1px;
  border-top-color: #D1D1D1;
  border-radius: 2px;
  padding: 16px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
`;

const Title = styled(TitleTextBox)`
  font-size: 16px;
  flex: 1;
  color: ${({theme}) => theme.mainColor};
  font-weight: bold;
`;

const MemberText = styled(BodyTextBox)`
  color: ${({theme}) => theme.textGrayColor};
  font-weight: normal;
  font-size: 14px;
`;

interface Props {
    chatGroup: ChatGroupType;
    onPress: () => void;
}

const ChatGroupListItem: React.FC<Props> = ({chatGroup, onPress}) => {
    return (
        <Box onClick={onPress}>
            <Title>{chatGroup.name}<MemberText>（メンバー{chatGroup.users_count}人）</MemberText></Title>
            <Icon name={'angle-right'} size={24} color={'#212121'}/>
        </Box>
    )
};

export default ChatGroupListItem;
