import React from "react";
import InvoiceType from "../../interfaces/InvoiceType";
import styled from "styled-components";
import {Link} from "react-router-dom";

const Background = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: #EF4642;
  text-align: center;
`;

const PurchaseLink = styled(Link)`
  color: #FDF5A7;
  font-weight: bold;
  font-size: 20px;

  &:active, &:visited, &:hover {
    color: #FDF5A7;
  }
`;

const WarningText = styled.p`
  color: white;
  margin-bottom: 0;
  line-height: 1.2em;
`;

interface Props {
    invoice: InvoiceType | null;
}

const InvoiceAlert: React.FC<Props> = ({invoice}) => {
    if (invoice === null) {
        return <></>;
    }

    if (invoice.type === 'credit') {
        return (
            <Background>
                <PurchaseLink to={'/payment/change/exec'}>利用料のお支払い＞</PurchaseLink>
                <WarningText>
                    ご登録のクレジットカードで利用料の引き落としが行えませんでした。<br/>
                    お手数ですがクレジットカードの変更手続きをお願いいたします。
                </WarningText>
            </Background>
        );
    } else {
        return (
            <Background>
                <PurchaseLink to={'/purchase'}>年会費のお支払い＞</PurchaseLink>
                <WarningText>
                    ココリンクの次年度年会費が未払いです。<br/>
                    手続きをお願いいたします。
                </WarningText>
            </Background>
        );
    }
};

export default InvoiceAlert;