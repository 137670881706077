import React from "react";
import ChatMessageType from "../../interfaces/ChatMessageType";
import styled from "styled-components";
import pinIcon from "../../assets/icons/sub_pin.png";
import {BodyText} from "../AppStyles";

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PinImage = styled.img`
  width: 14px;
  height: 14px;
`;

const Text = styled(BodyText)`
  font-weight: bold;
  font-size: 14px;
  margin-left: 8px;
`;

interface Props {
    message: ChatMessageType;
    show: boolean;
}

const ChatPinView: React.FC<Props> = ({message, show}) => {
    if (!message.pin || !show) {
        return <></>
    }

    return (
        <Section>
            <PinImage src={pinIcon}/>
            <Text>固定された投稿</Text>
        </Section>
    )
};

export default ChatPinView;