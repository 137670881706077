import InvoiceType from "../interfaces/InvoiceType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchDetailReturnType} from "../interfaces/FetchReturnType";
import axios from "../http/axios";

export type InvoiceState = {
    invoice: InvoiceType | null;
    isFetching: boolean;
};

const initialState: InvoiceState = {
    invoice: null,
    isFetching: false
}

export const fetchInvoice = createAsyncThunk<FetchDetailReturnType<InvoiceType | null>>(
    'fetch/invoice',
    async () => {
        const {data} = await axios.get('invoices/check');
        return data as FetchDetailReturnType<InvoiceType | null>;
    }
)

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchInvoice.fulfilled, (state, action) => ({
                ...state,
                invoice: action.payload.data,
                isFetching: false
            }))
            .addCase(fetchInvoice.pending, state => ({
                ...state,
                isFetching: true
            }))
            .addCase(fetchInvoice.rejected, state => ({
                ...state,
                isFetching: false,
            }))
    }
});