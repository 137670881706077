import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import VideoType from "../interfaces/VideoType";
import axios from "../http/axios";
import {FetchReturnType} from "../interfaces/FetchReturnType";

export type VideoState = {
    items: VideoType[];
    isFetching: boolean;
    selectCategoryId?: number;
};

const initialState: VideoState = {
    items: [],
    isFetching: false,
    selectCategoryId: undefined
};

export const fetchVideos = createAsyncThunk<FetchReturnType<VideoType>, number>(
    'videos/fetch',
    async (categoryId) => {
        const {data} = await axios.get(`categories/${categoryId}/videos`);
        return data as FetchReturnType<VideoType>;
    }
);

export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchVideos.fulfilled, (state, action) => {
                return {
                    ...state,
                    items: action.payload.data,
                    isFetching: false,
                    selectCategoryId: action.meta.arg,
                }
            })
            .addCase(fetchVideos.pending, (state, action) => {
                if (action.meta.arg === state.selectCategoryId) {
                    return {
                        ...state,
                        isFetching: true
                    }
                } else {
                    return {
                        ...state,
                        items: [],
                        isFetching: true
                    }
                }
            })
            .addCase(fetchVideos.rejected, (state) => {
                return {
                    ...state,
                    items: [],
                    isFetching: false
                }
            })
    }
});


