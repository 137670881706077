import React, {useContext} from 'react';
import styled, {ThemeContext} from "styled-components";
import ArrowIcon from "../ArrowIcon";
import {TitleText} from "../AppStyles";
import guideImage from '../../assets/icons/guide.png';
import TouchableOpacity from "../TouchableOpacity";

const Box = styled(TouchableOpacity)`
  background-color: ${({theme}) => theme.whiteColor};
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 24px 24px 0;
  padding: 12px 32px;
  position: relative;
`;

const IconImage = styled.img`
  height: 30px;
  width: 30px;
`;

const Title = styled(TitleText)`
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.mainColor};
  text-align: center;
`;

const ArrowIconRight = styled(ArrowIcon)`
  position: absolute;
  right: 16px;
`;

interface Props {
    onPress: () => void;
}

const GuideButton: React.FC<Props> = ({onPress}) => {
    const theme = useContext(ThemeContext);
    return (
        <Box onClick={onPress}>
            <IconImage src={guideImage}/>
            <Title>
                ココリンク | 使い方ガイド
            </Title>
            <ArrowIconRight size={16} color={theme.mainColor}/>
        </Box>
    )
};

export default GuideButton;
