import React, {FormEvent, useContext, useState} from 'react';
import styled, {ThemeContext} from "styled-components";
import Icon from "../Icon";

const Box = styled.form`
  margin-top: 16px;
  border: 1px solid ${({theme}) => theme.subColor};
  border-radius: 16px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  @media screen and (min-width: 480px) {
    margin-top: 0;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const SearchIcon = styled(Icon)`
`;

const TextArea = styled.input`
  padding: 8px;
  flex: 1;
  background: transparent;
  border: none;
`;

interface Props {
    onSearch: (text: string) => void;
    initialSearchText: string;
}

const VideoSearchText: React.FC<Props> = ({onSearch, initialSearchText}) => {
    const theme = useContext(ThemeContext);
    const [searchText, setSearchText] = useState<string>(initialSearchText);

    const onSubmit = (event: FormEvent) => {
        onSearch(searchText);
        event.preventDefault();
    };

    return (
        <Box onSubmit={onSubmit}>
            <SearchIcon name={'search'} color={theme.subColor} size={16}/>
            <TextArea
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                placeholder={'講師名・キーワードで検索'}
                maxLength={100}
            />
        </Box>
    )
};

export default VideoSearchText;
