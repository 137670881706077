import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {isColorful, tintData} from 'react-image-tint/lib/utils';

interface ReactImageTintProps {
    src: string;
    color?: string;
    alt?: string;
    cache?: boolean;
    className?: string;
}

const Image: React.FC<ReactImageTintProps> = (props) => {
    const {src, color, alt, cache, className} = props;
    const [mounted, setMounted] = useState<boolean>(false);
    const [tintSrc, setTintSrc] = useState<string>();
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, []);

    useEffect(() => {
        (async () => {
            if (!mounted || color === undefined) {
                return;
            }
            if (src.indexOf !== undefined && !isColorful(src) && imageRef.current) {
                const tintSrc = await tintData(imageRef.current, color, {cache: Boolean(cache)});
                setTintSrc(tintSrc);
            }
        })();
    }, [cache, color, src, mounted]);

    return (
        <div className={className}>
            <img
                src={tintSrc || src}
                alt={alt}
                ref={imageRef}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                }}
            />
        </div>
    );
};

export default Image;