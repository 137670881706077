import React, {useContext} from 'react';
import EventType from "../../interfaces/EventType";
import styled, {ThemeContext} from "styled-components";
import SectionLabel from "../SectionLabel";
import {BodyTextBox, TitleTextBox} from "../AppStyles";
import Icon from "../Icon";
import EventHtmlView from "../EventHtmlView";

const InformationBox = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

const Title = styled(TitleTextBox)`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  padding: 0 16px;
  color: ${({theme}) => theme.mainColor};
`;

const ApplyInformation = styled.div`
  border-top-width: 1px;
  border-top-color: ${({theme}) => theme.borderColor};
  margin: 16px 16px 32px 16px;
  padding-top: 32px;
`;

const DateTime = styled(BodyTextBox)`
  color: ${({theme}) => theme.mainColor};
  font-size: 20px;
  font-weight: bold;
  margin-top: 16px;
`;

const DetailBox = styled.div`
  border-radius: 8px;
  margin-top: 16px;

  & > div {
    margin-top: 8px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LineCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.subColor};
`;

const LineText = styled(BodyTextBox)`
  margin-left: 8px;
  font-weight: bold;
  font-size: 14px;
`;

const QualificationBox = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QualificationIcon = styled(Icon)`
  margin-right: 4px;
`;

const QualificationText = styled(BodyTextBox)`
  color: ${({theme}) => theme.textGrayColor};
`;

const QualificationDescription = styled(BodyTextBox)`
  margin-top: 8px;
  color: ${({theme}) => theme.textGrayColor};
`;

const TypeLabel = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

const TypeLabelText = styled.div`
  background-color: ${({theme}) => theme.mainColor};
  color: white;
  padding: 4px 16px;
  font-weight: bold;
  border-radius: 8px;
`;

interface Props {
    event: EventType;
}

const EventInformation: React.FC<Props> = ({event}) => {
    const theme = useContext(ThemeContext);
    return (
        <InformationBox>
            <Title>{event.name}</Title>
            <ApplyInformation>
                <SectionLabel title={'開催日程'}/>
                <DateTime>
                    {event.published_at}
                    {event.start_time || event.end_time ? (
                        <>{event.start_time} - {event.end_time}</>
                    ) : <></>}
                    {event.opening_time && (event.start_time !== event.opening_time) ? (
                        <> (開場 {event.opening_time})</>
                    ) : <></>}
                </DateTime>
                <TypeLabel>
                    <TypeLabelText>
                        {event.event_type}
                    </TypeLabelText>
                </TypeLabel>
                <DetailBox>
                    <Line>
                        <LineCircle/>
                        <LineText>
                            {event.place}<br/>
                            {event.place_address}
                        </LineText>
                    </Line>
                    {event.place_email ? (
                        <Line>
                            <LineCircle/>
                            <LineText>メール: {event.place_email}</LineText>
                        </Line>
                    ) : <></>}
                    {event.place_tel ? (
                        <Line>
                            <LineCircle/>
                            <LineText>TEL: {event.place_tel}</LineText>
                        </Line>
                    ) : <></>}
                    {event.place_person ? (
                        <Line>
                            <LineCircle/>
                            <LineText>担当者名: {event.place_person}</LineText>
                        </Line>
                    ) : <></>}
                    {event.place_organizer ? (
                        <Line>
                            <LineCircle/>
                            <LineText>主催者: {event.place_organizer}</LineText>
                        </Line>
                    ) : <></>}
                    <Line>
                        <LineCircle/>
                        <LineText>申し込み人数 {event.applicants}（人）</LineText>
                    </Line>
                </DetailBox>
                {event.qualifications.map(qualification => {
                    return qualification.unit > 0 ? (
                      <div key={qualification.qualification_id}>
                          <QualificationBox>
                              <QualificationIcon name="medal" color={theme.mainColor} size={14}/>
                              <QualificationText>
                                  {qualification.qualification_name} {qualification.unit}単位
                              </QualificationText>
                          </QualificationBox>
                          <QualificationDescription>
                              このセミナーは{qualification.qualification_name}の更新単位を獲得できます。
                          </QualificationDescription>
                      </div>
                    ) : (
                      <QualificationBox key={qualification.qualification_id}>
                          <QualificationText>{qualification.qualification_name}</QualificationText>
                      </QualificationBox>
                    )
                })}
            </ApplyInformation>
            {event.description ? (
                <EventHtmlView html={event.description}/>
            ) : <></>}
        </InformationBox>
    )
};

export default EventInformation;
